import React from 'react';
import FormFrame from "features/ui/FormFrame";
import FormRow from "features/ui/FormRow";
import OffersSelect from "features/offers/lib/OffersSelect";
import { WithTranslation, withTranslation } from "react-i18next";
import Input from "features/ui/Input";
import { Button } from "reactstrap";
import { FormikProps } from "formik";

class Form extends React.Component<FormikProps<{}> & WithTranslation>{

  onOfferChange = (value: number) => {
    const {
      setFieldValue,
    } = this.props;
    setFieldValue('offer', value)
  }

  handleChangeEmails = (event) => {
    const { value } = event.target;
    const {
      setFieldValue,
    } = this.props;

    setFieldValue('emails', value);
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {
      validateForm,
      submitForm,
    } = this.props;
    validateForm().then(submitForm);
    return false;
  }

  render() {
    const {
      values,
      handleBlur,
      t,
    } = this.props;

    return (
      <FormFrame
        {...this.props}
        submitBtn={false}
        >
        <FormRow label={t('offer')} id="offer" required>
          <OffersSelect
            isMulti={false}
            id="offer"
            name="offer"
            value={values.offer}
            onChange={this.onOfferChange}
          />
        </FormRow>
        <FormRow
          id="emails"
          label={t('emails')}
          key="emails"
          text="email@example.com, email@example.com"
          required
          >
          <Input
            value={values.emails}
            onChange={this.handleChangeEmails}
            onBlur={handleBlur}
            type="textarea"
            id="emails"
            name="emails"
          />
        </FormRow>
        <Button
          type="submit"
          color="danger"
          onClick={this.onSubmit}>Удалить</Button>
      </FormFrame>
    )
  }
}

export default withTranslation('usersmanagement')(Form);
