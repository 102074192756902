import React from 'react';
import {
  withTranslation,
  WithTranslation,
} from 'react-i18next';
import CardPage from 'features/ui/Page/CardPage';
import BadgeForm from '../lib/BadgeForm';
import { makeBadgeUrl } from '../lib/url';
import { FEATURE_ICON } from '../consts';

const PageCreate = ({ t }: WithTranslation) => {
  return (
    <CardPage
      icon={FEATURE_ICON}
      title={t('new_badge')}
      crumbs={[{ link: makeBadgeUrl(), name: t('badges') }]}
    >
      <BadgeForm />
    </CardPage>
  );
};

export default withTranslation('badges')(PageCreate);
