import React from 'react';
import i18n from 'features/intl/i18n';
import cn from 'classnames';

import styles from './styles.module.scss';

export interface IOnOffIndicator {
  value: boolean;
  on?: string;
  off?: string;
}

export default ({ value, on, off }: IOnOffIndicator) => {
  const text = i18n.t(value ? on || 'dict:on' : off || 'dict:off');
  const color = value ? styles.on : styles.off;
  return (
    <span className={cn(styles.root, color)}>
      {text}
    </span>
  );
};
