import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';

const colorStatusCode: {[key: string]: string} = {
  new: 'text-info',
  pending: 'text-warning',
  paid: 'text-success',
  failed: 'text-danger',
  cancelled: 'text-danger',
};

export default withTranslation('orders')(
  (props: WithTranslation & { value: string }) => {
    const {
      t,
      value,
    } = props;
    return (
      <span className={colorStatusCode[value]}>
        {t(`orders:${value}`)}
      </span>
    );
  },
);
