import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';

interface IRoleText extends WithTranslation {
  role: string;
}

const RoleText = (props: IRoleText) => {
  const {
    t,
    role,
  } = props;
  return (
    <React.Fragment>
      {t(role)}
    </React.Fragment>
  );
};

export default withTranslation('users')(RoleText);
