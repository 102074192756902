import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { flow } from 'lodash';
import {
  match as IMatch,
  Redirect,
} from 'react-router';
import CardPage from 'features/ui/Page/CardPage';
import Spinner from 'features/ui/Spinner';
import LinkBlock from 'features/ui/LinkBlock';
import deepOmit from 'lib/deepOmit';
import UserSubscriptionForm from '../lib/UserSubscriptionForm';

import {
  makeUsersSubscriptionUrl,
  makeUsersSubscriptionUrlTo,
} from '../lib/url';
import getUserSubscription from '../queries/getUserSubscription.gql';
import { FEATURE_ICON } from '../consts';

import { IUserSubscription } from '../types';

interface IPageUserSubscriptionProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  userSubscription: IUserSubscription;
}

class PageEdit extends React.Component<IPageUserSubscriptionProps> {
  render() {
    const {
      t,
      userSubscription,
      isLoading,
    } = this.props;
    if (!isLoading && !userSubscription) {
      return <Redirect to="/404" />;
    }
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={userSubscription ? `${t('edit_user_subscription')}: ${userSubscription.id}` : ''}
        crumbs={[{ link: makeUsersSubscriptionUrl(), name: t('user_subscriptions') }]}
      >
        <LinkBlock viewLink={makeUsersSubscriptionUrlTo(userSubscription, 'view')} />
        <UserSubscriptionForm userSubscription={userSubscription} />
      </CardPage>
    );
  }
}

const mapResultsToProps = (props: OptionProps<IUserSubscription>) => {
  const { data, ownProps } = props;
  const userSubscription = data && data.UserSubscriptionQuery ? deepOmit(data.UserSubscriptionQuery.items[0], '__typename') as IUserSubscription : null;
  return {
    userSubscription,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphql(getUserSubscription, {
    props: mapResultsToProps,
    options: ({ match }: { match: IMatch<any> }) => {
      const { params } = match;
      const { id } = params || { id: 0 };
      return {
        context: {
          isGlobalLoading: true,
        },
        variables: {
          id: [id],
        },
      };
    },
  }),
  withTranslation('user_subscriptions'),
)(PageEdit);
