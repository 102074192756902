import React from 'react';
import { Col, Row } from 'reactstrap';
import Switch from 'features/ui/Switch';
import { FormikProps } from 'formik';

interface IFieldSwitcherProps {
  name: string;
  offValue: any;
  form: FormikProps<any>;
}

interface IFieldSwitcherState {
  checked: boolean;
}

export default class FieldSwitcher extends React.Component<IFieldSwitcherProps, IFieldSwitcherState> {
  constructor(props: IFieldSwitcherProps) {
    super(props);
    const { form, name, offValue } = this.props;
    const value = form.values[name];

    this.state = {
      checked: value !== undefined && value !== null && value !== offValue,
    };
  }

  handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { offValue, form, name } = this.props;
    const {
      checked,
    } = e.currentTarget;

    if (!checked) {
      form.setFieldValue(name, offValue);
    }

    this.setState({
      checked,
    });
  };

  render() {
    const { children } = this.props;
    const { checked } = this.state;

    return (
      <Row>
        <Col xs="1">
          <Switch
            checked={checked}
            onChange={this.handleChange}
          />
        </Col>
        <Col xs="11">
          {checked ? children : null}
        </Col>
      </Row>
    );
  }
}
