import React from 'react';
import { Link } from 'react-router-dom';
import { makeItemUrl } from 'features/items/lib/url';
import { ICategory } from '../../types';

export default ({ category }: { category: ICategory }) => {
  return (
    <Link to={makeItemUrl({ query: { categories: category.id } })}>
      Перейти к итемам этой категории ({category.items.length})
    </Link>
  );
};
