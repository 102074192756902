import React from 'react';
import {
  Alert,
} from 'reactstrap';

import styles from './styles.module.scss';

interface IFormFieldFeedback {
  color: string;
  text: string;
}

export default (props: IFormFieldFeedback) => {
  const {
    color,
    text,
  } = props;

  return (
    <Alert color={color} className={styles.invalidTooltip}>{text}</Alert>
  );
};
