import React from 'react';
import { stubTrue } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { formFieldsBuilder } from 'features/ui/FormFields';
import FormFrame from 'features/ui/FormFrame';
import ExternalsTable from './ExternalsTable';
import ModelsTable, { IModel } from './ModelsTable';
import {
  IOfferSettingsBuildExternalFormValues,
  IOfferSettingsBuildFormValues,
} from '../types';
import styles from './styles.module.scss';

const cssTransitions = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive,
};

interface IFormProps extends FormikProps<IOfferSettingsBuildFormValues>, WithTranslation {
  id?: string;
}

interface IFormState {
  boundFields: {[key: string]: boolean};
}

const Android = formFieldsBuilder({
  labelPrefix: 'build_',
  namePrefix: 'android_',
  useLabel: false,
});

const Ios = formFieldsBuilder({
  labelPrefix: 'build_',
  namePrefix: 'ios_',
  useLabel: false,
});

class BaseForm extends React.Component<IFormProps, IFormState> {
  static getDerivedStateFromState(props: IFormProps) {
    return {
      boundFields: BaseForm.prepareState(props),
    };
  }

  static prepareState(props: IFormProps) {
    const boundFields: {[key: string]: boolean} = {};
    [
      'bundle_id',
      'features_corporate',
      'features_in_app_purchase',
      'features_books',
      'features_content_table',
      'features_documents',
      'features_banners',
      'features_badges',
      'features_wishlist',
      'features_comments',
      'features_push_notification',
      'features_local_notification',
      'features_required_login',
      'features_socials',
      'features_pins',
      'features_user_groups',
      'features_analytic',
      'features_app_rate',
      'interface_title_showcase',
      'interface_title_my_collection',
      'interface_title_my_documents',
      'branding_title',
      'branding_splash_title',
      'branding_splash_subtitle',
      'branding_primary_color',
      'branding_bar_color',
      'branding_selected_button_color',
      'branding_unselected_button_color',
      'branding_background_splash_color',
      'branding_splash_text_color',
      'branding_splash_subtitle_color',
    ].forEach((key) => {
      const { values } = props;
      boundFields[key] = values[`android_${key}`] === values[`ios_${key}`];
    });
    return boundFields;
  }

  constructor(props: IFormProps) {
    super(props);
    this.state = {
      boundFields: BaseForm.prepareState(props),
    };
  }

  handleCloseIosInput = (name: string) => {
    const { values } = this.props;
    const { boundFields } = this.state;
    if (boundFields[name]) {
      this.props.setFieldValue(`ios_${name}`, values[`android_${name}`]);
    }
  }

  handleErrorMessage = (name: string): string | undefined => {
    const { errors } = this.props;
    return errors[`android_${name}`] || errors[`ios_${name}`];
  }

  onClickIosIcon = (event: React.SyntheticEvent) => {
    const name = event.currentTarget.getAttribute('data-name');
    const { boundFields } = this.state;
    if (name) {
      this.setState(
        {
          boundFields: {
            ...boundFields,
            [name]: !boundFields[name],
          },
        },
        () => this.handleCloseIosInput(name),
      );
    }
  }

  onChangeBothSwitch = (e: React.ChangeEvent<any>) => {
    const {
      checked,
      name,
    } = e.currentTarget;
    this.props.setFieldValue(name, checked);
    this.props.setFieldValue(name.replace('android_', 'ios_'), checked);
  }

  onChangeBothColor = (name: string, value: string) => {
    this.props.setFieldValue(name, value);
    this.props.setFieldValue(name.replace('android_', 'ios_'), value);
  }

  onChangeBothText = (e: React.ChangeEvent<any>) => {
    const {
      value,
      name,
    } = e.currentTarget;
    this.props.setFieldValue(name, value);
    this.props.setFieldValue(name.replace('android_', 'ios_'), value);
  }

  onChangeExternals = (externals: IOfferSettingsBuildExternalFormValues[]) => {
    this.props.setFieldValue('externals', externals);
  }

  onChangeSocials = (socials: IModel[]) => {
    this.props.setFieldValue('socials', socials);
  }

  onChangeAnalytics = (analytics: IModel[]) => {
    this.props.setFieldValue('analytics', analytics);
  }

  renderIosIcon(name: string, className?: string) {
    return (
      <i
        className={cn('fa', 'fa-apple', 'fa-lg', className)}
        onClick={this.onClickIosIcon}
        data-name={name}
      />
    );
  }

  renderText(name: string) {
    const form = this.props;
    const { boundFields } = this.state;
    const shouldHandleBoth = boundFields[name];
    return (
      <Android.Row
        name={name}
        form={form}
        shouldUpdate={stubTrue}
      >
        <div className={styles.row}>
          <Android.Input
            name={name}
            type="text"
            form={form}
            onChange={shouldHandleBoth ? this.onChangeBothText : null}
          />
          <i className="fa fa-android fa-lg" />
          <CSSTransition
            in={!shouldHandleBoth}
            classNames={cssTransitions}
            unmountOnExit
            timeout={300}
          >
            <Ios.Input name={name} type="text" form={form} />
          </CSSTransition>
          {this.renderIosIcon(name)}
        </div>
      </Android.Row>
    );
  }

  renderSwitch(name: string) {
    const form = this.props;
    const { boundFields } = this.state;
    const shouldHandleBoth = boundFields[name];
    const ios = (
      <Ios.Switch name={name} form={form} />
    );
    return (
      <Android.Row
        name={name}
        form={form}
        shouldUpdate={stubTrue}
      >
        <div className={styles.row}>
          <Android.Switch
            name={name}
            form={form}
            onChange={shouldHandleBoth ? this.onChangeBothSwitch : undefined}
          />
          <i className={cn('fa', 'fa-android', 'fa-lg', styles.shiftSwitchIcon)} />
          {shouldHandleBoth ? null : ios}
          {this.renderIosIcon(name, styles.shiftSwitchIcon)}
        </div>
      </Android.Row>
    );
  }

  renderColor(name: string) {
    const form = this.props;
    const { errors } = form;
    const { boundFields } = this.state;
    const shouldHandleBoth = boundFields[name];
    const ios = (
      <Ios.Color name={name} form={form} />
    );
    const rowStyles = cn([
      styles.row,
      errors[`android_${name}`] && styles.andriodErrorColor,
      errors[`ios_${name}`] && styles.iosErrorColor,
    ]);
    return (
      <Android.Row
        name={name}
        form={form}
        error={this.handleErrorMessage(name)}
        shouldUpdate={stubTrue}
      >
        <div className={rowStyles}>
          <Android.Color
            name={name}
            form={form}
            onChangeColor={shouldHandleBoth ? this.onChangeBothColor : undefined}
          />
          <i className="fa fa-android fa-lg" />
          {shouldHandleBoth ? null : ios}
          {this.renderIosIcon(name)}
        </div>
      </Android.Row>
    );
  }

  renderAndroidColor(name: string) {
    const form = this.props;
    return (
      <Android.Row
        name={name}
        form={form}
      >
        <div className={styles.row}>
          <Android.Color name={name} form={form} />
          <i className="fa fa-android fa-lg" />
        </div>
      </Android.Row>
    );
  }

  render () {
    const form = this.props;
    return (
      <FormFrame
        cancelLink={'/offers'}
        {...this.props}
      >
        {this.renderText('bundle_id')}
        <hr />
        {this.renderSwitch('features_corporate')}
        {this.renderSwitch('features_in_app_purchase')}
        {this.renderSwitch('features_books')}
        {this.renderSwitch('features_content_table')}
        {this.renderSwitch('features_documents')}
        {this.renderSwitch('features_banners')}
        {this.renderSwitch('features_badges')}
        {this.renderSwitch('features_wishlist')}
        {this.renderSwitch('features_comments')}
        {this.renderSwitch('features_push_notification')}
        {this.renderSwitch('features_local_notification')}
        {this.renderSwitch('features_required_login')}
        {this.renderSwitch('features_socials')}
        {this.renderSwitch('features_pins')}
        {this.renderSwitch('features_user_groups')}
        {this.renderSwitch('features_analytic')}
        {this.renderSwitch('features_app_rate')}
        <hr />
        {this.renderText('interface_title_showcase')}
        {this.renderText('interface_title_my_collection')}
        {this.renderText('interface_title_my_documents')}
        <hr />
        {this.renderText('branding_title')}
        {this.renderText('branding_splash_title')}
        {this.renderText('branding_splash_subtitle')}
        {this.renderColor('branding_primary_color')}
        {this.renderColor('branding_bar_color')}
        {this.renderColor('branding_selected_button_color')}
        {this.renderColor('branding_unselected_button_color')}
        {this.renderColor('branding_background_splash_color')}
        {this.renderColor('branding_splash_text_color')}
        {this.renderColor('branding_splash_subtitle_color')}
        {this.renderAndroidColor('branding_primary_dark_color')}
        {this.renderAndroidColor('branding_primary_light_color')}
        {this.renderAndroidColor('branding_accent_color')}
        {this.renderAndroidColor('branding_accent_dark_color')}
        {this.renderAndroidColor('branding_text_primary_color')}
        {this.renderAndroidColor('branding_secondary_text_color')}
        {this.renderAndroidColor('branding_icons_color')}
        {this.renderAndroidColor('branding_divider_color')}
        {this.renderAndroidColor('branding_background_light_color')}
        <hr />
        <ExternalsTable
          externals={form.values.externals}
          errors={form.errors.externals}
          onChange={this.onChangeExternals}
        />
        <hr />
        <br />
        <ModelsTable
          serviceName="socials"
          models={form.values.socials}
          errors={form.errors.socials}
          onChange={this.onChangeSocials}
        />
        <hr />
        <br />
        <ModelsTable
          serviceName="analytics"
          models={form.values.analytics}
          errors={form.errors.analytics}
          onChange={this.onChangeAnalytics}
        />
        <hr />
      </FormFrame>
    );
  }
}

export default withTranslation('offers')(BaseForm);
