import React from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { minIntValidator } from 'lib/validators';
import { contentValidator } from 'features/items/lib/validators';
import ItemForm from 'features/items/lib/Form';
import BaseForm from './Form';

import getVideoQuery from 'features/videos/queries/getVideoQuery.gql';

import { IFormValues } from 'features/videos/types';
import { IItem } from 'features/types';
import field from "lib/field";

interface IFormProps extends WithTranslation {
  data?: IItem;
}

class Form extends React.PureComponent<IFormProps> {
  resolveMutationData = (values: any) => {
    return values;
  };

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: any) => {
    const {
      t,
      data,
    } = this.props;
    const isEdit = !!(data && data.id);
    const required = true;
    return {
      ...shape,
      content: contentValidator(t, isEdit, {
        ext: '*.mp4',
        formats: ['video/mp4'],
      }),
      video: Yup.object().shape({
        duration: minIntValidator({ required, field: t('duration'), min: 0 }),
        type: field(data, 'video.type', 'full'),
      }),
    };
  };

  resolveInitialValues = (values: any) => {
    const { data } = this.props;

    const defaultValues = {
      video: {
        duration: 0,
      },
    };

    return data ? {
      ...defaultValues,
      ...values,
      ...{
        video: {
          duration: data.video ? data.video.duration : 0,
          type: data.video ? data.video.type : 'full',
        },
      },
    } : {
      ...defaultValues,
      ...values,
    };
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="video"
        data={data}
        getItemQuery={getVideoQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        renderForm={this.renderForm}
      />
    );
  }
}

export default withTranslation('video')(Form);
