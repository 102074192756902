import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { IAutoPrice } from '../../types';

export default () => ([
  {
    accessor: 'id',
    Header: 'ID',
    width: 100,
    className: 'text-right',
    filterable: false,
  },
  {
    accessor: 'status',
    Header: 'Статус',
    filterable: false,
  },
  {
    accessor: 'created_at',
    Header: 'Дата создания',
    filterable: false,
  },
  {
    id: 'items',
    accessor: (data: IAutoPrice) =>
      data.items!.map(item => (
        <div key={item.id}>
          <Link to={`/${item.type}/edit/${item.id}`}>
            {`${item.type}.${item.id} ${item.name}`}
          </Link>
        </div>
      )),
    Header: 'Итемы',
    expanded: true,
    expandable: true,
    maxWidth: 400,
    filterable: false,
  },
  {
    id: 'dataFile',
    accessor: (data: IAutoPrice) => {
      const path = get(data, 'dataFile.path', null);
      if (path) {
        return (
          <a href={path} className="btn btn-link">
            <i className="fa fa-file-o fa-lg"/>
          </a>
        );
      }
      return null;
    },
    Header: 'Файл',
    className: 'text-center',
    filterable: false,
    maxWidth: 60,
  },
]);
