import gql from 'graphql-tag';

export const GET_STATE_QUERY = gql`
  query getAppState {
    appState {
      components {
        breadcrumbs {
          data
        }
        sidemenu {
          activeId
        }
      }
    }
  }
`;
