import {
  IImage,
  IItem,
} from 'features/types';

export const getCoverForWidth = (item: IItem, width: number): IImage | null => {
  if (!(item.images && item.images.length)) {
    return null;
  }
  let minDistance = Number.MAX_SAFE_INTEGER;
  let closestImage = null;
  for (const image of item.images) {
    if (image.width - width === 0) {
      return image;
    }
    const distance = Math.abs(width - image.width);
    if (minDistance > distance) {
      closestImage = image;
      minDistance = distance;
    }
  }
  return closestImage;
};

export const getCoverUrlForWidth = (item: IItem, width: number) =>
  item.images && item.images.length ? getCoverForWidth(item, width)!.path : null;
