import React from 'react';
import { IItem } from 'features/types';
import { AccessControl } from 'features/acl';
import Entity from 'features/ui/Entity';
import LinkToItem from 'features/items/lib/LinkToItem';

export default ({ items }: { items: IItem[] }) => {
  if (items.length) {
    const list = items.map((item => (
      <React.Fragment key={item.id}>
        <AccessControl
          permission="items:access"
          fallback={<Entity value={item} />}
        >
          <LinkToItem value={item} action="view" />
        </AccessControl>
        &nbsp;
      </React.Fragment>
    )));
    return (
      <React.Fragment>
        {list}
      </React.Fragment>
    );
  }
  return null;
};
