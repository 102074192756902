export const FEATURE_ICON = 'fa fa-address-card';
export const ANSWER_TYPE_DEFAULT = 'default';
export const ANSWER_TYPE_SELECT = 'select';
export const ANSWER_TYPE_MULTISELECT = 'multiselect';
export const ANSWER_TYPE_ORDERING = 'ordering';
export const ANSWER_TYPE_MAPPING = 'mapping';
export const ANSWER_TYPE_CUSTOM = 'custom';
export const ANSWER_TYPES = [
  {
    value: ANSWER_TYPE_DEFAULT,
    label: 'Выберите тип вопроса',
  },
  {
    value: ANSWER_TYPE_SELECT,
    label: 'Одиночный выбор',
  },
  {
    value: ANSWER_TYPE_MULTISELECT,
    label: 'Множественный выбор',
  },
  {
    value: ANSWER_TYPE_ORDERING,
    label: 'Верный порядок',
  },
  {
    value: ANSWER_TYPE_MAPPING,
    label: 'Соответствие',
  },
  {
    value: ANSWER_TYPE_CUSTOM,
    label: 'Текстовый ответ',
  },
];
