import React from 'react';
import { components } from 'react-select';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { Confirm } from 'features/ui/Modal';
import CreatorsSelect from 'features/creators/lib/CreatorsSelect';
import { MultiValueGenericProps } from 'react-select/lib/components/MultiValue';
import {
  client,
  gql,
} from 'features/graphql';
import {
  IItemQueryResponse,
  IReactSelectActionMeta,
  ISelectOption,
} from 'features/types';
import { IAmountMap } from './types';

const QUERY = gql`
  query getItemsForTabItems(
    $filterByFields: ItemFilterByFields,
  ) {
    ItemQuery(
      perPage: 50000,
      filterByFields: $filterByFields,
    ) {
      items {
        id
        is_active
      }
    }
  }`;

type ItemHandlerFunc = (values: number[]) => Promise<IItemQueryResponse>;

interface IItemsByCreatorsSelectProps extends WithTranslation {
  value: number[];
  type: string;
  creatorsCounts: IAmountMap;
  creatorsCountsAll: IAmountMap;
  onAdd: ItemHandlerFunc;
  onRemove: ItemHandlerFunc;
}

interface IItemsByCreatorsSelectState {
  value: number[];
  isOfferSpecific: boolean;
  valueForRemove: number[] | null;
}

class ItemsByCreatorsSelect extends React.Component<IItemsByCreatorsSelectProps, IItemsByCreatorsSelectState> {
  constructor(props: IItemsByCreatorsSelectProps) {
    super(props);
    this.state = {
      value: props.value,
      isOfferSpecific: false,
      valueForRemove: null,
    };
  }

  onChangeCreators = (value: number[], meta: IReactSelectActionMeta<number>) => {
    const { onAdd } = this.props;

    if (meta.action === 'select-option') {
      this.findItemsForSelect(meta.option!.value, onAdd);
    }

    if (meta.action === 'remove-value') {
      this.setState({ valueForRemove: [meta.removedValue!.value] });
    }
    if (meta.action === 'clear') {
      this.setState({ valueForRemove: this.state.value });
    }
  };

  onCloseRemoveModal = () => {
    this.setState({ valueForRemove: null });
  }

  onRemoveItems = () => {
    const { onRemove } = this.props;
    const { valueForRemove } = this.state;
    if (valueForRemove) {
      valueForRemove.forEach((item) => {
        this.findItemsForDelete(item, onRemove);
      });
    }
  }

  findItemsForDelete = (value: number, cb: ItemHandlerFunc) => {
    this.findItems(
      value,
      {
        filterByFields: {
          creators: {
            value: String(value),
            operator: '=',
          },
        },
      },
      cb,
    );
  }

  findItemsForSelect = (value: number, cb: ItemHandlerFunc) => {
    const { isOfferSpecific } = this.state;
    const filterByFields: { [key: string]: object } = {
      creators: {
        value: String(value),
        operator: '=',
      },
    };
    if (!isOfferSpecific) {
      filterByFields.is_offer_specific = {
        value: '0',
        operator: '=',
      };
    }
    this.findItems(
      value,
      { filterByFields },
      cb,
    );
  }

  findItems = (value: number, variables: object, cb: ItemHandlerFunc) => {
    client.query<IItemQueryResponse>({
      variables,
      query: QUERY,
    }).then((response) => {
      const items = response.data && response.data.ItemQuery ? response.data.ItemQuery.items : [];
      return cb(items.map(item => +item.id));
    });
  };

  MultiValueLabel = (props: MultiValueGenericProps<ISelectOption<number>>) => {
    const {
      creatorsCounts,
      creatorsCountsAll,
    } = this.props;
    const {
      label,
      value,
    } = props.data;
    return (
      <components.MultiValueLabel {...props}>
        <b>{value}</b> | {label} | <b>{creatorsCounts[value] || '?'}/{creatorsCountsAll[value] || '?'}</b>
      </components.MultiValueLabel>
    );
  };

  render() {
    const { type } = this.props;
    const {
      value,
      valueForRemove,
    } = this.state;
    return (
      <React.Fragment>
        <CreatorsSelect
          isMulti={true}
          type={type}
          value={value}
          onChange={this.onChangeCreators}
          renderedComponents={{ MultiValueLabel: this.MultiValueLabel }}
        />
        <Confirm
          title={'Удаление Итемов'}
          description={`Удалить Итемы принадлежащие Создателю №${valueForRemove}?`}
          type="warning"
          isOpened={valueForRemove !== null}
          onClose={this.onCloseRemoveModal}
          onResolve={this.onRemoveItems}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation('offers')(ItemsByCreatorsSelect);
