import React from 'react';
import { get, stubTrue } from 'lodash';
import moment from 'moment'
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import {
  InfoRowImage,
  InfoRowText,
} from 'features/ui/InfoRow';
import Switch from 'features/ui/Switch';
import DatePicker from 'features/ui/DatePicker';
import OffersSelect from 'features/offers/lib/OffersSelect';
import UserRoleSelect from 'features/users/lib/UserRoleSelect';
import UserGenderSelect from 'features/users/lib/UserGenderSelect';
import UserGroupsSelect from 'features/usergroups/lib/UserGroupsSelect';

import { IFormValues } from 'features/users/types';
import {
  IUser,
  Moment,
} from 'features/types';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IUser;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onChangeDate = (field: string) => (date: Moment | null) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, date ? date.format('YYYY-MM-DD') : '');
  };

  onOfferChange = (value: number) => this.props.setFieldValue('offer', value);

  onGroupsChange = (value: number) => this.props.setFieldValue('groups', value);

  onPhoneChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.props.setFieldValue('phone', `${e.target.value}`)
  };

  renderCreatedAt() {
    const {
      t,
      values,
    } = this.props;
    if (values.id) {
      return (
        <FormRow label={t('created_at')} id="created_at">
          <p className="form-control-static">{values.created_at}</p>
        </FormRow>
      );
    }
    return null;
  }

  renderEmail() {
    const {
      t,
      values,
      handleBlur,
      handleChange,
    } = this.props;
    let component;
    if (values.id) {
      component = <p className="form-control-static">{values.email}</p>;
    } else {
      component = (
        <Input
          type="text"
          id="email"
          name="email"
          placeholder="example@domain.com"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    }
    return (
      <FormRow label={t('email')} id="email" required>
        {component}
      </FormRow>
    );
  }

  renderAvatar() {
    const {
      t,
      item,
    } = this.props;
    if (!item) {
      return null;
    }
    return (
      <InfoRowImage
        key="avatar"
        value={get(item, 'avatar.path', null)}
        label={t('avatar')}
      />
    );
  }

  render() {
    const {
      t,
      item,
      values,
      handleBlur,
      handleChange,
    } = this.props;
    const isPasswordRequired = !item;
    return (
      <FormFrame
        id={values.id}
        cancelLink="/users"
        {...this.props}
      >
        {this.renderCreatedAt()}
        {this.renderEmail()}
        <FormRow key="phone" label={t('phone')} id="phone">
          <Input
            type="number"
            id="phone"
            name="phone"
            value={values.phone}
            onChange={this.onPhoneChange}
            onBlur={this.onPhoneChange}
          />
        </FormRow>

        <InfoRowText label={t('used_pin')} value={item && item.used_pin} />

        {this.renderAvatar()}

        <FormRow key="is_activated" label={t('is_activated')} id="is_activated">
          <Switch
            id="is_activated"
            name="is_activated"
            offColor="danger"
            checked={values.is_activated}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow key="is_deleted" label={t('is_deleted')} id="is_deleted">
          <Switch
            id="is_deleted"
            name="is_deleted"
            onColor="danger"
            checked={values.is_deleted}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow key="labEnabled" label={t('labEnabled')} id="labEnabled">
          <Switch
            id="labEnabled"
            name="labEnabled"
            checked={values.labEnabled}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow
          id="password"
          label={t('password')}
          required={isPasswordRequired}
        >
          <Input
            type="text"
            id="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow
          key="role"
          label={t('role')}
          id="role"
        >
          <UserRoleSelect
            id="role"
            name="role"
            value={values.role}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('offer')} id="offer" required>
          <OffersSelect
            id="offer"
            name="offer"
            value={values.offer}
            isMulti={false}
            onChange={this.onOfferChange}
          />
        </FormRow>

        <hr />

        <FormRow key="groups" label={t('groups')} id="groups" shouldUpdate={stubTrue}>
          <UserGroupsSelect
            id="groups"
            name="groups"
            offer={values.offer}
            value={values.groups}
            onChange={this.onGroupsChange}
          />
        </FormRow>

        <FormRow key="is_subscribed" label={t('is_subscribed')} id="is_subscribed">
          <Switch
            id="is_subscribed"
            name="is_subscribed"
            checked={values.is_subscribed}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow key="can_be_restored" label={t('can_be_restored')} id="can_be_restored">
          <Switch
            id="can_be_restored"
            name="can_be_restored"
            checked={values.can_be_restored}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('first_name')} id="first_name">
          <Input
            type="text"
            id="first_name"
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('last_name')} id="last_name">
          <Input
            type="text"
            id="last_name"
            name="last_name"
            value={values.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow key="gender" label={t('gender')} id="gender">
          <UserGenderSelect
            id="gender"
            name="gender"
            value={values.gender}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow key="birthday" label={t('birthday')} id="birthday">
          <DatePicker
            id="birthday"
            value={values.birthday ? moment(values.birthday).format('YYYY-MM-DD') : ''}
            onChange={this.onChangeDate('birthday')}
          />
        </FormRow>

        <FormRow key="city" id="city" label={t('city')}>
          <Input
            type="text"
            id="city"
            name="city"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow key="position" id="position" label={t('position')}>
          <Input
            type="text"
            id="position"
            name="position"
            value={values.position}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow key="department" id="department" label={t('department')}>
          <Input
            type="text"
            id="department"
            name="department"
            value={values.department}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow key="expire_date" label={t('expire_date')} id="expire_date">
          <DatePicker
            id="expire_date"
            value={values.expire_date ? moment(values.expire_date).format('YYYY-MM-DD') : ''}
            onChange={this.onChangeDate('expire_date')}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('users')(BaseForm);
