import React from 'react';
import {
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import ReactSelect from 'features/ui/ReactSelect/ReactSelectWithId';

interface IPriceTiersSelectState {
  options: ISelectOption[];
}

class TaxSelect extends React.PureComponent<ISharedSelectProps, IPriceTiersSelectState> {
  constructor(props: ISharedSelectProps) {
    super(props);
    this.state = {
      options: [
        {
          value: '1',
          label: 'без НДС',
        },
        {
          value: '2',
          label: 'ставка НДС 0%',
        },
        {
          value: '3',
          label: 'ставка 10%',
        },
        {
          value: '4',
          label: 'ставка 20%',
        },
        {
          value: '5',
          label: 'расчетная ставка 10/110',
        },
        {
          value: '6',
          label: 'расчетная ставка 20/120',
        },
      ],
    };
  }

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        shrinkHeight={150}
        isMulti={false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

export default TaxSelect;
