import React from 'react';

export default ({
  condition,
  children,
}: {
  condition: boolean,
  children: React.ReactNode | React.ReactNode[],
}) => {
  if (condition) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
  return null;
};
