import React from 'react';

class Aside extends React.PureComponent {
  render() {
    return (
      <aside className="aside-menu">
        {/*Aside Menu*/}
      </aside>
    );
  }
}

export default Aside;
