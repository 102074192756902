import React, { Component } from 'react';
import {
  Alert,
  Button,
} from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { createView } from 'features/common';
import { makeCreatorUrl } from 'features/creators/lib/url';
import { makeItemUrlTo } from 'features/items/lib/url';
import { InfoRow } from 'features/ui/InfoRow';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import getItemsForPublisherQuery from '../queries/getItemsForPublisher.gql';
import getItemsForProviderQuery from '../queries/getItemsForProvider.gql';

import {
  TYPE_PROVIDER,
  TYPE_PUBLISHER,
} from 'features/creators/consts';

import { ICreator } from 'features/creators/types';
import { IItem } from 'features/items/types';
import { IOffer } from '../types';

const EXPANDING_LIMIT = 3;

const ViewProvider = createView<IOffer>(getItemsForProviderQuery, 'OfferQuery');
const ViewPublisher = createView<IOffer>(getItemsForPublisherQuery, 'OfferQuery');
const compare = (a: ITabViewItem, b: ITabViewItem) => a.full_name.localeCompare(b.full_name);

type ITabViewItemsProps = WithTranslation;

interface ITabViewItemsState {
  expanded: {[key: string]: boolean};
}

interface ITabViewItem {
  id: ID;
  full_name: string;
  type: string;
  totalItemsAmount: number;
  items: Array<{
    id: ID;
    name: string;
    type: string;
  }>;
}

class TabViewItems extends Component<ITabViewItemsProps, ITabViewItemsState> {
  constructor(props: ITabViewItemsProps) {
    super(props);
    this.state = { expanded: {} };
  }

  handleExpanding = (event: React.SyntheticEvent<HTMLElement>) => {
    const { expanded } = this.state;
    const creatorId = event.currentTarget.getAttribute('data-creator-id');
    if (typeof creatorId === 'string') {
      this.setState({
        expanded: {
          ...expanded,
          [creatorId]: !expanded[creatorId],
        },
      });
    }
  };

  transformData(offer: IOffer): ITabViewItem[] {
    const { items } = offer;
    const amountOfItemsByCreator: { [key: string]: number } = (offer._calc_CreatorsViaItems || [])
      .reduce(
        (acc: { [key: string]: number }, creator: ICreator) => {
          acc[creator.id] = (creator.items || []).length;
          return acc;
        },
        {},
      );
    const creators = items.reduce(
      (acc: { [key: string]: ITabViewItem }, item: IItem) => {
        if (!item.creators) {
          return acc;
        }
        item.creators.forEach((origCreator) => {
          if (![TYPE_PROVIDER, TYPE_PUBLISHER].includes(origCreator.type)) {
            return;
          }
          if (!(origCreator.id in acc)) {
            acc[origCreator.id] = {
              id: origCreator.id,
              full_name: origCreator.full_name,
              type: origCreator.type,
              totalItemsAmount: amountOfItemsByCreator[origCreator.id] || 0,
              items: [],
            };
          }
          const creator = acc[origCreator.id];
          if (creator.items) {
            creator.items.push({
              id: item.id,
              name: item.name,
              type: item.type || '',
            });
          }
          return acc;
        });
        return acc;
      },
      {},
    );
    return Object.values(creators);
  }

  renderList(creators: ITabViewItem[]) {
    const { expanded } = this.state;
    return creators.map((creator: ITabViewItem) => {
      const isExpanded = !!expanded[creator.id];
      const items = creator.items || [];
      const label = (
        <LinkToEntityInLists
          id={creator.id}
          name={creator.full_name}
          to={makeCreatorUrl({ id: creator.id, action: 'view' })}
        />
      );
      const itemsList = (
        isExpanded
          ? items
          : items.slice(0, EXPANDING_LIMIT))
        .map(item => (
          <div key={item.id}>
            <LinkToEntityInLists
              id={item.id}
              name={item.name}
              to={makeItemUrlTo(item as IItem, 'view')}
            />
          </div>
        ));
      let button = null;
      if (items.length > EXPANDING_LIMIT) {
        button = (
          <Button
            color="info"
            size="sm"
            data-creator-id={creator.id}
            onClick={this.handleExpanding}
          >
            {isExpanded ? 'Скрыть' : 'Показать все'}
          </Button>
        );
      }
      return (
        <InfoRow key={creator.id} label={label}>
          Количество: {items.length} из {creator.totalItemsAmount}
          {itemsList}
          {button}
        </InfoRow>
      );
    });
  }

  renderProvider = (offer: IOffer) => {
    const { t } = this.props;
    const data = this.transformData(offer);
    const providers = data
      .filter(creator => creator.type === TYPE_PROVIDER)
      .sort(compare);
    let component = null;
    if (providers.length) {
      component = this.renderList(providers);
    } else {
      component = <Alert color="info">{t('errors:data_is_empty')}</Alert>;
    }
    return (
      <React.Fragment>
        <h3>{t('providers')}</h3>
        {component}
      </React.Fragment>
    );
  }

  renderPulibsher = (offer: IOffer) => {
    const { t } = this.props;
    const data = this.transformData(offer);
    const publishers = data
      .filter(creator => creator.type === TYPE_PUBLISHER)
      .sort(compare);
    let component = null;
    if (publishers.length) {
      component = this.renderList(publishers);
    } else {
      component = <Alert color="info">{t('errors:data_is_empty')}</Alert>;
    }
    return (
      <React.Fragment>
        <h3>{t('publishers')}</h3>
        {component}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <ViewPublisher children={this.renderPulibsher} />
        <ViewProvider children={this.renderProvider} />
      </React.Fragment>
    );
  }
}

export default withTranslation('offers')(TabViewItems);
