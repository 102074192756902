import React from 'react';
import { get } from 'lodash';
import {
  graphql,
  GraphqlQueryControls,
  OperationVariables,
  OptionProps,
} from 'react-apollo';
import { DocumentNode } from 'graphql';
import { client } from 'features/graphql';
import { match as IMatch } from 'react-router';
import deepOmit from 'lib/deepOmit';
import { GET_STATE_QUERY } from 'features/graphql/queries';
import { initialState } from 'features/graphql/cache';

import { RouteComponentProps } from 'react-router-dom';
import {
  IAppStateQueryResponse,
  IListQueryVariables,
} from 'features/types';

export type MapProps<Props extends {}, ResponseProps extends {}> = OptionProps<Props, ResponseProps, IListQueryVariables>;
export type MapResult<BaseComponentProps extends {}> = Partial<GraphqlQueryControls & BaseComponentProps>;

export const updateState = (resolver: any) => {
  let data;
  // Если readQuery не находит данных в кеше, то он выбрасывает исключение.
  try {
    data = client.readQuery<IAppStateQueryResponse>({
      query: GET_STATE_QUERY,
    });
  } catch (e) {
    if (e.name !== 'Invariant Violation') {
      throw(e);
    }
  }

  const isInitialized = !!(data && data.appState && data.appState.components);
  const state = data && isInitialized ? data.appState : initialState.appState;

  const newState = resolver(state);

  client.writeQuery({
    query: GET_STATE_QUERY,
    data: {
      appState: newState,
    },
  });
};

export const graphqlDynamic = (): any => {
  return (component: React.ComponentType) => {
    return (props: any) => {
      const { graphqlQuery, graphqlConfig } = props;
      return React.createElement(graphqlQuery ? graphql(graphqlQuery, graphqlConfig)(component) : component, props);
    };
  };
};

export const mapResultsToPropsForGetQuery = <T extends { match: IMatch<{ id: string }> }, U extends {}>(queryType: string) => {
  return ({ data, ownProps }: OptionProps<T, U, IListQueryVariables> & OperationVariables) => {
    const loadedData = get(data, `${queryType}.items[0]`, null);
    return Object.assign(
      {},
      ownProps,
      {
        data: deepOmit(loadedData, '__typename'),
        refetch: data && data.refetch,
        isLoading: get(data, 'loading', false),
      },
    );
  };
};

export const graphqlById = <TResponse extends {}, TBaseComponentProps extends {}>({
  query,
  props,
  queryType,
}: {
  query: DocumentNode,
  props?: (props: OptionProps<RouteComponentProps<{ id: string }>, TResponse, IListQueryVariables>) => any,
  queryType?: string,
}) => {
  return graphql<RouteComponentProps<{ id: string }>, TResponse, IListQueryVariables, TBaseComponentProps>(
    query,
    {
      props: queryType && !props ? mapResultsToPropsForGetQuery(queryType) : props,
      options: ({ match }) => {
        const id: ID = get(match, 'params.id', '0');
        return {
          context: {
            isGlobalLoading: true,
          },
          variables: {
            id: [id],
          },
        };
      },
    },
  );
};
