import React from 'react';
import styles from './styles.module.scss';

interface IFixedOnTheRight {
  children: JSX.Element[] | JSX.Element;
  side: JSX.Element[] | JSX.Element;
}

export default ({ children, side }: IFixedOnTheRight) => {
  return (
    <div className={styles.container}>
      <div>
        {children}
      </div>
      <div>
        {side}
      </div>
    </div>
  );
};
