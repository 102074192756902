import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import LinkBlock from 'features/ui/LinkBlock';
import {
  InfoRow,
  InfoRowText,
} from 'features/ui/InfoRow';
import { createView } from 'features/common';
import { get } from 'lodash';
import { makeUserUrl } from 'features/users/lib/url';
import buildFullName from 'features/users/lib/buildFullName';
import moment from 'moment';
import {
  makeUsersSubscriptionUrl,
  makeUsersSubscriptionUrlTo,
} from '../lib/url';
import getEntity from '../queries/getUserSubscription.gql';

import { FEATURE_ICON } from '../consts';

import { IUserSubscription } from '../types';

const View = createView<IUserSubscription>(getEntity, 'UserSubscriptionQuery');

interface IPageViewProps extends WithTranslation {
  userSubscription: IUserSubscription;
}

class PageView extends React.PureComponent<IPageViewProps> {
  renderChildren = (userSubscription: IUserSubscription) => {
    const { t } = this.props;
    const fullName = buildFullName(userSubscription.user);
    const isEditable = userSubscription.cost === 0;
    return (
      <React.Fragment>
        {
          isEditable
            ? <LinkBlock editLink={makeUsersSubscriptionUrlTo(userSubscription, 'edit')} />
            : <p className="alert alert-danger" role="alert">{t('not_editable')}</p>
        }
        <InfoRowText label="ID" value={userSubscription.id} />
        <InfoRow label={t('users:user')}>
          {fullName ? `${fullName} ` : ''}
          <LinkToEntityInLists
            id={get(userSubscription, 'user.id', 0)}
            name={get(userSubscription, 'user.email', '')}
            to={makeUserUrl({ id: get(userSubscription, 'user.id', 0) })}
          />
        </InfoRow>
        <InfoRowText label={t('subscription')} value={get(userSubscription, 'subscription.name', '')} />
        <InfoRowText label={t('cost')} value={userSubscription.cost} />
        <InfoRowText label={t('from')} value={moment(userSubscription.from).format('ll')} />
        <InfoRowText label={t('to')} value={moment(userSubscription.to).format('ll')} />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        title={t('user_subscriptions_view')}
        icon={FEATURE_ICON}
        crumbs={[{ link: makeUsersSubscriptionUrl(), name: t('user_subscriptions') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('user_subscriptions')(PageView);
