import React from 'react';
import cn from 'classnames';
import { toast } from 'features/ui/Toast';
import OutsideClickHandler from 'react-outside-click-handler';
import { client } from 'features/graphql';
import editMiniBanner from 'features/blocks/queries/editMiniBanner.gql';
import createMiniBanner from 'features/blocks/queries/createMiniBanner.gql';
import deleteMiniBanner from 'features/blocks/queries/deleteMiniBanner.gql';
import getMiniBanners from 'features/blocks/queries/getMiniBanners.gql';
import { ISharedSelectProps } from 'features/types';
import { IItem } from 'features/items/types';
import ImageUploader from 'features/ui/ImageUploader';
import styles from './styles.module.scss';

interface IItemsSelectProps extends ISharedSelectProps {
  data: IItem[];
  itemId?: string;
}

interface IItemsSelectState {
  options: any[];
  isSelectOpen: boolean;
  isModalOpen: boolean;
  modalCreatable: {
    name: string;
    description: string;
    link: string;
    image: null | string | File;
    currEditedOptionId: string | undefined;
  };
}

class ItemsSelect extends React.PureComponent<IItemsSelectProps, IItemsSelectState> {
  static intersect = (firstList: any[], secondList: any[]): any[] => {
    const map = new Map(secondList.map(obj => [obj.id, obj]));
    return firstList.filter(item => map.has(item.id)).map(item => ({ ...item, ...map.get(item.id) }));
  };

  constructor(props: IItemsSelectProps) {
    super(props);
    this.state = {
      options: [],
      isSelectOpen: false,
      isModalOpen: false,
      modalCreatable: {
        currEditedOptionId: undefined,
        name: '',
        description: '',
        link: '',
        image: null,
      },
    };
  }

  componentDidMount() {
    this.loadOptions();
  }

  loadOptions() {
    client.query({
      query: getMiniBanners,
      variables: {
        pageNum: 1,
        perPage: 100,
      },
    }).then((res) => {
      const options = res.data.ReferenceQuery.items;
      this.setState({ options });
    });
  }

  onImageChange = (e: any) => {
    this.setState(prevState => ({
      ...prevState,
      modalCreatable: {
        ...prevState.modalCreatable,
        image: e !== null ? e.target.files[0] : null,
      },
    }));
  };

  onModalParamsChange = (field: string, value: any) => {
    this.setState(prevState => ({
      ...prevState,
      modalCreatable: {
        ...prevState.modalCreatable,
        [field]: value,
      },
    }));
  };

  onChangeOption = () => {
    const {
      modalCreatable: {
        name,
        description,
        link,
        image,
        currEditedOptionId,
      },
    } = this.state;
    const buildRefObj = {
      name,
      description,
      link,
      image,
    };
    if (currEditedOptionId && typeof image === 'string') {
      delete buildRefObj.image;
    }
    client.mutate({
      mutation: currEditedOptionId ? editMiniBanner : createMiniBanner,
      variables: {
        id: currEditedOptionId,
        reference: buildRefObj,
      },
    }).then(() => {
      this.loadOptions();
      this.setState(prevState => ({
        ...prevState,
        isModalOpen: false,
        modalCreatable: {
          name: '',
          description: '',
          link: '',
          image: null,
          currEditedOptionId: undefined,
        },
      }));
      this.onImageChange(null);
    });
  }

  onCloseModal = () => {
    this.setState({
      isModalOpen: false,
      modalCreatable: {
        name: '',
        description: '',
        link: '',
        image: null,
        currEditedOptionId: undefined,
      },
    });
  }

  onEdit = (option: any) => {
    this.setState({
      isModalOpen: true,
      modalCreatable: {
        name: option.name,
        description: option.description,
        link: option.link,
        image: option.image ? option.image.path : undefined,
        currEditedOptionId: option.id,
      },
    });
  }

  onDelete = (id: number) => {
    const { options } = this.state;
    client.mutate({
      mutation: deleteMiniBanner,
      variables: { id },
    }).then(() => {
      this.setState({ options: options.filter(opt => opt.id !== id) });
    });
  }

  render() {
    const {
      value,
      onChange,
      onDelete,
    } = this.props;

    const {
      options,
      isModalOpen,
      modalCreatable,
      isSelectOpen,
    } = this.state;

    return (
      <OutsideClickHandler onOutsideClick={() => this.setState({ isSelectOpen: false })}>
        <div className="d-flex position-relative">
          <div className={cn(styles.selectedOptionsList, 'form-control')} onClick={() => this.setState({ isSelectOpen: !isSelectOpen })}>
            {ItemsSelect.intersect(value.map((v: any) => ({ id: v })), options).map(v => (
              <div className={styles.selectedOptions}>
                {v.name}
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(v.id);
                  }}
                >
                  &times;
                </span>
              </div>
            ))}
          </div>
          <div className={cn(styles.selectorList, isSelectOpen ? styles.selectorList_flex : styles.selectorList_none)}>
            {options.filter(option => !value.includes(option.id)).map(option => (
              <div className={styles.optionCardWrapper}>
                <div
                  key={option.id}
                  className={styles.optionCard}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (value.length === 3) {
                      toast.error('Превышено максимальное число блоков (3)');
                    } else {
                      onChange(option.id);
                    }
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <p className={styles.optionCard_name}>{option.name}</p>
                    <p className={styles.optionCard_description}>{option.description}</p>
                    <p className={styles.optionCard_link}>{'(ссылка: '}{option.link}{')'}</p>
                  </div>
                  <div className={styles.optionCard_image}>
                    {option.image ? (
                      <img src={option.image.path} alt={option.image.id} />
                    ) : (
                      <div className={styles.optionCard_image__placeholder} />
                    )}
                  </div>
                </div>
                <div className={styles.optionCardActionsWrapper}>
                  <div className={styles.optionActionEdit} style={{ padding: 16 }} onClick={() => this.onEdit(option)}>
                    <i className="icon-pencil icons font-lg" />
                  </div>
                  <div className={styles.optionActionDelete} style={{ padding: 16 }} onClick={() => this.onDelete(option.id)}>
                    <i className="icon-trash icons font-lg" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={cn(styles.addNewBtn, 'btn', 'btn-primary')} onClick={() => this.setState({ isModalOpen: true })}>
            Создать
          </div>
          {isModalOpen ? (
            <div className="position-absolute" style={{ display: 'block', width: 600, height: 400 }} role="dialog">
              <div role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Создание нового мини-баннера</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="input-group-prepend mb-1">
                      <label className={cn(styles.btn_base_width, 'input-group-text')} htmlFor="modal-name">Название</label>
                      <input
                        id="modal-name"
                        type="text"
                        className="form-control"
                        value={modalCreatable.name}
                        onChange={(e) => this.onModalParamsChange('name', e.target.value)}
                      />
                    </div>
                    <div className="input-group-prepend mb-1">
                      <label className={cn(styles.btn_base_width, 'input-group-text')} htmlFor="modal-description">Описание</label>
                      <input
                        id="modal-description"
                        type="text"
                        className="form-control"
                        value={modalCreatable.description}
                        onChange={(e) => this.onModalParamsChange('description', e.target.value)}
                      />
                    </div>
                    <div className="input-group-prepend mb-1">
                      <label className={cn(styles.btn_base_width, 'input-group-text')} htmlFor="modal-link">Ссылка</label>
                      <input
                        id="modal-link"
                        type="text"
                        className="form-control"
                        value={modalCreatable.link}
                        onChange={(e) => this.onModalParamsChange('link', e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-wrap">
                      <ImageUploader
                        id="imageTop"
                        tooltip="Формат изображения: 1 к 1 (например: 82x82)"
                        name="imageTop"
                        value={modalCreatable.image}
                        onChange={this.onImageChange}
                        imageWidth={300}
                        imageHeight={180}
                        canBeDeleted={false}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => this.onChangeOption()}>Сохранить</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.onCloseModal}>Отмена</button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </OutsideClickHandler>
    );
  }
}

export default ItemsSelect;
