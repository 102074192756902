const bowser = require('bowser');

const browser = bowser.getParser(window.navigator.userAgent);
const isNotValidBrowser = browser.satisfies({
  'internet explorer': '<10',
  // 'safari': '<11',
});

if (isNotValidBrowser) {
  window.location.href = '/browser.html';
  throw new Error('Not supported Browser!');
}

require('./app/index');
