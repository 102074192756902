import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { flow } from 'lodash';
import {
  match as IMatch,
  Redirect,
} from 'react-router';
import CardPage from 'features/ui/Page/CardPage';
import Spinner from 'features/ui/Spinner';
import LinkBlock from 'features/ui/LinkBlock';
import deepOmit from 'lib/deepOmit';
import PopularRequestForm from '../lib/PopularRequestsForm';
import {
  makePopularRequestUrl,
  makePopularRequestUrlTo,
} from '../lib/url';
import getPopularRequestQuery from '../queries/getPopularRequest.gql';
import { FEATURE_ICON } from '../consts';

import { IListQueryVariables } from 'features/types';
import {
  IPopularRequest,
  IPopularRequestsQueryResponse,
} from '../types';

interface IPagePopularRequestProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  request: IPopularRequest;
}

interface IPagePopularRequestState {
  request: IPopularRequest;
}

class PageEdit extends React.Component<IPagePopularRequestProps, IPagePopularRequestState> {
  render() {
    const {
      t,
      request,
      isLoading,
    } = this.props;
    if (!isLoading && !request) {
      return <Redirect to="/404" />;
    }
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={request ? `${t('edit_popular_request')}: ${request.label}` : ''}
        crumbs={[{ link: makePopularRequestUrl(), name: t('popular_requests') }]}
      >
        <LinkBlock viewLink={makePopularRequestUrlTo(request, 'view')} />
        <PopularRequestForm request={request} />
      </CardPage>
    );
  }
}

const mapResultsToProps = (props: OptionProps<IPagePopularRequestProps, IPopularRequestsQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  const request = data && data.PopularRequestQuery ?
    deepOmit(data.PopularRequestQuery.items[0], '__typename') as unknown as IPopularRequestsQueryResponse : null;
  return {
    request,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphql(getPopularRequestQuery, {
    props: mapResultsToProps,
    options: ({ match }: { match: IMatch<any> }) => {
      const { params } = match;
      const { id } = params || { id: 0 };
      return {
        context: {
          isGlobalLoading: true,
        },
        variables: {
          id: [id],
        },
      };
    },
  }),
  withTranslation('popular_requests'),
)(PageEdit);
