import React from 'react';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/pushes/consts';
import { makeNewPushUrl } from 'features/pushes/lib/url';
import PushForm from 'features/pushes/lib/Form';

export default class PageCreate extends React.Component<any, any> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Создание нового push-уведомления"
        crumbs={[{ link: makeNewPushUrl(), name: 'Push-уведомления' }]}
      >
        <PushForm />
      </CardPage>
    );
  }
}
