import React from 'react';
import { set } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import CardPage from 'features/ui/Page/CardPage';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import makeColumns from './columns';
import { makePopularRequestUrl } from '../lib/url';
import getPopularRequestsQuery from '../queries/getPopularRequests.gql';
import deletePopularRequest from '../queries/deletePopularRequest.gql';
import { FEATURE_ICON } from '../consts';

type IPagePopularRequests = WithTranslation;

class PagePopularRequests extends React.Component<IPagePopularRequests> {
  private _columns: any;

  constructor(props: IPagePopularRequests) {
    super(props);
    this._columns = makeColumns();
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.name) {
      set(variables, 'filterByFields.name', {
        operator: 'like',
        value: fields.name,
      });
    }
    if (fields.offer) {
      set(variables, 'filterByFields.offers', {
        operator: '=',
        value: String(fields.offer),
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('popular_requests')}
        buttons={[{ link: makePopularRequestUrl({ action: 'create' }), name: t('new_popular_request') }]}
      >
        <IndexTableBase
          query={getPopularRequestsQuery}
          queryResultName="PopularRequestQuery"
          columns={this._columns}
          filterFields={['id', 'name', 'offer']}
          prepareRequestData={this.prepareRequestData}
          deleteMutation={deletePopularRequest}
          makeCrudUrl={makePopularRequestUrl}
          isFilterableById={true}
          showAutoEditIconColumn={true}
          showViewIconColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('popular_requests')(PagePopularRequests);
