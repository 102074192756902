import {
  ApolloLink,
  NextLink,
} from 'apollo-link';
import { get } from 'lodash';
import appStore from 'features/app/mobxStore';

const timeStartLink = new ApolloLink((operation, forward?: NextLink) => {
  operation.setContext({ startTime: new Date() });
  return forward ? forward(operation) : null;
});

const reset = () => {
  appStore.updateState({
    isLoading: false,
    globalProgress: 0,
  });
};

const logResponseLink = new ApolloLink((operation, forward?: NextLink) => {
  if (process.env.NODE_ENV === 'production') {
    if (forward) {
      return forward(operation).map((data) => {
        reset();
        return data;
      });
    }
    return null;
  }
  const answerType = get(operation, 'query.definitions[0].selectionSet.selections[0].name.value', '');
  global.console.group(`Request-Response-Start: ${operation.operationName} -> ${answerType}`);
  global.console.groupEnd();
  return forward ? forward(operation).map((data) => {
    reset();

    const time = +new Date() - operation.getContext().startTime;

    global.console.group(`Request-Response-End: ${operation.operationName} -> ${answerType}`);
    global.console.log(`Time took ${time} to complete`);
    global.console.info(data);
    global.console.info(operation);
    global.console.info(operation.getContext());
    global.console.groupEnd();
    return data;
  }) : null;
});

const loggerLink = timeStartLink.concat(logResponseLink);

export default loggerLink;
