import React from 'react';
import { set } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { IMakeUrl } from 'lib/crudUrlBuilder';
import { makeOrderUrl } from 'features/orders/lib/url';
import CardPage from 'features/ui/Page/CardPage';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import { FEATURE_ICON } from '../consts';
import makeColumns from './columns';

import getOrdersQuery from 'features/orders/queries/getOrdersQuery.gql';

type IPageOrders = WithTranslation;

class PageOrders extends React.Component<IPageOrders> {
  columns: object;

  constructor(props: IPageOrders) {
    super(props);
    this.columns = makeColumns();
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      set(variables, 'id', fields.id);
    }
    if (fields.status) {
      set(variables, 'filterByFields.status', {
        operator: '=',
        value: fields.status,
      });
    }
    if (fields.email) {
      set(variables, 'filterByFields.user', {
        operator: 'in',
        value: fields.email,
      });
    }
    if (fields.items) {
      set(variables, 'filterByFields.items', {
        operator: '=',
        value: String(fields.items),
      });
    }
  };

  crudUrlIdHandler(value: IMakeUrl) {
    return makeOrderUrl({ id: value.id, action: 'view' });
  }

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('orders')}
      >
        <IndexTableBase
          columns={this.columns}
          filterFields={['id', 'status', 'email', 'items']}
          isFilterableById={true}
          makeCrudUrl={makeOrderUrl}
          crudUrlIdHandler={this.crudUrlIdHandler}
          prepareRequestData={this.prepareRequestData}
          query={getOrdersQuery}
          queryResultName="OrderQuery"
          showAutoEditIconColumn={false}
          showViewIconColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('orders')(PageOrders);
