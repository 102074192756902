import { IMakeUrlFunction } from 'lib/crudUrlBuilder';
import { itemTypeTranslator } from 'features/ui/ReactSelect/helpers';

interface ImakeLinkListValueToItemType {
  id: ID;
  type: string;
  book?: { type: string };
  audiobook?: { type: string };
  video?: { type: string };
}

export const makeLinkListValue = <TEntity extends { id: ID, name: string, email: string }>(
  list: TEntity[],
  textField: keyof TEntity,
  makeUrl: IMakeUrlFunction,
  action: string = 'edit',
) => {
  return list.map(entity => ({
    id: entity.id,
    text: String(entity[textField]),
    to: makeUrl({ action, id: entity.id }),
  }));
};

export const makeLinkListValueTo = <TEntity extends { id: ID }>(
  list: TEntity[],
  textField: keyof TEntity,
  makeUrlTo: (item: TEntity, action: string) => string,
  action: string = 'edit',
) => {
  return list.map(entity => ({
    id: entity.id,
    text: String(entity[textField]),
    to: makeUrlTo(entity, action),
  }));
};

export const makeLinkListValueToItem = <TEntity extends ImakeLinkListValueToItemType>(
  list: TEntity[],
  textField: keyof TEntity,
  makeUrlTo: (item: TEntity, action: string) => string,
  action: string = 'edit',
) => {
  return list.map((entity) => {
    let subtype = '';
    if (entity.book && entity.book.type === 'summary') { subtype = 'summary'; }
    if (entity.audiobook && entity.audiobook.type === 'summary') { subtype = 'audiosummary'; }
    if (entity.video && entity.video.type === 'summary') { subtype = 'videosummary'; }
    return {
      id: entity.id,
      text: String(`${entity[textField]} [${itemTypeTranslator(subtype.length ? subtype : entity.type)}]`),
      to: makeUrlTo(entity, action),
    };
  });
};
