import { createBrowserHistory, History } from 'history';
import qs from 'qs';
import { updateBreadcrumbsData } from 'features/breadcrumbs/helpers';
import { updateSideMenuActiveId } from 'features/layout/helpers';

const browserHistory = createBrowserHistory();

const addLocationQuery = (history: History) => {
  history.location = Object.assign(
    history.location,
    {
      query: qs.parse(history.location.search.slice(1)),
    },
  );
};

addLocationQuery(browserHistory);

browserHistory.listen(() => {
  addLocationQuery(browserHistory);
  updateBreadcrumbsData([]); // reset breadcrumbs
  updateSideMenuActiveId(''); // reset side menu
});

export default browserHistory;
