import React, { Component } from 'react';
import { isString } from 'lodash';
import reactElementToJSXString from 'react-element-to-jsx-string';
import './styles.scss';

interface ICollapsibleTextProps {
  text: string | React.ReactElement<any>;
  limit?: number;
  id: string | number;
  maxHeight?: number;
  maxLines?: number;
  onToggle?: (state: boolean) => void;
}

interface ICollapsibleTextState {
  collapse: boolean;
}

class CollapsibleText extends Component<ICollapsibleTextProps, ICollapsibleTextState> {
  static defaultProps = {
    limit: 500,
    maxHeight: 0,
    maxLines: 0,
  };

  private readonly triggerRef: React.RefObject<HTMLInputElement>;

  constructor(props: ICollapsibleTextProps) {
    super(props);

    this.triggerRef = React.createRef();
    this.state = {
      collapse: true,
    };
  }

  shouldComponentUpdate(nextProps: ICollapsibleTextProps, nextState: ICollapsibleTextState) {
    return (
      this.props.text !== nextProps.text ||
      this.state.collapse !== nextState.collapse
    );
  }

  onToggle = () => {
    const { onToggle } = this.props;

    if (this.triggerRef && this.triggerRef.current) {
      const isChecked = this.triggerRef.current.checked;

      this.setState({ collapse: !isChecked });

      if (onToggle) {
        onToggle(isChecked);
      }
    }
  };

  convertToString = (value: any): string => {
    if (isString(value)) {
      return value;
    }

    if (Array.isArray(value)) {
      return value.map(this.convertToString).join('');
    }

    if (React.isValidElement(value)) {
      return reactElementToJSXString(value);
    }

    return JSON.stringify(value);
  };

  render() {
    const {
      id, text, limit, maxHeight, maxLines,
    } = this.props;
    const { collapse } = this.state;
    const stringText = this.convertToString(text);

    const isCollapsedMode = Boolean(text && limit && stringText.length > limit);

    if (!isCollapsedMode) {
      return (
        <div className="collapsable-text-component">
          <div className="text-wrapper">
            <div>{text}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="collapsable-text-component">
        <input
          ref={this.triggerRef}
          type="checkbox"
          className="trigger"
          id={`collapsable-text-component-${id}`}
          onChange={this.onToggle}
        />
        <div
          className={`text-wrapper${maxLines ? ` max-lines-${maxLines}` : ''}`}
          style={collapse && maxHeight ? { maxHeight } : {}}
        >
          <span className="more more__top">
            <label htmlFor={`collapsable-text-component-${id}`} className="opened">
              Свернуть
            </label>
          </span>
          <div>{text}</div>
          <span className="hide-if-need" />
          <span className="more">
            <span className="closed">…{' '}
              <label htmlFor={`collapsable-text-component-${id}`}>
                Еще
              </label>
            </span>
            <label htmlFor={`collapsable-text-component-${id}`} className="opened">
              Свернуть
            </label>
          </span>
        </div>
      </div>
    );
  }
}

export default CollapsibleText;
