/**
 * https://teamcity.addev.alpinadigital.ru/guestAuth/app/rest/builds?locator=start:0,count:3
 * https://teamcity.addev.alpinadigital.ru/httpAuth/app/rest/buildQueue/431
 * https://teamcity.addev.alpinadigital.ru/guestAuth/app/rest/builds?locator=running:true
 * https://teamcity.addev.alpinadigital.ru/httpAuth/app/rest/builds/439/statusIcon.svg
 */
import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import today from 'lib/today';
import { DATETIME_FORMAT_DEFAULT } from 'features/app/consts';
import { toast } from 'features/ui/Toast';
import CardPage from 'features/ui/Page/CardPage';
import { IOffer } from 'features/offers/types';
import {
  INPUT_MD,
  LABEL_MD,
  XS,
} from 'features/ui/FormRow/consts';
import Offers from './Offers';
import request from '../lib/request';
import { IFormValues } from '../types';

import getOffersQuery from '../queries/getOffers.gql';

interface IPageViewProps {
  enabledOffers: IOffer[];
  disabledOffers: IOffer[];
}

class PageView extends React.Component<IPageViewProps> {
  buildFormData(): IFormValues {
    return {
      offers: [],
      type: 's3',
    };
  }

  onSubmit(values: IFormValues, formActions: FormikActions<IFormValues>) {
    if (values.offers.length === 0) {
      toast.info('Пожалуйста, выберите Предложение из списка');
      return;
    }
    request({
      action: 'run',
      offers: values.offers.join(','),
      environment: values.type,
    });
  }

  renderStartButton() {
    return (
      <FormGroup row>
        <Col md="12" xs="12">
          <Button type="submit" color="primary">Начать развёртывание</Button>
        </Col>
      </FormGroup>
    );
  }

  renderForm = (formikProps: FormikProps<IFormValues>) => {
    const {
      enabledOffers,
      disabledOffers,
    } = this.props;
    const {
      handleBlur,
      handleChange,
      handleSubmit,
    } = formikProps;
    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Col md={LABEL_MD}>
            <Label htmlFor="type_select">Тип сборки</Label>
          </Col>
          <Col xs={XS} md={INPUT_MD}>
            <Input
              type="select"
              name="type"
              id="type_select"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="s3">На сервер</option>
              <option value="production">В стор</option>
            </Input>
          </Col>
        </FormGroup>
        {this.renderStartButton()}
        <Offers
          enabledOffers={enabledOffers}
          disabledOffers={disabledOffers}
          formikProps={formikProps}
        />
        {this.renderStartButton()}
      </Form>
    );
  }

  render() {
    return (
      <CardPage title="Развертывание">
        <Formik
          initialValues={this.buildFormData()}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </CardPage>
    );
  }
}

const mapResultsToProps = ({ data, ownProps }: OptionProps) => {
  const offers = get(data, 'OfferQuery.items', []);
  const pagination = get(data, 'OfferQuery.cursor');
  const enabledOffers = [];
  const disabledOffers = [];
  const todayDate = today();
  for (const offer of offers) {
    const date = moment(offer.expire_date, DATETIME_FORMAT_DEFAULT);
    if (date.valueOf() >= todayDate) {
      enabledOffers.push(offer);
    } else {
      disabledOffers.push(offer);
    }
  }
  return {
    ...ownProps,
    enabledOffers,
    disabledOffers,
    pagination,
  };
};

export default graphql(
  getOffersQuery,
  {
    props: mapResultsToProps,
    options: () => ({
      context: { isGlobalLoading: true },
      variables: { perPage: 10000 },
    }),
  },
)(PageView);
