import React from 'react';
import cn from 'classnames';

type IconButton = React.HTMLProps<HTMLButtonElement> & {
  icon: string;
  caption?: string;
};

export default (props: IconButton) => {
  const { type, className, ...rest } = props;
  return (
    <button
      {...rest}
      type={type as any}
      className={cn('btn', 'btn-link', className)}
    >
      {props.caption ? `${props.caption} ` : null}
      <i className={props.icon} />
    </button>
  );
};
