import crudUrlBuilder from 'lib/crudUrlBuilder';
import { ICreator } from 'features/types';

export const makeCreatorUrl = crudUrlBuilder('creator', 'creators');

export const makeCreatorUrlTo = (user: ICreator, action: string = 'view') => {
  return makeCreatorUrl({
    action,
    id: user.id,
  });
};
