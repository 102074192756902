import LinkBlock from 'features/ui/LinkBlock';
import React from 'react';
import { get } from 'lodash';
import { Col } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import i18n from 'features/intl/i18n';
import ReactTable from 'react-table';
import {makeLinkListValue, makeLinkListValueToItem} from 'lib/makeLinkListValue';
import timeFormatter from 'lib/timeFormatter';
import history from 'features/app/history';
import {
  InfoRow,
  InfoRowCourseStages,
  InfoRowImage,
  InfoRowLink,
  InfoRowLinkList,
  InfoRowNotSet,
  InfoRowSwitch,
  InfoRowTags,
  InfoRowText,
} from 'features/ui/InfoRow';
import moment from 'moment';
import { createView } from 'features/common';
import { getCoverUrlForWidth } from 'features/items/lib/cover';
import { makeItemUrlTo } from 'features/items/lib/url';
import { makeOfferUrl } from 'features/offers/lib/url';
import { makeBadgeUrl } from 'features/badges/lib/url';
import { makeCategoryUrl } from 'features/categories/lib/url';
import AudiobooksList from 'features/audiobooks/lib/AudiobooksList';
import VideoPreview from 'features/videos/lib/VideoPreview';
import separateCreators from 'features/creators/lib/separateCreators';
import { makeCreatorUrl } from 'features/creators/lib/url';

import { FEATURE_ICON as BOOKS_FEATURE_ICON } from 'features/books/consts';
import { FEATURE_ICON as HYBRIDS_FEATURE_ICON } from 'features/hybrids/consts';
import { FEATURE_ICON as DOCUMENTS_FEATURE_ICON } from 'features/documents/consts';
import { FEATURE_ICON as AUDIOBOOKS_FEATURE_ICON } from 'features/audiobooks/consts';
import { FEATURE_ICON as VIDEOS_FEATURE_ICON } from 'features/videos/consts';
import { FEATURE_ICON as COLLECTIONS_FEATURE_ICON } from 'features/collections/consts';
import { FEATURE_ICON as EVENTS_FEATURE_ICON } from 'features/events/consts';
import { FEATURE_ICON as COURSES_FEATURE_ICON } from 'features/courses/consts';
import {
  TYPE_AUDIO,
  TYPE_COLLECTION,
  TYPE_VIDEO,
  TYPE_EVENT,
  TYPE_COURSE,
} from 'features/items/consts';

import { IItem } from 'features/items/types';
import { IPrice } from 'features/prices/types';
import styles from './styles.module.scss';
import getEntity from '../queries/getItemQuery.gql';
import Spinner from "features/ui/Spinner";

interface IConfType {
  [key: string]: {
    icon: string;
    crumbs: Array<{
      link: string;
      name: string;
    }>;
  };
}

const View = createView<IItem>(getEntity, 'ItemQuery');

class PageView extends React.PureComponent<WithTranslation> {
  static CONF: IConfType = {
    book: {
      icon: BOOKS_FEATURE_ICON,
      crumbs: [{ link: '/books', name: i18n.t('books:books') }],
    },
    hybrid: {
      icon: HYBRIDS_FEATURE_ICON,
      crumbs: [{ link: '/hybrids', name: i18n.t('hybrids:hybrids') }],
    },
    document: {
      icon: DOCUMENTS_FEATURE_ICON,
      crumbs: [{ link: '/documents', name: i18n.t('documents:documents') }],
    },
    audiobook: {
      icon: AUDIOBOOKS_FEATURE_ICON,
      crumbs: [{ link: '/audiobooks', name: i18n.t('audiobooks:audiobooks') }],
    },
    video: {
      icon: VIDEOS_FEATURE_ICON,
      crumbs: [{ link: '/videos', name: i18n.t('video:video') }],
    },
    collection: {
      icon: COLLECTIONS_FEATURE_ICON,
      crumbs: [{ link: '/collections', name: i18n.t('collections:collections') }],
    },
    events: {
      icon: EVENTS_FEATURE_ICON,
      crumbs: [{ link: '/events', name: i18n.t('events:events') }],
    },
    courses: {
      icon: COURSES_FEATURE_ICON,
      crumbs: [{ link: '/courses', name: i18n.t('course:course') }],
    },
  };

  static COLUMNS = [
    {
      Header: 'ID',
      accessor: 'id',
      className: 'text-right',
      maxWidth: 100,
    },
    {
      Header: 'Прайс-тир',
      id: 'tier',
      accessor: (data: IPrice) => {
        const { tier } = data;
        if (tier) {
          return `${tier.id}: ${tier.rub}`;
        }
        return '';
      },
      maxWidth: 200,
    },
    {
      Header: 'РРЦ',
      accessor: 'reference_price',
      className: 'text-right',
      maxWidth: 100,
    },
    {
      Header: 'InApp Id',
      accessor: 'in_app_id',
    },
    {
      Header: 'App Bundle Id',
      accessor: 'bundle_id',
    },
    {
      Header: 'Код НДС',
      id: 'tax_code',
      accessor: (data: IPrice) => {
        const { tax_code } = data;
        if (tax_code) {
          return `${tax_code}`;
        }
        return '';
      },
    },
  ];

  renderBookType(entity: IItem) {
    const { t } = this.props;
    if (!entity.book) {
      return null;
    }
    return (
      <React.Fragment>
        <InfoRowText label={t('books:type')} value={String(t(`books:${entity.book.type}`))} />
      </React.Fragment>
    );
  }

  renderEvent(entity: IItem) {
    const { t } = this.props;
    if (!entity.event) {
      return null;
    }
    return (
      <React.Fragment>
        <InfoRowSwitch label={t('events:is_autorecord')} value={entity.event.is_autorecord} on="dict:yes" off="dict:no" />
        <InfoRowText label={t('events:started_at')} value={String(t(`events:${moment(entity.event.started_at).format('lll')}`))} />
        <InfoRowText label={t('events:finished_at')} value={String(t(`events:${moment(entity.event.finished_at).format('lll')}`))} />
        <InfoRowText label={t('events:duration')} value={String(t(`events:${entity.event.duration}`))} />
        <InfoRowText label={t('events:link')} value={String(t(`events:${entity.event.link}`))} />
        <InfoRowText label={t('events:description')} value={String(t(`events:${entity.description || ''}`))} />

      </React.Fragment>
    );
  }

  renderBook(entity: IItem) {
    const { t } = this.props;
    if (!entity.book) {
      return null;
    }
    return (
      <React.Fragment>
        <InfoRowText label={t('books:paper_pages')} value={entity.book.paper_pages} />
        <InfoRowText label={t('books:isbn')} value={entity.book.isbn} />
      </React.Fragment>
    );
  }

  renderHybrid(entity: IItem) {
    const { t } = this.props;
    if (!entity.hybrid) {
      return null;
    }
    return (
      <React.Fragment>
        <InfoRowText label={t('hybrids:paper_pages')} value={entity.hybrid.paper_pages} />
        <InfoRowText label={t('hybrids:audio_time')} value={entity.hybrid.audio_time} />
        <InfoRowText label={t('hybrids:isbn')} value={entity.hybrid.isbn} />
      </React.Fragment>
    );
  }

  renderDocument(entity: IItem) {
    const { t } = this.props;
    if (!entity.document) {
      return null;
    }
    return (
      <InfoRowSwitch
        label={t('documents:is_important')}
        value={entity.document.is_important}
        on="dict:yes"
        off="dict:no"
      />
    );
  }

  renderDocumentISBN(entity: IItem) {
    const { t } = this.props;
    if (!entity.document) {
      return null;
    }
    return (
      <InfoRowText label={t('books:isbn')} value={entity.document.isbn} />
    );
  }

  renderAudioType(entity: IItem) {
    const { t } = this.props;
    if (!entity.audiobook) {
      return null;
    }
    return (
      <React.Fragment>
        <InfoRowText label={t('audiobooks:type')} value={String(t(`audiobooks:${entity.audiobook.type}`))} />
      </React.Fragment>
    );
  }

  renderAudiobook(entity: IItem) {
    const { t } = this.props;
    if (!entity.audiobook) {
      return null;
    }
    const { duration } = entity.audiobook;
    return (
      <React.Fragment>
        <InfoRowText label={t('audiobooks:duration')} value={duration && timeFormatter(duration)} />
        <InfoRowText label={t('audiobooks:isbn')} value={entity.audiobook.isbn} />
      </React.Fragment>
    );
  }

  renderNarrator(entity: IItem) {
    const { t } = this.props;
    if (!entity.audiobook) {
      return null;
    }
    const separatedCreators = separateCreators(entity.creators);
    const narratorsLinkListValue = makeLinkListValue(separatedCreators.narrator, 'full_name', makeCreatorUrl);
    return (
      <InfoRowLinkList label={t('audiobooks:narrator')} value={narratorsLinkListValue} />
    );
  }

  renderVideoType(entity: IItem) {
    const { t } = this.props;
    if (!entity.video) {
      return null;
    }
    return (
      <React.Fragment>
        <InfoRowText label={t('video:type')} value={String(t(`video:${entity.video.type}`))} />
      </React.Fragment>
    );
  }

  renderVideo(entity: IItem) {
    const { t } = this.props;

    if (!entity.video) {
      return null;
    }
    const { duration } = entity.video;
    return <InfoRowText label={t('video:duration')} value={duration && timeFormatter(duration)} />;
  }

  renderPrices(entity: IItem) {
    const { t } = this.props;
    const { prices } = entity;
    if (prices === null || prices === undefined || prices.length === 0) {
      return <InfoRowNotSet label={t('prices')} />;
    }
    return (
      <InfoRow label={t('prices')}>
        <ReactTable
          columns={PageView.COLUMNS}
          data={prices}
          minRows={0}
          filterable={false}
          showPagination={false}
        />
      </InfoRow>
    );
  }

  renderCollection(entity: IItem) {
    const { t } = this.props;
    if (!entity.collection) {
      return null;
    }
    return (
      <InfoRowSwitch
        label={t('collections:is_important')}
        value={entity.collection.is_important}
        on="dict:yes"
        off="dict:no"
      />
    );
  }

  renderCollectionWideImage(entity: IItem) {
    const { t } = this.props;
    if (entity.type === TYPE_COLLECTION) {
      return (
        <InfoRowImage
          label={t('image_wide')}
          value={getCoverUrlForWidth(entity, 1846)}
          width={512}
        />
      );
    }
    return null;
  }

  renderContent(entity: IItem) {
    const { t } = this.props;
    const { type } = entity;
    if (type === TYPE_EVENT) {
      return null;
    }
    if (type === TYPE_AUDIO) {
      return (
        <InfoRow label={t('audiobooks:content')}>
          <AudiobooksList list={entity.content} />
        </InfoRow>
      );
    }
    if (type === TYPE_VIDEO) {
      const videoSrc = get(entity, 'content[0].file.path', undefined);
      return (
        <InfoRow label={t('video:content')}>
          <Col xs={6}>
            { videoSrc ?
              <VideoPreview src={videoSrc} />
              :
              <>
                <Spinner/>
                <p className="p-3 mb-2 bg-warning text-dark">{t('video:awaitLoading')}</p>
              </>
            }
          </Col>
        </InfoRow>
      );
    }
    if (type === TYPE_COLLECTION) {
      const childrenItems = entity.childrenItems ? entity.childrenItems : [];
      const value = childrenItems
        .sort((a, b) => (+a.id - +b.id))
        .map(item => ({
          id: item.id,
          to: `/${item.type}/${item.id}`,
          text: `[${item.type}] ${item.name}`,
        }));
      return (
        <InfoRowLinkList
          label={t('collections:childrenItems')}
          value={value}
          byLine={true}
        />
      );
    }
    return (
      <InfoRowLink
        label={t(`${type}s:content`)}
        value={get(entity, 'content[0].file.path', null)}
        text={get(entity, 'content[0].file.path', '').split('/').reverse()[0]}
      />
    );
  }

  renderCourseStages(entity: IItem) {
    const { t } = this.props;
    const { type } = entity;
    if (type !== TYPE_COURSE) {
      return null;
    }
    return (
      <React.Fragment>
        <InfoRowText label={t('course:duration')} value={entity.course ? entity.course.duration : 0} />
        <InfoRowCourseStages label={t('course:stages')} value={entity.childStages} />
      </React.Fragment>
    );
  }

  renderChildren = (entity: IItem) => {
    const { t } = this.props;
    const tagsValue = entity.tags && entity.tags.map(tag => tag.name);
    const offersLinkListValue = makeLinkListValue(entity.offers || [], 'name', makeOfferUrl);
    const relatedSecondaryItems = makeLinkListValueToItem(entity.relatedSecondaryItems || [], 'name', makeItemUrlTo, 'view');
    const recommendedSecondaryItems = makeLinkListValueToItem(entity.recommendedSecondaryItems || [], 'name', makeItemUrlTo, 'view');
    const categoriesLinkListValue = makeLinkListValue(entity.categories || [], 'name', makeCategoryUrl);
    const badgeLinkListValue = makeLinkListValue(entity.badge ? [entity.badge] : [], 'name', makeBadgeUrl);
    const separatedCreators = separateCreators(entity.creators);
    const providersLinkListValue = makeLinkListValue(separatedCreators.provider, 'full_name', makeCreatorUrl);
    const publishersLinkListValue = makeLinkListValue(separatedCreators.publisher, 'full_name', makeCreatorUrl);
    const authorsLinkListValue = makeLinkListValue(separatedCreators.author, 'full_name', makeCreatorUrl);
    return (
      <React.Fragment>
        <LinkBlock editLink={makeItemUrlTo(entity, 'edit')} />
        <InfoRowText label="ID" value={entity.id} />
        {this.renderBookType(entity)}
        {this.renderAudioType(entity)}
        {this.renderVideoType(entity)}
        <InfoRowSwitch label={t('is_active')} value={entity.is_active} on="dict:yes" off="dict:no" />
        {this.renderDocument(entity)}
        <InfoRowSwitch label={t('is_offer_specific')} value={entity.is_offer_specific} on="dict:yes" off="dict:no" />
        <InfoRowSwitch label={t('is_hidden')} value={entity.is_hidden} on="dict:yes" off="dict:no" />
        {this.renderCollection(entity)}

        <InfoRowText label={t('name')} value={entity.name} />
        <InfoRowText label={t('original_name')} value={entity.original_name} />
        {this.renderEvent(entity)}
        <InfoRowText label={t('description')} value={entity.description} className={styles.largeTextFormat} />

        {this.renderContent(entity)}
        <InfoRowImage
          label={t('image')}
          value={getCoverUrlForWidth(entity, 512)}
          width={256}
        />
        {this.renderCollectionWideImage(entity)}
        <InfoRowText label={t('copyright_expire')} value={entity.copyright_expire && moment(entity.copyright_expire).format('ll')} />
        <InfoRowText label={t('age_limit')} value={`${entity.age_limit}+`} />
        <InfoRowLinkList label={t('offers')} value={offersLinkListValue} max={10} byLine={true} />
        <InfoRowLinkList label={t('relatedSecondaryItems')} value={relatedSecondaryItems} max={10} byLine={true} />
        <InfoRowLinkList label={t('recommendedSecondaryItems')} value={recommendedSecondaryItems} max={10} byLine={true} />
        <InfoRowText label={t('recommendation_description')} value={entity.recommendation_description} />
        <InfoRowLinkList label={t('categories')} value={categoriesLinkListValue} max={10} byLine={true} />
        <InfoRowText label={t('language')} value={get(entity, 'language.name', '')} />

        <InfoRowLinkList label={t('provider')} value={providersLinkListValue} />
        <InfoRowLinkList label={t('publisher')} value={publishersLinkListValue} />
        <InfoRowLinkList label={t('authors')} value={authorsLinkListValue} />
        {this.renderNarrator(entity)}
        {this.renderCourseStages(entity)}

        <InfoRowTags label={t('tags')} value={tagsValue} />
        <InfoRowLinkList label={t('badge')} value={badgeLinkListValue} />
        {this.renderDocumentISBN(entity)}
        <InfoRowText label={t('year')} value={entity.year} />
        <InfoRowText label={t('bisac')} value={entity.bisac} />
        <InfoRowText label={t('external_link')} value={entity.external_link} />
        <InfoRowText label={t('external_id')} value={entity.external_id} />

        {this.renderBook(entity)}
        {this.renderHybrid(entity)}
        {this.renderAudiobook(entity)}
        {this.renderVideo(entity)}
        {this.renderPrices(entity)}

        <InfoRowText label={t('position_latest')} value={entity.position_latest} />
        <InfoRowText label={t('position_bestsellers')} value={entity.position_bestsellers} />

        <hr />
        <InfoRowText label={t('dict:updated')} value={moment(entity.updated_at).format('lll')} />
        <InfoRowText label={t('dict:created')} value={moment(entity.created_at).format('lll')} />
      </React.Fragment>
    );
  };

  render() {
    let conf;
    const typeOfItem = history.location.pathname.split('/')[1];
    if (PageView.CONF[typeOfItem]) {
      conf = PageView.CONF[typeOfItem];
    } else {
      conf = { icon: '', crumbs: [] };
    }
    return (
      <View
        titleField="name"
        icon={conf.icon}
        crumbs={conf.crumbs}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('items')(PageView);
