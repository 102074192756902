import React from 'react';
import * as Yup from 'yup';
import { Formik, FormikActions, FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { crudMutate } from 'features/common/helpers';
import editUserSubscription from 'features/userSubscriptions/queries/editUserSubscription.gql';
import createUserSubscription from 'features/userSubscriptions/queries/createUserSubscription.gql';
import { makeUsersSubscriptionUrl } from 'features/userSubscriptions/lib/url';
import { stringValidator } from 'lib/validators';
import { IUserSubscription } from 'features/userSubscriptions/types';
import filterChangedValues from 'lib/filterChangedValues';
import BaseUserSubscriptionForm from './BaseUserSubscriptionForm';
import field from 'lib/field';

interface IUserSubscriptionFormProps extends WithTranslation {
  userSubscription?: IUserSubscription[];
  isCreateForm?: boolean;
}

class UserSubscriptionForm extends React.Component<IUserSubscriptionFormProps> {
  getScheme() {
    const {
      t,
    } = this.props;
    const required = true;

    return Yup.object().shape({
      from: stringValidator({ required, field: t('from') }).nullable(true),
      to: stringValidator({ required, field: t('to') }).nullable(true),
      user: Yup.number().required(t('forms:required_field', { field: t('user') })).nullable(true),
      subscription: Yup.number().required(t('forms:required_field', { field: t('subscription') })).nullable(true),
    });
  }

  getChangedValues(values: IUserSubscription) {
    const formData = this.buildFormData();
    return filterChangedValues(formData, values);
  }

  onSubmit = (
    values: IUserSubscriptionFormProps,
    formActions: FormikActions<IUserSubscription>,
  ) => {
    let userSubscription = { ...values };
    let id;
    if (this.props.userSubscription) {
      userSubscription = this.getChangedValues(values);
      id = this.props.userSubscription.id;
    }
    delete userSubscription.cost;

    crudMutate({
      id,
      formActions,
      variables: id ? { id, userSubscription } : { userSubscription },
      mutation: id ? editUserSubscription : createUserSubscription,
      redirect: makeUsersSubscriptionUrl(),
      check: !!Object.keys(userSubscription).length,
    });
  }

  buildFormData() {
    const { userSubscription } = this.props;
    return {
      user: field(userSubscription, 'user.id', null),
      subscription: field(userSubscription, 'subscription.id', null),
      from: field(userSubscription, 'from', null),
      to: field(userSubscription, 'to', null),
      cost: field(userSubscription, 'cost', null),
    };
  }

  render() {
    const { userSubscription } = this.props;
    return (
      <Formik
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getScheme()}
        onDelete={() => {}}
      >
        {(props: FormikProps<IUserSubscription>) => <BaseUserSubscriptionForm {...props} userSubscription={userSubscription} />}
      </Formik>
    );
  }
}

export default withTranslation('user_subscriptions')(UserSubscriptionForm);
