import React from 'react';
import { set } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import getPromocodesQuery from 'features/promocodes/queries/getPromocodesQuery.gql';
import resolveColumns from './columns';
import deletePromocodeMutation from 'features/promocodes/queries/deletePromocodeMutation.gql';
import { makePromocodeUrl } from 'features/promocodes/lib/url';

import { Column } from 'features/types';

type IDataTableProps = WithTranslation;

class DataTable extends React.PureComponent<IDataTableProps> {
  columns: Column[];

  constructor(props: IDataTableProps) {
    super(props);

    this.columns = resolveColumns().filter(col => !!col) as Column[];
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.is_active) {
      set(variables, 'filters.is_active', { operator: '=', value: fields.is_active });
    }
    if (fields.offer) {
      set(variables, 'filters.offer', { operator: '=', value: fields.offer });
    }
    if (fields.code) {
      set(variables, 'filters.code', { operator: '=', value: fields.code });
    }
    if (fields.used) {
      set(variables, 'filters.used', { operator: '=', value: fields.used });
    }
    if (fields.count) {
      set(variables, 'filters.count', { operator: '=', value: fields.count });
    }
    if (fields.expire_date) {
      set(variables, 'filters.expire_date', { operator: '=', value: fields.expire_date });
    }
    if (fields.item) {
      set(variables, 'filters.items', {
        operator: '=',
        value: `${fields.item}`,
      });
    }
  };

  render() {
    return (
      <IndexTableBase
        columns={this.columns}
        filterFields={['id', 'is_active', 'offer', 'code', 'used', 'count', 'expire_date', 'item']}
        prepareRequestData={this.prepareRequestData}
        query={getPromocodesQuery}
        queryResultName="PromoCodeQuery"
        deleteMutation={deletePromocodeMutation}
        makeCrudUrl={makePromocodeUrl}
        showViewIconColumn={true}
      />
    );
  }
}

export default withTranslation()(DataTable);
