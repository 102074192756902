import React from 'react';
import {
  get,
} from 'lodash';
import { DocumentNode } from 'graphql';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import field from 'lib/field';
import filterChangedValues from 'lib/filterChangedValues';
import { stringValidator } from 'lib/validators';
import { crudMutate } from 'features/common/helpers';
import * as Yup from 'yup';
import { ILayout } from 'features/layouts/types';
import { ILayoutFormValues } from 'features/blocks/types';
import { makeLayoutBlockUrl } from '../url';
import BaseLayoutForm from './BaseLayoutForm';

interface ICategoryFormProps extends WithTranslation {
  block?: ILayout;
  mutation: DocumentNode;
}

class LayoutForm extends React.Component<ICategoryFormProps> {
  getValidationScheme() {
    const { t } = this.props;
    const required = true;
    const validators: any = {
      name: stringValidator({ required, field: t('name') }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: ILayoutFormValues) {
    const formData = this.buildFormData();
    if (formData) {
      return filterChangedValues(formData, values);
    }
    return values;
  }

  onSubmit = (
    formData: ILayoutFormValues,
    formActions: FormikActions<ILayoutFormValues>,
  ) => {
    const { mutation } = this.props;
    const id = get(this.props, 'block.id', undefined);
    const variables = (id ? { id, layout_block: this.getChangedValues(formData) } : { layout_block: { ...formData } });
    const item_types = variables.layout_block.view && variables.layout_block.view.item_types;
    if (item_types && item_types.length === 0) {
      variables.layout_block.view.item_types = null;
    }
    crudMutate({
      id,
      formActions,
      variables,
      mutation,
      redirect: makeLayoutBlockUrl(),
      check: !!Object.keys(variables.layout_block).length,
    });
  };

  buildFormData(): ILayoutFormValues {
    const { block } = this.props;
    const removeEmptyViewFields = (obj: any) => {
      Object.keys(obj).forEach(key => obj[key] === null && delete obj[key]);
      return obj;
    };

    return {
      name: field(block, 'name', ''),
      type: field(block, 'type.id', ''),
      items: block && block.items ? block.items.map(itm => itm.id) : [],
      references: block && block.references ? block.references.map(ref => ref.id) : [],
      view: removeEmptyViewFields(field(block, 'view', {
        bg_color: '#F4F4F4',
        item_types: null,
      })),
    };
  }

  renderForm = (props: FormikProps<ILayoutFormValues>) => {
    const { block } = this.props;
    return <BaseLayoutForm {...props} block={block} />;
  };

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getValidationScheme()}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('blocks')(LayoutForm);
