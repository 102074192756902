import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { get } from 'lodash';
import {
  InfoRowDateBlock,
  InfoRowLink,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { makeUserGroupUrl } from 'features/usergroups/lib/url';
import { makeOfferUrl } from 'features/offers/lib/url';
import { createView } from 'features/common';
import getEntity from '../queries/getUserGroupQuery.gql';

import { FEATURE_ICON } from '../consts';

import { IUserGroup } from '../types';

const View = createView<IUserGroup>(getEntity, 'UserGroupQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderChildren = (user: IUserGroup) => {
    const { t } = this.props;
    const offerId = get(user, 'offer.id', null);
    return (
      <React.Fragment>
        <LinkBlock editLink={makeUserGroupUrl({ id: user.id, action: 'edit' })} />
        <InfoRowText label="ID" value={user.id} />
        <InfoRowText label={t('name')} value={user.name} />
        <InfoRowText label={t('alias')} value={user.alias} />
        <InfoRowLink
          label={t('offers:offer')}
          id={offerId}
          value={offerId && makeOfferUrl({ id: offerId, action: 'view' })}
          text={get(user, 'offer.name', '')}
        />
        <hr />
        <InfoRowDateBlock value={user} />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField={'name'}
        icon={FEATURE_ICON}
        crumbs={[{ link: makeUserGroupUrl(), name: t('usergroups') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('usergroups')(PageView);
