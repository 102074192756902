import React from 'react';
import { match as IMatch } from 'react-router';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { AccessControl } from 'features/acl';
import { Page404 } from 'features/http';
import PageOffers from '../PageOffers';
import PageCreate from '../PageCreate';
import PageEdit from '../PageEdit';
import PageView from '../PageView';

export default ({ match }: { match: IMatch<any> }) => {
  return (
    <AccessControl permission="offers:access">
      <Switch>
        <AppRoute path={'/offer/create'} menuId="offers" component={PageCreate} exact />
        <AppRoute path={'/offer/edit/:id/:part?'} menuId="offers" component={PageEdit} exact />
        <AppRoute path={'/offers'} menuId="offers" component={PageOffers} exact />
        <AppRoute path={'/offer/:id/:part?'} menuId="offers" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
