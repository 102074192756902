import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {set} from 'lodash';
import CardPage from 'features/ui/Page/CardPage';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import { IFeaure } from 'features/featureToggle/types';
import { FEATURE_ICON } from 'features/featureToggle/consts';
import makeColumns from './columns';
import getFeaturesQuery from '../queries/getFeatures.gql';
import { makeFeatureUrl } from '../lib/url';
import deleteFeatureQuery from '../queries/deleteFeature.gql';

class PageFeature extends React.Component<IFeaure> {
  constructor(props: IFeaure) {
    super(props);
    this._columns = makeColumns();
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.name) {
      set(variables, 'filterByFields.name', {
        operator: '=',
        value: fields.name,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        buttons={[{ link: '/feature-toggle/create', name: t('add_new_feature') }]}
        title={t('features')}
      >
        <IndexTableBase
          query={getFeaturesQuery}
          queryResultName="FeatureQuery"
          columns={this._columns}
          makeCrudUrl={makeFeatureUrl}
          filterFields={['id', 'name']}
          deleteMutation={deleteFeatureQuery}
          prepareRequestData={this.prepareRequestData}
          isFilterableById={true}
          showAutoEditIconColumn={true}
          showViewIconColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('features')(PageFeature);
