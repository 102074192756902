import React from 'react';
import {
  flow,
  get,
} from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  gql,
} from 'features/graphql';
import { ReactSelectWithId } from 'features/ui/ReactSelect';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  IBadge,
  IBadgeQueryResponse,
} from 'features/badges/types';

interface IBadgeOptionType {
  image: null | string;
  label: string;
  value: number;
}

interface IBadgesSelectProps extends ISharedSelectProps {
  data: IBadge[];
}

interface IBadgesSelectState {
  options: IBadgeOptionType[];
}

class BadgesSelect extends React.PureComponent<IBadgesSelectProps, IBadgesSelectState> {
  static getDerivedStateFromProps(props: any) {
    const { t } = props;
    const badges = props
      .data
      .map((entity: IBadge) => ({
        value: +entity.id,
        label: entity.name,
        image: get(entity, 'image.path', null),
      }))
      .sort((a: ISelectOption<number>, b: ISelectOption<number>) => (a.value - b.value));
    return {
      options: [{
        label: t('dict:not_selected'),
        value: 0,
        image: null,
      }].concat(badges),
    };
  }

  state = {
    options: [],
  };

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelectWithId
        id={id}
        name={name}
        options={options}
        isMulti={false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

const QUERY = gql`
  query getBadgesForSelect($pageNum: Int, $perPage: Int) {
    BadgeQuery(page: $pageNum, perPage: $perPage) {
      items {
        id
        name
        image {
          id
          path
        }
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, IBadgeQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.BadgeQuery ? data.BadgeQuery.items : [],
    ...ownProps,
  };
};

export default flow(
  graphql<ISharedSelectProps & WithTranslation, IBadgeQueryResponse, IListQueryVariables, any>(QUERY, {
    props: mapResultsToProps,
    options: () => {
      return {
        variables: {
          pageNum: 1,
          perPage: 5000,
        },
      };
    },
  }),
  withTranslation('badges'),
)(BadgesSelect);
