import React from 'react';
import {
  Col,
  FormGroup,
  FormText,
  Label,
} from 'reactstrap';
import { get } from 'lodash';
import {
  FastField,
  FormikContext,
  GenericFieldHTMLAttributes,
} from 'formik';
import FormFieldFeedback from 'features/ui/FormFieldFeedback';
import {
  INPUT_MD,
  LABEL_MD,
  XS,
} from './consts';
import styles from './styles.module.scss';

interface IFormikFieldProps {
  field: any;
  form: any;
}

interface IFormRowProps {
  id: string;
  label: string;
  required?: boolean;
  text?: string;
  error?: string | boolean;
  errorPath?: string;
  shouldUpdate?: (
    nextProps: GenericFieldHTMLAttributes & { formik: FormikContext<any> },
    props: {},
  ) => boolean;
}

class FormRow extends React.PureComponent<IFormRowProps> {
  renderError(form: any) {
    const {
      id,
      error,
      errorPath,
    } = this.props;
    const { errors } = form;
    let message;
    if (error) {
      message = error;
    } else if (errorPath) {
      for (const path of errorPath.split('|')) {
        const err = get(errors, path, null);
        if (typeof err === 'string') {
          message = err;
          break;
        }
      }
    } else {
      message = get(errors, id, null);
    }
    if (message) {
      if (Array.isArray(message)) {
        return <FormFieldFeedback color="danger" text={message.filter(msg => msg).flatMap(msg => Object.values(msg)).join('\n')} />;
      }
      return <FormFieldFeedback color="danger" text={message} />;
    }
    return null;
  }

  renderRequired = () => {
    const { required } = this.props;
    if (required) {
      return (
        <span
          className="text-danger"
          title="Обязательное поле"
        >
          *
        </span>
      );
    }
    return null;
  }

  renderRow = (formikBag: IFormikFieldProps) => {
    const { form } = formikBag;
    const {
      id,
      label,
      text,
      children,
    } = this.props;
    const { errors } = form;

    return (
      <FormGroup row>
        <Col md={LABEL_MD} className={get(errors, id, null) ? styles.invalidLabel : null}>
          <Label htmlFor={id}>{label}{this.renderRequired()}</Label>
        </Col>
        <Col xs={XS} md={INPUT_MD}>
          <React.Fragment>
            {children}
            {this.renderError(form)}
          </React.Fragment>
          {text ? <FormText color="muted">{text}</FormText> : null}
        </Col>
      </FormGroup>
    );
  };

  render() {
    const {
      id,
      shouldUpdate,
    } = this.props;
    return (
      <FastField name={id} render={this.renderRow} shouldUpdate={shouldUpdate} />
    );
  }
}

export default FormRow;
