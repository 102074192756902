import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { AccessControl } from 'features/acl';
import { Page404 } from 'features/http';
import PageCreate from 'features/subscriptions/PageCreate';
import PageEdit from 'features/subscriptions/PageEdit';
import PageSubscription from 'features/subscriptions/PageSubscription';
import PageView from 'features/subscriptions/PageView';

export default () => (
    <AccessControl permission="offers:access">
      <Switch>
        <AppRoute path="/subscriptions" menuId="subscriptions" component={PageSubscription} exact />
        <AppRoute path="/subscriptions/create" menuId="subscriptions" component={PageCreate} exact />
        <AppRoute path="/subscriptions/edit/:id" menuId="subscriptions" component={PageEdit} exact />
        <AppRoute path="/subscriptions/:id" menuId="subscriptions" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
);
