import React from 'react';
import Message from '../helpers/Message';

export default () => {
  return (
    <Message
      code={403}
      title={'Forbidden!'}
      description={'You don’t have permission.'}
    />
  );
};
