import React from 'react';
import {
  Nav,
  NavItem,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import CardPage from 'features/ui/Page/CardPage';
import Empty from 'features/ui/Empty';
import { FEATURE_ICON } from 'features/autoimport/consts';
import Table from 'features/autoimport/lib/Table';
import Form from 'features/autoimport/lib/Form';

class PageIndex extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink className="nav-link active" to="/autoimports">
              <i className={FEATURE_ICON} />
              &nbsp;
              Итемы
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" to="/autoprices">
              <i className={FEATURE_ICON} />
              &nbsp;
              Цены
            </NavLink>
          </NavItem>
        </Nav>
        <CardPage
          icon={FEATURE_ICON}
          title="Автозаливатор"
          renderHeader={Empty}
        >
          <Form />
          <hr />
          <Table />
        </CardPage>
      </React.Fragment>
    );
  }
}

export default PageIndex;
