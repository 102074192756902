import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import InputSelect from 'features/ui/InputSelect';

import { UserRoleEnum } from 'features/types';

interface IUserRoleSelectProps extends WithTranslation {
  id?: string;
  name?: string;
  value: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
}

let types: { [type: string]: string } | null = null;

const UserRoleSelect = (props: IUserRoleSelectProps) => {
  const {
    t,
    value,
  } = props;
  if (types === null) {
    types = {
      '': '-',
      [UserRoleEnum.user]: t(UserRoleEnum.user),
      [UserRoleEnum.hr]: t(UserRoleEnum.hr),
      [UserRoleEnum.promo]: t(UserRoleEnum.promo),
      [UserRoleEnum.sales]: t(UserRoleEnum.sales),
      [UserRoleEnum.outsource]: t(UserRoleEnum.outsource),
      [UserRoleEnum.administrator]: t(UserRoleEnum.administrator),
      [UserRoleEnum.support]: t(UserRoleEnum.support),
    };
  }
  return (
    <InputSelect
      {...props}
      value={value || ''}
      options={types}
    />
  );
};

export default withTranslation('users')(UserRoleSelect);
