import React from 'react';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/usergroups/consts';
import { makeUserGroupUrl } from 'features/usergroups/lib/url';
import UsersTable from 'features/usergroups/lib/Table';

class PageIndex extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Группы пользователей"
        buttons={[{ link: makeUserGroupUrl({ action: 'create' }), name: 'Создать группу пользователя' }]}
      >
        <UsersTable />
      </CardPage>
    );
  }
}

export default PageIndex;
