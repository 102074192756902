import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import UserForNotifySelect from 'features/offers/lib/UserForNotifySelect';
import FormRow from 'features/ui/FormRow';
import { IOfferSettingsContentFormValues } from '../types';

interface IFormProps extends FormikProps<IOfferSettingsContentFormValues>, WithTranslation {
  id?: string;
}

class BaseForm extends React.Component<IFormProps> {
  onChangeUsers = (users: string[], field: string) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, users);
  }

  renderNotifications() {
    const { handleBlur, values: { offerId, notifications, ...notificationsData } } = this.props;
    return (
      <>
        {Object.values(notificationsData).map((usersList, index) => {
          const id = Object.keys(notificationsData)[index];
          const { name } = notifications.filter(noty => noty.key === Object.keys(notificationsData)[index])[0];
          const { description } = notifications.filter(noty => noty.key === Object.keys(notificationsData)[index])[0];
          return (
            <FormRow
              key={id}
              id={id}
              label={name}
              text={description}
            >
              <UserForNotifySelect
                id={id}
                name={id}
                isMulti
                offer={+offerId}
                value={usersList}
                onChange={(data: string[]) => this.onChangeUsers(data, id)}
                onBlur={handleBlur}
              />
            </FormRow>
          );
        })}
      </>
    );
  }

  render() {
    const { id } = this.props;
    return (
      <FormFrame
        id={id}
        cancelLink="/offers"
        {...this.props}
      >
        {this.renderNotifications()}
      </FormFrame>
    );
  }
}

export default withTranslation('offers')(BaseForm);
