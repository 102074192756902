import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import YesNoSelect, {
  IYesNoSelectProps,
} from 'features/ui/YesNoSelect';

const DeletedSelector = (props: IYesNoSelectProps & WithTranslation) => {
  return (
    <YesNoSelect
      {...props}
      yesCaption={props.t('deleted2')}
      noCaption={props.t('available')}
    />
  );
};

export default withTranslation('dict')(DeletedSelector);
