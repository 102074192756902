import React from 'react';
import Form from 'features/collections/lib/Form';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/collections/consts';
import { makeCollectionUrl } from 'features/collections/lib/url';

class PageCreate extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Создание новой Коллекции"
        crumbs={[{ link: makeCollectionUrl(), name: 'Коллекции' }]}
      >
        <Form />
      </CardPage>
    );
  }
}

export default PageCreate;
