import React from 'react';
import { get } from 'lodash';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { crudMutate } from 'features/common/helpers';
import BaseForm from './Form';
import { STATUS_VALIDATED } from 'features/autoprice/consts';

import getAutoPriceQuery from 'features/autoprice/queries/getAutoPriceQuery.gql';
import getAutoPricesQuery from 'features/autoprice/queries/getAutoPricesQuery.gql';
import createAutoPriceMutation from 'features/autoprice/queries/createAutoPriceMutation.gql';

type IFormProps = WithTranslation;

class Form extends React.PureComponent<IFormProps> {
  state = {
    validated: false,
  };

  onSubmit = (values: {}, formActions: FormikActions<{}>) => {
    crudMutate({
      formActions,
      mutation: createAutoPriceMutation,
      variables: values,
      updateRefetchQuery: getAutoPriceQuery,
      createRefetchQuery: getAutoPricesQuery,
      preventDefaultOnCreation: true,
    }).then((res) => {
      const status = get(res, 'data.AutoPriceMutation.status', null);
      this.setState({ validated: status === STATUS_VALIDATED });
    });
  };

  renderForm = (props: FormikProps<{}>) => (<BaseForm {...props} validated={this.state.validated} />);

  validationSchema = () => {
    return Yup.object().shape({});
  };

  resolveInitialValues = () => ({});

  render() {
    return (
      <Formik
        validationSchema={this.validationSchema()}
        enableReinitialize
        initialValues={this.resolveInitialValues()}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation()(Form);
