export type PrefixFunc = (v: string) => string;

export function buildString(
  {
    prefix,
    name,
  }: {
    prefix?: string | PrefixFunc,
    name: string,
  },
) {
  if (typeof prefix === 'string') {
    return `${prefix}${name}`;
  }
  if (typeof prefix === 'function') {
    return prefix(name);
  }
  return name;
}

export function makeLabel(
  {
    labelPrefix: prefix,
    name,
  }: {
    labelPrefix?: string | PrefixFunc,
    name: string,
  },
) {
  return buildString({ name, prefix });
}

export function makeName(
  {
    namePrefix: prefix,
    name,
  }: {
    namePrefix?: string | PrefixFunc,
    name: string,
  },
) {
  return buildString({ name, prefix });
}
