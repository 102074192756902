import crudUrlBuilder from 'lib/crudUrlBuilder';
import { IItem } from '../../types';

export const makeItemUrl = crudUrlBuilder('item', 'items');

export const makeItemUrlTo = (item: IItem, action = 'edit') => {
  if (action === 'view') {
    return `/${item.type}/${item.id}`;
  }
  return `/${item.type}/${action}/${item.id}`;
};
