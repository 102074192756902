import React from 'react';
import graphql from './graphql';
import CollapsibleText from 'features/ui/CollapsibleText';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import {
  IOffer,
  ISelectOption,
} from 'features/types';

interface IOffersSelectProps {
  value: ID[];
  data: IOffer[];
}

interface IOffersSelectState {
  options: ISelectOption[];
}

class OffersSelectView extends React.PureComponent<IOffersSelectProps, IOffersSelectState> {
  render() {
    const {
      data,
    } = this.props;

    return (
      <CollapsibleText
        id="view_offers"
        text={
          <React.Fragment>{
            data.map(item => (
              <div key={item.id}>
                <LinkToEntityInLists
                  id={item.id}
                  name={item.name}
                  to={`/offer/${item.id}`}
                />
              </div>
            ))}
          </React.Fragment>
        }
      />
    );
  }
}

export default graphql(OffersSelectView);
