import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { Redirect } from 'react-router';
import { IOffer } from 'features/offers/types';
import Spinner from 'features/ui/Spinner';
import OfferForm from './OfferForm';

interface IPageEditProps extends WithTranslation {
  isLoading: boolean;
  offer: IOffer | null;
}

class PageEdit extends React.Component<IPageEditProps> {
  render() {
    const {
      isLoading,
      offer,
    } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (!offer) {
      return <Redirect to="/404" />;
    }

    return <OfferForm offer={offer} />;
  }
}

export default withTranslation('offers')(PageEdit);
