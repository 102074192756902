import React from 'react';
import { filterInputHandler } from 'features/common';
import CreatorsTypeSelect from 'features/creators/lib/CreatorsTypeSelect';
import LinkToItems from '../LinkToItems';

import {
  ICreator,
  RowRenderProps,
} from 'features/types';
import { ICreatorsDataTableProps } from './types';

export default (props: ICreatorsDataTableProps) => ([
  {
    accessor: 'type',
    Header: 'Тип',
    maxWidth: 100,
    Cell: (row: RowRenderProps) => props.t(`creators:types.${row.value}`),
    Filter: ({ filter, onChange }: any) => {
      const handler = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);
      return (
        <CreatorsTypeSelect
          onChange={handler}
          value={filter && filter.value}
          emptyOption={{ value: '', label: '-' }}
        />
      );
    },
  },
  {
    accessor: 'full_name',
    Header: 'Полное название',
    Filter: filterInputHandler,
  },
  {
    accessor: 'short_name',
    Header: 'Сокращенное название',
    Filter: () => <div />,
  },
  {
    accessor: 'first_name',
    Header: 'Имя',
    Filter: filterInputHandler,
  },
  {
    accessor: 'last_name',
    Header: 'Фамилия',
    Filter: filterInputHandler,
  },
  {
    id: 'items',
    accessor: (data: ICreator) => <LinkToItems creator={data} />,
    Header: 'Итемы',
    maxWidth: 400,
    filterable: false,
  },
]);
