import React from 'react';
import { get } from 'lodash';
import { Input } from 'reactstrap';
import { readImageFile } from './helpers';
import Property from './Property';
import {
  ColorFillInput,
  HeightInput,
  IWithFormikProps,
  NameInput,
  WidthInput,
  withFormik,
  XInput,
  YInput,
} from './inputs';
import { IImage } from 'banner-editor-component';

class ImageForm extends React.Component<IWithFormikProps<IImage>> {
  onChangeFillColor = (color: string) => {
    const {
      setFieldValue,
      submitForm,
    } = this.props;
    setFieldValue('fillColor', color);
    setTimeout(submitForm, 0);
  };

  onFileChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const {
      setFieldValue,
      submitForm,
    } = this.props;
    const file = get(e, 'currentTarget.files[0]', null);
    if (file) {
      readImageFile(file, (image: HTMLImageElement) => {
        setFieldValue('image', image.src);
        setFieldValue('width', image.width);
        setFieldValue('height', image.height);
        setTimeout(submitForm, 0);
      });
    }
  };

  render() {
    const {
      values,
      handleSubmit,
      onChange,
      colors,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <NameInput value={values.name} onChange={onChange} />

        <Property name="Изображение" id="image">
          <Input
            id="image"
            name="image"
            type="file"
            onChange={this.onFileChange}
          />
        </Property>

        <Property name="Отразить" id="isReflected">
          <Input
            id="isReflected"
            name="isReflected"
            type="checkbox"
            onChange={onChange}
            checked={values.isReflected}
          />
        </Property>

        <ColorFillInput
          value={values.fillColor}
          onChange={this.onChangeFillColor}
          colors={colors}
        />

        <XInput value={values.x} onChange={onChange} />
        <YInput value={values.y} onChange={onChange} />
        <WidthInput value={values.width} onChange={onChange} />
        <HeightInput value={values.height} onChange={onChange} />
      </form>
    );
  }
}

export default withFormik<IImage>(ImageForm);
