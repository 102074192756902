import React from 'react';
import { get } from 'lodash';
import {
  Input,
  Label,
} from 'reactstrap';
import {
  Formik,
  FormikProps,
} from 'formik';
import { IScene } from 'banner-editor-component';
import { NameInput } from './inputs';
import { readTextFile } from './helpers';
import Property from './Property';

import style from '../style.module.scss';

interface IPropertySceneProps {
  element: IScene;
  onSubmit: (values: IScene) => void;
  onImport: (data: string) => void;
}

export default class extends React.PureComponent<IPropertySceneProps> {
  onChangeFile = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const file = get(e, 'target.files[0]', null);
    readTextFile(file, this.props.onImport);
  }

  renderForm = ({
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    setFieldValue,
  }: FormikProps<IScene>) => {
    const onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
      handleChange(e);
      setTimeout(submitForm, 0);
    };
    const json = encodeURIComponent(JSON.stringify(values, null, '\t'));
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit}>
          <NameInput value={values.name} onChange={onChange} />

          <Property name="Отобразить фон" id="hasBackground">
            <Input
              id="hasBackground"
              name="hasBackground"
              type="checkbox"
              onChange={onChange}
              checked={values.hasBackground}
            />
          </Property>
        </form>
        <div className={style.aloneProperty}>
          <a
            className="btn btn-primary"
            download="banner.json"
            href={`data:text/json;charset=utf-8,${json}`}
          >
            Экспорт сцены в файл
          </a>
        </div>
        <div className={style.aloneProperty}>
          <Label for="file">Импорт сохраненной сцены:</Label>
          <Input
            id="file"
            type="file"
            onChange={this.onChangeFile}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      element,
      onSubmit,
    } = this.props;
    return (
      <Formik
        initialValues={element}
        onSubmit={onSubmit}
        render={this.renderForm}
      />
    );
  }
}
