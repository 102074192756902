import React from 'react';
import {
  isEqual,
  set,
} from 'lodash';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import * as Yup from 'yup';
import { crudMutate } from 'features/common/helpers';
import { colorValidator } from 'lib/validators';
import field from 'lib/field';
import BaseForm from './BaseForm';
import {
  IOffer,
  IOfferSettingsBuild,
  IOfferSettingsBuildAnalyticFormValues,
  IOfferSettingsBuildExternalFormValues,
  IOfferSettingsBuildFormValues,
  IOfferSettingsBuildInput,
  IOfferSettingsBuildSocialFormValues,
} from '../types';

import editOfferQuery from '../queries/editOffer.gql';

interface IFormProps extends WithTranslation {
  offer: IOffer;
}

class Form extends React.Component<IFormProps> {
  getScheme() {
    const { t } = this.props;
    return Yup.object().shape({
      ...([
        'android_branding_primary_color',
        'android_branding_bar_color',
        'android_branding_selected_button_color',
        'android_branding_unselected_button_color',
        'android_branding_background_splash_color',
        'android_branding_splash_text_color',
        'android_branding_splash_subtitle_color',
        'android_branding_primary_dark_color',
        'android_branding_primary_light_color',
        'android_branding_accent_color',
        'android_branding_accent_dark_color',
        'android_branding_text_primary_color',
        'android_branding_secondary_text_color',
        'android_branding_icons_color',
        'android_branding_divider_color',
        'android_branding_background_light_color',
        'ios_branding_primary_color',
        'ios_branding_bar_color',
        'ios_branding_selected_button_color',
        'ios_branding_unselected_button_color',
        'ios_branding_background_splash_color',
        'ios_branding_splash_text_color',
        'ios_branding_splash_subtitle_color',
      ].reduce(
        (acc: {[key: string]: Yup.StringSchema}, key) => {
          acc[key] = colorValidator({ field: t(key.replace(/^(android|ios)/, 'build')) });
          return acc;
        },
        {},
      )),
      externals: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().trim().required(t('forms:required_field', { field: t('build_externals_type') })),
        }),
      ),
      socials: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().trim().required(t('forms:required_field', { field: t('build_socials_type') })),
        }),
      ),
      analytics: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().trim().required(t('forms:required_field', { field: t('build_analytics_type') })),
        }),
      ),
    });
  }

  getFormData(): IOfferSettingsBuildFormValues {
    const platform = (array: any[], name: string) => array.map(a => ({ ...a, platform: name }));
    const { offer } = this.props;
    let android;
    let ios;
    let externals: IOfferSettingsBuildExternalFormValues[] = [];
    let socials: IOfferSettingsBuildSocialFormValues[] = [];
    let analytics: IOfferSettingsBuildAnalyticFormValues[] = [];
    if (offer) {
      android = offer.settingsBuildAndroid as IOfferSettingsBuild;
      if (android && android.externals) {
        externals = [...platform(android.externals, 'android')];
      }
      if (android && android.socials) {
        socials = [...platform(android.socials, 'android')];
      }
      if (android && android.analytics) {
        analytics = [...platform(android.analytics, 'android')];
      }
      ios = offer.settingsBuildIos as IOfferSettingsBuild;
      if (ios && ios.externals) {
        externals = [...externals, ...platform(ios.externals, 'ios')];
      }
      if (ios && ios.socials) {
        socials = [...socials, ...platform(ios.socials, 'ios')];
      }
      if (ios && ios.analytics) {
        analytics = [...analytics, ...platform(ios.analytics, 'ios')];
      }
    }
    return {
      externals,
      socials,
      analytics,
      android_bundle_id: field(android, 'bundle_id', ''),
      android_features_corporate: field(android, 'features.corporate', false),
      android_features_in_app_purchase: field(android, 'features.in_app_purchase', false),
      android_features_books: field(android, 'features.books', false),
      android_features_content_table: field(android, 'features.content_table', false),
      android_features_documents: field(android, 'features.documents', false),
      android_features_banners: field(android, 'features.banners', false),
      android_features_badges: field(android, 'features.badges', false),
      android_features_wishlist: field(android, 'features.wishlist', false),
      android_features_comments: field(android, 'features.comments', false),
      android_features_push_notification: field(android, 'features.push_notification', false),
      android_features_local_notification: field(android, 'features.local_notification', false),
      android_features_required_login: field(android, 'features.required_login', false),
      android_features_socials: field(android, 'features.socials', false),
      android_features_pins: field(android, 'features.pins', false),
      android_features_user_groups: field(android, 'features.user_groups', false),
      android_features_analytic: field(android, 'features.analytic', false),
      android_features_app_rate: field(android, 'features.app_rate', false),
      android_interface_title_showcase: field(android, 'interface.title_showcase', ''),
      android_interface_title_my_collection: field(android, 'interface.title_my_collection', ''),
      android_interface_title_documents: field(android, 'interface.title_documents', ''),
      android_interface_title_my_documents: field(android, 'interface.title_my_documents', ''),
      android_branding_title: field(android, 'branding.title', ''),
      android_branding_splash_title: field(android, 'branding.splash_title', ''),
      android_branding_splash_subtitle: field(android, 'branding.splash_subtitle', ''),
      android_branding_primary_color: field(android, 'branding.primary_color', ''),
      android_branding_bar_color: field(android, 'branding.bar_color', ''),
      android_branding_selected_button_color: field(android, 'branding.selected_button_color', ''),
      android_branding_unselected_button_color: field(android, 'branding.unselected_button_color', ''),
      android_branding_background_splash_color: field(android, 'branding.background_splash_color', ''),
      android_branding_splash_text_color: field(android, 'branding.splash_text_color', ''),
      android_branding_splash_subtitle_color: field(android, 'branding.splash_subtitle_color', ''),
      android_branding_primary_dark_color: field(android, 'branding.primary_dark_color', ''),
      android_branding_primary_light_color: field(android, 'branding.primary_light_color', ''),
      android_branding_accent_color: field(android, 'branding.accent_color', ''),
      android_branding_accent_dark_color: field(android, 'branding.accent_dark_color', ''),
      android_branding_text_primary_color: field(android, 'branding.text_primary_color', ''),
      android_branding_secondary_text_color: field(android, 'branding.secondary_text_color', ''),
      android_branding_icons_color: field(android, 'branding.icons_color', ''),
      android_branding_divider_color: field(android, 'branding.divider_color', ''),
      android_branding_background_light_color: field(android, 'branding.background_light_color', ''),
      ios_bundle_id: field(ios, 'bundle_id', ''),
      ios_features_corporate: field(ios, 'features.corporate', false),
      ios_features_in_app_purchase: field(ios, 'features.in_app_purchase', false),
      ios_features_books: field(ios, 'features.books', false),
      ios_features_content_table: field(ios, 'features.content_table', false),
      ios_features_documents: field(ios, 'features.documents', false),
      ios_features_banners: field(ios, 'features.banners', false),
      ios_features_badges: field(ios, 'features.badges', false),
      ios_features_wishlist: field(ios, 'features.wishlist', false),
      ios_features_comments: field(ios, 'features.comments', false),
      ios_features_push_notification: field(ios, 'features.push_notification', false),
      ios_features_local_notification: field(ios, 'features.local_notification', false),
      ios_features_required_login: field(ios, 'features.required_login', false),
      ios_features_socials: field(ios, 'features.socials', false),
      ios_features_pins: field(ios, 'features.pins', false),
      ios_features_user_groups: field(ios, 'features.user_groups', false),
      ios_features_analytic: field(ios, 'features.analytic', false),
      ios_features_app_rate: field(ios, 'features.app_rate', false),
      ios_interface_title_showcase: field(ios, 'interface.title_showcase', ''),
      ios_interface_title_my_collection: field(ios, 'interface.title_my_collection', ''),
      ios_interface_title_documents: field(ios, 'interface.title_documents', ''),
      ios_interface_title_my_documents: field(ios, 'interface.title_my_documents', ''),
      ios_branding_title: field(ios, 'branding.title', ''),
      ios_branding_splash_title: field(ios, 'branding.splash_title', ''),
      ios_branding_splash_subtitle: field(ios, 'branding.splash_subtitle', ''),
      ios_branding_primary_color: field(ios, 'branding.primary_color', ''),
      ios_branding_bar_color: field(ios, 'branding.bar_color', ''),
      ios_branding_selected_button_color: field(ios, 'branding.selected_button_color', ''),
      ios_branding_unselected_button_color: field(ios, 'branding.unselected_button_color', ''),
      ios_branding_background_splash_color: field(ios, 'branding.background_splash_color', ''),
      ios_branding_splash_text_color: field(ios, 'branding.splash_text_color', ''),
      ios_branding_splash_subtitle_color: field(ios, 'branding.splash_subtitle_color', ''),
    };
  }

  getSendindValues(values: IOfferSettingsBuildFormValues): {[key: string]: IOfferSettingsBuildInput} {
    const filterPlatform = (array: any[], platform: string) => array
      .filter(a => a.platform === platform)
      .map((a) => {
        const copy = { ...a };
        delete copy.platform;
        return copy;
      });
    const android: IOfferSettingsBuildInput = {};
    const ios: IOfferSettingsBuildInput = {};
    const asIs = (val: any) => val;
    const asStr = (val: any) => val.toString().trim();
    [
      ['bundle_id', asStr, true],
      ['features.corporate', asIs, true],
      ['features.in_app_purchase', asIs, true],
      ['features.books', asIs, true],
      ['features.content_table', asIs, true],
      ['features.documents', asIs, true],
      ['features.banners', asIs, true],
      ['features.badges', asIs, true],
      ['features.wishlist', asIs, true],
      ['features.comments', asIs, true],
      ['features.push_notification', asIs, true],
      ['features.local_notification', asIs, true],
      ['features.required_login', asIs, true],
      ['features.socials', asIs, true],
      ['features.pins', asIs, true],
      ['features.user_groups', asIs, true],
      ['features.analytic', asIs, true],
      ['features.app_rate', asIs, true],
      ['features.support', asIs, true],
      ['features.parantal_gate', asIs, true],
      ['interface.title_showcase', asStr, true],
      ['interface.title_my_collection', asStr, true],
      ['interface.title_documents', asStr, true],
      ['interface.title_my_documents', asStr, true],
      ['branding.title', asStr, true],
      ['branding.splash_title', asStr, true],
      ['branding.splash_subtitle', asStr, true],
      ['branding.primary_color', asStr, true],
      ['branding.bar_color', asStr, true],
      ['branding.selected_button_color', asStr, true],
      ['branding.unselected_button_color', asStr, true],
      ['branding.background_splash_color', asStr, true],
      ['branding.splash_text_color', asStr, true],
      ['branding.splash_subtitle_color', asStr, true],
      ['branding.primary_dark_color', asStr],
      ['branding.primary_light_color', asStr],
      ['branding.accent_color', asStr],
      ['branding.accent_dark_color', asStr],
      ['branding.text_primary_color', asStr],
      ['branding.secondary_text_color', asStr],
      ['branding.icons_color', asStr],
      ['branding.divider_color', asStr],
      ['branding.background_light_color', asStr],
    ].forEach((val: any) => {
      const [sendingName, cb, includeIos] = val;
      const formName = sendingName.replace('.', '_');
      set(android, sendingName, cb(values[`android_${formName}`]));
      if (includeIos) {
        set(ios, sendingName, cb(values[`ios_${formName}`]));
      }
    });
    android.externals = filterPlatform(values.externals, 'android');
    android.socials = filterPlatform(values.socials, 'android');
    android.analytics = filterPlatform(values.analytics, 'android');
    ios.externals = filterPlatform(values.externals, 'ios');
    ios.socials = filterPlatform(values.socials, 'ios');
    ios.analytics = filterPlatform(values.analytics, 'ios');
    return {
      settingsBuildAndroid: android,
      settingsBuildIos: ios,
    };
  }

  onSubmit = (
    values: IOfferSettingsBuildFormValues,
    formActions: FormikActions<IOfferSettingsBuildFormValues>,
  ) => {
    const newFormData = { ...values };
    const oldFormData = this.getFormData();
    const haveDifferences = Object.keys(oldFormData).some(key => !isEqual(newFormData[key], oldFormData[key]));
    if (haveDifferences) {
      const sendingValues = this.getSendindValues(newFormData);
      const { id } = this.props.offer;
      const offer: {[key: string]: IOfferSettingsBuildInput} = {};
      offer.settingsBuildAndroid = sendingValues.settingsBuildAndroid;
      offer.settingsBuildIos = sendingValues.settingsBuildIos;
      crudMutate({
        id,
        formActions,
        variables: { id, offer },
        mutation: editOfferQuery,
      });
    } else {
      formActions.setSubmitting(false);
    }
  }

  render() {
    return (
      <Formik
        initialValues={this.getFormData()}
        validationSchema={this.getScheme()}
        enableReinitialize
        onSubmit={this.onSubmit}
      >
        {(props: FormikProps<IOfferSettingsBuildFormValues>) => <BaseForm {...props} />}
      </Formik>
    );
  }
}

export default withTranslation('offers')(Form);
