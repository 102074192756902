import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { crudMutate } from 'features/common/helpers';
import { stringValidator } from 'lib/validators';
import filterChangedValues from 'lib/filterChangedValues';
import field from 'lib/field';
import BaseFeatureForm from './BaseFeatureForm';
import { makeFeatureUrl } from '../url';
import createFeatureMutation from '../../queries/createFeature.gql';
import editFeatureMutation from '../../queries/editFeature.gql';
import {
  IFeaure,
  // IFeatureFormValues,
} from '../../types';

import * as Yup from 'yup';
import moment from 'moment';

interface IFeatureFormProps extends WithTranslation {
  feature?: IFeaure;
  isCreateForm?: boolean;
}

class FeatureForm extends React.Component<IFeatureFormProps> {
  getScheme() {
    const {
      t,
    } = this.props;
    const required = true;
    const validators: any = {
      name: stringValidator({ required, field: t('name') }),
      key: stringValidator({ required, field: t('key') }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: IFeaure) {
    const formData = this.buildFormData();
    return filterChangedValues(formData, values);
  }

  onSubmit = (
    values: IFeaure,
    formActions: FormikActions<IFeaure>,
  ) => {
    let feature = { ...values };
    let id;
    if (this.props.feature) {
      feature = this.getChangedValues(values);
      id = this.props.feature.id;
    }
    crudMutate({
      id,
      formActions,
      variables: id ? { id, feature } : { feature },
      mutation: id ? editFeatureMutation : createFeatureMutation,
      redirect: makeFeatureUrl(),
      check: !!Object.keys(feature).length,
    });
  };

  buildFormData() {
    const { feature } = this.props;
    return {
      is_active: field(feature, 'is_active', false),
      name: field(feature, 'name', ''),
      key: field(feature, 'key', ''),
      description: field(feature, 'description', ''),
      build_versions: field(feature, 'build_versions', ''),
      platforms: field(feature, 'platforms', []),
      begin_date: field(feature, 'begin_date', moment().format('YYYY-MM-DD')),
      end_date: field(feature, 'end_date', null),
      offers: feature ? feature.offers.map(o => o.id) : [],
    };
  }

  render() {
    const { feature } = this.props;
    return (
      <Formik
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getScheme()}
      >
        {(props: FormikProps<IFeaure>) => <BaseFeatureForm {...props} feature={feature} />}
      </Formik>
    );
  }
}

export default withTranslation('features')(FeatureForm);
