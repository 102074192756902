import React from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import {
  IModalProps,
  Modal,
} from './Modal';

interface IConfirmProps extends Omit<IModalProps, 'children'> {
  description?: string;
  onReject?: () => void;
  onResolve?: () => void;
  type?: 'info' | 'danger' | 'warning' | 'success' | 'primary';
}

export class Confirm extends React.PureComponent<IConfirmProps> {
  static IconMapping: { [key: string]: string } = {
    info: 'fa fa-info-circle fa-lg',
    danger: 'fa fa-warning fa-lg',
    warning: 'fa fa-warning fa-lg',
    success: 'fa fa-check-circle fa-lg',
    primary: 'fa fa-bell fa-lg',
  };

  getType = () => {
    const { type } = this.props;
    return type ? type : 'info';
  }

  getIcon = () => {
    const { type } = this.props;
    return Confirm.IconMapping[type ? type : 'info'];
  }

  onResolve = () => {
    const {
      onResolve,
      onClose,
    } = this.props;
    if (typeof onResolve === 'function') {
      onResolve();
    }
    onClose();
  }

  onReject = () => {
    const {
      onReject,
      onClose,
    } = this.props;
    if (typeof onReject === 'function') {
      onReject();
    }
    onClose();
  }

  renderDescription() {
    const { description } = this.props;
    if (description) {
      return (
        <ModalBody style={{ overflowWrap: 'break-word' }}>
          {description}
        </ModalBody>
      );
    }
    return null;
  }

  render() {
    const {
      backdrop,
      className,
      isOpened,
      onClose,
      title,
    } = this.props;
    const type = this.getType();
    const icon = this.getIcon();
    return (
      <Modal
        backdrop={backdrop}
        className={className}
        isOpened={isOpened}
        onClose={onClose}
        title={title}
        titleIcon={icon}
        type={type}
      >
        {this.renderDescription()}
        <ModalFooter>
          <Button color={type} onClick={this.onResolve}>Подтвердить</Button>
          {' '}
          <Button color="secondary" onClick={this.onReject}>Отмена</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
