import React from 'react';
import { Input } from 'reactstrap';
import { IText } from 'banner-editor-component';
import Property from './Property';
import {
  AlignInput,
  ColorTextInput,
  FontFamilyInput,
  FontSizeInput,
  HeightInput,
  IWithFormikProps,
  NameInput,
  TextAlignInput,
  TextInput,
  WidthInput,
  withFormik,
  XInput,
  YInput,
} from './inputs';

const TextForm = ({
  values,
  handleSubmit,
  onChange,
  onChangeColor,
  colors,
}: IWithFormikProps<IText>) => (
  <form onSubmit={handleSubmit}>
    <NameInput value={values.name} onChange={onChange} />

    <TextInput value={values.text} onChange={onChange} />

    <AlignInput value={values.align} onChange={onChange} />

    <FontFamilyInput value={values.fontFamily} onChange={onChange} />

    <FontSizeInput value={values.fontSize} onChange={onChange} />

    <Property name="Межстрочный интервал" id="lineHeight">
      <Input
        id="lineHeight"
        name="lineHeight"
        type="number"
        onChange={onChange}
        value={values.lineHeight}
      />
    </Property>

    <ColorTextInput value={values.color} onChange={onChangeColor} colors={colors}/>

    <TextAlignInput value={values.textAlign} onChange={onChange} />

    <XInput value={values.x} onChange={onChange} />

    <YInput value={values.y} onChange={onChange} />

    <WidthInput value={values.width} onChange={onChange} />

    <HeightInput value={values.height} onChange={onChange} />
  </form>
);

export default withFormik<IText>(TextForm);
