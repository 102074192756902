import React from 'react';
import ItemsIdsTextarea from 'features/items/lib/ItemsIdsTextarea';

import updateUserMutation from '../../queries/updateUserMutation.gql';
import getUserQuery from '../../queries/getUserQuery.gql';

import { IUser } from '../../types';

interface IEditInventoryProps {
  user?: IUser;
  onAfterUpdate?: () => void;
}

class EditInventory extends React.Component<IEditInventoryProps> {
  render() {
    const {
      user,
      onAfterUpdate,
    } = this.props;
    if (user && user.id) {
      return (
        <ItemsIdsTextarea
          data={user}
          entityName="user"
          fieldName="itemsInventory"
          mutation={updateUserMutation}
          onAfterAddition={onAfterUpdate}
          onAfterRemoving={onAfterUpdate}
          query={getUserQuery}
        />
      );
    }
    return null;
  }
}

export default EditInventory;
