import React from 'react';
import moment from 'moment';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  flow,
  get,
} from 'lodash';
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
} from 'reactstrap';
import { graphqlById } from 'features/graphql/helpers';
import { makeUserUrl } from 'features/users/lib/url';
import buildFullName from 'features/users/lib/buildFullName';
import CardPage from 'features/ui/Page/CardPage';
import Waiter from 'features/ui/Waiter';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import {
  InfoRow,
  InfoRowText,
} from 'features/ui/InfoRow';
import OrderStatus from 'features/orders/lib/OrderStatus';
import GoodsList from '../lib/GoodsList';
import { makeOrderUrl } from '../lib/url';

import getOrderQuery from '../queries/getOrderQuery.gql';
import { DATETIME_FORMAT_DEFAULT } from 'features/app/consts';
import { FEATURE_ICON } from '../consts';

import {
  IOrder,
  IOrderPayment,
} from '../types';

interface IPageViewProps extends WithTranslation {
  data: IOrder | null;
  isLoading: boolean;
}

class PageView extends React.Component<IPageViewProps> {
  renderPayments(payments: IOrderPayment[]) {
    const { t } = this.props;
    const list = payments.map((payment) => {
      let paidAt = null;
      if (payment.paid_at) {
        paidAt = moment(payment.paid_at, DATETIME_FORMAT_DEFAULT);
      }
      const createdAt = moment(payment.created_at, DATETIME_FORMAT_DEFAULT);
      return (
        <Card key={payment.id}>
          <CardHeader>Транзакция №{payment.id}</CardHeader>
          <CardBody>
            <InfoRowText label={t('payments:currency')} value={payment.currency} />
            <InfoRowText label={t('payments:total')} value={payment.total} />
            <InfoRowText label={t('users:email')} value={payment.email} />
            <InfoRowText label={t('payments:provider')} value={payment.provider} />
            <InfoRowText label={t('payments:transaction')} value={payment.transaction} />
            <InfoRow label={t('status')}>
              <OrderStatus value={payment.status} />
            </InfoRow>
            <InfoRowText label={t('payments:paid_at')} value={paidAt && paidAt.format('lll')} />
            <InfoRowText label={t('dict:created_at')} value={createdAt.format('lll')} />
          </CardBody>
        </Card>
      );
    });
    if (list.length) {
      return list;
    }
    return (
      <Card>
        <CardHeader>Транзакция &infin;</CardHeader>
        <CardBody>
          <CardText className="text-warning">Транзакций не найдено</CardText>
        </CardBody>
      </Card>
    );
  }

  renderElements(data: IOrder) {
    const { t } = this.props;
    const createdAt = moment(data.created_at, DATETIME_FORMAT_DEFAULT);
    const fullName = buildFullName(data.user);
    return (
      <React.Fragment>
        <InfoRowText label="ID" value={data.id} />
        <InfoRow label={t('status')}>
          <OrderStatus value={data.status} />
        </InfoRow>
        <InfoRowText label={t('dict:created_at')} value={createdAt.format('lll')} />

        <InfoRow label={t('users:user')}>
          {fullName ? `${fullName} ` : ''}
          <LinkToEntityInLists
            id={get(data, 'user.id', 0)}
            name={get(data, 'user.email', '')}
            to={makeUserUrl({ id: get(data, 'user.id', 0) })}
          />
        </InfoRow>
        <InfoRow label={t('goods')}>
          <GoodsList items={data.items} />
        </InfoRow>
      </React.Fragment>
    );
  }

  render() {
    const {
      t,
      data,
      isLoading,
    } = this.props;
    return (
      <Waiter data={data} isLoading={isLoading}>
        <CardPage
          icon={FEATURE_ICON}
          title={data && data.id || ''}
          crumbs={[{ link: makeOrderUrl(), name: t('orders') }]}
        >
          {data ? this.renderElements(data) : null}
        </CardPage>
        {data ? this.renderPayments(data.payments) : null}
      </Waiter>
    );
  }
}

export default flow(
  graphqlById({
    query: getOrderQuery,
    queryType: 'OrderQuery',
  }),
  withTranslation('orders'),
)(PageView);
