import React from 'react';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { IInfoRowOptions } from './types';
import styles from './styles.module.scss';

export const InfoRowTags = ({
  label,
  value,
  options,
}: {
  label: string,
  value: string[] | null | undefined,
  options?: IInfoRowOptions,
}) => {
  if (value === null || value === undefined) {
    return <InfoRowNotSet label={label} options={options} />;
  }
  return (
    <InfoRow label={label} {...options}>
      {value.map((tag, id) => (<span key={id} className={styles.tag}>{tag}</span>))}
    </InfoRow>
  );
};
