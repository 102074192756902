import React from 'react';
import PageItems from 'features/items/PageItems';
import { FEATURE_ICON } from 'features/documents/consts';

import { Column } from 'features/types';

class PageDocuments extends React.PureComponent<{}> {
  resolveColumns = (columns: Column[]) => {
    return columns.filter(column => !['provider', 'publisher'].includes(column.id!));
  };

  render() {
    return (
      <PageItems
        title="Документы"
        type="document"
        resolveColumns={this.resolveColumns}
        icon={FEATURE_ICON}
      />
    );
  }
}

export default PageDocuments;
