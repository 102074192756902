import React from 'react';
import Input from 'features/ui/Input';
import { ReactTableFunction } from 'react-table';

export default ({
  filter,
  onChange,
}: {
  filter: any,
  onChange: ReactTableFunction,
}) => {
  const handleChange = (event: React.ChangeEvent<any>) => onChange(event.target.value);
  return (
    <Input
      onEnter={handleChange}
      onBlur={handleChange}
      defaultValue={filter && filter.value || ''}
    />
  );
};
