import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { flow } from 'lodash';
import {
  match as IMatch,
  Redirect,
} from 'react-router';

import CardPage from 'features/ui/Page/CardPage';
import LinkBlock from 'features/ui/LinkBlock';
import { IListQueryVariables } from 'features/types';
import deepOmit from 'lib/deepOmit';
import Spinner from 'features/ui/Spinner';
import {
  ICategory,
  ICategoryQueryResponse,
} from '../types';
import CategoryForm from '../lib/CategoryForm';
import {
  makeCategoryUrl,
  makeCategoryUrlTo,
} from '../lib/url';
import getCategoryQuery from '../queries/getCategory.gql';
import editCategoryMutation from '../queries/editCategory.gql';
import { FEATURE_ICON } from '../consts';

interface IPageEditProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  category: ICategory;
}

class PageEdit extends React.Component<IPageEditProps> {
  constructor(props: IPageEditProps) {
    super(props);
    this.state = {
      wasUpdated: false,
    };
  }

  render() {
    const {
      category,
      isLoading,
      t,
    } = this.props;

    if (!isLoading && !category) {
      return <Redirect to="/404" />;
    }

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <CardPage
        icon={FEATURE_ICON}
        title={category ? `${t('edit_category')}: ${category.name}` : ''}
        crumbs={[{ link: makeCategoryUrl(), name: t('categories') }]}
      >
        <LinkBlock viewLink={makeCategoryUrlTo(category, 'view')} />
        <CategoryForm
          category={category}
          mutation={editCategoryMutation}
        />
      </CardPage>
    );
  }
}

const mapResultsToProps = (props: OptionProps<IPageEditProps, ICategoryQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  if (data && data.CategoryQuery) {
    const category = deepOmit(data.CategoryQuery.items[0], '__typename');
    return {
      category,
      isLoading: data && data.loading,
      ...ownProps,
    };
  }
  return {
    category: null,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphql(getCategoryQuery, {
    props: mapResultsToProps,
    options: ({ match }: { match: IMatch<any> }) => {
      const { params } = match;
      const { id } = params || { id: 0 };
      return {
        context: {
          isGlobalLoading: true,
        },
        variables: {
          id: [id],
        },
      };
    },
  }),
  withTranslation('categories'),
)(PageEdit);
