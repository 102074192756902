import { MixedSchema } from 'yup';
import { match as IMatch } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

export { Moment } from 'moment';

export { IAppRoute } from 'features/app/Route';
export { IFile, IImage } from 'features/app/types';
export { ICategory } from 'features/categories/types';
export { IBadge } from 'features/badges/types';
export { IOffer } from 'features/offers/types';
export { ICreator } from 'features/creators/types';
export {
  ISharedSelectProps,
  ISelectOption,
  ISelectOptionsByValue,
  IReactSelectActionMeta,
} from 'features/ui/ReactSelect/types';
export {
  IFormValues as IItemFormValues,
  IItem,
  IItemContent,
  IItemQueryResponse,
} from 'features/items/types';
export { IBook } from 'features/books/types';
export { IEvent } from 'features/events/types';
export { IHybrid } from 'features/hybrids/types';
export { IDocument } from 'features/documents/types';
export { IVideo } from 'features/videos/types';
export { IAudiobook } from 'features/audiobooks/types';
export { ICollection } from 'features/collections/types';
export { ILanguage } from 'features/languages/types';
export { IPrice, IPriceInput, IPriceValue } from 'features/prices/types';
export { ITag } from 'features/tags/types';
export { Column, RowRenderProps, ColumnRenderProps } from 'react-table';
export { IState, IAppStateQueryResponse } from 'features/graphql/types';
export { IBreadcrumb } from 'features/breadcrumbs/types';
export { IPromocode } from 'features/promocodes/types';
export { ICardPageProps, IBasePageProps } from 'features/ui/Page/types';
export { IUser, IUserInput, UserRoleEnum } from 'features/users/types';
export { IUserGroup } from 'features/usergroups/types';
export { IPushes } from 'features/pushes/types';
export { IAutoImport } from 'features/autoimport/types';

export interface IListQueryVariables {
  id?: number | string | Array<number | string>;
  pageNum?: number;
  perPage?: number;
}

export const nullable = <T>(a: T) => a as T | null;

export interface ICursor {
  currentPage: number;
  total: number;
}

export interface IYupValidationFields {
  [key: string]: MixedSchema;
}

export enum RequestState {
  init,
  pending,
  success,
  fail,
}

export interface IBasePageEditProps<T> extends RouteComponentProps<{ id: string }> {
  data: T | null;
  isLoading: boolean;
}

export interface IMatchIdProps {
  match: IMatch<{ id: string }>;
}
