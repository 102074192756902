import React from 'react';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/usergroups/consts';
import { makeUserGroupUrl } from 'features/usergroups/lib/url';
import UsersForm from 'features/usergroups/lib/Form';

class PageCreate extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Создание новой группы пользователей"
        crumbs={[{ link: makeUserGroupUrl(), name: 'Группы пользователей' }]}
      >
        <UsersForm />
      </CardPage>
    );
  }
}

export default PageCreate;
