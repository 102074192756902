import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { flow } from 'lodash';
import {
  match as IMatch,
  Redirect,
} from 'react-router';
import CardPage from 'features/ui/Page/CardPage';
import Spinner from 'features/ui/Spinner';
import LinkBlock from 'features/ui/LinkBlock';
import deepOmit from 'lib/deepOmit';
import SubscriptionForm from '../lib/SubscriptionForm';

import {
  makeSubscriptionUrl,
  makeSubscriptionUrlTo,
} from '../lib/url';
import getSubscription from '../queries/getSubscription.gql';
import { SUBSCRIPTION_ICON } from '../consts';

import { ISubscription } from '../types';

interface IPageSubscriptionProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  subscription: ISubscription;
}

class PageEdit extends React.Component<IPageSubscriptionProps> {
  render() {
    const {
      t,
      subscription,
      isLoading,
    } = this.props;
    if (!isLoading && !subscription) {
      return <Redirect to="/404" />;
    }
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <CardPage
        icon={SUBSCRIPTION_ICON}
        title={subscription ? `${t('edit_subscription')}: ${subscription.name}` : ''}
        crumbs={[{ link: makeSubscriptionUrl(), name: t('subscriptions') }]}
      >
        <LinkBlock viewLink={makeSubscriptionUrlTo(subscription, 'view')} />
        <SubscriptionForm subscription={subscription} />
      </CardPage>
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISubscription>) => {
  const { data, ownProps } = props;
  const subscription = data && data.SubscriptionQuery ? deepOmit(data.SubscriptionQuery.items[0], '__typename') as ISubscription : null;
  return {
    subscription,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphql(getSubscription, {
    props: mapResultsToProps,
    options: ({ match }: { match: IMatch<any> }) => {
      const { params } = match;
      const { id } = params || { id: 0 };
      return {
        context: {
          isGlobalLoading: true,
        },
        variables: {
          id: [id],
        },
      };
    },
  }),
  withTranslation('subscriptions'),
)(PageEdit);
