import React from 'react';
import { Input } from 'reactstrap';
import {
  Formik,
  FormikProps,
} from 'formik';
import ColorPicker from 'features/ui/ColorPicker';
import Color from './Color';
import Property from './Property';
import { IMargin } from 'banner-editor-component';
import { Colors } from '../types';

import style from '../style.module.scss';

interface IProps<T> {
  value: T;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

export interface IWithFormikProps<T> extends FormikProps<T> {
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  onChangeButtonColor: (color: string) => void;
  onChangeColor: (color: string) => void;
  colors?: Colors;
}

export const withFormik = <T extends {}>(Component: React.ComponentType<IWithFormikProps<T>>) => {
  const Wrapper = class extends React.PureComponent<FormikProps<T> & { colors?: Colors }> {
    setField(field: string, value: any) {
      const {
        submitForm,
        setFieldValue,
      } = this.props;
      setFieldValue(field, value);
      setTimeout(submitForm, 0);
    }

    onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
      const {
        submitForm,
        handleChange,
      } = this.props;
      handleChange(e);
      setTimeout(submitForm, 0);
    };

    onChangeColor = (color: string) => {
      this.setField('color', color);
    };

    onChangeButtonColor = (color: string) => {
      this.setField('buttonColor', color);
    };

    render() {
      return (
        <Component
          {...this.props}
          onChange={this.onChange}
          onChangeButtonColor={this.onChangeButtonColor}
          onChangeColor={this.onChangeColor}
        />
      );
    }
  };

  return ({
    element,
    onSubmit,
    colors,
  }: {
    element: T;
    onSubmit: (values: T) => void;
    colors?: Colors
  }) => (
    <Formik
      enableReinitialize={true}
      initialValues={element}
      onSubmit={onSubmit}
    >
      {props => <Wrapper {...props} colors={colors} />}
    </Formik>
  );
};

export const AlignInput = ({ value, onChange }: IProps<string>) => (
  <Property id="align" name="Выравнивание">
    <div className={style.alignInputRow}>
      <input
        id="align_top-left"
        name="align"
        type="radio"
        onChange={onChange}
        value="top-left"
        checked={value === 'top-left'}
        title="Сверху слева"
      />
      <span>-</span>
      <input
        id="align_top"
        name="align"
        type="radio"
        onChange={onChange}
        value="top"
        checked={value === 'top'}
        title="Сверху по центру"
      />
      <span>-</span>
      <input
        id="align_top-right"
        name="align"
        type="radio"
        onChange={onChange}
        value="top-right"
        checked={value === 'top-right'}
        title="Сверху справа"
      />
    </div>
    <div className={style.alignInputRow}>
      <input
        id="align_left"
        name="align"
        type="radio"
        onChange={onChange}
        value="left"
        checked={value === 'left'}
        title="Слева по центру"
      />
      <span>&nbsp;</span>
      <input
        id="align_center"
        name="align"
        type="radio"
        onChange={onChange}
        value="center"
        checked={value === 'center'}
        title="По центру"
      />
      <span>&nbsp;</span>
      <input
        id="align_right"
        name="align"
        type="radio"
        onChange={onChange}
        value="right"
        checked={value === 'right'}
        title="Справа по центру"
      />
    </div>
    <div className={style.alignInputRow}>
      <input
        id="align_bottom-left"
        name="align"
        type="radio"
        onChange={onChange}
        value="bottom-left"
        checked={value === 'bottom-left'}
        title="Снизу слева"
      />
      <span>-</span>
      <input
        id="align_bottom"
        name="align"
        type="radio"
        onChange={onChange}
        value="bottom"
        checked={value === 'bottom'}
        title="Снизу по центру"
      />
      <span>-</span>
      <input
        id="align_bottom-right"
        name="align"
        type="radio"
        onChange={onChange}
        value="bottom-right"
        checked={value === 'bottom-right'}
        title="Снизу справа"
      />
    </div>
  </Property>
);

export const TextAlignInput = ({ value, onChange }: IProps<string>) => (
  <Property id="alignText" name="Выравнивание текста">
    <div className={style.alignInputRow}>
      <input
        id="textalign_left"
        name="textAlign"
        type="radio"
        onChange={onChange}
        value="left"
        checked={value === 'left'}
        title="Слева"
      />
      <span>-</span>
      <input
        id="textalign_center"
        name="textAlign"
        type="radio"
        onChange={onChange}
        value="center"
        checked={value === 'center'}
        title="По центру"
      />
      <span>-</span>
      <input
        id="textalign_right"
        name="textAlign"
        type="radio"
        onChange={onChange}
        value="right"
        checked={value === 'right'}
        title="Справа"
      />
    </div>
  </Property>
);

export const WidthInput = ({ value, onChange }: IProps<number>) => (
  <Property name="Ширина" id="width">
    <Input
      id="width"
      name="width"
      type="number"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const HeightInput = ({ value, onChange }: IProps<number>) => (
  <Property name="Высота" id="height">
    <Input
      id="height"
      name="height"
      type="number"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const buildColorInput = (name: string, caption: string) => ({
  value,
  onChange,
  colors,
}: {
  value: string,
  onChange: (color: string) => void,
  colors?: Colors,
}) => {
  let list = null;
  if (Array.isArray(colors)) {
    list = colors.map((item, id) => {
      const handler = () => onChange(item.color);
      return (
        <Color
          key={id}
          color={item.color}
          caption={item.caption}
          onClick={handler}
        />
      );
    });
  }
  return (
    <Property name={caption} id={name}>
      <ColorPicker
        id={name}
        name={name}
        left={-340}
        onChange={onChange}
        color={value}
      />
      {list}
    </Property>
  );
};

export const ColorInput = buildColorInput('color', 'Цвет');
export const ColorTextInput = buildColorInput('color', 'Цвет текста');
export const ColorLineInput = buildColorInput('lineColor', 'Цвет линии');
export const ColorButtonInput = buildColorInput('buttonColor', 'Цвет кнопки');
export const ColorFillInput = buildColorInput('fillColor', 'Закрасить цветом');

export const FontFamilyInput = ({ value, onChange }: IProps<string>) => (
  <Property name="Шрифт" id="fontFamily">
    <Input
      id="fontFamily"
      name="fontFamily"
      type="select"
      onChange={onChange}
      value={value}
    >
      <option value="Helvetica">Helvetica</option>
      <option value="Arial">Arial</option>
    </Input>
  </Property>
);

export const FontSizeInput = ({ value, onChange }: IProps<number>) => (
  <Property name="Размер шрифта" id="fontSize">
    <Input
      id="fontSize"
      name="fontSize"
      type="number"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const NameInput = ({ value, onChange }: IProps<string>) => (
  <Property name="Название" id="elemName">
    <Input
      id="elemName"
      name="name"
      type="text"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const TextInput = ({ value, onChange }: IProps<string>) => (
  <Property name="Текст" id="text">
    <Input
      id="text"
      name="text"
      type="textarea"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const RoundInput = ({ value, onChange }: IProps<number>) => (
  <Property name="Скругление" id="round">
    <Input
      id="round"
      name="round"
      type="number"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const XInput = ({ value, onChange }: IProps<number>) => (
  <Property name="x" id="x">
    <Input
      id="x"
      name="x"
      type="number"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const YInput = ({ value, onChange }: IProps<number>) => (
  <Property name="y" id="y">
    <Input
      id="y"
      name="y"
      type="number"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const MarginTopInput = ({ value, onChange }: IProps<number>) => (
  <Property name="Отступ сверху" id="margin.top">
    <Input
      id="maring.top"
      name="margin.top"
      type="number"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const MarginBottomInput = ({ value, onChange }: IProps<number>) => (
  <Property name="Отступ снизу" id="margin.bottom">
    <Input
      id="maring.bottom"
      name="margin.bottom"
      type="number"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const MarginLeftInput = ({ value, onChange }: IProps<number>) => (
  <Property name="Отступ слева" id="margin.left">
    <Input
      id="maring.left"
      name="margin.left"
      type="number"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const MarginRightInput = ({ value, onChange }: IProps<number>) => (
  <Property name="Отступ справа" id="margin.right">
    <Input
      id="maring.right"
      name="margin.right"
      type="number"
      onChange={onChange}
      value={value}
    />
  </Property>
);

export const MarginInput = ({ value, onChange }: IProps<IMargin>) => (
  <Property name="Отступы" id="margins">
    <div className={style.marginInputRow}>
      <span>┏━━</span>
      <Input
        id="maring.top"
        name="margin.top"
        type="number"
        onChange={onChange}
        value={value.top}
        title="Отступ сверху"
      />
      <span>━━┓</span>
    </div>
    <div className={style.marginInputRow}>
      <Input
        id="maring.left"
        name="margin.left"
        type="number"
        onChange={onChange}
        value={value.left}
        title="Отступ слева"
      />
      <span>&nbsp;</span>
      <Input
        id="maring.right"
        name="margin.right"
        type="number"
        onChange={onChange}
        value={value.right}
        title="Отступ справа"
      />
    </div>
    <div className={style.marginInputRow}>
      <span>┗━━</span>
      <Input
        id="maring.bottom"
        name="margin.bottom"
        type="number"
        onChange={onChange}
        value={value.bottom}
        title="Отступ снизу"
      />
      <span>━━┛</span>
    </div>
  </Property>
);
