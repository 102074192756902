import React from 'react';
import Helmet from 'react-helmet';
import { updateBreadcrumbsData } from 'features/breadcrumbs/helpers';

import { IBasePageProps } from './types';

class BasePage extends React.PureComponent<IBasePageProps> {
  static defaultProps = {
    isTitleCrumb: true,
  };

  componentDidMount() {
    updateBreadcrumbsData(this.resolveCrumbs());
  }

  componentDidUpdate(prevProps: IBasePageProps) {
    if (this.props.crumbs !== prevProps.crumbs) {
      updateBreadcrumbsData(this.resolveCrumbs());
    }
  }

  resolveCrumbs = () => {
    const {
      crumbs,
      title,
      isTitleCrumb,
    } = this.props;
    const autoTitleCrumb = title ? [{ name: title, link: '' }] : [];
    return crumbs ? crumbs.concat(isTitleCrumb ? autoTitleCrumb : []) : autoTitleCrumb;
  };

  render() {
    const {
      title,
      children,
    } = this.props;

    return (
      <React.Fragment>
        {title ?  <Helmet title={title} /> : null}
        {children}
      </React.Fragment>
    );
  }
}

export default BasePage;
