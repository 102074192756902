const KB = 1024; // 2 ** 10;
const MB = 1048576; // 2 ** 20;
const GB = 1073741824; // 2 ** 30;
const TB = 1099511627776; // 2 ** 40;

function format(value: number, measure: string, roundTo: number) {
  const fixedFractionPart = value.toFixed(roundTo);
  const removedZeros = fixedFractionPart.replace(/\.?0*$/, '');
  return `${removedZeros} ${measure}`;
}

/**
 * @param { number } value Целое число начиная с 0
 */
export default function bytesFormatter(value: number, roundTo: number = 2): string {
  if (value >= TB) {
    return format(value / TB, 'TB', roundTo);
  }
  if (value >= GB) {
    return format(value / GB, 'GB', roundTo);
  }
  if (value >= MB) {
    return format(value / MB, 'MB', roundTo);
  }
  if (value >= KB) {
    return format(value / KB, 'KB', roundTo);
  }
  return format(value, 'B', roundTo);
}
