import crudUrlBuilder from 'lib/crudUrlBuilder';
import { IUserGroup } from '../../types';

export const makeUserGroupUrl = crudUrlBuilder('usergroup', 'usergroups');

export const makeUserGroupUrlTo = (usergroup: IUserGroup, action: string = 'view') => {
  return makeUserGroupUrl({
    action,
    id: usergroup.id,
  });
};
