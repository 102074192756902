import React from 'react';
import SidebarMenu from 'features/layout/Sidebar/SidebarMenu';

class Sidebar extends React.PureComponent {
  render() {
    return (
      <div className="sidebar">
        <SidebarMenu />
      </div>
    );
  }
}

export default Sidebar;
