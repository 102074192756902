import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'features/intl/i18n';
import styles from './styles.module.scss';

export default ({
  editLink,
  viewLink,
  inventoryLink,
}: {
  editLink?: string,
  viewLink?: string,
  inventoryLink?: string,
}) => {
  const inventoryComponent = inventoryLink
    ? <Link to={inventoryLink} title={i18n.t('dict:inventory')} ><i className="fa fa-suitcase font-lg" /></Link>
    : null;
  const viewComponent = viewLink
    ? <Link to={viewLink} title={i18n.t('dict:view')} ><i className="fa fa-eye fa-lg" /></Link>
    : null;
  const editComponent = editLink
    ? <Link to={editLink} title={i18n.t('dict:edit')} ><i className="icon-pencil font-lg" /></Link>
    : null;
  return (
    <div
      style={{ textAlign: 'right' }}
      className={styles.linkBlock}
    >
      {inventoryComponent}
      {editComponent}
      {viewComponent}
    </div>
  );
};
