import React from 'react';
import {
  ElementTypeEnum,
  IButton,
  IImage,
  IPropertiesComponentProps,
  IRect,
  IScene,
  IText,
} from 'banner-editor-component';
import PropertiesScene from './elements/PropertiesScene';
import PropertiesText from './elements/PropertiesText';
import PropertiesRect from './elements/PropertiesRect';
import PropertiesButton from './elements/PropertiesButton';
import PropertiesImage from './elements/PropertiesImage';
import { Colors } from './types';

export default ({
  element,
  onSubmit,
  onImport,
  colors,
}: IPropertiesComponentProps & { colors?: Colors }) => {
  switch (element._type) {
    case ElementTypeEnum.scene:
      return <PropertiesScene element={element as IScene} onSubmit={onSubmit} onImport={onImport} />;
    case ElementTypeEnum.text:
      return <PropertiesText element={element as IText} onSubmit={onSubmit} colors={colors} />;
    case ElementTypeEnum.rect:
      return <PropertiesRect element={element as IRect} onSubmit={onSubmit} colors={colors} />;
    case ElementTypeEnum.button:
      return <PropertiesButton element={element as IButton} onSubmit={onSubmit} colors={colors} />;
    case ElementTypeEnum.image:
      return <PropertiesImage element={element as IImage} onSubmit={onSubmit} colors={colors} />;
  }
  return null;
};
