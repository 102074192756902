import React from 'react';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/pushes/consts';
import { makeNewPushUrl } from 'features/pushes/lib/url';
import PushTable from 'features/pushes/lib/Table';

class PageView extends React.Component<any, any> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Push-уведомления"
        buttons={[{ link: makeNewPushUrl({ action: 'create' }), name: 'Создать новое push-уведомление' }]}
      >
        <PushTable />
      </CardPage>
    );
  }
} export default PageView;
