import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import PageOrders from 'features/orders/PageOrders';
import { Page404 } from 'features/http';
import PageView from 'features/orders/PageView';
import { AccessControl } from 'features/acl';

export default () => {
  return (
    <AccessControl permission="orders:access">
      <Switch>
        <AppRoute path="/orders" menuId="orders" component={PageOrders} exact />
        <AppRoute path="/order/:id" menuId="orders" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
