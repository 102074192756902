import React from 'react';
import Form from 'features/audiobooks/lib/Form';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/audiobooks/consts';
import { makeAudiobookUrl } from 'features/audiobooks/lib/url';

class PageCreate extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        title="Создание новой Аудиокниги"
        icon={FEATURE_ICON}
        crumbs={[{ link: makeAudiobookUrl(), name: 'Аудиокниги' }]}
      >
        <Form />
      </CardPage>
    );
  }
}

export default PageCreate;
