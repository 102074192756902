import React from 'react';
import {
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import ReactSelect from 'features/ui/ReactSelect';
import { ANSWER_TYPES } from 'features/tests/consts';

interface ILocaleSelectState {
  options: ISelectOption[];
}

class AnswerTypeSelect extends React.PureComponent<ISharedSelectProps, ILocaleSelectState> {
  constructor(props: ISharedSelectProps) {
    super(props);
    this.state = {
      options: ANSWER_TYPES,
    };
  }

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        shrinkHeight={150}
        isMulti={false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

export default AnswerTypeSelect;
