import React from 'react';
import {
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { minIntValidator } from 'lib/validators';
import { contentValidator } from 'features/items/lib/validators';
import ItemForm from 'features/items/lib/Form';
import BaseForm from './Form';

import getHybridQuery from 'features/hybrids/queries/getHybridQuery.gql';

import {
  IFormValues,
} from 'features/hybrids/types';
import { IItem } from 'features/types';

interface IFormProps extends WithTranslation {
  data?: IItem;
}

class Form extends React.PureComponent<IFormProps> {
  resolveMutationData = (values: any) => {
    return values;
  };

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: any) => {
    const {
      data,
      t,
    } = this.props;
    const isEdit = !!(data && data.id);
    const required = true;
    return {
      ...shape,
      content: contentValidator(t, isEdit, {
        ext: '*.zip',
        formats: ['application/zip', 'application/x-zip-compressed'],
      }),
      hybrid: Yup.object().shape({
        paper_pages: minIntValidator({ required, field: t('paper_pages'), min: 0 }),
        audio_time: minIntValidator({ field: t('audio_time'), min: 0 }),
      }),
    };
  };

  resolveInitialValues = (values: any) => {
    const { data } = this.props;

    const defaultValues = {
      hybrid: {
        isbn: '',
        paper_pages: 0,
        audio_time: 0,
      },
    };

    return data ? {
      ...defaultValues,
      ...values,
      ...{
        hybrid: {
          isbn: data.hybrid!.isbn || '',
          paper_pages: data.hybrid!.paper_pages || 0,
          audio_time: data.hybrid!.audio_time || 0,
        },
      },
    } : {
      ...defaultValues,
      ...values,
    };
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="hybrid"
        data={data}
        getItemQuery={getHybridQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        renderForm={this.renderForm}
      />
    );
  }
}

export default withTranslation('hybrids')(Form);
