import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import CardPage from 'features/ui/Page/CardPage';
import { IFeaure } from '../types';
import FeatureForm from '../lib/FeatureForm';
import { makeFeatureUrl } from '../lib/url';
import { FEATURE_ICON } from '../consts';

interface IPageCreateProps extends WithTranslation {
  feature: IFeaure;
}

class PageCreate extends React.Component<IPageCreateProps> {
  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('new_feature')}
        crumbs={[{ link: makeFeatureUrl(), name: t('feature') }]}
      >
        <FeatureForm isCreateForm={true} />
      </CardPage>
    );
  }
}

export default withTranslation('features')(PageCreate);
