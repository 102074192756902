import React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
    WithTranslation,
    withTranslation,
} from 'react-i18next';
import AvatarImageSrc from 'static/img/avatar.png';

interface IHeaderDropdownAccountProps extends WithTranslation {
  email: string;
  onLogout: () => void;
}

interface IHeaderDropdownAccountState {
  dropdownOpen: boolean;
}

class HeaderDropdownAccount extends React.PureComponent<IHeaderDropdownAccountProps, IHeaderDropdownAccountState> {
  constructor(props: IHeaderDropdownAccountProps) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  onLogout = () => {
    this.props.onLogout();
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  render() {
    const {
      t,
      email,
    } = this.props;
    const { dropdownOpen } = this.state;
    return (
      <Dropdown nav isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <img src={AvatarImageSrc} className="img-avatar" alt="" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header>
            {email}
          </DropdownItem>
          <DropdownItem onClick={this.onLogout}>
            <i className="fa fa-lock" /> {t('header:logout')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default withTranslation()(HeaderDropdownAccount);
