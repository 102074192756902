import React from 'react';
import { Input } from 'reactstrap';

import {
  TYPE_FULL,
  TYPE_SUMMARY,
} from 'features/videos/consts';

interface IBookTypeSelectProps {
  id: string;
  name: string;
  value: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
}

const types: { [type: string]: string } = {
  [TYPE_FULL]: 'Видео',
  [TYPE_SUMMARY]: 'Мини-курс',
};

const VideoTypeSelect = (props: IBookTypeSelectProps) => {
  const {
    id,
    name,
    value,
    onChange,
    onBlur,
  } = props;
  const options = Object
    .keys(types)
    .map((type: string) => (
      <option
        key={type}
        value={type}
      >
        {types[type]}
      </option>
    ));
  return (
    <Input
      type="select"
      id={id}
      name={name}
      value={value || 'full'}
      onChange={onChange}
      onBlur={onBlur}
    >
      {options}
    </Input>
  );
};

export default VideoTypeSelect;
