import React from 'react';
import { Column } from 'react-table';
import i18n from 'features/intl/i18n';
import LinkToOffer from 'features/offers/lib/LinkToOffer';
import OffersSelect from 'features/offers/lib/OffersSelect';
import { IBadge } from 'features/types';

import commonStyles from 'css/typography.module.scss';
import styles from './styles.module.scss';

export default (): Column[] => {
  return [
    {
      Header: i18n.t('badges:name'),
      accessor: 'name',
      filterable: false,
      minWidth: 100,
    },
    {
      Cell: ({ value }: { value: {[key: string]: string }}) => {
        if (value && value.path) {
          return (
            <img
              src={value.path}
              className={styles.image}
            />
          );
        }
        return null;
      },
      Header: i18n.t('badges:image'),
      accessor: 'image',
      className: commonStyles.textAlignCenter,
      filterable: false,
      minWidth: 130,
      maxWidth: 130,
    },
    {
      id: 'offer',
      accessor: (data: IBadge) => data.ownerOffer ? (
        <LinkToOffer value={data.ownerOffer} />
      ) : '',
      Header: i18n.t('badges:ownerOffer'),
      maxWidth: 200,
      Filter: ({ filter, onChange }: any) => (
        <OffersSelect
          onChange={onChange}
          value={filter && filter.value}
          isMulti={false}
        />
      ),
    },
  ];
};
