import React from 'react';
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { client, gql } from "features/graphql";
import { IAutoImportFormValues } from "features/autoimport/types";
import BaseForm from "features/usersmanagement/lib/Form/Form";
import { WithTranslation, withTranslation } from "react-i18next";
import { toast } from "react-toastify";

const postTransactionMutation = gql`
  mutation postTransaction($transaction: BatchTransactionInput) {
    BatchTransactionMutation(transaction: $transaction) {
      id
      error
      status
      progress
    }
  }
`
type IFormProps = WithTranslation;

class Form extends React.Component<IFormProps>{

  validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape(Object.assign({
      offer: Yup.number().required().positive().min(1, t('forms:required_field', { field: t('offer') })).integer(),
      emails: Yup.string().required((t('forms:required_field', { field: t('emails') }))),
    }));
  };

  onSubmit = (values) => {
    const separator = /[,;\s\n]/;
    const validation = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z0-9](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/;
    const emails = values.emails.split(separator).filter((el: string) => validation.test(el));
    client.mutate({
      mutation: postTransactionMutation,
      variables: {
        transaction: {
          parameters: {
            operation: 'force_delete',
            group_ids: null,
            notification: false,
            ignore_exist_users: true,
            email_list: emails,
          },
          offer: values.offer,
          entity : 'user',
        },
      },
    })
      .then((req) => {
        const errors = req.data.BatchTransactionMutation.error;
        if (errors) {
          return toast.error(errors);
        }
        return toast.info('Успешная операция');

      })
      .catch(err => toast.error(err));
  }

  renderForm = (props: FormikProps<IAutoImportFormValues>) => (<BaseForm {...props} />);
  resolveInitialValues = () => ({});
  render() {
    return (
      <div>
        <Formik
          validationSchema={this.validationSchema()}
          enableReinitialize
          initialValues={this.resolveInitialValues()}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </div>
    )
  }
} export default withTranslation('usersmanagement')(Form);
