import { client, gql } from 'features/graphql';
import { useEffect, useState } from 'react';

const useAbortiveQuery = (value: string) => {
  const query = gql`
  query getItemForSelect(
    $pageNum: Int
    $perPage: Int
    $searchByFields: ItemSearchByFields
    $filterByFields: ItemFilterByFields
  ) {
    ItemQuery(
      page: $pageNum,
      perPage: $perPage,
      searchByFields: $searchByFields,
      filterByFields: $filterByFields,
    ) {
      items {
        id
        name
        type
        book {
          type
        }
        video {
          type
        }
        audiobook {
          type
        }
      }
    }
  }
`;
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!value.length) {
      setData([]);
      setLoading(false);
      setError(null);
    }
    if (value.length) {
      setLoading(true);
      const watchedQuery = client.watchQuery({
        query,
        variables: {
          pageNum: 1,
          perPage: 50,
          searchByFields: null,
          filterByFields: {
            name: {
              value,
              operator: 'like',
            },
          },
        },
      });

      const sub = watchedQuery.subscribe({
        next(x) {
          if (!x.partial) {
            setData(x.data ? x.data.ItemQuery.items.map(item => ({ id: item.id, name: item.name, type: item.type })) : []);
            setError(null);
            setLoading(false);
          }
        },
        error(err) {
          setError(err);
          setLoading(false);
        },
        complete() {
          setLoading(false);
        },
      });

      return () => {
        sub.unsubscribe();
      };
    }
    return () => {};
  }, [value]);

  return { data, error, loading };
};

export { useAbortiveQuery };
