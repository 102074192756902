import React from 'react';
import { get } from 'lodash';
import { Input } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import {
  FormikContext,
  FormikProps,
  GenericFieldHTMLAttributes,
} from 'formik';
import i18next from 'i18next';
import ColorPicker from 'features/ui/ColorPicker';
import FormRow from 'features/ui/FormRow';
import ImageUploader from 'features/ui/ImageUploader';
import Switch from 'features/ui/Switch';
import FieldSwitcher from 'features/ui/FieldSwitcher';
import MarkdownComponent from 'features/ui/MarkdownComponent';
import BytesInput from './BytesInput';
import {
  makeLabel,
  makeName,
  PrefixFunc,
} from './helpers';
import 'easymde/dist/easymde.min.css';

/* ===== TYPES ===== */

export type FormPropsType = FormikProps<any> & {
  t: i18next.TFunction,
};

export interface IComponentProps {
  name: string;
  form: FormPropsType;
  required?: boolean;
  placeholder?: string;
  labelPrefix?: string | PrefixFunc;
  namePrefix?: string | PrefixFunc;
  useLabel?: boolean;
  text?: string;
  type?: InputType;
  width?: number;
  height?: number;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  imageWidth?: number;
  imageHeight?: number;
  imageBackground?: string;
  path?: string;
  children?: JSX.Element;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onChangeColor?: (name: string, value: string) => void;
  error?: string;
  disabled?: boolean;
  hasSwitcher?: boolean;
  shouldUpdate?: (
    nextProps: GenericFieldHTMLAttributes & { formik: FormikContext<any> },
    props: {},
  ) => boolean;
}

/* ===== FUNCTIONS ===== */

export function formFieldsBuilder(
  {
    labelPrefix,
    namePrefix,
    useLabel = true,
  }: {
    labelPrefix: string | PrefixFunc,
    namePrefix?: string | PrefixFunc,
    useLabel?: boolean,
  },
) {
  const map: { [key: string]: (props: IComponentProps) => JSX.Element } = {
    Bytes: renderBytesInput,
    Color: renderColorInput,
    Image: renderImageInput,
    Input: renderInput,
    Switch: renderSwitch,
    Row: renderRow,
    MarkDown: renderMarkDown,
  };
  return Object.keys(map).reduce(
    (acc: any, key: string) => {
      acc[key] = (componentProps: IComponentProps) => map[key]({
        ...componentProps,
        labelPrefix,
        namePrefix,
        useLabel,
      });
      return acc;
    },
    {},
  );
}

/* ===== COMPONENTS ===== */

export function renderSwitch(props: IComponentProps) {
  const {
    t,
    values,
    handleBlur,
  } = props.form;
  const {
    required,
    useLabel,
    text,
    shouldUpdate,
    disabled,
  } = props;
  const name = makeName(props);
  const handleChange = props.onChange ? props.onChange : props.form.handleChange;
  const component = (
    <Switch
      id={name}
      name={name}
      checked={get(values, name)}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
    />
  );
  if (useLabel) {
    const label = t(makeLabel(props));
    return (
      <FormRow
        id={name}
        label={label}
        text={text}
        required={required}
        shouldUpdate={shouldUpdate}
      >
        {component}
      </FormRow>
    );
  }
  return component;
}

export function renderInput(props: IComponentProps) {
  const {
    t,
    values,
    handleBlur,
  } = props.form;
  const {
    type,
    text,
    placeholder,
    useLabel,
    hasSwitcher,
    required,
  } = props;

  const name = makeName(props);
  const handleChange = props.onChange ? props.onChange : props.form.handleChange;
  let component = (
    <Input
      type={type}
      id={name}
      name={name}
      rows={3}
      placeholder={placeholder}
      value={get(values, name)}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
  if (hasSwitcher) {
    component = (
      <FieldSwitcher
        offValue=""
        form={props.form}
        name={name}
      >
        {component}
      </FieldSwitcher>
    );
  }
  if (useLabel) {
    const label = t(makeLabel(props));
    return (
      <FormRow
        id={name}
        label={label}
        text={text}
        required={required}
      >
        {component}
      </FormRow>
    );
  }
  return component;
}

export function renderColorInput(props: IComponentProps) {
  const {
    t,
    values,
    handleBlur,
  } = props.form;
  const {
    placeholder,
    text,
    useLabel,
    required,
  } = props;
  const name = makeName(props);
  const handleChange = (v: string) => {
    if (props.onChangeColor === undefined) {
      props.form.setFieldValue(name, v);
    } else {
      props.onChangeColor(name, v);
    }
  };
  const component = (
    <ColorPicker
      id={name}
      name={name}
      placeholder={placeholder}
      color={get(values, name)}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
  if (useLabel) {
    const label = t(makeLabel(props));
    return (
      <FormRow
        id={name}
        label={label}
        text={text}
        required={required}
      >
        {component}
      </FormRow>
    );
  }
  return component;
}

export function renderImageInput(props: IComponentProps) {
  const {
    t,
    values,
    handleBlur,
    setFieldValue,
  } = props.form;
  const {
    width,
    height,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    imageWidth,
    imageHeight,
    imageBackground,
    required,
    path,
  } = props;
  const name = makeName(props);
  const handleChange = (data: any) => {
    if (data.target.validity.valid) {
      setFieldValue(name, data.target.files[0]);
    }
  };
  let text;
  if (width && height) {
    text = t('forms:expected_size', { width, height });
  } else if (height) {
    text = t('forms:expected_width', { width });
  } else if (minWidth && minHeight && maxWidth && maxHeight) {
    text = t('forms:expected_range', { minWidth,
      minHeight,
      maxWidth,
      maxHeight
    });
  }

  const timestamp = Date.now();
  return (
    <FormRow
      label={t(makeLabel(props))}
      id={name}
      text={text}
      required={required}
    >
      <ImageUploader
        id={name}
        name={name}
        value={path ? `${path}?t=${timestamp}` : get(values, name)}
        onChange={handleChange}
        onBlur={handleBlur}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        imageBackground={imageBackground}
      />
    </FormRow>
  );
}

export function renderBytesInput(props: IComponentProps) {
  return <BytesInput {...props} />;
}

export function renderRow(props: IComponentProps) {
  const { t } = props.form;
  const {
    text,
    children,
    error,
    shouldUpdate,
    required,
  } = props;
  return (
    <FormRow
      label={t(makeLabel(props))}
      id={makeName(props)}
      text={text}
      error={error}
      shouldUpdate={shouldUpdate}
      required={required}
    >
      {children}
    </FormRow>
  );
}

export function renderMarkDown(props: IComponentProps) {
  const {
    t,
    values,
    handleBlur,
    setFieldValue,
  } = props.form;
  const {
    text,
    shouldUpdate,
    error,
  } = props;
  const name = makeName(props);
  const handleChange = (data: any) => setFieldValue(name, data);
  return (
    <FormRow
      id={name}
      key={name}
      label={t(makeLabel(props))}
      text={text}
      error={error}
      shouldUpdate={shouldUpdate}
    >
      <MarkdownComponent
        onChange={handleChange}
        onBlur={handleBlur}
        value={get(values, name)}
      />
    </FormRow>
  );
}
