import {
  IItem,
  IUser,
} from 'features/types';

export interface IOrderQueryData {
  items: IOrder[];
}

export interface IOrderQueryResponse {
  OrderQuery: IOrderQueryData;
}

export enum OrderPaymentStatus {
  pending = 'pending',
  paid = 'paid',
  failed = 'failed',
}

export enum OrderStatus {
  new = 'new',
  pending = 'pending',
  paid = 'paid',
  cancelled = 'cancelled',
  failed = 'failed',
}

export interface IOrderPayment {
  id: ID;
  currency: string;
  total: number;
  email: string;
  provider: string;
  transaction: string;
  status: OrderPaymentStatus;
  paid_at: string;
  created_at: string;
  updated_at: string;
  user: IUser;
}

export interface IOrder {
  id: ID;
  status: OrderStatus;
  created_at: string;
  updated_at: string;
  user: IUser;
  items: IItem[];
  payments: IOrderPayment[];
}
