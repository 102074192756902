export default (
  event: any,
  contentType: string,
  setFieldValue: (field: string, files: object[] | undefined) => void,
) => {
  if (event.target.validity.valid) {
    if (event.target.files.length > 0) {
      setFieldValue('content', [{
        type: contentType,
        file: event.target.files[0],
        sort: 1,
      }]);
    }
  }
};
