import crudUrlBuilder from 'lib/crudUrlBuilder';
import { IUser } from '../../types';

export const makeUserUrl = crudUrlBuilder('user', 'users');

export const makeUserUrlTo = (user: IUser, action: string = 'view', part: string = '') => {
  return makeUserUrl({
    action,
    part,
    id: user.id,
  });
};
