import React from 'react';

class Footer extends React.PureComponent {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="app-footer">
        <span>
          &copy; {year} Alpina Digital.
        </span>
        <span className="ml-auto">Powered by{' '}
          <a href="https://alpinadigital.ru" rel="external noopener noreferrer" target="_blank">
            AD.DevTeam
          </a>
        </span>
      </footer>
    );
  }
}

export default Footer;
