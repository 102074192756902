import React from 'react';
import { Input } from 'reactstrap';

export interface IInputSelectProps {
  id?: string;
  name?: string;
  value?: string;
  options: { [value: string]: string };
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

const InputSelect = (props: IInputSelectProps) => {
  const {
    id,
    name,
    value,
    options,
    onChange,
    onBlur,
  } = props;

  return (
    <Input
      type="select"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {Object.keys(options).map((option: string) => (
        <option
          key={option}
          value={option}
        >
          {options[option]}
        </option>
      ))}
    </Input>
  );
};

export default InputSelect;
