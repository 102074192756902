import React from 'react';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { IInfoRowOptions } from './types';

function renderByType(value: string | number | boolean | null | undefined) {
  if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
  }
  return value;
}

export const InfoRowObjectAsList = ({
  label,
  value,
  options,
}: {
  label: string,
  value: {[key: string]: string | number} | null,
  options?: IInfoRowOptions,
}) => {
  if (value === null) {
    return <InfoRowNotSet label={label} options={options} />;
  }
  return (
    <InfoRow label={label} {...options}>
      {Object.keys(value).map(key => (<div key={key}><b>{key}</b> {renderByType(value[key])}</div>))}
    </InfoRow>
  );
};
