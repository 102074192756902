import {
  action,
  decorate,
  observable,
  observe,
} from 'mobx';

interface IAppState {
  isLoading?: boolean;
  globalProgress?: number;
}

class AppStore {
  state = {
    isLoading: false,
    globalProgress: 0,
  };

  constructor() {
    observe(this.state, this.onChangeState);
  }

  onChangeState = (change: object) => {
    if (process.env.NODE_ENV === 'development') {
      global.console.log('mobx onChangeState', this.state, change);
    }
  };

  updateState(state: IAppState) {
    Object.assign(this.state, state);
  }
}

decorate(AppStore, {
  state: observable,
  updateState: action,
});

const appStore = new AppStore();

export default appStore;
