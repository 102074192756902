import React from 'react';
import {
  get,
  set,
} from 'lodash';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import * as Yup from 'yup';
import { crudMutate } from 'features/common/helpers';
import filterChangedValues from 'lib/filterChangedValues';
import field from 'lib/field';
import {
  imageFileValidator,
  minValidator,
  stringValidator,
} from 'lib/validators';
import BaseOfferForm from './BaseOfferForm';
import { makeOfferUrl } from '../lib/url';
import {
  asIs,
  toInt,
  toStr,
} from 'lib/crudConvert';
import createOfferQuery from '../queries/createOffer.gql';
import editOfferQuery from '../queries/editOffer.gql';
import defaults from './defaults';

import {
  IOffer,
  IOfferFormValues,
  IOfferInput,
  OfferBusinessModelEnum,
} from '../types';

interface IOfferFormProps extends WithTranslation {
  offer?: IOffer;
}

class OfferForm extends React.Component<IOfferFormProps> {
  getScheme() {
    const { t } = this.props;
    const required = true;
    return Yup.object().shape({
      name: stringValidator({ required, field: t('offer_name') }),
      prefix: stringValidator({ required, field: t('offer_prefix') }),
      cost: minValidator({ required, field: t('offer_cost'), min: 0 }),
      languages: Yup.array().min(1, t('forms:required_field', { field: 'languages' })),
      defaultLanguage: Yup.object().nullable(true),
      mailLanguage: Yup.object().nullable().required(t('forms:required_field', { field: 'mailLanguage' })),
      grossing_period: minValidator({ required, field: t('offer_grossing_period'), min: 0 }),
      settingsBranding: Yup.object().shape({
        logoBottom: imageFileValidator({
          field: t('settingsBranding.logoBottom'),
        }),
        logoTop: imageFileValidator({
          field: t('settingsBranding.logoTop'),
        }),
        appIcon: imageFileValidator({
          field: t('settingsBranding.appIcon'),
        }),
        splashLogoTop: imageFileValidator({
          field: t('settingsBranding.splashLogoTop'),
        }),
        splashLogoBottom: imageFileValidator({
          field: t('settingsBranding.splashLogoBottom'),
        }),
      }),
    });
  }

  getFormData(): IOfferFormValues {
    const { offer } = this.props;
    const defaultLanguage = {
      code: 'ru',
      id: '1',
      name: 'русский',
      sort: 2,
    };
    return {
      name: field(offer, 'name', ''),
      prefix: field(offer, 'prefix', ''),
      is_trial: field(offer, 'is_trial', false),
      contacts: field(offer, 'contacts', ''),
      created_at: field(offer, 'created_at', ''),
      languages: offer === undefined ? [defaultLanguage.id] : offer.languages.map(language => language.id),
      defaultLanguage: field(offer, 'defaultLanguage', defaultLanguage),
      mailLanguage: field(offer, 'mailLanguage', defaultLanguage),
      base_domain: field(offer, 'base_domain', offer === undefined ? 'alpinadigital.ru' : offer.base_domain),
      expire_date: field(offer, 'expire_date', ''),
      cost: field(offer, 'cost', 0),
      business_model: field(offer, 'business_model', OfferBusinessModelEnum.Library),
      grossing_period: field(offer, 'grossing_period', 15),
      ios_prefix: field(offer, 'ios_prefix', ''),
      ios_link: field(offer, 'ios_link', ''),
      android_prefix: field(offer, 'android_prefix', ''),
      android_link: field(offer, 'android_link', ''),
      singleapp_ios_enabled: field(offer, 'singleapp_ios_enabled', false),
      singleapp_android_enabled: field(offer, 'singleapp_android_enabled', false),
      singleapp_title: field(offer, 'singleapp_title', ''),
      enable_banners: field(offer, 'enable_banners', false),
      enable_pushes: field(offer, 'enable_pushes', false),
      enable_external_link: field(offer, 'enable_external_link', false),
      enable_comments: field(offer, 'enable_comments', false),
      new_items_by_default: field(offer, 'new_items_by_default', false),
      categories: offer === undefined ? [] : offer.categories.map(category => category.id),
      banners: offer === undefined ? [] : offer.banners.map(banner => banner.id),
    };
  }

  getChangedValues(values: IOfferFormValues) {
    const formData = this.getFormData();
    if (Array.isArray(values.categories)) {
      values.categories = values.categories.map(v => v.toString());
    }
    if (Array.isArray(values.banners)) {
      values.banners = values.banners.map(v => v.toString());
    }
    return filterChangedValues(formData, values);
  }

  getSendingValues(values: IOfferFormValues): IOfferInput {
    const { offer } = this.props;
    const isCreateMode = !offer;
    const result = {} as IOfferInput;
    [
      ['name', toStr],
      ['prefix', toStr],
      ['contacts', toStr],
      ['base_domain', toStr],
      ['languages', asIs],
      ['defaultLanguage', asIs],
      ['mailLanguage', asIs],
      ['cost', toInt],
      ['expire_date', toStr],
      ['ios_prefix', toStr],
      ['ios_link', toStr],
      ['android_prefix', toStr],
      ['android_link', toStr],
      ['singleapp_title', toStr],
      ['business_model', toStr],
      ['grossing_period', toInt],
      ['singleapp_ios_enabled', asIs],
      ['singleapp_android_enabled', asIs],
      ['singleapp_title', toStr],
      ['enable_banners', asIs],
      ['enable_pushes', asIs],
      ['enable_external_link', asIs],
      ['enable_comments', asIs],
      ['is_trial', asIs],
      ['new_items_by_default', asIs],
    ].forEach((val: any) => {
      const [name, cb] = val;
      if (name in values) {
        set(result, name, cb(values[name]));
      }
    });
    if (values.defaultLanguage) {
      result.defaultLanguage = values.defaultLanguage.id;
    }
    if (values.defaultLanguage === '') {
      result.defaultLanguage = null;
    }
    if (values.mailLanguage) {
      result.mailLanguage = values.mailLanguage.id;
    }
    if (Array.isArray(values.categories)) {
      result.categories = values.categories.slice();
    }
    if (Array.isArray(values.banners)) {
      result.banners = values.banners.slice();
    }
    if (isCreateMode) {
      defaults(result);
      result.items = [];
      [
        'settingsBranding.logoTop',
        'settingsBranding.logoBottom',
        'settingsBranding.appIcon',
        'settingsBranding.splashLogoTop',
        'settingsBranding.splashLogoBottom',
      ].forEach(key => set(result, key, get(values, key)));
    }
    return result;
  }

  onSubmit = (
    values: IOfferFormValues,
    formActions: FormikActions<IOfferFormValues>,
  ) => {
    const id = get(this.props, 'offer.id', undefined);
    const offer = { ...values };
    const variables = (
      id ?
        { id, offer: this.getSendingValues(this.getChangedValues(offer)) } :
        { offer: this.getSendingValues(offer) }
    );
    crudMutate({
      id,
      formActions,
      variables,
      mutation: !!id ? editOfferQuery : createOfferQuery,
      redirect: makeOfferUrl(),
      check: !!Object.keys(variables.offer).length,
    });
  };

  render() {
    const { offer } = this.props;
    let id: ID;
    if (offer) {
      id = offer.id;
    }
    return (
      <React.Fragment>
        <Formik
          initialValues={this.getFormData()}
          validationSchema={this.getScheme()}
          enableReinitialize
          onSubmit={this.onSubmit}
        >
          {(props: FormikProps<IOfferFormValues>) => <BaseOfferForm {...props} id={id} />}
        </Formik>
      </React.Fragment>
    );
  }
}

export default withTranslation('offers')(OfferForm);
