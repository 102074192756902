import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  gql,
} from 'features/graphql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  IPriceTier,
  IPriceTierQueryResponse,
} from '../types';
import ReactSelect from 'features/ui/ReactSelect/ReactSelectWithId';

interface IPriceTiersSelectProps extends ISharedSelectProps {
  data: IPriceTier[];
}

interface IPriceTiersSelectState {
  options: ISelectOption[];
}

class PriceTiersSelect extends React.PureComponent<IPriceTiersSelectProps, IPriceTiersSelectState> {
  static getDerivedStateFromProps(props: any) {
    return {
      options: PriceTiersSelect.mapDataToOptions(props.data),
    };
  }

  static mapDataToOptions = (data: IPriceTier[]) => data.map((tier: IPriceTier) => ({
    value: +tier.id!,
    label: tier.rub,
  }));

  state = {
    options: [],
  };

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        isMulti={false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

const QUERY = gql`
  query ItemPriceTierQuery($pageNum: Int, $perPage: Int) {
    ItemPriceTierQuery(page: $pageNum, perPage: $perPage) {
      items {
        id
        rub
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, IPriceTierQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.ItemPriceTierQuery ? data.ItemPriceTierQuery.items.reverse() : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, IPriceTierQueryResponse, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: () => {
    return {
      variables: {
        pageNum: 1,
        perPage: 10000,
      },
      context: {
        isGlobalLoading: true,
      },
    };
  },
})(PriceTiersSelect);
