import crudUrlBuilder from 'lib/crudUrlBuilder';
import { IPromocode } from '../../types';

export const makePromocodeUrl = crudUrlBuilder('promocode', 'promocodes');

export const makePromocodeUrlTo = (promo: IPromocode, action: string = 'view') => {
  return makePromocodeUrl({
    action,
    id: promo.id,
  });
};
