import React from 'react';
import IconButton from 'features/ui/IconButton';
import i18n from 'features/intl/i18n';

export default (props: React.HTMLProps<HTMLButtonElement>) => {
  return (
    <IconButton
      {...props}
      title={i18n.t('dict:delete')}
      icon={'icon-trash icons font-lg'}
    />
  );
};
