import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { AccessControl } from 'features/acl';
import { Page404 } from 'features/http';
import PageCreate from 'features/userSubscriptions/PageCreate';
import PageEdit from 'features/userSubscriptions/PageEdit';
import PageUserSubscription from 'features/userSubscriptions/PageUserSubscription';
import PageView from 'features/userSubscriptions/PageView';

export default () => (
    <AccessControl permission="offers:access">
      <Switch>
        <AppRoute path="/user-subscriptions" menuId="user-subscriptions" component={PageUserSubscription} exact />
        <AppRoute path="/user-subscriptions/create" menuId="user-subscriptions" component={PageCreate} exact />
        <AppRoute path="/user-subscriptions/edit/:id" menuId="user-subscriptions" component={PageEdit} exact />
        <AppRoute path="/user-subscriptions/:id" menuId="user-subscriptions" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
);
