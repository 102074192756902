import React from 'react';
import 'react-dates/initialize';
import {
  SingleDatePicker,
} from 'react-dates';
import moment from 'moment';
import { Moment } from 'features/types';

interface IDatePickerProps {
  id: string;
  name?: string;
  value: number | string | null;
  onChange: (date: Moment | null) => void;
}

interface IDatePickerState {
  date: Moment | null;
  focused: boolean;
}

const minDate = moment('1971-01-01');
const maxDate = moment('2038-01-01');

class DatePicker extends React.PureComponent<IDatePickerProps, IDatePickerState> {
  constructor(props: IDatePickerProps) {
    super(props);

    const date = props.value ? moment(new Date(props.value)) : null;

    this.state = {
      date,
      focused: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IDatePickerProps) {
    const date = nextProps.value ? moment(new Date(nextProps.value)) : null;

    return {
      date,
    };
  }

  onChange = (date: Moment | null) => {
    if (date && date.valueOf() < minDate.valueOf()) {
      date = minDate;
    }
    if (date && date.valueOf() > maxDate.valueOf()) {
      date = maxDate;
    }
    this.props.onChange(date);
  };

  onFocus = ({ focused }: { focused: boolean | null }) => {
    this.setState({
      focused: !!focused,
    });
  };

  isOutsideRange = () => {
    return false;
  };

  render() {
    const {
      id,
    } = this.props;

    const {
      date,
      focused,
    } = this.state;

    return (
      <SingleDatePicker
        id={id}
        date={date}
        focused={focused}
        onDateChange={this.onChange}
        onFocusChange={this.onFocus}
        displayFormat="DD-MM-YYYY"
        placeholder="Дата"
        isOutsideRange={this.isOutsideRange}
      />
    );
  }
}

export default DatePicker;
