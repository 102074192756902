import React from 'react';
import {
  Button,
  ModalFooter,
} from 'reactstrap';
import { Modal } from 'features/ui/Modal';
import {
  BannerEditor,
  ElementTypeEnum,
  IScene,
} from 'banner-editor-component';
import PropertiesComponent from './Properties';
import OutlinerComponent from './Outliner';
import { Colors } from './types';

import style from './style.module.scss';

interface IModalBannerEditorProps {
  canvasWidth: number;
  canvasHeight: number;
  onSave: (blob: Blob | null) => void;
  colors?: Colors;
}

interface IModalBannerEditorState {
  isOpened: boolean;
}

export class ModalBannerEditor extends React.Component<IModalBannerEditorProps, IModalBannerEditorState> {
  private editorRef: React.RefObject<BannerEditor>;

  constructor(props: IModalBannerEditorProps) {
    super(props);
    this.state = {
      isOpened: false,
    };
    this.editorRef = React.createRef();
  }

  getDefaultScene = (): IScene => {
    const {
      canvasWidth,
      canvasHeight,
    } = this.props;

    return {
      name: 'Сцена',
      width: canvasWidth,
      height: canvasHeight,
      hasBackground: true,
      children: [{
        name: 'Текст',
        x: (canvasWidth - 400) / 2,
        y: (canvasHeight - 300) / 2,
        width: 400,
        height: 300,
        align: 'center',
        fontFamily: 'Helvetica',
        fontSize: 60,
        textAlign: 'left',
        lineHeight: 70,
        text: 'Начните с ввода текста баннера',
        color: '#000000',
        _type: ElementTypeEnum.text,
      }],
      _type: ElementTypeEnum.scene,
    };
  };

  onSave = () => {
    const editor = this.editorRef.current;
    if (editor) {
      editor.onSave();
      this.onModalClose();
    }
  };

  onModalOpen = () => {
    this.setState({ isOpened: true });
  };

  onModalClose = () => {
    this.setState({ isOpened: false });
  };

  renderPropertiesComponent = (props: any) => {
    const { colors } = this.props;
    return <PropertiesComponent {...props} colors={colors} />;
  };

  render() {
    const {
      canvasWidth,
      canvasHeight,
      onSave,
    } = this.props;
    const { isOpened } = this.state;
    const bannerClassNames = {
      editor: style.editor,
      canvasContainer: style.canvasContainer,
      sidebar: style.sidebar,
    };
    return (
      <div>
        <Button
          color="primary"
          className={style.openEditorButton}
          onClick={this.onModalOpen}
        >
          Редактор {canvasWidth}x{canvasHeight}px
        </Button>
        <Modal
          title="Редактор Баннеров"
          isOpened={isOpened}
          onClose={this.onModalClose}
          className={style.modal}
          backdrop="static"
        >
          <BannerEditor
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
            propertiesComponent={this.renderPropertiesComponent}
            outlinerComponent={OutlinerComponent}
            onSave={onSave}
            ref={this.editorRef}
            classNames={bannerClassNames}
            initialScene={this.getDefaultScene()}
          />
          <ModalFooter>
            <Button color="primary" onClick={this.onSave}>Сохранить</Button>
            {' '}
            <Button color="secondary" onClick={this.onModalClose}>Отмена</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
