import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { makeLinkListValue } from 'lib/makeLinkListValue';
import {
  InfoRow,
  InfoRowDateBlock,
  InfoRowImage,
  InfoRowLinkList,
  InfoRowNotSet,
  InfoRowSwitch,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { createView } from 'features/common';
import { makeOfferUrl } from 'features/offers/lib/url';
import ResourceLink from '../lib/ResourceLink';
import { DATETIME_FORMAT_DEFAULT } from 'features/app/consts';
import {
  makeBannerUrl,
  makeBannerUrlTo,
} from '../lib/url';
import getEntity from '../queries/getBanner.gql';

import { FEATURE_ICON } from '../consts';

import { IBanner } from '../types';

const View = createView<IBanner>(getEntity, 'BannerQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderResourse(banner: IBanner) {
    const { t } = this.props;
    if (!banner.resource_link || !banner.resource_type) {
      return <InfoRowNotSet label={t('resource_link')} />;
    }
    return (
      <InfoRow label={t('resource_link')}>
        <ResourceLink banner={banner} />
      </InfoRow>
    );
  }

  renderChildren = (banner: IBanner) => {
    const { t } = this.props;
    const offersLinkListValue = makeLinkListValue(banner.offers, 'name', makeOfferUrl, 'view');
    const resourceType = banner.resource_type && t(`resource_type_${banner.resource_type}`);
    return (
      <React.Fragment>
        <LinkBlock editLink={makeBannerUrlTo(banner, 'edit')} />
        <InfoRowText label="ID" value={banner.id} />
        <InfoRowText label={t('name')} value={banner.name} />
        <InfoRowText label={t('weight')} value={banner.weight} />
        <hr />
        <InfoRowText label={t('started_at')} value={moment(banner.started_at, DATETIME_FORMAT_DEFAULT).format('lll')} />
        <InfoRowText label={t('finished_at')} value={moment(banner.finished_at, DATETIME_FORMAT_DEFAULT).format('lll')} />
        <hr />
        <InfoRowSwitch label={t('is_active')} value={banner.is_active} on="dict:yes" off="dict:no" />
        <InfoRowText label={t('resource_type')} value={resourceType} />
        {this.renderResourse(banner)}
        <InfoRowLinkList
          label={t('offers:offers')}
          value={offersLinkListValue}
          byLine={true}
          max={10}
        />
        <InfoRowImage label={t('image')} value={get(banner, 'image.path', null) as (string | null)} />
        <hr />
        <InfoRowDateBlock value={banner} />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField={'name'}
        icon={FEATURE_ICON}
        crumbs={[{ link: makeBannerUrl(), name: t('banners') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('banners')(PageView);
