import React from 'react';
import { FormikProps } from 'formik';
import ItemForm from 'features/items/lib/Form';
import getTestQuery from 'features/tests/queries/getTestQuery.gql';
import { IFormValues } from 'features/courses/types';
import { IItem } from 'features/types';
import BaseForm from './Form';

interface IFormProps {
  data?: IItem;
}

class Form extends React.PureComponent<IFormProps> {
  resolveMutationData = (values: any) => values;

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: any) => shape;

  resolveInitialValues = (values: any) => {
    const { data } = this.props;

    const sortInitialContentValues = () => {
      if (data.content && data.content[0] && data.content[0].test.questions) {
        return [{
          ...data.content[0],
          test: {
            id: data.content[0].test.id,
            questions: data.content[0].test.questions.sort((first: IQuestion, second: IQuestion) => first.sort - second.sort).map((question: IQuestion) => ({
              ...question,
              answer: {
                ...question.answer,
                options: question.answer.options.sort((first, second) => first.sort - second.sort),
              },
            })),
          },
        }];
      }
      return [];
    };

    const defaultValues = {
      childStages: [],
    };

    return data ? {
      ...defaultValues,
      ...values,
      content: sortInitialContentValues(),
      test: {
        attempts_count: data.test.attempts_count || 2,
      },
    } : {
      ...defaultValues,
      ...values,
      test: {
        attempts_count: 2,
      },
    };
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="test"
        data={data}
        getItemQuery={getTestQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        renderForm={this.renderForm}
      />
    );
  }
}

export default Form;
