import crudUrlBuilder from 'lib/crudUrlBuilder';
import { IFeaure } from '../../types';

export const makeFeatureUrl = crudUrlBuilder('feature-toggle', 'feature-toggle');

export const makeFeatureUrlTo = (feature: IFeaure, action: string = 'view') => {
  return makeFeatureUrl({
    action,
    id: feature.id,
  });
};
