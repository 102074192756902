import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { flow } from 'lodash';
import {
  match as IMatch,
  Redirect,
} from 'react-router';

import CardPage from 'features/ui/Page/CardPage';
import LinkBlock from 'features/ui/LinkBlock';
import { IListQueryVariables } from 'features/types';
import deepOmit from 'lib/deepOmit';
import Spinner from 'features/ui/Spinner';
import { ILayout, ILayoutResponse } from '../types';
import LayoutForm from '../lib/LayoutForm';
import {
  makeLayoutUrl,
  makeLayoutUrlTo,
} from '../lib/url';
import getLayoutQuery from '../queries/getLayoutQuery.gql';
import editLayoutMutation from '../queries/editLayout.gql';
import { LAYOUT_ICON } from '../consts';

interface IPageEditProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  layout: ILayout;
}

class PageEdit extends React.Component<IPageEditProps> {
  render() {
    const {
      layout,
      isLoading,
      t,
    } = this.props;

    if (!isLoading && !layout) {
      return <Redirect to="/404" />;
    }

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <CardPage
        icon={LAYOUT_ICON}
        title={layout ? `${t('edit_layout')}: ${layout.name}` : ''}
        crumbs={[{ link: makeLayoutUrl(), name: t('layouts') }]}
      >
        <LinkBlock viewLink={makeLayoutUrlTo(layout, 'view')} />
        <LayoutForm
          layout={layout}
          mutation={editLayoutMutation}
        />
      </CardPage>
    );
  }
}

const mapResultsToProps = (props: OptionProps<IPageEditProps, ILayoutResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  if (data && data.LayoutQuery) {
    const layout = deepOmit(data.LayoutQuery.items[0], '__typename');
    return {
      layout,
      isLoading: data && data.loading,
      ...ownProps,
    };
  }
  return {
    layout: null,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphql(getLayoutQuery, {
    props: mapResultsToProps,
    options: ({ match }: { match: IMatch<any> }) => {
      const { params } = match;
      const { id } = params || { id: 0 };
      return {
        context: {
          isGlobalLoading: true,
        },
        variables: {
          id: [id],
        },
      };
    },
  }),
  withTranslation('layouts'),
)(PageEdit);
