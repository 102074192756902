import React from 'react';
import PageItems from 'features/items/PageItems';
import { FEATURE_ICON } from 'features/hybrids/consts';

class PageHybrids extends React.PureComponent<{}> {
  render() {
    return (
      <PageItems
        title="Гибриды"
        type="hybrid"
        icon={FEATURE_ICON}
      />
    );
  }
}

export default PageHybrids;
