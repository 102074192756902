import { get } from 'lodash';
import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { gql } from 'features/graphql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  IBanner,
  IBannerQueryResponse,
} from '../../types';
import ReactSelect from 'features/ui/ReactSelect/ReactSelectWithId';

interface IBannersSelectProps extends ISharedSelectProps {
  data: IBanner[];
}

interface IBannersSelectState {
  options: ISelectOption[];
}

class BannersSelect extends React.PureComponent<IBannersSelectProps, IBannersSelectState> {
  static getDerivedStateFromProps(props: any) {
    return {
      options: BannersSelect.mapBannersToOptions(props.data),
    };
  }

  static mapBannersToOptions = (data: IBanner[]) => data.map((banner: IBanner) => ({
    value: +banner.id,
    label: banner.name,
    image: get(banner, 'image.path', null),
  }));

  state = {
    options: [],
  };

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
      isMulti,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        isMulti={!!isMulti}
        value={value}
        minSearchLength={1}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

const QUERY = gql`
  query getBannersForSelect($pageNum: Int, $perPage: Int) {
    BannerQuery(page: $pageNum, perPage: $perPage) {
      items {
        id
        name
        image {
          id
          path
        }
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, IBannerQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.BannerQuery ? data.BannerQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, IBannerQueryResponse, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: () => {
    return {
      variables: {
        pageNum: 1,
        perPage: 10000,
      },
    };
  },
})(BannersSelect);
