import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import YesNoSelect, {
  IYesNoSelectProps,
} from 'features/ui/YesNoSelect';

const ActiveSelector = (props: IYesNoSelectProps & WithTranslation) => {
  return (
    <YesNoSelect
      {...props}
      yesCaption={props.t('active')}
      noCaption={props.t('not_active')}
    />
  );
};

export default withTranslation('dict')(ActiveSelector);
