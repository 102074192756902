import React from 'react';
import {
  Input,
  InputProps,
  Label,
} from 'reactstrap';
import cn from 'classnames';

interface ISwitchProps {
  offColor?: string;
  onColor?: string;
}

export default (props: InputProps<ISwitchProps>) => {
  const { offColor, onColor, ...passProps } = props;

  return (
    <Label
      className={cn('switch switch-text switch-pill', `switch-${onColor || 'success'}`, {
        [`switch-off-${offColor}`]: offColor,
      })}
    >
      <Input
        {...passProps}
        className="switch-input"
        type="checkbox"
      />
      <span className="switch-label" data-on={'on'} data-off={'off'} />
      <span className="switch-handle" />
    </Label>
  );
};
