import React from 'react';
import {
  noop,
  pick,
} from 'lodash';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import CreatableSelect from 'react-select/lib/AsyncCreatable';
import {
  ISelectOption,
  ISelectOptionsByValue,
  ISharedSelectProps,
} from 'features/types';

export interface IReactSelectProps extends ISharedSelectProps {
  options: ISelectOption[];
  isClearable?: boolean;
  isMulti: boolean;
  shrinkHeight?: number;
  async?: boolean;
  creatable?: boolean;
  minSearchLength?: number;
  loadOptions?: any;
  noOptionsMessage?: () => string;
  onCreateOption?: any;
  formatCreateLabel?: any;
  createOptionPosition?: 'first' | 'last';
  components?: any;
  styles?: any;
  onPaste?: boolean | ((e: any) => void);
  responsive?: boolean;
}

interface IReactSelectState {
  optionsByValue: ISelectOptionsByValue;
  showOptions: boolean;
  selectedOptions: ISelectOption[];
  creatableOptions: ISelectOption[];
}

class ReactSelect extends React.PureComponent<IReactSelectProps, IReactSelectState> {
  static getDerivedStateFromProps(props: IReactSelectProps) {
    const optionsByValue = props.options.reduce(
      (res: ISelectOptionsByValue, option: ISelectOption) => {
        res[option.value] = option;
        return res;
      },
      {});
    return {
      optionsByValue,
      selectedOptions: Object.values(pick(optionsByValue, props.value)),
    };
  }

  searchTimeout?: number;

  constructor(props: IReactSelectProps) {
    super(props);

    this.state = {
      optionsByValue: {},
      showOptions: !props.minSearchLength,
      selectedOptions: [],
      creatableOptions: [],
    };
  }

  onPaste = (e: any) => {
    const { onChange } = this.props;
    const { selectedOptions } = this.state;
    e.preventDefault();
    e.stopPropagation();
    const value = e.clipboardData.getData('text/plain');

    const options: string[] = value
      .replace(/[^\d\wа-яА-ЯёЁ,;\r\n ]/g, '')
      .split(/,|;|\n/g)
      .map((option: string) => option.replace(/[^\d\wа-яА-ЯёЁ]/g, ''))
      .filter((option: string) => option);

    if (options.length) {
      const creatableOptions = options.map(option => ({ value: option, label: option }));
      this.setState({
        creatableOptions,
      });
      onChange({
        creatableOptions,
        data: selectedOptions.concat(creatableOptions),
      });
    }
  };

  // todo: ValueType from react-select is not exported
  // @see https://github.com/JedWatson/react-select/issues/2902
  onChange = (data: any, action: any) => {
    const {
      isMulti,
      creatable,
    } = this.props;
    const { creatableOptions } = this.state;

    if (!creatable) {
      const value = isMulti ? (data ? data.map((cur: ISelectOption) => cur.value) : []) : data && data.value;
      const other = isMulti ? (data ? data.map((cur: ISelectOption) => cur) : []) : data && data;
      this.props.onChange(value, action, other);
      return;
    }

    const newCreatableOptions = creatableOptions.filter(
      (option: ISelectOption) => data.some((cur: ISelectOption) => cur.value === option.value),
    );

    this.setState({
      creatableOptions: newCreatableOptions,
    });

    this.props.onChange(
      {
        data,
        creatableOptions: newCreatableOptions,
      },
      action,
    );
  };

  onInputChange = (str: string) => {
    this.setState({
      showOptions: str.length >= this.props.minSearchLength!,
    });
  };

  handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  loadOptions = (value: string) => {
    const { loadOptions, minSearchLength } = this.props;

    if (value.length >= 2 || (minSearchLength && value.length >= minSearchLength)) {
      if (this.searchTimeout) {
        window.clearTimeout(this.searchTimeout);
      }
      return new Promise((resolve) => {
        this.searchTimeout = window.setTimeout(
          () => {
            loadOptions(value).then((options: ISelectOption[]) => resolve(options));
          },
          500,
        );
      });
    }

    return Promise.resolve([]);
  };

  noOptionsMessage = () => 'Ничего не найдено';

  isValidNewOption = (inputValue: string, selectValue: any, selectOptions: ISelectOption[]) => {
    return inputValue.length > 1 && !selectOptions.some(option => option.label === inputValue);
  };

  onCreateOption = (inputValue: string) => {
    const {
      creatableOptions,
      selectedOptions,
    } = this.state;

    const newOption = {
      label: inputValue,
      value: inputValue,
    };

    const newCreatableOptions = [...creatableOptions, newOption];

    this.setState({
      creatableOptions: newCreatableOptions,
    });

    this.props.onCreateOption({
      selectedOptions,
      option: newOption,
      creatableOptions: newCreatableOptions,
    });
  };

  renderCreatable = () => {
    const {
      id,
      name,
      options,
      isMulti,
      loadOptions,
      noOptionsMessage,
      formatCreateLabel,
      createOptionPosition,
      onPaste,
    } = this.props;

    const {
      selectedOptions,
      creatableOptions,
    } = this.state;

    const select = (
      <CreatableSelect
        inputId={id}
        name={name}
        // options={options}
        // cacheOptions
        onCreateOption={this.onCreateOption}
        createOptionPosition={createOptionPosition}
        defaultOptions={[...options, ...creatableOptions]}
        loadOptions={loadOptions ? this.loadOptions : noop}
        isMulti={isMulti}
        value={[...selectedOptions, ...creatableOptions]}
        isValidNewOption={this.isValidNewOption}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        noOptionsMessage={noOptionsMessage || this.noOptionsMessage}
        placeholder={'Начните вводить для поиска или создания...'}
        formatCreateLabel={formatCreateLabel}
      />
    );

    return onPaste ? (<div onPaste={onPaste === true ? this.onPaste : onPaste}>{select}</div>) : select;
  };

  renderAsync = () => {
    const {
      id,
      name,
      options,
      isMulti,
      loadOptions,
      noOptionsMessage,
      components,
      isClearable,
      onPaste,
    } = this.props;

    const {
      selectedOptions,
    } = this.state;

    const getClipboardData = (e: any) => {
      const clipboardData = e.clipboardData || window.clipboardData;
      return clipboardData.getData('Text');
    };

    const select = (
      <AsyncSelect
        inputId={id}
        name={name}
        isClearable={isClearable}
        cacheOptions
        defaultOptions={options}
        loadOptions={loadOptions ? this.loadOptions : noop}
        isMulti={isMulti}
        value={selectedOptions}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        noOptionsMessage={noOptionsMessage || this.noOptionsMessage}
        placeholder={'Начните вводить (минимально 3 символа)...'}
        components={components}
      />
    );

    return onPaste ? (<div onPaste={(e) => onPaste(getClipboardData(e))}>{select}</div>) : select;
  };

  renderSelect() {
    const {
      id,
      name,
      options,
      isMulti,
      minSearchLength,
      isClearable,
      components,
      styles,
      shrinkHeight,
    } = this.props;

    const {
      showOptions,
      selectedOptions,
    } = this.state;

    const selectStyles = {
      menu: (s: any) => ({
        ...s,
        maxHeight: shrinkHeight ? shrinkHeight : 500,
        overflowY: 'scroll',
      }),
      singleValue: (s: any) => ({
        ...s,
        ...(styles && styles.singleValue && styles.singleValue(s)),
      }),
      valueContainer: (s: any) => ({
        ...s,
        maxHeight: 200,
        overflowY: 'scroll',
        ...(styles && styles.valueContainer && styles.valueContainer(s)),
      }),
    };

    return (
      <Select
        styles={selectStyles}
        inputId={id}
        name={name}
        options={showOptions ? options : selectedOptions}
        isMulti={isMulti}
        isClearable={isClearable}
        value={selectedOptions}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        onInputChange={minSearchLength ? this.onInputChange : undefined}
        noOptionsMessage={this.noOptionsMessage}
        placeholder={showOptions ? 'Выберите...' : 'Начните вводить...'}
        components={components}
      />
    );
  }

  render() {
    const {
      async,
      creatable,
    } = this.props;

    if (creatable) {
      return this.renderCreatable();
    }

    if (async) {
      return this.renderAsync();
    }

    return this.renderSelect();
  }
}

export default ReactSelect;
