import React from 'react';
import BooksForm from 'features/books/lib/BooksForm';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/books/consts';
import { makeBookUrl } from 'features/books/lib/url';

class PageCreate extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Создание новой книги"
        crumbs={[{ link: makeBookUrl(), name: 'Книги' }]}
      >
        <BooksForm />
      </CardPage>
    );
  }
}

export default PageCreate;
