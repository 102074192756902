import React from 'react';
import { Link } from 'react-router-dom';
import {
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import BasePage from './index';

import { ICardPageProps } from './types';

class CardPage extends React.Component<ICardPageProps> {
  renderHeader = () => {
    const {
      icon,
      title,
    } = this.props;
    return title ? (
      <CardHeader>
        {icon ? <i className={icon} /> : null}
        {title}
      </CardHeader>
    ) : null;
  };

  renderButtons = () => {
    const { buttons } = this.props;

    if (!buttons) {
      return null;
    }

    return (
      <ButtonToolbar>
        <ButtonGroup>
          {buttons.map(btn => <Link key={btn.name} to={btn.link} className="btn btn-primary btn-lg">{btn.name}</Link>)}
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    const {
      renderHeader,
      children,
      ...basePageProps
    } = this.props;

    return (
      <BasePage {...basePageProps}>
        <div className="animated">
          <Card>
            {renderHeader ? renderHeader() : this.renderHeader()}
            <CardBody>
              {this.renderButtons()}
              {children}
            </CardBody>
          </Card>
        </div>
      </BasePage>
    );
  }
}

export default CardPage;
