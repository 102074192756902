import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import gql from 'graphql-tag';
import { client } from 'features/graphql';
import { toast } from 'features/ui/Toast';
import RenderIf from 'features/ui/RenderIf';
import { Confirm } from 'features/ui/Modal';
import { InfoRowText } from 'features/ui/InfoRow';
import AddButton from 'features/ui/AddButton';
import DeleteButton from 'features/ui/DeleteButton';
import styles from './styles.module.scss';
import { IOfferToken } from '../types';

const ADD_MUTATION = gql`
  mutation addOfferToken($offerToken: OfferTokenInput) {
    OfferTokenMutation(offerToken: $offerToken) {
      id
      token
      password
    }
  }
`;

const DELETE_MUTATION = gql`
  mutation deleteOfferToken($id: ID) {
    OfferTokenMutation(id: $id, delete: true) {
      id
      token
      password
    }
  }
`;

interface IApiTokensProps extends WithTranslation {
  offerId: ID;
  tokens: IOfferToken[];
  hasControl?: boolean;
  refetch?: (vars?: any) => Promise<any>;
}

interface IApiTokensState {
  tokens: IOfferToken[];
  tokenForRemove: string | null;
}

class ApiTokens extends React.Component<IApiTokensProps, IApiTokensState> {
  static defaultProps = {
    hasControl: true,
  };

  constructor(props: IApiTokensProps) {
    super(props);
    this.state = {
      tokens: [...props.tokens],
      tokenForRemove: null,
    };
  }

  onAddToken = () => {
    const { offerId } = this.props;
    client
      .mutate({
        mutation: ADD_MUTATION,
        variables: { offerToken: { offer: offerId } },
        context: { isGlobalLoading: true },
      })
      .then(() => {
        const {
          t,
          refetch,
        } = this.props;
        if (refetch) {
          refetch();
        }
        toast.info(t('token_added_new'));
      });
  }

  onDeleteToken = () => {
    const { tokenForRemove } = this.state;
    const foundToken = this.state.tokens.find(i => i.token === tokenForRemove);
    if (!foundToken) {
      return;
    }
    const { id } = foundToken;
    client
      .mutate({
        mutation: DELETE_MUTATION,
        variables: { id, delete: true },
        context: { isGlobalLoading: true },
      })
      .then(() => {
        const { t } = this.props;
        const token = this.state.tokens.find(i => i.id === id);
        if (token) {
          this.setState(
            { tokens: this.state.tokens.filter(i => i.id !== id) },
            () => {
              toast.info(t('token_deleted_successfully', { token: token.token }));
            },
          );
        }
      });
  }

  onOpenRemoveModal = (tokenForRemove: string) => {
    this.setState({ tokenForRemove });
  }

  onCloseRemoveModal = () => {
    this.setState({ tokenForRemove: null });
  }

  render() {
    const {
      t,
      hasControl,
    } = this.props;
    const {
      tokens,
      tokenForRemove,
    } = this.state;
    const amount = tokens.length;
    const list = tokens.map((token, order) => {
      const openModalHandler = () => this.onOpenRemoveModal(token.token);
      return (
        <div
          className={styles.tokenBlock}
          key={token.id}
        >
          <InfoRowText
            label="API Key (ключ API)"
            value={token.token}
          />
          <InfoRowText
            label="API Secret"
            value={token.password}
          />
          <RenderIf condition={Boolean(hasControl)}>
            <div className={styles.tokenDeleteButton}>
              <DeleteButton
                type="button"
                onClick={openModalHandler}
              />
            </div>
          </RenderIf>
          {(order + 1) < amount ? <hr /> : null}
        </div>
      );
    });
    return (
      <React.Fragment>
        <RenderIf condition={Boolean(hasControl)}>
          <AddButton
            type="button"
            caption={t('token_add_new')}
            onClick={this.onAddToken}
          />
        </RenderIf>
        {tokens.length ? list : <p>{t('tokens_not_found')}</p>}
        <Confirm
          title={'Удаление токена'}
          description={`Удалить токен ${tokenForRemove} безвозвратно?`}
          type="danger"
          isOpened={tokenForRemove !== null}
          onClose={this.onCloseRemoveModal}
          onResolve={this.onDeleteToken}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation('offers')(ApiTokens);
