import React, {EventHandler, SyntheticEvent} from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import ReactSelect from 'features/ui/ReactSelect';
import FormRow from 'features/ui/FormRow';
import Switch from 'features/ui/Switch';
import OffersSelectWithButtons from 'features/offers/lib/OffersSelectWithButtons';
import DatePicker from 'features/ui/DatePicker';
import { Moment } from 'features/types';
import { makeFeatureUrl } from '../url';
import {
  IFeaure, IPlatforms,
} from '../../types';

interface IFeatureFormProps extends FormikProps<IFeaure>, WithTranslation {
  feature?: IFeaure;
}

const platoformOptions = [
  { label: 'ios', value: 1 },
  { label: 'android', value: 2 },
  { label: 'web', value: 3 },
  { label: 'backend', value: 4 },
];

class BaseFeatureForm extends React.Component<IFeatureFormProps> {
  onChangeOffers = (ids: ID[]) => {
    this.props.setFieldValue('offers', ids.slice());
  };

  onChangeDate = (date: Moment | null, field: string) => {
    this.props.setFieldValue(field, date ? date.format('YYYY-MM-DD') : '');
  };

  onHandleChangePlatforms = (data: string[]) => {
    const platforms = platoformOptions
      .filter((platform: {value: number }) => data.some((d: string) => +d === platform.value))
      .map(platform => platform.value);
    this.props.setFieldValue('platforms', platforms);
  };

  handleChangeKey = (event: SyntheticEvent) => {
    const { value } = event.target;
    const reg = /^[A-z0-9_-]+$/;
    if (reg.test(value) || value === '') {
      this.props.setFieldValue('key', value);
    }
  }

  render() {
    const {
      handleBlur,
      handleChange,
      t,
      values,
      feature,
    } = this.props;
    const platforms = values.platforms.map((platform: IPlatforms) => (+platform.id ? +platform.id : platform)) || [];
    return (
      <FormFrame
        id={feature ? feature.id : undefined}
        cancelLink={makeFeatureUrl()}
        {...this.props}
      >
        <FormRow label={t('is_active')} id="is_active">
          <Switch
            id="is_active"
            name="is_active"
            checked={values.is_active}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Введите название фичи"
          />
        </FormRow>

        <FormRow label={t('key')} id="key" required>
          <Input
            type="text"
            id="key"
            name="key"
            value={values.key}
            onChange={this.handleChangeKey}
            onBlur={handleBlur}
            placeholder="Введите ключ (латиница)"
          />
        </FormRow>

        <FormRow label={t('description')} id="description">
          <Input
            type="textarea"
            id="description"
            name="description"
            rows="7"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Укажите гипотезу, цель и требуемый метрики"
          />
        </FormRow>

        <FormRow label={t('build_versions')} id="build_versions">
          <Input
            type="text"
            id="build_versions"
            name="build_versions"
            value={values.build_versions}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Укажите версию мобильного приложения"
          />
        </FormRow>

        <FormRow label={t('platforms')} id="platforms">
          <ReactSelect
            isMulti
            id="platforms"
            name="platforms"
            value={platforms}
            onChange={this.onHandleChangePlatforms}
            options={platoformOptions}

          />
        </FormRow>

        <strong>Период тестирования</strong>
        <FormRow
          id="begin_date"
          label={t('begin_date')}
        >
          <DatePicker
            id="begin_date"
            value={Date.parse(values.begin_date)}
            onChange={(date: Moment | null) => this.onChangeDate(date, 'begin_date')}
          />
        </FormRow>
        <FormRow
          id="end_date"
          label={t('end_date')}
        >
          <DatePicker
            id="end_date"
            value={Date.parse(values.end_date)}
            onChange={(date: Moment | null) => this.onChangeDate(date, 'end_date')}
          />
        </FormRow>

        <strong>Выборка</strong>
        <FormRow key="offers" label={t('offers')} id="offers" text={t('offers')}>
          <OffersSelectWithButtons
            id="offers"
            name="offers"
            value={values.offers}
            onChange={this.onChangeOffers}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('features')(BaseFeatureForm);
