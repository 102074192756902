import React from 'react';

const style = {
  width: 220,
  height: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const Logo = () => (
  <div style={style}>
    <svg
      width="120"
      height="34"
      viewBox="0 0 120 34"
      fill="none"
    >
      <g clipPath="url(#clip0_90:4469)">
        <path d="M67.3806 7.27515H70.16V10.0545H67.3806V7.27515Z" fill="#1A1A1A"/>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A1A1A"
          d="M42.9812 11.2693C41.4843 11.2693 40.0487 11.8639 38.9902 12.9224C37.9318 13.9808 37.3372 15.4164 37.3372 16.9133C37.3372 18.4102 37.9318 19.8457 38.9902 20.9042C40.0487 21.9626 41.4843 22.5573 42.9812 22.5573H48.6252V16.9133C48.6252 16.1721 48.4792 15.4382 48.1956 14.7534C47.9119 14.0687 47.4962 13.4465 46.9721 12.9224C46.448 12.3983 45.8258 11.9825 45.141 11.6989C44.4563 11.4153 43.7223 11.2693 42.9812 11.2693ZM40.1157 16.9133C40.1157 16.1533 40.4176 15.4245 40.955 14.8871C41.4924 14.3498 42.2212 14.0479 42.9812 14.0479C43.7411 14.0479 44.47 14.3498 45.0073 14.8871C45.5447 15.4245 45.8466 16.1533 45.8466 16.9133V19.7787H42.9812C42.2212 19.7787 41.4924 19.4768 40.955 18.9394C40.4176 18.4021 40.1157 17.6732 40.1157 16.9133Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A1A1A"
          d="M88.8278 11.2693C87.3309 11.2693 85.8954 11.8639 84.8369 12.9224C83.7785 13.9808 83.1838 15.4164 83.1838 16.9133C83.1838 18.4102 83.7785 19.8457 84.8369 20.9042C85.8954 21.9626 87.3309 22.5573 88.8278 22.5573H94.4718V16.9133C94.4718 15.4164 93.8772 13.9808 92.8187 12.9224C91.7603 11.8639 90.3247 11.2693 88.8278 11.2693ZM85.9624 16.9133C85.9624 16.1533 86.2643 15.4245 86.8017 14.8871C87.339 14.3498 88.0679 14.0479 88.8278 14.0479C89.5878 14.0479 90.3166 14.3498 90.854 14.8871C91.3913 15.4245 91.6932 16.1533 91.6932 16.9133V19.7787H88.8278C88.0679 19.7787 87.339 19.4768 86.8017 18.9394C86.2643 18.4021 85.9624 17.6732 85.9624 16.9133Z"
        />
        <path
          fill="#1A1A1A"
          d="M70.1591 11.4429H67.3806V22.5572H70.1591V11.4429Z"
        />
        <path
          fill="#1A1A1A"
          d="M50.3618 8.66431H53.1404V22.5572H50.3618V8.66431Z"
        />
        <path
          fill="#1A1A1A"
          d="M74.5008 11.4429V12.0612C75.2082 11.549 76.0586 11.272 76.932 11.2693C78.2677 11.2693 79.5486 11.7999 80.4931 12.7444C81.4375 13.6888 81.9681 14.9698 81.9681 16.3055V22.5573H79.1896V16.1318C79.1896 15.5331 78.9517 14.9588 78.5284 14.5354C78.105 14.1121 77.5308 13.8742 76.932 13.8742C76.3333 13.8742 75.759 14.1121 75.3357 14.5354C74.9123 14.9588 74.6744 15.5331 74.6744 16.1318V22.5573H71.8959V11.4429H74.5008Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A1A1A"
          d="M66.1651 17.0001C66.1651 18.52 65.5613 19.9777 64.4865 21.0524C63.4118 22.1272 61.9541 22.7309 60.4342 22.7309C59.4394 22.7289 58.4693 22.4209 57.6556 21.8487V26.7252H54.877V11.4429H57.4819V12.2774C58.3286 11.6262 59.3661 11.272 60.4342 11.2693C61.9541 11.2693 63.4118 11.8731 64.4865 12.9478C65.5613 14.0225 66.1651 15.4802 66.1651 17.0001ZM60.4342 19.9524C61.0181 19.9524 61.5889 19.7792 62.0744 19.4548C62.5599 19.1304 62.9383 18.6693 63.1617 18.1299C63.3852 17.5904 63.4436 16.9968 63.3297 16.4242C63.2158 15.8515 62.9346 15.3254 62.5217 14.9126C62.1089 14.4997 61.5828 14.2185 61.0101 14.1046C60.4374 13.9907 59.8438 14.0491 59.3044 14.2726C58.7649 14.496 58.3038 14.8744 57.9794 15.3599C57.655 15.8454 57.4819 16.4162 57.4819 17.0001C57.4819 17.7831 57.7929 18.534 58.3466 19.0877C58.9003 19.6413 59.6512 19.9524 60.4342 19.9524Z"
        />
        <path
          fill="#1A1A1A"
          d="M102.807 8.14331H103.85V9.18558H102.807V8.14331Z"
        />
        <path
          fill="#1A1A1A"
          d="M102.807 9.7063H103.849V14.2215H102.807V9.7063Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A1A1A"
          d="M99.4211 8.66431H97.2504V14.2215H99.4211C100.158 14.2215 100.865 13.9287 101.386 13.4076C101.907 12.8866 102.2 12.1798 102.2 11.4429C102.2 10.706 101.907 9.99922 101.386 9.47813C100.865 8.95705 100.158 8.66431 99.4211 8.66431ZM99.4211 13.1795H98.2923V9.70628H99.4211C99.8817 9.70628 100.323 9.88924 100.649 10.2149C100.975 10.5406 101.158 10.9823 101.158 11.4429C101.158 11.9035 100.975 12.3452 100.649 12.6709C100.323 12.9965 99.8817 13.1795 99.4211 13.1795Z"
        />
        <path d="M120 8.66431H118.958V14.2215H120V8.66431Z" fill="#1A1A1A" />
        <path d="M109.754 8.14331H110.796V9.18558H109.754V8.14331Z" fill="#1A1A1A" />
        <path d="M109.754 9.7063H110.796V14.2215H109.754V9.7063Z" fill="#1A1A1A" />
        <path d="M112.88 8.66431H111.838V9.70628H111.317V10.6614H111.838V14.2215H112.88V10.6614H113.401V9.70628H112.88V8.66431Z" fill="#1A1A1A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M118.263 11.9204C118.263 11.4653 118.128 11.0204 117.876 10.642C117.623 10.2636 117.263 9.9687 116.843 9.79454C116.422 9.62038 115.96 9.57481 115.513 9.6636C115.067 9.75238 114.657 9.97154 114.335 10.2933C114.014 10.6151 113.794 11.0251 113.706 11.4715C113.617 11.9179 113.662 12.3805 113.837 12.801C114.011 13.2214 114.306 13.5808 114.684 13.8336C115.062 14.0865 115.507 14.2214 115.962 14.2214H118.263V11.9204ZM115.962 10.6614C116.296 10.6614 116.617 10.794 116.853 11.0301C117.089 11.2662 117.221 11.5865 117.221 11.9204V13.1795H115.962C115.628 13.1795 115.308 13.0468 115.072 12.8107C114.836 12.5746 114.703 12.2543 114.703 11.9204C114.703 11.5865 114.836 11.2662 115.072 11.0301C115.308 10.794 115.628 10.6614 115.962 10.6614Z" fill="#1A1A1A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M106.758 9.7063C106.303 9.7063 105.858 9.84125 105.48 10.0941C105.102 10.3469 104.807 10.7063 104.632 11.1268C104.458 11.5472 104.413 12.0099 104.502 12.4562C104.59 12.9026 104.809 13.3126 105.131 13.6344C105.453 13.9562 105.863 14.1753 106.309 14.2641C106.756 14.3529 107.219 14.3073 107.639 14.1332C108.059 13.959 108.419 13.6641 108.672 13.2857C108.924 12.9073 109.059 12.4624 109.059 12.0073V9.7063H106.758ZM105.499 12.0073C105.499 11.6734 105.632 11.3531 105.868 11.117C106.104 10.8809 106.424 10.7483 106.758 10.7483H108.017V12.0073C108.017 12.3412 107.885 12.6615 107.649 12.8976C107.413 13.1337 107.092 13.2664 106.758 13.2664C106.424 13.2664 106.104 13.1337 105.868 12.8976C105.632 12.6615 105.499 12.3412 105.499 12.0073Z" fill="#1A1A1A" />
        <path d="M109.059 14.2214H108.017V15.2634H105.76V16.3054H109.059V14.2214Z" fill="#1A1A1A" />
        <path d="M11.1144 11.4429H22.2287C25.1764 11.4429 28.0034 12.6138 30.0877 14.6982C32.172 16.7825 33.343 19.6095 33.343 22.5572C33.343 25.5049 32.172 28.3319 30.0877 30.4162C28.0034 32.5006 25.1764 33.6716 22.2287 33.6716H11.1144V11.4429Z" fill="url(#paint0_linear_90:4469)" />
        <path d="M22.2286 11.443C22.2286 13.6412 21.5768 15.79 20.3555 17.6177C19.1343 19.4455 17.3984 20.87 15.3676 21.7113C13.3367 22.5525 11.102 22.7726 8.94598 22.3437C6.79001 21.9149 4.80963 20.8563 3.25526 19.302C1.70089 17.7476 0.642352 15.7672 0.213503 13.6112C-0.215346 11.4553 0.0047546 9.22056 0.845972 7.18968C1.68719 5.1588 3.11174 3.42298 4.93949 2.20172C6.76723 0.980458 8.91607 0.328613 11.1143 0.328613C14.062 0.328613 16.889 1.49958 18.9733 3.58393C21.0576 5.66827 22.2286 8.49524 22.2286 11.443Z" fill="url(#paint1_linear_90:4469)" />
        <path d="M11.1144 11.4429H22.2287V22.5572H11.1144V11.4429Z" fill="url(#paint2_linear_90:4469)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_90:4469" x1="22.2287" y1="33.6716" x2="22.2287" y2="11.4429" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0064FF" />
          <stop offset="0.1" stopColor="#0085FF" />
          <stop offset="0.22" stopColor="#00AAFF" />
          <stop offset="0.35" stopColor="#00C9FF" />
          <stop offset="0.49" stopColor="#00E1FF" />
          <stop offset="0.64" stopColor="#00F2FF" />
          <stop offset="0.8" stopColor="#00FCFF" />
          <stop offset="1" stopColor="#00FFFF" />
        </linearGradient>
        <linearGradient id="paint1_linear_90:4469" x1="11.1143" y1="22.5573" x2="11.1143" y2="0.328613" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF914B" />
          <stop offset="0.21" stopColor="#FF8E4A" />
          <stop offset="0.37" stopColor="#FF8449" />
          <stop offset="0.52" stopColor="#FF7346" />
          <stop offset="0.66" stopColor="#FF5B42" />
          <stop offset="0.8" stopColor="#FF3C3C" />
          <stop offset="0.93" stopColor="#FF1736" />
          <stop offset="1" stopColor="#FF0032" />
        </linearGradient>
        <linearGradient id="paint2_linear_90:4469" x1="11.1144" y1="22.5572" x2="22.2287" y2="11.4429" gradientUnits="userSpaceOnUse">
          <stop stopColor="#585858" />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_90:4469">
          <rect width="120" height="33.343" fill="white" transform="translate(0 0.328613)" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default Logo;
