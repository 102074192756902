import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import appStore from 'features/app/mobxStore';
import styles from './styles.module.scss';

class GlobalLoading extends React.Component {
  render() {
    const { isLoading } = appStore.state;

    if (!isLoading) {
      return null;
    }

    return (
      <div className={cn(styles.globalLoading, 'sk-fading-circle')}>
        <div className="sk-circle1 sk-circle" />
        <div className="sk-circle2 sk-circle" />
        <div className="sk-circle3 sk-circle" />
        <div className="sk-circle4 sk-circle" />
        <div className="sk-circle5 sk-circle" />
        <div className="sk-circle6 sk-circle" />
        <div className="sk-circle7 sk-circle" />
        <div className="sk-circle8 sk-circle" />
        <div className="sk-circle9 sk-circle" />
        <div className="sk-circle10 sk-circle" />
        <div className="sk-circle11 sk-circle" />
        <div className="sk-circle12 sk-circle" />
      </div>
    );
  }
}

export default observer(GlobalLoading);
