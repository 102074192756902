import React from 'react';
import {
  get,
} from 'lodash';
import { DocumentNode } from 'graphql';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import field from 'lib/field';
import filterChangedValues from 'lib/filterChangedValues';
import {
  colorValidator,
  imageFileValidator,
  stringValidator,
  weightValidator,
} from 'lib/validators';
import { crudMutate } from 'features/common/helpers';
import * as Yup from 'yup';
import { makeCategoryUrl } from '../url';
import BaseCategoryForm from './BaseCategoryForm';

import {
  ICategory,
  ICategoryFormValues,
} from '../../types';

interface ICategoryFormProps extends WithTranslation {
  category?: ICategory;
  mutation: DocumentNode;
}

class CategoryForm extends React.Component<ICategoryFormProps> {
  getValidationScheme() {
    const { t } = this.props;
    const required = true;
    const validators: any = {
      name: stringValidator({ required, field: t('name') }),
      weight: weightValidator({ required, field: t('weight') }),
      color: colorValidator({ field: t('color') }),
      imageBottom: imageFileValidator({ field: t('imageBottom') }),
      imageTop: imageFileValidator({ field: t('imageTop') }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: ICategoryFormValues) {
    const formData = this.buildFormData();
    if (formData) {
      return filterChangedValues(formData, values);
    }
    return values;
  }

  onSubmit = (
    formData: ICategoryFormValues,
    formActions: FormikActions<ICategoryFormValues>,
  ) => {
    const { mutation } = this.props;
    const id = get(this.props, 'category.id', undefined);
    const variables = (
      id
        ? { id, category: this.getChangedValues(formData) }
        : { category: { ...formData } }
    );
    crudMutate({
      id,
      formActions,
      variables,
      mutation,
      redirect: makeCategoryUrl(),
      check: !!Object.keys(variables.category).length,
    });
  };

  buildFormData(): ICategoryFormValues {
    const { category } = this.props;
    return {
      name: field(category, 'name', ''),
      color: field(category, 'color', ''),
      parentCategory: field(category, 'parentCategory', ''),
      childrenCategories: category ? category.childrenCategories.map(child => child.id) : [],
      weight: field(category, 'weight', 0),
      is_default: field(category, 'is_default', false),
      offers: category ? category.offers.map(o => o.id) : [],
      imageTop: field(category, 'imageTop.path', null),
      imageBottom: field(category, 'imageBottom.path', null),
    };
  }

  renderForm = (props: FormikProps<ICategoryFormValues>) => {
    const { category } = this.props;
    return (
      <BaseCategoryForm
        {...props}
        category={category}
      />
    );
  };

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getValidationScheme()}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('categories')(CategoryForm);
