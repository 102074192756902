import React, { Component } from 'react';
import qs from 'qs';
import {
  get,
  omit,
  pick,
} from 'lodash';
import { Link } from 'react-router-dom';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  Filter,
  SortingRule,
} from 'react-table';
import { IMakeUrl } from 'lib/crudUrlBuilder';
import { filterInputHandler } from 'features/common';
import DataTable from 'features/ui/DataTable';
import { toast } from 'features/ui/Toast';
import { Confirm } from 'features/ui/Modal';
import { client } from 'features/graphql';
import { graphqlDynamic } from 'features/graphql/helpers';
import browserHistory from 'features/app/history';
import {
  Column,
  ICursor,
} from 'features/types';
import {
  UI_DATA_TABLE_PAGE,
  UI_DATA_TABLE_PER_PAGE,
} from 'features/ui/DataTable/consts';

enum Direction {
  asc = 'asc',
  desc = 'desc',
}

export interface IOrderBy {
  field: string;
  direction: Direction;
}

export interface IControlFields {
  [key: string]: string;
}

export interface IIndexTableBaseProps extends WithTranslation {
  columns: Column[];
  filterFields?: string[];
  sortFields?: string[];
  prepareRequestData?: (variables: any, data: IControlFields) => void;
  query: any;
  queryResultName: string;
  deleteMutation?: any;
  makeCrudUrl?: any;
  crudUrlIdHandler?: (values: IMakeUrl) => string;
  showAutoEditIconColumn?: boolean;
  showAutoIdColumn?: boolean;
  showViewIconColumn?: boolean;
  isFilterableById?: boolean;
  bindRequestData?: (cb: () => void) => void;
  isEditable?: boolean;
  data?: any;
}

export interface IIndexTableBaseState {
  controlFields: IControlFields;
  orderBy?: IOrderBy;
  initialSorting: SortingRule[];
  filtered?: Filter[];
  isLoading: boolean;
  items: any[];
  pagination: ICursor;
  idForDelete: ID | null;
}

class IndexTableBase extends Component<IIndexTableBaseProps, IIndexTableBaseState> {
  static defaultProps = {
    showAutoEditIconColumn: true,
    showAutoIdColumn: true,
    showViewIconColumn: false,
    isFilterableById: true,
    isEditable: true,
  };

  private _isUnmounted: boolean;
  private _controlFieldsList: string[];
  private _columns: object[];

  constructor(props: IIndexTableBaseProps) {
    super(props);
    const searchData = this.getSearchData();
    const orderBy = this.getOrderFromString(searchData._orderby);
    this._isUnmounted = false;
    this._columns = this.resolveColumns(props);
    this._controlFieldsList = ['page', 'perPage'].concat(props.filterFields || []);
    this.state = {
      orderBy,
      controlFields: {
        page: UI_DATA_TABLE_PAGE,
        perPage: UI_DATA_TABLE_PER_PAGE,
        ...pick(searchData, this._controlFieldsList),
      },
      initialSorting: orderBy ? [{
        id: orderBy.field,
        sort: orderBy.direction,
        asc: orderBy.direction === Direction.asc ? true : undefined,
        desc: orderBy.direction === Direction.desc ? true : undefined,
      }] : [],
      pagination: {
        currentPage: UI_DATA_TABLE_PAGE,
        total: 0,
      },
      items: [],
      isLoading: true,
      idForDelete: null,
    };
    if (props.bindRequestData) {
      props.bindRequestData(this.requestData.bind(this));
    }
  }

  shouldComponentUpdate(nextProps: IIndexTableBaseProps, nextState: IIndexTableBaseState) {
    return (
      this.props.data !== nextProps.data ||
      (this.props.data && this.props.data[this.props.queryResultName] !== nextProps.data[nextProps.queryResultName]) ||
      this.state.items !== nextState.items ||
      this.state.isLoading !== nextState.isLoading ||
      this.state.pagination.currentPage !== nextState.pagination.currentPage ||
      this.state.idForDelete !== nextState.idForDelete ||
      this._controlFieldsList.some((key) => {
        return this.state.controlFields[key] !== nextState.controlFields[key];
      })
    );
  }

  componentDidUpdate(prevProps: IIndexTableBaseProps) {
    if (prevProps.data && this.props.data[this.props.queryResultName] !== prevProps.data[prevProps.queryResultName]) {
      this.requestData();
    }
  }

  componentDidMount() {
    this.requestData();
  }

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  shouldSort(field: string): boolean {
    const { sortFields } = this.props;
    if (sortFields && sortFields.length > 0) {
      return sortFields.includes(field);
    }
    return false;
  }

  getOrderFromString(urlParam?: string): IOrderBy | undefined {
    if (!urlParam) {
      return undefined;
    }
    const params = urlParam.split('-');
    if (params.length === 2) {
      const [field, direction] = params;
      if (this.shouldSort(field) && Object.keys(Direction).includes(direction)) {
        return {
          field,
          direction: direction as Direction,
        };
      }
    }
    return undefined;
  }

  renderCellId = ({ value }: { value: string | number }) => {
    const {
      t,
      makeCrudUrl,
      crudUrlIdHandler,
    } = this.props;
    const urlIdHandler = crudUrlIdHandler ? crudUrlIdHandler : makeCrudUrl;
    return (
      <Link
        to={urlIdHandler({ action: 'edit', id: value })}
        title={t('dict:edit')}
      >
        {value}
      </Link>
    );
  }

  resolveColumns = (props: IIndexTableBaseProps) => {
    const {
      t,
      makeCrudUrl,
      showAutoEditIconColumn,
      showAutoIdColumn,
      showViewIconColumn,
      isFilterableById,
      sortFields,
      isEditable,
    } = this.props;
    const columns = props.columns.map(
      (i) => {
        let sortable = false;
        if (sortFields) {
          if (typeof i.accessor === 'string' && sortFields.includes(i.accessor)) {
            sortable = true;
          }
          if (typeof i.id === 'string' && sortFields.includes(i.id)) {
            sortable = true;
          }
        }
        return {
          ...i,
          sortable,
        };
      },
    );
    return (!showAutoIdColumn ? [] : [
      {
        accessor: 'id',
        Header: 'ID',
        maxWidth: 90,
        className: 'text-right',
        sortable: sortFields ? sortFields.includes('id') : false,
        Cell: isEditable ? this.renderCellId : null,
        filterable: isFilterableById,
        Filter: filterInputHandler,
      },
    ] as Column[])
      .concat(columns)
      .concat(!showViewIconColumn ? [] :
        {
          id: 'showColumn',
          Header: '',
          maxWidth: 60,
          className: 'text-center',
          accessor: (data: any) => data,
          Cell: (row: any) => (
            <Link
              className="btn btn-link"
              title={t('dict:view')}
              to={makeCrudUrl({ action: 'view', id: row.value.id })}
            >
              <i className="fa fa-eye fa-lg" />
            </Link>
          ),
          Filter: () => <div />,
        },
      )
      .concat(!showAutoEditIconColumn ? [] :
        {
          id: 'editColumn',
          Header: '',
          maxWidth: 60,
          className: 'text-center',
          accessor: (data: any) => data,
          sortable: false,
          Cell: (row: any) => (
            <Link
              className="btn btn-link"
              title={t('dict:edit')}
              to={makeCrudUrl({ action: 'edit', id: row.value.id })}
            >
              <i className="icon-pencil icons font-lg" />
            </Link>
          ),
          Filter: () => <div />,
        },
      )
      .concat(props.deleteMutation ? {
        id: 'deleteColumn',
        Header: '',
        maxWidth: 50,
        className: 'text-center',
        accessor: (data: any) => data,
        sortable: false,
        Cell: (row: any) => (
          <button
            className="btn btn-link"
            onClick={this.onClickDeleteButton}
            data-id={row.value.id}
            title={t('dict:delete')}
          >
            <i className="icon-trash icons font-lg" />
          </button>
        ),
        Filter: () => <div />,
      } : []);
  };

  update = () => {
    this.updateUrl();
    this.requestData();
  }

  updateUrl() {
    const { location: l } = browserHistory;
    const {
      controlFields,
      orderBy,
    } = this.state;
    const search = this.getSearchData();
    const unitedSearch: { [key: string]: string } = {
      ...omit(search, this._controlFieldsList),
      ...controlFields,
    };
    const filteredSearch: { [key: string]: string } = Object
      .keys(unitedSearch)
      .reduce(
        (acc: { [key: string]: string }, key: string) => {
          if (
            (key === 'page' && unitedSearch[key] === String(UI_DATA_TABLE_PAGE)) ||
            (key === 'perPage' && unitedSearch[key] === String(UI_DATA_TABLE_PER_PAGE)) ||
            (key === '_orderby')
          ) {
            return acc;
          }
          if (unitedSearch[key]) {
            acc[key] = unitedSearch[key];
          }
          return acc;
        },
        {},
      );
    if (orderBy) {
      filteredSearch._orderby = `${orderBy.field}-${orderBy.direction}`;
    }
    let newSearch = qs.stringify(filteredSearch);
    newSearch = newSearch ? `?${newSearch}` : '';
    newSearch = newSearch.replace(/[a-z]+=(&|$)/, '');
    browserHistory.replace(`${l.pathname}${newSearch}${l.hash}`);
  }

  getSearchData() {
    const { location } = browserHistory;
    return qs.parse(location.search.replace(/^\?/, ''));
  }

  requestData() {
    const {
      prepareRequestData,
      query,
    } = this.props;
    const {
      controlFields,
      orderBy,
    } = this.state;
    const variables: any = {
      page: parseInt(controlFields.page, 10) || UI_DATA_TABLE_PAGE,
      perPage: parseInt(controlFields.perPage, 10) || UI_DATA_TABLE_PER_PAGE,
    };
    if (orderBy) {
      variables.orderBy = { ...orderBy };
    }
    if (prepareRequestData) {
      prepareRequestData(variables, this.state.controlFields);
    }
    this.setState(
      { isLoading: true },
      () => {
        client.query({
          variables,
          query,
          context: {
            isGlobalLoading: true,
          },
          fetchPolicy: 'network-only',
        }).then((data) => {
          if (this._isUnmounted) {
            return;
          }
          const { queryResultName } = this.props;
          this.setState({
            items: get(data, `data.${queryResultName}.items`, []),
            pagination: get(data, `data.${queryResultName}.cursor`),
            isLoading: false,
          });
        }).catch(() => {
          if (this._isUnmounted) {
            return;
          }
          this.setState({
            items: [],
            pagination: {
              currentPage: 1,
              total: 0,
            },
            isLoading: false,
          });
        });
      },
    );
  }

  onDelete = () => {
    const { t, deleteMutation } = this.props;
    const { idForDelete } = this.state;
    if (idForDelete === null) {
      return;
    }
    client.mutate({
      mutation: deleteMutation,
      variables: {
        id: idForDelete,
      },
    })
      .then(() => {
        toast.info(t('dict:deleted'));
        if (this._isUnmounted) {
          return;
        }
        this.requestData();
      })
      .catch(() => {
        toast.error(t('dict:error'));
      });
  };

  onClickDeleteButton = (e: React.SyntheticEvent) => {
    const id = e.currentTarget.getAttribute('data-id');
    if (id) {
      this.setState({ idForDelete: id });
    }
  }

  onFilteredChange = (filtered: Filter[]) => {
    const { filterFields } = this.props;
    const { controlFields } = this.state;
    if (!filterFields) {
      return;
    }
    const filterData = filtered.reduce(
      (accum: any, data: Filter) => {
        accum[data.id] = data.value || '';
        return accum;
      },
      {},
    );
    const shouldUpdate = filterFields.some((field: string) => {
      return filterData[field] !== controlFields[field];
    });
    if (shouldUpdate) {
      this.setState(
        {
          controlFields: {
            page: String(UI_DATA_TABLE_PAGE),
            perPage: String(UI_DATA_TABLE_PER_PAGE),
            ...filterData,
          },
        },
        this.update,
      );
    }
  };

  onPageChange = (page: number) => {
    const { controlFields } = this.state;
    const newControlFields = {
      ...controlFields,
      page: String(page + 1),
    };
    this.setState({ controlFields: newControlFields }, this.update);
  };

  onSortedChange = (sortData: Array<{ id: string, desc?: boolean }>) => {
    if (sortData.length) {
      const { id: field, desc } = sortData[0];
      if (this.shouldSort(field)) {
        this.setState(
          {
            orderBy: {
              field,
              direction: desc ? Direction.desc : Direction.asc,
            },
          },
          this.update,
        );
      }
    } else {
      this.setState({ orderBy: undefined }, this.update);
    }
  }

  onDeleteModalToggle = () => {
    this.setState({ idForDelete: null });
  }

  getFilteredData(): Filter[] {
    const { filterFields } = this.props;
    const { controlFields } = this.state;
    if (!filterFields) {
      return [];
    }
    const filters: any = pick(controlFields, filterFields);
    return Object.keys(filters).reduce(
      (acc: Filter[], key: string) => {
        acc.push({
          id: key,
          value: filters[key],
        });
        return acc;
      },
      [],
    );
  }

  render() {
    const { t } = this.props;
    const {
      isLoading,
      initialSorting,
      items,
      pagination,
      controlFields,
      idForDelete,
    } = this.state;
    const { page } = controlFields;
    const paginationData = {
      currentPage: parseInt(page || '1', 10),
      total: pagination.total,
    };
    const isDeleteModalOpened = idForDelete !== null;
    return (
      <React.Fragment>
        <DataTable
          columns={this._columns}
          items={items}
          loading={isLoading}
          pageSize={UI_DATA_TABLE_PER_PAGE}
          pagination={paginationData}
          filtered={this.getFilteredData()}
          defaultSorted={initialSorting}
          onPageChange={this.onPageChange}
          onFilteredChange={this.onFilteredChange}
          onSortedChange={this.onSortedChange}
        />
        <Confirm
          title={t('forms:delete_record')}
          description={t('forms:delete_confirmation_question', { id: idForDelete })}
          type="danger"
          isOpened={isDeleteModalOpened}
          onClose={this.onDeleteModalToggle}
          onResolve={this.onDelete}
        />
      </React.Fragment>
    );
  }
}

export default graphqlDynamic()(withTranslation()(IndexTableBase));
