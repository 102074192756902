import React from 'react';
import * as Yup from 'yup';
import { FormikProps } from 'formik';
import { minValidator } from 'lib/validators';
import ItemForm from 'features/items/lib/Form';
import getVideoQuery from 'features/courses/queries/getVideoQuery.gql';
import { IFormValues } from 'features/courses/types';
import { IItem } from 'features/types';
import BaseForm from './Form';
import { withTranslation } from 'react-i18next';

interface IFormProps {
  data?: IItem;
}

class Form extends React.PureComponent<IFormProps> {
  resolveMutationData = (values: any) => values;

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: any) => {
    const {
      t,
      data,
    } = this.props;
    return {
      ...shape,
      childStages: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().min(1, t('forms:required_field', { field: 'Название раздела' })),
          childItems: Yup.array().min(1, t('forms:empty_field', { field: 'Содержание раздела' })),
        }),
      ),
      course: Yup.object().shape({
        duration: minValidator({
          field: 'Длительность',
          min: 0,
          required: true,
        }),
      }),
    };
  }


  resolveInitialValues = (values: any) => {
    const { data } = this.props;

    const defaultValues = {
      childStages: [],
    };

    return data ? {
      ...defaultValues,
      ...values,
      course: {
        duration: data.course ? data.course.duration : 0,
      },
      childStages: data.childStages ? data.childStages.sort((a, b) => a.sort - b.sort).map(st => ({
        ...st,
        childItems: st.childItems.length ? st.childItems.sort((a, b) => a.sort - b.sort) : [],
      })) : [],
    } : {
      ...defaultValues,
      ...values,
    };
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="course"
        data={data}
        getItemQuery={getVideoQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        renderForm={this.renderForm}
      />
    );
  }
}

export default withTranslation()(Form);
