import React from 'react';
import {
  cloneDeep,
  get,
  intersection,
  pick,
} from 'lodash';
import {
  Formik,
  FormikActions,
} from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  TYPE_AUTHOR,
  TYPE_NARRATOR,
  TYPE_PROVIDER,
  TYPE_PUBLISHER,
} from 'features/creators/consts';
import { crudMutate } from 'features/common/helpers';
import { getCoverUrlForWidth } from 'features/items/lib/cover';
import field from 'lib/field';
import {
  imageFileValidator,
  intValidator,
} from 'lib/validators';
import filterChangedValues from 'lib/filterChangedValues';
import { TYPE_COLLECTION } from '../../consts';

import mutationUpdateItem from 'features/items/queries/updateItemMutation.gql';
import mutationCreateItem from 'features/items/queries/createItemMutation.gql';
import linkRelatedItemsMutation from 'features/items/queries/linkRelatedItemsMutation.gql';

import {
  IFormValues,
  IItemMutationInput,
} from 'features/items/types';
import {
  IItem,
  IYupValidationFields,
} from 'features/types';
import { ANSWER_TYPE_MAPPING } from 'features/tests/consts';

interface IFormProps extends WithTranslation {
  type: string;
  data?: IItem;
  getItemQuery?: any;
  resolveInitialValues?: (values: IFormValues) => IFormValues;
  resolveValidationSchema?: (schema: IYupValidationFields) => IYupValidationFields;
  resolveMutationData?: (data: IItemMutationInput) => IItemMutationInput;
  resolveChangedValues?: (data: IFormValues) => IFormValues;
  renderForm?: (props: any) => JSX.Element;
}

class Form extends React.PureComponent<IFormProps> {
  validationSchema = () => {
    const {
      t,
      data,
      resolveValidationSchema,
    } = this.props;
    const isEdit = data && data.id;
    const required = true;
    const messageRequired = (f: string) => t('forms:required_field', { field: t(f) });
    const shape = Object.assign(
      {
        name: Yup.string().required(messageRequired('name')),
        language: Yup.number().min(1, messageRequired('language')),
        position_latest: intValidator({ required, field: t('items:position_latest') }),
        position_bestsellers: intValidator({ required, field: t('items:position_bestsellers') }),
        publisher: Yup.number(),
        image: imageFileValidator({ field: t('image') }),
      },
      isEdit ? {} : {
        image: imageFileValidator({ required, field: t('image') }),
      },
    );

    return Yup.object().shape(resolveValidationSchema ? resolveValidationSchema(shape) : shape);
  };

  getChangedValues(values: IFormValues) {
    const {
      type,
      resolveChangedValues,
    } = this.props;
    const formData = this.resolveInitialValues();
    let data = filterChangedValues(formData, values);
    [type, 'prices'].forEach((name) => {
      if (JSON.stringify(values[name]) === JSON.stringify(formData[name])) {
        delete data[name];
      }
    });
    if (resolveChangedValues) {
      data = resolveChangedValues(data);
    }
    return data;
  }

  onSubmit = (values: IFormValues, formActions: FormikActions<IFormValues>) => {
    const {
      resolveMutationData,
      type,
      data: initialData,
      getItemQuery,
    } = this.props;
    const id = initialData ? initialData!.id : 0;
    const changedData = id ? this.getChangedValues(cloneDeep(values)) : cloneDeep(values);
    const changedFieldNames = Object.keys(changedData);
    if (changedFieldNames.length) {
      const sendingData: IItemMutationInput = pick(changedData, [
        type,
        'bisac',
        'is_hidden',
        'categories',
        'childrenItems',
        'content',
        'copyright_expire',
        'description',
        'external_id',
        'external_link',
        'image',
        'image_wide',
        'is_active',
        'is_offer_specific',
        'language',
        'name',
        'offers',
        'relatedSecondaryItems',
        'recommendation_description',
        'recommendedSecondaryItems',
        'original_name',
        'position_bestsellers',
        'position_latest',
        'prices',
        'tags',
        'year',
        'age_limit',
        'chat',
        'childStages',
        'subscriptions',
        'is_public',
      ]);
      if (type === 'test' && changedFieldNames.includes('content') && sendingData.content[0].test) {
        sendingData.test = {
          ...sendingData.test,
          questions_count: sendingData.content[0].test.questions.length || 0,
        };
        sendingData.content[0] = {
          ...sendingData.content[0],
          test: {
            id: sendingData.content[0].test.id || '',
            questions: sendingData.content[0].test.questions.map((question, qIndex) => {
              const parsedOptions = question.answer.options.map((opt, optIndex) => {
                if (question.answer.type === ANSWER_TYPE_MAPPING) {
                  return {
                    ...opt,
                    id: opt.id.includes('temp') ? undefined : opt.id,
                  };
                }
                return {
                  ...opt,
                  id: opt.id.includes('temp') ? undefined : opt.id,
                  sort: optIndex,
                };
              });
              return {
                ...question,
                id: question.id.includes('temp') ? undefined : question.id,
                sort: qIndex,
                answer: {
                  ...question.answer,
                  points: question.answer.points !== 0 ? question.answer.points : 1,
                  id: question.answer.id.includes('temp') ? undefined : question.answer.id,
                  options: parsedOptions,
                },
              };
            }),
          },
        };
      }
      if (type === 'article' && changedFieldNames.includes('content')) {
        sendingData.content[0] = {
          ...sendingData.content[0],
          sort: 0,
          type: 'article',
        };
      }
      if (intersection(['publisher', 'provider', 'authors', 'narrators'], changedFieldNames).length) {
        sendingData.creators = values.authors
          .concat(values.provider ? [values.provider] : [])
          .concat(values.narrators ? values.narrators : [])
          .concat(values.publisher ? [values.publisher] : []);
      }
      if (changedFieldNames.includes('stags')) {
        sendingData.tags = changedData.stags;
      }
      if (changedFieldNames.includes('childStages')) {
        sendingData.childStages = sendingData.childStages.map((item, index) => ({
          ...item,
          sort: index,
          id: item.id.includes('temp') ? undefined : item.id,
          childItems: item.childItems.map((itm, indx) => ({ id: itm.id, sort: indx })),
        }));
      }
      if (changedFieldNames.includes('badge')) {
        if (changedData.badge > 0) {
          sendingData.badge = changedData.badge;
        } else {
          sendingData.badge = null;
        }
      }
      if (changedFieldNames.includes('chat')) {
        const chat = {};
        sendingData.chat.moderators ? chat.moderators = sendingData.chat.moderators : null;
        sendingData.chat.blockedUsers ? chat.blockedUsers = sendingData.chat.blockedUsers : null;
        sendingData.chat.speakers ? chat.speakers = sendingData.chat.speakers : null;
        sendingData.chat = chat;
      }
      const item = typeof resolveMutationData === 'function' ? resolveMutationData!(sendingData) : sendingData;
      const oldRelatedItems = this.resolveInitialValues().relatedSecondaryItems;
      const deletedItems = oldRelatedItems.filter(rId => !values.relatedSecondaryItems.includes(rId));
      crudMutate({
        id,
        formActions,
        mutation: id ? mutationUpdateItem : mutationCreateItem,
        variables: id ? { id, item } : { item: { ...item, type } },
        redirect: `/${type}s`,
        updateRefetchQuery: getItemQuery,
        check: !!Object.keys(item).length,
      }).then((data) => {
        const dataItemId = get(data, 'data.ItemMutation.id', 0);
        Promise.all([...values.relatedSecondaryItems.map((itemId) => {
          crudMutate({
            formActions,
            id: dataItemId,
            mutation: linkRelatedItemsMutation,
            variables: {
              id: itemId,
              item: {
                relatedSecondaryItems: [...values.relatedSecondaryItems.filter(currId => currId !== itemId), dataItemId],
              },
            },
          });
        }),
          ...deletedItems.map((itemId) => {
            crudMutate({
              formActions,
              id: dataItemId,
              mutation: linkRelatedItemsMutation,
              variables: {
                id: itemId,
                item: {
                  relatedSecondaryItems: [],
                },
              },
            });
          })],
        );
      });
    } else {
      formActions.setSubmitting(false);
    }
  };

  resolveInitialValues = (): IFormValues => {
    const {
      data,
      type,
      resolveInitialValues,
    } = this.props;
    const publishers = data && data.creators ? data.creators.filter(creator => creator.type === TYPE_PUBLISHER) : [];
    const providers = data && data.creators ? data.creators.filter(creator => creator.type === TYPE_PROVIDER) : [];
    const authors = data && data.creators ? data.creators.filter(creator => creator.type === TYPE_AUTHOR) : [];
    const narrators = data && data.creators ? data.creators.filter(creator => creator.type === TYPE_NARRATOR) : [];
    const prices = data && data.prices ? data.prices.map(price => ({
      ...price,
      tier: price.tier ? +price.tier.id : 0,
      in_app_id: price.in_app_id ? price.in_app_id : '',
      bundle_id: price.bundle_id ? price.bundle_id : '',
    })) : [];
    const initialValues: IFormValues = {
      prices,
      name: field(data, 'name', ''),
      is_hidden: field(data, 'is_hidden', false),
      original_name: field(data, 'original_name', ''),
      description: field(data, 'description', ''),
      position_latest: field(data, 'position_latest', 0),
      position_bestsellers: field(data, 'position_bestsellers', 0),
      is_active: field(data, 'is_active', false),
      is_offer_specific: field(data, 'is_offer_specific', false),
      external_id: field(data, 'external_id', '0'),
      external_link: field(data, 'external_link', ''),
      copyright_expire: field(data, 'copyright_expire', ''),
      year: field(data, 'year', 0),
      age_limit: field(data, 'age_limit', 0),
      bisac: field(data, 'bisac', ''),
      badge: field(data, 'badge.id', 0),
      image: data ? getCoverUrlForWidth(data, 200) : null,
      images: field(data, 'images', []),
      language: data && data.language ? +data.language.id : 1,
      authors: authors.length ? authors.map(author => +author.id) : [],
      narrators: narrators.length ? narrators.map(author => +author.id) : [],
      categories: data && data.categories ? data.categories.map(category => +category.id) : [],
      subscriptions: data && data.subscriptions ? data.subscriptions.map(subscription => +subscription.id) : [],
      publisher: publishers.length ? +publishers[0].id : 0,
      provider: providers.length ? +providers[0].id : 0,
      offers: data && data.offers ? data.offers.map(offer => +offer.id) : [],
      relatedSecondaryItems: data && data.relatedSecondaryItems ? data.relatedSecondaryItems.map(item => +item.id) : [],
      recommendation_description: field(data, 'recommendation_description', ''),
      recommendedSecondaryItems: data && data.recommendedSecondaryItems ? data.recommendedSecondaryItems.map(item => +item.id) : [],
      tags: data && data.tags ? data.tags.map(tag => tag.id.toString()) : [],
      stags: data && data.tags ? data.tags.map(tag => tag.name) : [],
      is_public: field(data, 'is_public', false),
    };
    if (type === TYPE_COLLECTION) {
      initialValues.image_wide = data ? getCoverUrlForWidth(data, 1846) : null;
    }
    return resolveInitialValues ? resolveInitialValues(initialValues) : initialValues;
  };

  render() {
    const { renderForm } = this.props;
    return (
      <Formik
        validationSchema={this.validationSchema()}
        initialValues={this.resolveInitialValues()}
        onSubmit={this.onSubmit}
        render={renderForm}
        enableReinitialize
        validateOnChange={false}
      />
    );
  }
}

export default withTranslation('items')(Form);
