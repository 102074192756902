import React from 'react';
import { Label } from 'reactstrap';
import style from '../style.module.scss';

export default ({
  id,
  name,
  children,
}: {
  id: string,
  name: string,
  children: React.ReactNode | React.ReactNode[],
}) => {
  return (
    <div className={style.propertyContainer}>
      <Label className={style.propertyChildren} for={id}>
        {name}
      </Label>
      <span className={style.propertyChildren}>
        {children}
      </span>
    </div>
  );
};
