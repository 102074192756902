import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import CardPage from 'features/ui/Page/CardPage';
import { ISubscription } from '../types';
import SubscriptionForm from '../lib/SubscriptionForm';
import { makeSubscriptionUrl } from '../lib/url';
import { SUBSCRIPTION_ICON } from '../consts';

interface IPageCreateProps extends WithTranslation {
  feature: ISubscription;
}

class PageCreate extends React.Component<IPageCreateProps> {
  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={SUBSCRIPTION_ICON}
        title={t('new_subscription')}
        crumbs={[{ link: makeSubscriptionUrl(), name: t('subscriptions') }]}
      >
        <SubscriptionForm isCreateForm={true} />
      </CardPage>
    );
  }
}

export default withTranslation('subscriptions')(PageCreate);
