import React from 'react';
import cn from 'classnames';
import {
  Modal as ReactstrapModal,
  ModalHeader,
} from 'reactstrap';

export interface IModalProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
  className?: string;
  type?: 'info' | 'danger' | 'warning' | 'success' | 'primary';
  titleIcon?: string;
  isOpened?: boolean;
  backdrop?: 'static' | boolean;
}

export class Modal extends React.PureComponent<IModalProps> {
  render() {
    const {
      type,
      title,
      titleIcon,
      onClose,
      isOpened,
      children,
      className,
      backdrop,
    } = this.props;
    const icon = titleIcon ? (
      <React.Fragment>
        <i className={titleIcon} />
        &nbsp;&nbsp;
      </React.Fragment>
    ) : null;
    return (
      <ReactstrapModal
        isOpen={isOpened}
        toggle={onClose}
        className={cn(type ? `modal-${type}` : null, className)}
        backdrop={backdrop}
      >
        <ModalHeader toggle={onClose}>
          {icon}
          {title}
        </ModalHeader>
        {children}
      </ReactstrapModal>
    );
  }
}
