import React from 'react';
import { DocumentNode } from 'graphql';
import { compose } from 'react-apollo';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import { graphqlById } from 'features/graphql/helpers';
import CardPage from 'features/ui/Page/CardPage';
import Waiter from 'features/ui/Waiter';

interface IEntity { id: ID; }

export interface IViewProps<TEntity> {
  isLoading: boolean;
  data: TEntity | null;
  crumbs?: Array<{ link: string, name: string }>;
  icon?: string;
  title?: string;
  titleField?: keyof TEntity;
  children?: (entity: TEntity) => React.ReactNode | React.ReactNode[];
  id?: string;
}

class View<TEntity> extends React.Component<IViewProps<TEntity>> {
  renderChildren() {
    const {
      data,
      children,
    } = this.props;
    if (data && children) {
      return children(data);
    }
    return null;
  }

  getTitle() {
    const {
      data,
      title,
      titleField,
    } = this.props;
    if (title) {
      return title;
    }
    if (titleField && data) {
      return String(data[titleField]);
    }
    return '';
  }

  render() {
    const {
      crumbs,
      data,
      isLoading,
      icon,
    } = this.props;
    return (
      <Waiter data={data} isLoading={isLoading}>
        <CardPage
          icon={icon ? icon : ''}
          title={this.getTitle()}
          crumbs={crumbs}
        >
          {this.renderChildren()}
        </CardPage>
      </Waiter>
    );
  }
}

type IProps<TEntity> = Omit<IViewProps<TEntity>, 'isLoading' | 'data'>;

export default <TEntity extends IEntity>(
  query: DocumentNode,
  queryType: string,
): React.ComponentType<IProps<TEntity>> => {
  return compose(
    withRouter,
    graphqlById<{}, IViewProps<TEntity> & RouteComponentProps<{ id: string }>>({
      query,
      queryType,
    }),
  )((props: IViewProps<TEntity>) => <View<TEntity> {...props} />);
};
