import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import ImageUploader from 'features/ui/ImageUploader';
import FormRow from 'features/ui/FormRow';
import Switch from 'features/ui/Switch';
import BaseItemForm from 'features/items/lib/Form/Form';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import { TYPE_COLLECTION } from 'features/items/consts';

import { IFormValues } from 'features/collections/types';
import { IItem } from 'features/types';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IItem;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onChangeChildrenItems = (ids: number[]) => {
    this.props.setFieldValue('childrenItems', ids);
  };

  onChangeWideImage = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    if (e) {
      if (e.target.validity.valid) {
        this.props.setFieldValue('image_wide', e.target.files![0]);
      }
    } else {
      this.props.setFieldValue('image_wide', null);
    }
  };

  resolveFields = (fields: any) => {
    const {
      t,
      values,
      handleBlur,
      handleChange,
    } = this.props;

    fields.splice(4, 0, (
      <FormRow
        key="childrenItems"
        label={t('childrenItems')}
        id="childrenItems"
        required
      >
        <ItemsSelect
          id="childrenItems"
          name="childrenItems"
          value={values.childrenItems}
          onChange={this.onChangeChildrenItems}
        />
      </FormRow>
    ));

    fields.splice(1, 0, [(
      <FormRow
        key="collection.is_important"
        label={t('is_important')}
        id="collection.is_important"
      >
        <Switch
          id="collection.is_important"
          name="collection.is_important"
          value="1"
          checked={!!values.collection.is_important}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    )]);

    fields.splice(7, 0, [(
      <FormRow
        key="image_wide"
        label={t('items:image_wide')}
        text={t('forms:expected_size', { width: 1846, height: 320 })}
        id="image_wide"
        required
      >
        <ImageUploader
          id="image_wide"
          name="image_wide"
          value={typeof values.image_wide === 'string' ? values.image_wide : null}
          onChange={this.onChangeWideImage}
          onBlur={handleBlur}
          imageWidth={512}
        />
      </FormRow>
    )]);

    return fields;
  };

  render() {
    return (
      <BaseItemForm
        {...this.props}
        resolveFields={this.resolveFields}
        cancelLink={'/collections'}
        type={TYPE_COLLECTION}
      />
    );
  }
}

export default withTranslation('collections')(BaseForm);
