import React from 'react';
import { client, gql } from 'features/graphql';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import { get } from 'lodash';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import Switch from 'features/ui/Switch';
import DatePicker from 'features/ui/DatePicker';
import { InfoRowText } from 'features/ui/InfoRow';
import OffersSelect from 'features/offers/lib/OffersSelect';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import SubscriptionsSelect from 'features/subscriptions/lib/SubscriptionsSelect';
import { IFormValues } from 'features/promocodes/types';
import {
  IPromocode,
  Moment,
} from 'features/types';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IPromocode;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onOfferChange = (value: number) => this.props.setFieldValue('offer', value);

  onItemChange = (values: number[]) => this.props.setFieldValue('items', [...values]);

  onPaste = (values: string) => {
    const { values: { items }, setFieldValue } = this.props;
    const QUERY = gql`
      query getItemForSelect(
        $id: [ID]
        $pageNum: Int
        $perPage: Int
        $searchByFields: ItemSearchByFields
        $filterByFields: ItemFilterByFields
      ) {
        ItemQuery(
          id: $id,
          page: $pageNum,
          perPage: $perPage,
          searchByFields: $searchByFields,
          filterByFields: $filterByFields,
        ) {
          items {
            id
            name
            type
            book {
              type
            }
            video {
              type
            }
            audiobook {
              type
            }
          }
        }
      }
    `;
    Promise.all(values.split(',').map(value => client.query({
      query: QUERY,
      variables: {
        id: value.trim(),
        pageNum: 1,
        perPage: 100,
      },
    }).catch(err => global.console.log(err)))).then((res) => {
      const cleanIds = res.filter(item => item).map(item => +item.data.ItemQuery.items[0].id);
      setFieldValue('items', [...items, ...cleanIds]);
    });
  }

  onChangeDate = (date: Moment | null) => {
    this.props.setFieldValue('expire_date', date ? date.format('YYYY-MM-DD') : '');
  };

  renderUsed() {
    const {
      t,
      item,
    } = this.props;
    if (item) {
      return (
        <InfoRowText
          label={t('used')}
          value={item.used}
        />
      );
    }
    return null;
  }

  handleChange = (subs) => {
    this.props.setFieldValue('subscriptions', [...subs]);
  }

  renderTypeItem = () => {
    const {
      t,
      values,
    } = this.props;
    return (
      <FormRow label={t('items')} id="items">
        <ItemsSelect
          id="items"
          name="items"
          isMulti={true}
          value={values.items}
          isClearable
          onChange={this.onItemChange}
          onPaste={this.onPaste}
        />
      </FormRow>
    );
  }

  renderTypeSubscriptions = () => {
    const {
      values,
      t,
      handleChange,
      handleBlur,
    } = this.props;
    return (
      <>
      <FormRow id="subscriptions" label={t('subscriptions')}>
        <SubscriptionsSelect
          id="subscriptions"
          name="subscriptions"
          isMulti
          value={values.subscriptions}
          onChange={this.handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
      {
        values.subscriptions.length ?
          (
            <>
              <FormRow label={t('trial_days')} id="trial_days">
                <Input
                  type="number"
                  id="trial_days"
                  value={values.trial_days}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormRow>
              <FormRow label={t('expire_days')} id="expire_days">
                <Input
                  type="number"
                  id="expire_days"
                  value={values.expire_days}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormRow>

              <FormRow label={t('discount_type')} id="discount_type">
                <Input
                  id="discount_type"
                  name="discount_type"
                  type="select"
                  value={values.discount_type}
                  onChange={handleChange}
                >
                  <option value="discount_type_price">{t('discount_type_price')}</option>
                  <option value="discount_type_percent">{t('discount_type_percent')}</option>
                </Input>
              </FormRow>

              {values.discount_type === 'discount_type_price' ? (
                <FormRow id="discount_price" label={t('discount_price')}>
                  <Input
                    type="number"
                    id="discount_price"
                    name="discount_price"
                    value={values.discount_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormRow>
              ) : null}
              {values.discount_type === 'discount_type_percent' ? (
                <FormRow id="discount_percent" label={t('discount_percent')}>
                  <Input
                    type="number"
                    id="discount_percent"
                    name="discount_percent"
                    value={values.discount_percent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormRow>
              ) : null}
            </>
          ) : null
      }
      </>
    );
  }

  render() {
    const {
      t,
      item,
      values,
      handleBlur,
      handleChange,
    } = this.props;
    return (
      <FormFrame
        id={get(item, 'id', undefined)}
        cancelLink="/promocodes"
        {...this.props}
      >
        <FormRow key="is_active" label={t('is_active')} id="is_active">
          <Switch
            id="is_active"
            name="is_active"
            value="1"
            offColor="danger"
            checked={values.is_active}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('code')} id="code" required>
          <Input
            type="text"
            id="code"
            name="code"
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('offer')} id="offer" required>
          <OffersSelect
            id="offer"
            name="offer"
            value={[values.offer]}
            isMulti={false}
            onChange={this.onOfferChange}
          />
        </FormRow>

        <FormRow label={t('promocode_type')} id="promocode_type">
          <Input
            id="promocode_type"
            name="promocode_type"
            type="select"
            value={values.promocode_type}
            onChange={handleChange}
          >
            <option value="promocode_type_item">{t('promocode_type_item')}</option>
            <option value="promocode_type_subscriptions">{t('promocode_type_subscriptions')}</option>
          </Input>
        </FormRow>

        {values.promocode_type === 'promocode_type_item' ? this.renderTypeItem() : null}
        {values.promocode_type === 'promocode_type_subscriptions' ? this.renderTypeSubscriptions() : null}

        <FormRow label={t('count')} id="count">
          <Input
            type="number"
            id="count"
            name="count"
            value={values.count}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        {this.renderUsed()}

        <FormRow key="expire_date" label={t('expire_date')} id="expire_date">
          <DatePicker
            id="expire_date"
            value={Date.parse(values.expire_date)}
            onChange={this.onChangeDate}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('promocodes')(BaseForm);
