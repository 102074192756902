import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { AccessControl } from 'features/acl';
import PageIndex from 'features/autoimport/PageIndex';

export default () => {
  return (
    <AccessControl permission="autoimports:access">
      <Switch>
        <AppRoute path="/autoimports" menuId="autoimports" component={PageIndex} exact />
      </Switch>
    </AccessControl>
  );
};
