import React from 'react';
import { capitalize } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import {
  History,
  UnregisterCallback,
} from 'history';
import cn from 'classnames';
import { match as IMatch } from 'react-router';
import { AccessControl } from 'features/acl';
import Empty from 'features/ui/Empty';
import { updateBreadcrumbsData } from 'features/breadcrumbs/helpers';
import { makeOfferUrl } from '../url';

import { FEATURE_ICON as FEATURE_ICON_ITEMS } from 'features/items/consts';
import {
  FEATURE_ICON,
  VIEW_TAB_DEFAULT,
  VIEW_TABS,
} from '../../consts';

interface ITabsProps extends WithTranslation {
  history: History;
  match: IMatch<any>;
  offerId: ID;
  offerName: string;
  children: (tabId: string) => React.ReactNode | React.ReactNode[];
  action?: string;
}

interface ITabsState {
  activeTab: string;
}

class Tabs extends React.Component<ITabsProps, ITabsState> {
  static defaultProps = {
    action: 'edit',
  };

  private _unlistenHistory: UnregisterCallback | null = null;
  private _tabTitles: {[key: string]: string};
  private _icons: {[key: string]: string};

  constructor(props: ITabsProps) {
    super(props);
    const { part } = props.match.params;
    const { t } = props;
    this.state = { activeTab: VIEW_TABS[part] || VIEW_TAB_DEFAULT };
    this._tabTitles = {
      offer: t('offer'),
      items: t('items:items'),
      branding: t('branding'),
      registration: t('registration'),
      content: t('content_settings'),
      build: t('build'),
      notifications: t('notifications'),
      others: t('others'),
      integrations: t('integrations'),
    };
    this._icons = {
      offer: FEATURE_ICON,
      items: FEATURE_ICON_ITEMS,
      branding: 'fa fa-paint-brush',
      registration: 'fa fa-users',
      content: 'fa fa-book',
      build: 'fa fa-gears',
      notifications: 'fa fa-envelope',
      others: 'fa fa-table',
      integrations: 'fa fa-gears',
    };
  }

  componentDidMount() {
    const { history } = this.props;
    this._unlistenHistory = history.listen((location) => {
      const { pathname } = location;
      const parts = pathname.split('/');
      const lastPart = parts[parts.length - 1];
      this.setState({ activeTab: VIEW_TABS[lastPart] || VIEW_TAB_DEFAULT });
    });
    this.updateBreadcrumbs();
  }

  componentDidUpdate() {
    this.updateBreadcrumbs();
  }

  componentWillUnmount() {
    if (this._unlistenHistory) {
      this._unlistenHistory();
    }
  }

  updateBreadcrumbs() {
    const {
      t,
      offerName,
    } = this.props;
    updateBreadcrumbsData([
      { link: makeOfferUrl(), name: t('offers') },
      { name: offerName },
    ]);
  }

  onToggleTab(tab: string) {
    const { action } = this.props;
    this.setState({ activeTab: tab }, () => {
      const {
        history,
        offerId,
      } = this.props;
      const { activeTab } = this.state;
      if (activeTab === VIEW_TAB_DEFAULT) {
        history.push(makeOfferUrl({ action, id: offerId }));
      } else {
        history.push(makeOfferUrl({ action, id: offerId, part: activeTab }));
      }
    });
  }

  onToggleTabOffer = () => this.onToggleTab(VIEW_TABS.offer);
  onToggleTabItems = () => this.onToggleTab(VIEW_TABS.items);
  onToggleTabBranding = () => this.onToggleTab(VIEW_TABS.branding);
  onToggleTabRegistration = () => this.onToggleTab(VIEW_TABS.registration);
  onToggleTabContent = () => this.onToggleTab(VIEW_TABS.content);
  onToggleTabBuild = () => this.onToggleTab(VIEW_TABS.build);
  onToggleTabOthers = () => this.onToggleTab(VIEW_TABS.others);
  onToggleTabNotifications = () => this.onToggleTab(VIEW_TABS.notifications);
  onToggleTabIntegrations = () => this.onToggleTab(VIEW_TABS.integrations);

  renderTabTitle(name: string) {
    const { action } = this.props;
    return (
      <AccessControl
        key={name}
        permission={`offers:${name}:${action}`}
        fallback={<Empty />}
      >
        <NavItem>
          <NavLink
            className={cn({ active: this.state.activeTab === VIEW_TABS[name] })}
            onClick={(this as any)[`onToggleTab${capitalize(name)}`]}
          >
            <i className={this._icons[name]} />
            &nbsp;
            {this._tabTitles[name]}
          </NavLink>
        </NavItem>
      </AccessControl>
    );
  }

  render() {
    const { children } = this.props;
    const { activeTab } = this.state;
    const titles = Object
      .keys(this._tabTitles)
      .map(name => this.renderTabTitle(name));
    return (
      <React.Fragment>
        <Nav tabs>
          {titles}
        </Nav>
        <Card>
          <CardBody>
            {children(activeTab)}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default withTranslation('offers')(Tabs);
