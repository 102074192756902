import React from 'react';
import PageItems from 'features/items/PageItems';
import { FEATURE_ICON } from 'features/books/consts';

class PageBooks extends React.PureComponent<{}> {
  render() {
    return (
      <PageItems
        title="Книги"
        type="book"
        icon={FEATURE_ICON}
      />
    );
  }
}

export default PageBooks;
