import React from 'react';
import cn from 'classnames';
import i18n from 'features/intl/i18n';

import styles from './styles.module.scss';

interface IExpanderProps<T> {
  component: React.ComponentType<{ value: T }>;
  data: T[];
  className?: ClassNamesValue;
  max?: number;
}

interface IExpanderState {
  isExpanded: boolean;
}

export default class Expander<T extends {}> extends React.Component<IExpanderProps<T>, IExpanderState> {
  static defaultProps = { max: 5 };

  constructor(props: IExpanderProps<T>) {
    super(props);
    this.state = { isExpanded: false };
  }

  onExpand = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  render() {
    const {
      component: Component,
      className,
      data,
      max,
    } = this.props;
    const { isExpanded } = this.state;

    let slice = data;
    if (typeof max === 'number' && isExpanded === false) {
      slice = data.slice(0, Math.max(0, max));
    }

    let button = null;
    let buttonTop = null;
    if (max !== undefined && max < data.length) {
      button = (
        <div
          className={styles.button}
          onClick={this.onExpand}
        >
          {isExpanded ? i18n.t('dict:hide') : i18n.t('dict:more_with_dots')}
        </div>
      );

      if (isExpanded && data.length > 10) {
        buttonTop = (
          <div className={cn(styles.button, styles.buttonTop)} onClick={this.onExpand}>
            {i18n.t('dict:hide')}
          </div>
        );
      }
    }

    return (
      <div className={cn(styles.root, className)}>
        {buttonTop}
        {slice.map((value, id) => <Component key={id} value={value} />)}
        {button}
      </div>
    );
  }
}
