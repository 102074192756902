import React from 'react';
import { match as IMatch } from 'react-router';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import AppRoute from 'features/app/Route';
import PageCategories from '../PageCategories';
import PageEdit from '../PageEdit';
import PageView from '../PageView';
import PageCreate from '../PageCreate';

export default ({ match }: { match: IMatch<any> }) => {
  return (
    <AccessControl permission="categories:access">
      <Switch>
        <AppRoute path={'/categories'} menuId="categories" component={PageCategories} exact />
        <AppRoute path={'/category/create'} menuId="categories" component={PageCreate} exact />
        <AppRoute path={'/category/edit/:id'} menuId="categories" component={PageEdit} exact />
        <AppRoute path={'/category/:id'} menuId="categories" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
