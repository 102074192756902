import React from 'react';
import LinkBlock from 'features/ui/LinkBlock';
import {
  flow,
  get,
} from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import ReactTable from 'react-table';
import {
  Link,
  match as IMatch,
} from 'react-router-dom';
import {
  graphqlById,
  MapProps,
  MapResult,
} from 'features/graphql/helpers';
import { client } from 'features/graphql';
import {
  makeUserUrl,
  makeUserUrlTo,
} from 'features/users/lib/url';
import CardPage from 'features/ui/Page/CardPage';
import Waiter from 'features/ui/Waiter';
import { toast } from 'features/ui/Toast';
import { InfoRow } from 'features/ui/InfoRow';
import { Confirm } from 'features/ui/Modal';
import DeleteButton from 'features/ui/DeleteButton';
import { makeItemUrlTo } from 'features/items/lib/url';
import EditInventory from '../lib/EditInventory';

import getUserInventoryQuery from 'features/users/queries/getUserInventoryQuery.gql';
import updateUserMutation from 'features/users/queries/updateUserMutation.gql';

import { INVENTORY_ICON } from '../consts';

import { IUserQueryResponse } from 'features/users/types';
import {
  IItem,
  IUser,
} from 'features/types';

interface IPageInventoryProps extends WithTranslation {
  data: IItem[];
  isLoading: boolean;
  user?: IUser;
  refetchData?: () => void;
}

interface IPageInventoryState {
  idForDelete: ID | null;
}

class PageInventory extends React.Component<IPageInventoryProps, IPageInventoryState> {
  constructor(props: IPageInventoryProps) {
    super(props);
    this.state = {
      idForDelete: null,
    };
  }

  shouldComponentUpdate(nextProps: IPageInventoryProps, nextState: IPageInventoryState) {
    return (
      this.props.isLoading !== nextProps.isLoading ||
      this.state.idForDelete !== nextState.idForDelete
    );
  }

  deleteInventoryItem = (id: ID) => {
    const {
      t,
      user,
      refetchData,
      data,
    } = this.props;
    if (!user) {
      return;
    }
    const ids = data.reduce(
      (acc: ID[], item: IItem) => {
        if (item.id !== id) {
          acc.push(item.id);
        }
        return acc;
      },
      [],
    );
    return client.mutate({
      mutation: updateUserMutation,
      context: { isGlobalLoading: true },
      variables: {
        id: user.id,
        user: { itemsInventory: ids },
      },
    }).then(() => {
      toast.info(t('forms:item_was_removed', { id }));
      if (refetchData) {
        refetchData();
      }
    });
  }

  makeColumns() {
    const { t } = this.props;
    return [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ original }: { original: IItem }) => <Link to={makeItemUrlTo(original, 'view')}>{original.id}</Link>,
        className: 'text-right',
        maxWidth: 150,
        filterable: true,
      },
      {
        Header: t('items:name'),
        accessor: 'name',
        filterable: true,
      },
      {
        Header: t('downloads'),
        accessor: 'downloads',
        className: 'text-right',
        maxWidth: 150,
        filterable: true,
      },
      {
        Header: '',
        Cell: ({ value }: { value: ID }) => {
          const handler = () => this.setState({ idForDelete: value });
          return <DeleteButton onClick={handler} />;
        },
        id: 'deleteButton',
        accessor: 'id',
        className: 'text-right',
        filterable: false,
        maxWidth: 50,
      },
    ];
  }

  onDeleteModalClose = () => {
    this.setState({ idForDelete: null });
  }

  onDelete = () => {
    const { idForDelete } = this.state;
    if (idForDelete !== null) {
      this.deleteInventoryItem(idForDelete);
    }
  }

  renderLinkBlock() {
    const { user } = this.props;
    if (!user) {
      return null;
    }
    return (
      <LinkBlock
        viewLink={makeUserUrlTo(user, 'view')}
        editLink={makeUserUrlTo(user, 'edit')}
      />
    );
  }

  render() {
    const {
      t,
      data,
      user,
      isLoading,
      refetchData,
    } = this.props;
    const { idForDelete } = this.state;
    let title = t('inventory');
    if (user && user.email) {
      title = `${title}: ${user.email}`;
    }
    const isDeleteModalOpened = idForDelete !== null;
    return (
      <Waiter
        data={user}
        isLoading={isLoading}
      >
        <CardPage
          icon={INVENTORY_ICON}
          title={title}
          crumbs={[{ link: makeUserUrl(), name: t('users') }]}
        >
          {this.renderLinkBlock()}
          <InfoRow label="Добавить/Удалить Итемы">
            <EditInventory
              user={user}
              onAfterUpdate={refetchData}
            />
          </InfoRow>
          <hr />
          <ReactTable
            data={data}
            columns={this.makeColumns()}
          />
        </CardPage>
        <Confirm
          title={t('forms:delete_record')}
          description={t('forms:delete_confirmation_question', { id: idForDelete })}
          type="danger"
          isOpened={isDeleteModalOpened}
          onClose={this.onDeleteModalClose}
          onResolve={this.onDelete}
        />
      </Waiter>
    );
  }
}

const mapResultsToProps = (props: MapProps<{ match: IMatch<any> }, IUserQueryResponse>): MapResult<IPageInventoryProps> => {
  const { data, ownProps } = props;
  const refetch = get(data, 'refetch', undefined);
  const user = get(data, 'UserQuery.items[0]', undefined);
  const itemsInventory = get(data, 'UserQuery.items[0].itemsInventory', []) || [];
  const itemsDownloads = get(data, 'UserQuery.items[0].itemsDownloads', []) || [];

  const idOnAmountMapping: {[key: string]: number} = {};
  itemsDownloads.forEach((item: IItem) => {
    if (item.id in idOnAmountMapping) {
      idOnAmountMapping[item.id] += 1;
    } else {
      idOnAmountMapping[item.id] = 1;
    }
  });

  const inventory = itemsInventory.map((item: IItem) => ({
    ...item,
    id: Number(item.id),
    downloads: item.id in idOnAmountMapping ? idOnAmountMapping[item.id] : 0,
  }));

  return {
    user,
    refetchData: (refetch && user) ? () => { refetch({ id: user.id }); } : undefined,
    data: inventory,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphqlById<IUserQueryResponse, IPageInventoryProps>({
    query: getUserInventoryQuery,
    props: mapResultsToProps,
  }),
  withTranslation('users'),
)(PageInventory);
