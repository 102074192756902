import React from 'react';
import Form from 'features/tests/lib/Form';
import CardPage from 'features/ui/Page/CardPage';
import { makeTestUrl } from 'features/tests/lib/url';
import { FEATURE_ICON } from 'features/videos/consts';

class PageCreate extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        title="Создание нового теста"
        icon={FEATURE_ICON}
        crumbs={[{ link: makeTestUrl(), name: 'Тест' }]}
      >
        <Form />
      </CardPage>
    );
  }
}

export default PageCreate;
