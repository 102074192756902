import React from 'react';
import { flow } from 'lodash';
import { Redirect } from 'react-router';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import LinkBlock from 'features/ui/LinkBlock';
import Spinner from 'features/ui/Spinner';
import CardPage from 'features/ui/Page/CardPage';
import { graphqlById } from 'features/graphql/helpers';
import { FEATURE_ICON } from 'features/users/consts';
import {
  makeUserUrl,
  makeUserUrlTo,
} from 'features/users/lib/url';
import UsersForm from 'features/users/lib/Form';
import getUserQuery from 'features/users/queries/getUserQuery.gql';
import { IUserQueryResponse } from 'features/users/types';
import { IUser } from 'features/types';

export interface IBasePageEditProps extends WithTranslation {
  data: IUser | null;
  isLoading: boolean;
}

class BasePageEdit extends React.Component<IBasePageEditProps> {
  shouldComponentUpdate(nextProps: IBasePageEditProps) {
    return this.props.isLoading !== nextProps.isLoading;
  }

  renderForm(data: IUser) {
    return (
      <React.Fragment>
        <LinkBlock
          viewLink={makeUserUrlTo(data, 'view')}
          inventoryLink={makeUserUrlTo(data, 'view', 'inventory')}
        />
        <UsersForm data={data} />
      </React.Fragment>
    );
  }

  render() {
    const {
      data,
      isLoading,
    } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (!data) {
      return <Redirect to="/404" />;
    }

    const email = data.email ? data.email : '';
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={`Редактирование: ${email}`}
        crumbs={[{ link: makeUserUrl(), name: 'Пользователи' }]}
      >
        {data ? this.renderForm(data) : null}
      </CardPage>
    );
  }
}

export default flow(
  graphqlById<IUserQueryResponse, IBasePageEditProps>({
    query: getUserQuery,
    queryType: 'UserQuery',
  }),
  withTranslation('users'),
)(BasePageEdit);
