import React from 'react';
import { Redirect } from 'react-router';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import Spinner from 'features/ui/Spinner';
import CardPage from 'features/ui/Page/CardPage';
import { IItem } from 'features/types';

interface IPageEditProps extends WithTranslation {
  data: IItem | null;
  i18ns: string;
  icon: string;
  isLoading: boolean;
}

class PageEdit extends React.PureComponent<IPageEditProps> {
  render() {
    const {
      data,
      children,
      icon,
      t,
      i18ns,
      isLoading,
    } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (!data) {
      return <Redirect to="/404" />;
    }

    return (
      <CardPage
        title={`Редактирование: ${data.name}`}
        icon={icon}
        crumbs={[{ link: `/${data.type}s`, name: t(`${i18ns}:grid.title`) }]}
      >
        {children}
      </CardPage>
    );
  }
}

export default withTranslation()(PageEdit);
