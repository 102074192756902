export const plugins = [
  'advlist',
  'autolink',
  'lists',
  'link',
  'image',
  'editimage',
  'charmap',
  'anchor',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'preview',
  'help',
  'wordcount',
  'accordion',
];

export const toolbar = [
  'preview undo alignleft alignright aligncenter alignjustify indent outdent',
  'numlist bullist fontsizeinput lineheight forecolor italic bold underline strikethrough',
  'blockquote background blocks hr backcolor link image accordion table',
];
