import React from 'react';
import ReactTable from 'react-table';
import {
  cloneDeep,
  isEqual,
} from 'lodash';
import { Input } from 'reactstrap';
import {
  Column,
  ColumnRenderProps,
  RowRenderProps,
} from 'features/types';
import LocaleSelect from 'features/offers/lib/LanguagesTable/LocaleSelect';
import ErrorCodeSelect from 'features/offers/lib/LanguagesTable/ErrorCodeSelect';
import { ITranslation } from 'features/offers/lib/LanguagesTable/types';

interface ITranslationsWidgetProps {
  translations: ITranslation[];
  onChange: (translation: number[]) => void;
}

interface ITranslationsWidgetState {
  initialTranslations: ITranslation[];
  translations: ITranslation[];
}

export default class ErrorTextsWidget extends React.Component<ITranslationsWidgetProps, ITranslationsWidgetState> {
  static getDerivedStateFromProps(props: ITranslationsWidgetProps, state: ITranslationsWidgetState) {
    if (!isEqual(props.translations, state.initialTranslations)) {
      return {
        initialTranslations: cloneDeep(props.translations),
        prices: cloneDeep(props.translations),
      };
    }
    return state;
  }

  columns: Column[];

  constructor(props: ITranslationsWidgetProps) {
    super(props);
    this.columns = this.resolveColumns().filter(col => !!col) as Column[];
    this.state = {
      initialTranslations: cloneDeep(props.translations),
      translations: cloneDeep(props.translations),
    };
  }

  shouldComponentUpdate(nextProps: ITranslationsWidgetProps, nextState: ITranslationsWidgetState) {
    return (
      !isEqual(nextProps.translations, this.state.initialTranslations) ||
      nextState.translations !== this.state.translations
    );
  }

  resolveColumns = () => ([
    {
      accessor: 'id',
      Header: () => (
        <span>
          ID
          <button type="button" className="btn btn-link" onClick={this.onAddRow}>
            <i className="icon-plus icons font-lg" />
          </button>
        </span>
      ),
      minWidth: 80,
      maxWidth: 80,
    },
    {
      id: 'translation',
      Header: 'Код ошибки',
      accessor: (data: ITranslation) => data,
      minWidth: 300,
      style: {
        overflow: 'visible',
      },
      Cell: (cellInfo: ColumnRenderProps & RowRenderProps) => {
        const onChange = (id: number) => {
          const translations = [...this.state.translations];
          translations[cellInfo.index!][cellInfo.column.id!] = +id;
          this.setState({ translations }, this.onChange);
        };
        return (
          <ErrorCodeSelect
            onChange={onChange}
            value={+this.state.translations[cellInfo.index!][cellInfo.column.id!]!}
          />
        );
      },
    },
    {
      id: 'language', // название ключа в values
      Header: 'Язык',
      accessor: (data: ITranslation) => data,
      minWidth: 180,
      maxWidth: 180,
      style: {
        overflow: 'visible',
      },
      Cell: (cellInfo: ColumnRenderProps & RowRenderProps) => {
        const onChange = (id: number) => {
          const translations = [...this.state.translations];
          translations[cellInfo.index!][cellInfo.column.id!] = +id;
          this.setState({ translations }, this.onChange);
        };
        return (
          <LocaleSelect
            onChange={onChange}
            value={+this.state.translations[cellInfo.index!][cellInfo.column.id!]!}
          />
        );
      },
    },
    {
      accessor: 'value',
      Header: 'Текст',
      minWidth: 100,
      Cell: this.renderInput,
    },
    {
      id: 'actions',
      Header: '',
      maxWidth: 100,
      className: 'text-center',
      accessor: (data: ITranslation) => data,
      Cell: (row: RowRenderProps) => {
        const onClick = () => this.onDeleteRow(row.index!);
        return (
          <button type="button" className="btn btn-link" onClick={onClick}>
            <i className="icon-trash icons font-lg" />
          </button>
        );
      },
      Filter: () => <div />,
    },
  ]);

  onChange = () => {
    // @ts-ignore
    this.props.onChange(this.state.translations);
  }

  onDeleteRow = (index: number) => {
    const { translations } = this.state;
    let newTranslations = cloneDeep(translations);
    if (newTranslations[index].id) {
      newTranslations[index].delete = true;
      // Перемещаю элементы с delete === true в конец массиве, что позволяет
      // обращаться к элементам с delete === undefined по их индексу и
      // сохранить данные об удалении в том же массиве.
      newTranslations.sort((a, b) => +!!a.delete - +!!b.delete);
    } else {
      newTranslations = newTranslations.filter((p, id) => id !== index);
    }
    this.setState(
      { translations: newTranslations },
      this.onChange,
    );
  };

  onAddRow = () => {
    const { translations } = this.state;
    this.setState(
      {
        translations: translations.concat([{
          translation: 1,
          language: 1,
          value: '',
        }]),
      },
      this.onChange,
    );
  };

  onChangeErrorText = (e: React.SyntheticEvent<any>, cellInfo: ColumnRenderProps & RowRenderProps) => {
    const translations = [...this.state.translations];
    translations[cellInfo.index!][cellInfo.column.id!] = e.currentTarget.value;
    this.setState({ translations }, this.onChange);
  }

  getTableStyleProps = () => ({
    // иначе в ячейках фильтров таблиц не видно выпадающих списков
    style: {
      overflow: 'visible',
    },
  });

  renderInput = (cellInfo: ColumnRenderProps & RowRenderProps) => {
    const onChange = (e: React.SyntheticEvent<any>) => this.onChangeErrorText(e, cellInfo);
    return (
      <Input
        onBlur={onChange}
        onChange={onChange}
        value={this.state.translations[cellInfo.index!][cellInfo.column.id!] as string}
      />
    );
  };

  filterDeleted(translations: ITranslation) {
    return translations.delete !== true;
  }

  renderNoDataComponent = () => null;

  render() {
    const { translations } = this.state;
    return (
      <ReactTable
        showPagination={false}
        data={translations.filter(this.filterDeleted)}
        columns={this.columns}
        minRows={0}
        getTableProps={this.getTableStyleProps}
        getTbodyProps={this.getTableStyleProps}
        NoDataComponent={this.renderNoDataComponent}
        sortable={false}
      />
    );
  }
}
