import React from 'react';
import LinkToCreator from '../LinkToCreator';
import { ICreator } from '../../types';

const renderList = (creators: ICreator[]) => {
  return creators.map((creator: ICreator) => (
    <React.Fragment key={creator.id}>
      <LinkToCreator value={creator} />
      <span>&ensp;</span>
    </React.Fragment>
  ));
};

export default ({ creators }: { creators: ICreator[] }) => {
  return (
    <React.Fragment>
      {renderList(creators)}
    </React.Fragment>
  );
};
