import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import AppRoute from 'features/app/Route';
import PageLayoutBlocks from '../PageLayoutBlocks';
import PageEdit from '../PageEdit';
import PageView from '../PageView';
import PageCreate from '../PageCreate';

export default () => (
  <AccessControl permission="categories:access">
    <Switch>
      <AppRoute path="/layout-blocks" menuId="layout-block" component={PageLayoutBlocks} exact />
      <AppRoute path="/layout-block/create" menuId="layout-block" component={PageCreate} exact />
      <AppRoute path="/layout-block/edit/:id" menuId="layout-block" component={PageEdit} exact />
      <AppRoute path="/layout-block/:id" menuId="layout-block" component={PageView} exact />
      <Route name="404" component={Page404} />
    </Switch>
  </AccessControl>
);
