import React from 'react';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import { makeOfferUrlTo } from '../url';
import { IOffer } from '../../types';

export default ({
  value,
  action,
}: {
  value: IOffer,
  action?: string,
}) => (
  <LinkToEntityInLists
    id={value.id}
    name={value.name}
    to={makeOfferUrlTo(value, action)}
  />
);
