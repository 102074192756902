import React from 'react';
import {
  Card,
  CardBody,
} from 'reactstrap';
import { updateBreadcrumbsData } from 'features/breadcrumbs/helpers';

class Dashboard extends React.PureComponent {
  resolveCrumbs = () => {
    return [{ name: 'Dashboard', link: '' }];
  };

  componentDidMount() {
    updateBreadcrumbsData(this.resolveCrumbs());
  }

  componentDidUpdate() {
    updateBreadcrumbsData(this.resolveCrumbs());
  }

  render() {
    return (
      <Card>
        <CardBody>
          <h1>
            Добро пожаловать
          </h1>
          <p>
            Административный интерфейс компании Alpina Digital
          </p>
        </CardBody>
      </Card>
    );
  }
}

export default Dashboard;
