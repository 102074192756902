import React from 'react';
import {
  NavLink,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import {
  Badge,
  Nav,
  NavItem,
  NavLink as RsNavLink,
} from 'reactstrap';
import {
  DataProps,
  graphql,
} from 'react-apollo';
import history from 'features/app/history';
import { AccessControl } from 'features/acl';
import classNames from 'classnames';
import nav from './_nav';
import { GET_STATE_QUERY } from 'features/graphql/queries';
import { sideMenuActiveIdSelector } from 'features/layout/helpers';

import {
  IBadge,
  INavItem,
} from './types';
import { IAppStateQueryResponse } from 'features/types';

type ISidebarProps = DataProps<IAppStateQueryResponse> & RouteComponentProps<{}>;

class Sidebar extends React.PureComponent<ISidebarProps> {
  handleClick = (e: any) => {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  };

  get activeId() {
    const { data } = this.props;
    return data && data.appState && data.appState.components ? sideMenuActiveIdSelector(data.appState) : '';
  }

  isActiveItem = (item: INavItem) => {
    const { location } = this.props;
    return item.url === location.pathname || (!!this.activeId && item.url === `/${this.activeId}`);
  };

  isActiveChildren = (children: INavItem[]) => children.some(item => this.isActiveItem(item));

  activeRoute = (item: INavItem) => {
    return this.isActiveChildren(item.children!) ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
  };

  hideMobile = () => {
    if (document.body.classList.contains('sidebar-mobile-show')) {
      document.body.classList.toggle('sidebar-mobile-show');
    }
  };

  onClickBadge = (e: React.SyntheticEvent<any>) => {
    e.preventDefault();
    history.push(e.currentTarget.getAttribute('href'));
  };

  isExternal = (url: string) => {
    const link = url ? url.substring(0, 4) : '';
    return link === 'http';
  };

  renderBadge = (badge?: IBadge) => {
    if (badge) {
      const classes = classNames(badge.class, 'float-right');
      return (
        <Badge
          href={badge.link}
          className={classes}
          color={badge.variant}
          onClick={badge.link ? this.onClickBadge : undefined}
        >
          {badge.text}
        </Badge>
      );
    }
    return null;
  };

  renderTitle = (item: INavItem, key: number) => {
    const classes = classNames('nav-title', item.class);
    return (
      <li key={key} className={classes}>
        {item.wrapper && item.wrapper.element ? (React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)) : item.name}
      </li>
    );
  };

  renderDivider = (item: INavItem, key: number) => {
    const classes = classNames('divider', item.class);
    return (<li key={key} className={classes} />);
  };

  renderLink = (item: INavItem, key: number, classes: any) => {
    const url = item.url ? item.url : '';
    return (
      <AccessControl
        key={key}
        permission={item.permission}
        shouldRenderComponent={false}
      >
        <NavItem className={classes.item}>
          {this.renderBadge(item.badge)}
          {this.isExternal(url) ?
            <RsNavLink href={url} className={classes.link} active>
              <i className={classes.icon} />{item.name}
            </RsNavLink>
            :
            <NavLink
              to={url}
              className={classes.link}
              activeClassName="active"
              onClick={this.hideMobile}
            >
              <i className={classes.icon} />{item.name}
            </NavLink>
          }
        </NavItem>
      </AccessControl>
    );
  };

  renderLabel = (item: INavItem, key: number) => {
    const classes = {
      item: classNames('hidden-cn', item.class),
      link: classNames('nav-label', item.class ? item.class : ''),
      icon: classNames(
        !item.icon ? 'fa fa-circle' : item.icon,
        item.label && item.label.variant ? `text-${item.label.variant}` : '',
        item.label && item.label.class ? item.label.class : '',
      ),
    };
    return this.renderLink(item, key, classes);
  };

  renderItem = (item: INavItem, key: number) => {
    const classes = {
      item: classNames(item.class),
      link: classNames(
        'nav-link',
        item.variant ? `nav-link-${item.variant}` : '',
        this.isActiveItem(item) ? 'active' : '',
      ),
      icon: classNames(item.icon),
    };
    return this.renderLink(item, key, classes);
  };

  renderDropdown = (item: INavItem, key: number) => {
    return (
      <AccessControl
        key={key}
        permission={item.permission}
        shouldRenderComponent={false}
      >
        <li className={this.activeRoute(item)}>
          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick}>
            <i className={item.icon} />{item.name}
          </a>
          {item.children ?
            <ul className="nav-dropdown-items">
              {this.renderList(item.children)}
            </ul>
            : null}
        </li>
      </AccessControl>
    );
  };

  renderByType = (item: INavItem, idx: number) =>
    item.title ? this.renderTitle(item, idx) :
      item.divider ? this.renderDivider(item, idx) :
        item.label ? this.renderLabel(item, idx) :
          item.children ? this.renderDropdown(item, idx)
            : this.renderItem(item, idx);

  renderList = (items: INavItem[]) => {
    return items.map((item: INavItem, index: number) => this.renderByType(item, index));
  };

  render() {
    return (
      <nav className="sidebar-nav">
        <Nav>
          {this.renderList(nav.items)}
        </Nav>
      </nav>
    );
  }
}

export default withRouter<RouteComponentProps>(
  graphql<RouteComponentProps, IAppStateQueryResponse, {}, ISidebarProps>(GET_STATE_QUERY, {
    options: {
      fetchPolicy: 'cache-only',
    },
  })(Sidebar),
);
