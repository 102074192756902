import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  difference,
  get,
  union,
} from 'lodash';
import { client } from 'features/graphql';
import { toast } from 'features/ui/Toast';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import Switch from 'features/ui/Switch';
import ItemsIdsTextarea from 'features/items/lib/ItemsIdsTextarea';
import { makeSubscriptionUrl } from '../url';
import { ISubscription } from '../../types';
import editSubscriptionMutation from '../../queries/editSubscription.gql';

interface IFeatureFormProps extends FormikProps<ISubscription>, WithTranslation {
  subscription?: ISubscription;
}

class BaseFeatureForm extends React.Component<IFeatureFormProps> {
  handleAddItems = (newIds: number[]) => {
    const { t, subscription } = this.props;
    const itemIds = subscription.items.map(item => +item.id);
    const ids = union(itemIds, newIds);
    const amount = ids.length - itemIds.length;

    return client.mutate({
      mutation: editSubscriptionMutation,
      variables: {
        id: subscription.id,
        subscription: {
          items: ids,
        },
      },
    }).then(() => toast.info(t('forms:amount_of_added_records', { amount })))
      .catch((result) => {
        const errorText = get(result, 'graphQLErrors[0].validation["offer.items"][0]', null);
        if (errorText) {
          toast.error(errorText);
        }
        return result;
      });
  };

  handleRemoveItems = (removeIds: number[]) => {
    const { t, subscription } = this.props;
    const itemIds = subscription.items.map(item => +item.id);
    const ids = difference(itemIds, removeIds);
    const removedIds = difference(itemIds, ids);
    const amount = removedIds.length;

    return client.mutate({
      mutation: editSubscriptionMutation,
      context: { isGlobalLoading: true },
      variables: {
        id: subscription.id,
        subscription: {
          items: ids,
        },
      },
    }).then(() => {
      toast.info(t('forms:amount_of_deleted_records', { amount }));
    });
  };

  renderItems = () => {
    const { subscription, t } = this.props;
    if (subscription && subscription.id) {
      return (
        <FormRow label={t('items')} id="items">
          <ItemsIdsTextarea
            onAdd={this.handleAddItems}
            onRemove={this.handleRemoveItems}
          />
        </FormRow>
      );
    }
    return null;
  };

  render() {
    const {
      handleBlur,
      handleChange,
      t,
      values,
      subscription,
    } = this.props;

    return (
      <FormFrame
        id={subscription ? subscription.id : undefined}
        cancelLink={makeSubscriptionUrl()}
        {...this.props}
      >

        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Имя подписки"
          />
        </FormRow>

        <FormRow label={t('title')} id="title">
          <Input
            type="text"
            id="title"
            name="title"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Официальное название для покупки"
          />
        </FormRow>

        <FormRow label={t('cost')} id="cost">
          <Input
            type="number"
            id="cost"
            name="cost"
            value={values.cost}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="0"
          />
        </FormRow>

        <FormRow label={t('old_cost')} id="old_cost">
          <Input
            type="number"
            id="old_cost"
            name="old_cost"
            value={values.old_cost}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="0"
          />
        </FormRow>

        <FormRow label={t('have_trial')} id="have_trial">
          <Switch
            id="have_trial"
            name="have_trial"
            checked={values.have_trial}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('trial_days')} id="trial_days">
          <Input
            type="number"
            id="trial_days"
            name="trial_days"
            value={values.trial_days}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="0"
          />
        </FormRow>
        <FormRow label={t('expire_days')} id="expire_days">
          <Input
            type="number"
            id="expire_days"
            name="expire_days"
            value={values.expire_days}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="0"
          />
        </FormRow>

        {this.renderItems()}
        <hr />

      </FormFrame>
    );
  }
}

export default withTranslation('subscriptions')(BaseFeatureForm);
