import React from 'react';
import LinkToOffer from '../LinkToOffer';
import { IOffer } from '../../types';

const renderList = (offers: IOffer[]) => {
  return offers.map((offer: IOffer) => (
    <React.Fragment key={offer.id}>
      <LinkToOffer value={offer} />
      <span>&ensp;</span>
    </React.Fragment>
  ));
};

export default ({ offers }: { offers: IOffer[] }) => {
  return (
    <React.Fragment>
      {renderList(offers)}
    </React.Fragment>
  );
};
