import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageCreators from 'features/creators/PageCreators';
import PageCreate from 'features/creators/PageCreate';
import PageEdit from 'features/creators/PageEdit';
import PageView from 'features/creators/PageView';

export default () => {
  return (
    <AccessControl permission="creators:access">
      <Switch>
        <AppRoute path="/creators" menuId="creators" component={PageCreators} exact />
        <AppRoute path="/creator/edit/:id" menuId="creators" component={PageEdit} exact />
        <AppRoute path="/creator/create" menuId="creators" component={PageCreate} exact />
        <AppRoute path="/creator/:id" menuId="creators" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
