import React from 'react';
import { omit } from 'lodash';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import ItemForm from 'features/items/lib/Form';
import { imageFileValidator } from 'lib/validators';
import BaseForm from './Form';

import getCollectionQuery from 'features/collections/queries/getCollectionQuery.gql';

import {
  IFormValues,
} from 'features/collections/types';
import { IItem } from 'features/types';

interface IFormProps extends WithTranslation {
  data?: IItem;
}

class Form extends React.PureComponent<IFormProps> {
  resolveMutationData = (values: any) => {
    return values;
  };

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: any) => {
    const { t } = this.props;
    return {
      ...omit(shape, 'content'),
      childrenItems: Yup.array().required(t('forms:required_field', { field: t('childrenItems') })),
      image_wide: imageFileValidator({ field: t('items:image_wide'), required: true }),
    };
  };

  resolveInitialValues = (values: any) => {
    const { data } = this.props;

    const defaultValues = {
      collection: {
        is_important: false,
      },
      childrenItems: [],
    };

    return data ? {
      ...defaultValues,
      ...values,
      ...{
        collection: {
          is_important: data.collection ? data.collection.is_important : false,
        },
        childrenItems: data.childrenItems!.map(item => item.id),
      },
    } : {
      ...defaultValues,
      ...values,
    };
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="collection"
        data={data}
        getItemQuery={getCollectionQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        renderForm={this.renderForm}
      />
    );
  }
}

export default withTranslation('collections')(Form);
