import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import moment from 'moment';
import { set } from 'lodash';
import CardPage from 'features/ui/Page/CardPage';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import { IUserSubscription } from 'features/userSubscriptions/types';
import makeColumns from './columns';
import getUserSubscriptions from '../queries/getUserSubscriptions.gql';
import { makeUsersSubscriptionUrl } from '../lib/url';
import { FEATURE_ICON } from '../consts';
import { DATETIME_FORMAT_DATE } from 'features/app/consts';

class PageUserSubscription extends React.Component<IUserSubscription & WithTranslation> {
  constructor(props: IUserSubscription & WithTranslation) {
    super(props);
    this._columns = makeColumns();
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.subscription) {
      set(variables, 'filterByFields.subscription_id', {
        operator: '=',
        value: fields.subscription.toString(),
      });
    }
    if (fields.user) {
      set(variables, 'filterByFields.user_id', {
        operator: '=',
        value: fields.user,
      });
    }

    if (fields.cost) {
      set(variables, 'filterByFields.cost', {
        operator: '=',
        value: fields.cost,
      });
    }
    if (fields.from) {
      set(variables, 'filterByFields.from', {
        operator: '>=',
        value: moment(fields.from).add(1, 'day').format(DATETIME_FORMAT_DATE),
      });
    }
    if (fields.to) {
      set(variables, 'filterByFields.to', {
        operator: '<=',
        value: moment(fields.to).add(1, 'day').format(DATETIME_FORMAT_DATE),
      });
    }
    if (fields.canceled_at) {
      set(variables, 'filterByFields.canceled_at', {
        operator: '<=',
        value: moment(fields.canceled_at).add(1, 'day').format(DATETIME_FORMAT_DATE),
      });
    }

  };

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        buttons={[{ link: '/user-subscriptions/create', name: t('add_new_user_subscription') }]}
        title={t('user_subscriptions')}
      >
        <IndexTableBase
          query={getUserSubscriptions}
          queryResultName="UserSubscriptionQuery"
          columns={this._columns}
          makeCrudUrl={makeUsersSubscriptionUrl}
          filterFields={['id', 'subscription', 'name', 'cost', 'user', 'from', 'to', 'canceled_at']}
          prepareRequestData={this.prepareRequestData}
          isFilterableById={true}
          showAutoEditIconColumn={true}
          showViewIconColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('user_subscriptions')(PageUserSubscription);
