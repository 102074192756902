import React from 'react';
import {
  IOffer,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  mapEntityToOptions,
  ReactSelectWithId,
} from 'features/ui/ReactSelect';
import graphql from './graphql';
import OffersSelectView from './view';

interface IOffersSelectProps extends ISharedSelectProps {
  data: IOffer[];
}

interface IOffersSelectState {
  options: ISelectOption[];
}

class OffersSelect extends React.PureComponent<IOffersSelectProps, IOffersSelectState> {
  static defaultProps = {
    isMulti: true,
  };

  static getDerivedStateFromProps(props: any) {
    return {
      options: mapEntityToOptions<IOffer>(props.data, 'name'),
    };
  }

  state = {
    options: [],
  };

  render() {
    const {
      id,
      name,
      value,
      isMulti,
      isClearable,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelectWithId
        id={id}
        name={name}
        options={options}
        isClearable={isClearable === undefined ? !isMulti : isClearable}
        isMulti={isMulti || false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        minSearchLength={1}
      />
    );
  }
}

export { OffersSelectView };
export default graphql(OffersSelect);
