import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import getAutoPricesQuery from 'features/autoprice/queries/getAutoPricesQuery.gql';
import resolveColumns from './columns';

import { Column } from 'features/types';

type IDataTableProps = WithTranslation;

class DataTable extends React.PureComponent<IDataTableProps> {
  columns: Column[];

  constructor(props: IDataTableProps) {
    super(props);

    this.columns = resolveColumns().filter(col => !!col) as Column[];
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
  };

  render() {
    const graphqlConfig = {
      options: () => {
        return { variables: {} };
      },
    };
    return (
      <IndexTableBase
        columns={this.columns}
        filterFields={['id']}
        prepareRequestData={this.prepareRequestData}
        query={getAutoPricesQuery}
        queryResultName="AutoPriceQuery"
        showAutoIdColumn={false}
        showAutoEditIconColumn={false}
        graphqlQuery={getAutoPricesQuery}
        graphqlConfig={graphqlConfig}
      />
    );
  }
}

export default withTranslation('autoprice')(DataTable);
