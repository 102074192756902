import React from 'react';
import { get } from 'lodash';
import BaseHeader from 'features/layout/Header/BaseHeader';
import AuthService from 'features/auth/AuthService';
import { client, gql } from 'features/graphql';

const LOGGED_IN_USER_QUERY = gql`
  query getCurrentUser {
    Profile {
      id,
      email
    }
  }`;

interface IHeader {
  email: string;
}

class Header extends React.PureComponent<any, IHeader> {
  constructor(props: any) {
    super(props);
    this.state = { email: '' };
  }

  componentDidMount() {
    client
      .query({
        query: LOGGED_IN_USER_QUERY,
      })
      .then(this.onFetchProfile);
  }

  onFetchProfile = (data: object) => {
    this.setState({ email: get(data, 'data.Profile.email', '') });
  };

  onLogout = () => {
    AuthService.logout();
  };

  render() {
    const { email } = this.state;
    return (
      <BaseHeader
        email={email}
        onLogout={this.onLogout}
      />
    );
  }
}

export default Header;
