import React from 'react';
import DragNDropComponent from 'features/ui/DragNDropComponent';
import { reorder } from 'features/ui/DragNDropComponent/methods';
import StageItemSelect from './StageContentSelect';
import styles from './style.module.scss';

const CourseStage = (props: any) => {
  const { item: { name, id, childItems }, changeStageParam, stageRemove } = props;

  return (
    <div className="card-block border border-light rounded bg-light shadow" {...props}>
      <div className="input-group input-group mb-4">
        <div className="input-group-prepend">
          <span className="input-group-text bg-light rounded-left" id="inputGroup-sizing-sm">Название раздела</span>
        </div>
        <input
          value={name}
          onChange={e => changeStageParam(id, 'name', e.target.value)}
          type="text"
          className="form-control"
        />
        <div className="input-group-append">
          <button type="button" style={{ zIndex: 0 }} className="btn btn-danger rounded-right" onClick={() => stageRemove(id)}>
            Удалить раздел
          </button>
        </div>
      </div>
      <p className="input-group-text">Содержание раздела</p>
      <div className="bg-white">
        <div className={styles['stages-container']}>
          <StageItemSelect id={id} items={childItems} changeStageParam={changeStageParam} />
        </div>
      </div>
    </div>
  );
};

interface IStage {
  id: string;
  name: string;
  sort: number;
  childItems: Array<{
    id: string;
  }>;
}

interface IStageContainerProps {
  values: IStage[];
  onChange: (value: any) => void;
}

const StagesContainer = ({ values, onChange }: IStageContainerProps) => {
  const addStage = () => {
    const hash = Date.now();
    const newItem = {
      id: `temp-${hash}`, // удаляем при отправке, ориентируясь на 'temp'
      name: '',
      childItems: [],
    };
    onChange([...values, newItem]);
  };

  const changeStageParam = (id: string, param: string, value: number[] | string) => {
    onChange(values.map((itm) => {
      if (itm.id !== id) {
        return itm;
      }
      return {
        ...itm,
        [param]: value,
      };
    }));
  };

  const stageRemove = (id: string) => {
    onChange(values.filter(itm => itm.id !== id));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newList = reorder(values, result.source.index, result.destination.index);
    onChange(newList);
  };

  const renderStages = () => {
    if (!values.length) {
      return <div>Этапы не добавлены</div>;
    }
    return (
      <DragNDropComponent items={values} onDragEnd={onDragEnd} childPropName="item">
        <CourseStage changeStageParam={changeStageParam} stageRemove={stageRemove} />
      </DragNDropComponent>
    );
  };

  return (
    <div>
      <div className="card p-4 m-0">
        {renderStages()}
      </div>
      <div>
        <button type="button" className="btn btn-info" onClick={addStage}>Добавить еще раздел</button>
      </div>
    </div>
  );
};

export default StagesContainer;
