import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import FormRow from 'features/ui/FormRow';
import BaseItemForm from 'features/items/lib/Form/Form';

import {
  TYPE_VIDEO,
} from 'features/items/consts';

import { IFormValues } from 'features/courses/types';
import { IItem } from 'features/types';
import StagesContainer from 'features/courses/lib/Stages';
import { Input } from 'reactstrap';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IItem;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onStagesChange = (event) => {
    const { setFieldValue } = this.props;
    setFieldValue('childStages', event);
  };

  resolveFields = (fields: any) => {
    const { values, t, handleChange, handleBlur } = this.props;

    fields.splice(16, 0, (
      <FormRow
        key="childStages"
        label={t('stages')}
        id="childStages"
      >
        <StagesContainer values={values.childStages} onChange={this.onStagesChange} />
      </FormRow>
    ));

    fields.splice(20, 0, (
      <FormRow
        key="course.duration"
        label={t('duration')}
        id="course.duration"
        required
      >
        <Input
          type="number"
          id="course.duration"
          name="course.duration"
          value={values.course ? values.course.duration : 0}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ));

    return fields;
  };

  render() {
    return (
      <BaseItemForm
        {...this.props}
        resolveFields={this.resolveFields}
        cancelLink="/courses"
        type={TYPE_VIDEO}
      />
    );
  }
}

export default withTranslation('course')(BaseForm);
