import React from 'react';
import cn from 'classnames';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { IInfoRowOptions } from './types';
import LinkToItem from 'features/items/lib/LinkToItem';
import styles from './styles.module.scss';

export const InfoRowCourseStages = ({
  label,
  value,
  options,
  className,
}: {
  label: string,
  value: any[],
  options?: IInfoRowOptions,
  className?: ClassNamesValueBaseType,
}) => {
  if (!value.length) {
    return <InfoRowNotSet label={label} options={options} />;
  }

  const renderStageItems = (stage: any) => {
    if (!stage.childItems.length) {
      return <InfoRowNotSet label={label} options={options} />;
    }
    return (
      <div>
        {stage.childItems.sort((a, b) => a.sort - b.sort).map((item) => (
          <div key={item.id} className={styles.stageItem}>
            <span>——</span>
            <LinkToItem value={item} action="view" />
          </div>
        ))}
      </div>
    );
  };

  return (
    <InfoRow label={label} {...options}>
      <div className={cn(className)}>
        {value.sort((a, b) => a.sort - b.sort).map(v => (
          <div className={styles.stage} key={v.id}>
            <p>{v.name}</p>
            <div>{renderStageItems(v)}</div>
          </div>
        ))}
      </div>
    </InfoRow>
  );
};
