import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import Switch from 'features/ui/Switch';
import CategoriesSelect from 'features/categories/lib/CategoriesSelect';
import OffersSelectWithButtons from 'features/offers/lib/OffersSelectWithButtons';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import { InfoRow } from 'features/ui/InfoRow';
import ResourceLink from '../ResourceLink';
import { makePopularRequestUrl } from '../url';
import {
  IPopularRequest,
  IPopularRequestFormValues,
} from '../../types';

interface IPopularRequestProps extends FormikProps<IPopularRequestFormValues>, WithTranslation {
  request?: IPopularRequest;
}

class BasePopularRequestForm extends React.Component<IPopularRequestProps> {

  onChangeResourceLink = (value: string) => {
    this.props.setFieldValue('content_id', value);
  };

  onChangeOffers = (ids: ID[]) => {
    this.props.setFieldValue('offers', ids.slice());
  };

  onBlurResourceLink = () => {
    this.props.setFieldTouched('content_id', true);
  };

  renderResourceLinkInputURL() {
    const {
      handleBlur,
      handleChange,
      t,
      values,
    } = this.props;
    return (
      <FormRow label={t('content_title')} id="content_link" text="https://some-address.com" required>
        <Input
          type="text"
          id="content_link"
          name="content_link"
          value={values.content_link}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    );
  }

  renderResourceLinkInputCategory() {
    const {
      t,
      values,
    } = this.props;
    return (
      <FormRow label={t('content_title')} id="content_id" required>
        <CategoriesSelect
          id="content_id"
          name="content_id"
          value={parseInt(values.content_id || '0', 10)}
          onChange={this.onChangeResourceLink}
          onBlur={this.onBlurResourceLink}
          isMulti={false}
        />
      </FormRow>
    );
  }

  renderResourceLinkInputItem() {
    const {
      t,
      values,
    } = this.props;
    return (
      <FormRow label={t('content_title')} id="content_id" required>
        <ItemsSelect
          id="content_link"
          name="content_link"
          value={parseInt(values.content_id || '0', 10)}
          isMulti={false}
          onBlur={this.onBlurResourceLink}
          onChange={this.onChangeResourceLink}
        />
      </FormRow>
    );
  }

  renderGeneratedLink() {
    const {
      request,
      t,
    } = this.props;
    if (request) {
      return (
        <InfoRow label={t('generated_link')} text={t('forms:updating_after_saving')}>
          <ResourceLink request={request} />
        </InfoRow>
      );
    }
    return null;
  }

  render() {
    const {
      request,
      handleBlur,
      handleChange,
      values,
      t,
    } = this.props;
    return (
      <FormFrame
        id={request ? request.id : undefined}
        cancelLink={makePopularRequestUrl()}
        {...this.props}
      >
        <FormRow label={t('label')} id="label" required>
          <Input
            type="text"
            id="label"
            name="label"
            value={values.label}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('content_type')} id="content_type">
          <Input
            id="content_type"
            name="content_type"
            type="select"
            value={values.content_type}
            onChange={handleChange}
          >
            <option value="category">{t('content_type_category')}</option>
            <option value="item">{t('content_type_item')}</option>
            <option value="url">{t('content_type_url')}</option>
          </Input>
        </FormRow>

        {values.content_type === 'category' ? this.renderResourceLinkInputCategory() : null}
        {values.content_type === 'item' ? this.renderResourceLinkInputItem() : null}
        {values.content_type === 'url' ? this.renderResourceLinkInputURL() : null}
        {this.renderGeneratedLink()}

        <FormRow label={t('is_active')} id="is_active">
          <Switch
            id="is_active"
            name="is_active"
            checked={values.is_active}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('weight')} id="weight">
          <Input
            type="number"
            id="weight"
            name="weight"
            value={values.weight === undefined ? '' : values.weight}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow key="offers" label={t('offers:offers')} id="offers" text={t('offers:offers')}>
          <OffersSelectWithButtons
            id="offers"
            name="offers"
            value={values.offers}
            onChange={this.onChangeOffers}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('popular_requests')(BasePopularRequestForm);
