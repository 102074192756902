import React from 'react';
import {
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { updateSideMenuActiveId } from 'features/layout/helpers';

export interface IAppRoute extends RouteProps {
  menuId?: string;
}

class AppRoute extends React.PureComponent<IAppRoute> {
  componentDidMount() {
    const { menuId } = this.props;
    if (menuId) {
      updateSideMenuActiveId(menuId);
    }
  }

  componentDidUpdate() {
    const { menuId } = this.props;
    if (menuId) {
      updateSideMenuActiveId(menuId);
    }
  }

  renderComponent = (props: RouteComponentProps) => {
    const Component: React.ComponentType<any> | undefined = this.props.component;
    return Component ? (
      <Component {...props} route={this.props} params={props.match.params} />
    ) : null;
  };

  render() {
    const { menuId, component, ...more } = this.props;

    return (
      <Route
        {...more}
        render={this.renderComponent}
      />
    );
  }
}

export default AppRoute;
