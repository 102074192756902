import React from 'react';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/promocodes/consts';
import { makePromocodeUrl } from 'features/promocodes/lib/url';
import PromocodesTable from 'features/promocodes/lib/PromocodesTable';

class PagePromocodes extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Промокоды"
        buttons={[{ link: makePromocodeUrl({ action: 'create' }), name: 'Создать промокод' }]}
      >
        <PromocodesTable />
      </CardPage>
    );
  }
}

export default PagePromocodes;
