import React from 'react';
import {
  Link,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import {
  DataProps,
  graphql,
} from 'react-apollo';
import { GET_STATE_QUERY } from 'features/graphql/queries';
import {
  breadcrumbsDataSelector,
} from 'features/breadcrumbs/helpers';

import {
  IAppStateQueryResponse,
  IBreadcrumb,
} from 'features/types';

type IBreadcrumbsProps = DataProps<IAppStateQueryResponse> & RouteComponentProps<{}>;

interface IBreadcrumbsState {
  breadcrumbs: IBreadcrumb[];
}

class Breadcrumbs extends React.Component<IBreadcrumbsProps, IBreadcrumbsState> {
  static getDerivedStateFromProps(nextProps: IBreadcrumbsProps) {
    const { data } = nextProps;
    return {
      breadcrumbs: data && data.appState && data.appState.components ? breadcrumbsDataSelector(data.appState) : [],
    };
  }

  state = {
    breadcrumbs: [],
  };

  renderBreadcrumb = (breadcrumb: IBreadcrumb) => {
    const {
      location,
    } = this.props;

    const isActive = !breadcrumb.link || (location.pathname === breadcrumb.link);
    const to = breadcrumb.link || '';
    const name = breadcrumb.name;

    return (
      <BreadcrumbItem key={breadcrumb.name} active={isActive}>
        {isActive ? name : <Link to={to}>{name}</Link>}
      </BreadcrumbItem>
    );
  };

  render() {
    const {
      data,
    } = this.props;
    let breadcrumbs = data && data.appState && data.appState.components ? breadcrumbsDataSelector(data.appState) : [];
    breadcrumbs = [{ name: 'Home', link: '/' } as IBreadcrumb].concat(breadcrumbs);

    return (
      <div>
        <Breadcrumb>
          {breadcrumbs.map(breadcrumb => this.renderBreadcrumb(breadcrumb))}
        </Breadcrumb>
      </div>
    );
  }
}

export default withRouter<RouteComponentProps>(
  graphql<RouteComponentProps, IAppStateQueryResponse, {}, IBreadcrumbsProps>(GET_STATE_QUERY, {
    options: {
      fetchPolicy: 'cache-only',
    },
  })(Breadcrumbs),
);
