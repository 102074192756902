import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'features/intl/i18n';
import { makeItemUrl } from 'features/items/lib/url';
import { ICreator } from 'features/types';

export default ({
  creator,
  children,
}: {
  creator: ICreator,
  children?: React.ReactNode | React.ReactNode[],
}) => {
  let child;
  if (children) {
    child = children;
  } else {
    child = i18n.t('items:measure', { count: creator.items ? creator.items.length : 0 });
  }
  return (
    <Link
      to={makeItemUrl({ query: { creators: creator.id } })}
      title={i18n.t('creators:go_to_items')}
    >
      {child}
    </Link>
  );
};
