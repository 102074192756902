import React from 'react';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { APP_IMAGES_URL } from 'features/app/consts';
import { IInfoRowOptions } from './types';
import styles from './styles.module.scss';
import backgroundTile from 'static/img/background_tile.png';

interface IInfoRowImageProps {
  label: string;
  value: string | null;
  width?: number;
  options?: IInfoRowOptions;
}

interface IInfoRowImageState {
  isImageSizeShown?: boolean;
  width?: number;
  height?: number;
}

export class InfoRowImage extends React.Component<IInfoRowImageProps, IInfoRowImageState> {
  private _imgRef = React.createRef<HTMLImageElement>();

  constructor(props: IInfoRowImageProps) {
    super(props);
    this.state = {
      isImageSizeShown: false,
      width: 0,
      height: 0,
    };
  }

  onLoad = () => {
    const img = this._imgRef.current;
    if (img) {
      this.setState({
        isImageSizeShown: true,
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    }
  }

  shouldComponentUpdate(nextProps: IInfoRowImageProps, nextState: IInfoRowImageState) {
    return (
      nextProps.label !== this.props.label ||
      nextProps.value !== this.props.value ||
      nextProps.options !== this.props.options ||
      nextState.width !== this.state.width ||
      nextState.height !== this.state.height
    );
  }

  renderImageSize() {
    const {
      isImageSizeShown,
      width,
      height,
    } = this.state;
    if (isImageSizeShown) {
      return (
        <div>{width}x{height}</div>
      );
    }
    return null;
  }

  render() {
    const {
      label,
      value,
      width,
      options,
    } = this.props;
    if (value === null) {
      return <InfoRowNotSet label={label} options={options} />;
    }
    let path;
    if (/^http/.test(value)) {
      path = value;
    } else {
      path = `${APP_IMAGES_URL}/${value}`;
    }
    const style: { [key: string]: number | string } = {
      backgroundImage: `url(${backgroundTile})`,
    };
    if (width) {
      style.maxWidth = width;
    }
    return (
      <InfoRow label={label} {...options}>
        <React.Fragment>
          <a href={path} target="_blank">
            <img
              ref={this._imgRef}
              className={styles.fitByWidth}
              style={style}
              src={path}
              onLoad={this.onLoad}
            />
          </a>
          {this.renderImageSize()}
        </React.Fragment>
      </InfoRow>
    );
  }
}
