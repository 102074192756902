import React from 'react';
import { get } from 'lodash';
import { IOffer } from '../types';
import { FormPropsType } from 'features/ui/FormFields';

export default ({
  components,
  form,
  offer,
}: {
  components: { Image: typeof React.Component },
  form: FormPropsType,
  offer?: IOffer,
}) => {
  const pathTop = get(offer, 'settingsBranding.logoTop.path', undefined) as string | undefined;
  const pathBottom = get(offer, 'settingsBranding.logoBottom.path', undefined) as string | undefined;
  const pathAppIcon = get(offer, 'settingsBranding.appIcon.path', undefined) as string | undefined;
  const pathSplashLogoTop = get(offer, 'settingsBranding.splashLogoTop.path', undefined) as string | undefined;
  const pathSplashLogoBottom = get(offer, 'settingsBranding.splashLogoBottom.path', undefined) as string | undefined;
  return (
    <React.Fragment>
      <components.Image
        name="logoTop"
        path={pathTop}
        form={form}
        minWidth={200}
        minHeight={40}
        maxWidth={600}
        maxHeight={120}
        imageWidth={200}
        imageHeight={40}
      />
      <components.Image
        name="logoBottom"
        path={pathBottom}
        form={form}
        width={364}
        height={76}
        imageWidth={364}
        imageHeight={76}
      />
      <components.Image
        name="appIcon"
        path={pathAppIcon}
        form={form}
        width={1024}
        height={1024}
        imageWidth={100}
        imageHeight={100}
      />
      <components.Image
        name="splashLogoTop"
        path={pathSplashLogoTop}
        form={form}
        width={318}
        height={209}
        imageWidth={318}
        imageHeight={209}
      />
      <components.Image
        name="splashLogoBottom"
        path={pathSplashLogoBottom}
        form={form}
        width={318}
        height={209}
        imageWidth={318}
        imageHeight={209}
      />
    </React.Fragment>
  );
};
