import { get } from 'lodash';

export default function field(obj: object | undefined | null, name: string, def: any) {
  if (obj) {
    const value = get(obj, name, null);
    if (Array.isArray(value)) {
      return value;
    }
    return value === null ? def : value;
  }
  return def;
}
