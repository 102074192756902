import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/offers/consts';
import { IOffer } from 'features/offers/types';
import OfferForm from '../TabEdit/OfferForm';
import { makeOfferUrl } from '../lib/url';

interface IPageCreateProps extends WithTranslation {
  offer: IOffer;
}

interface IPageCreateState {
  id?: string;
}

class PageCreate extends React.Component<IPageCreateProps, IPageCreateState> {
  render() {
    const { t } = this.props;

    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('new_offer')}
        crumbs={[{ link: makeOfferUrl(), name: t('offers') }]}
      >
        <OfferForm />
      </CardPage>
    );
  }
}

export default withTranslation('offers')(PageCreate);
