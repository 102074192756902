import React from 'react';
import { set } from 'lodash';
import 'spinkit/css/spinkit.css';

export default ({
  width,
  height,
  isInline,
}: {
  width?: number;
  height?: number;
  isInline?: boolean;
}) => {
  const style = {};
  if (width && height) {
    set(style, 'width', width);
    set(style, 'height', width);
  }
  if (isInline) {
    set(style, 'margin', '0');
  }
  return (
    <div className="sk-circle" style={style}>
      <div className="sk-circle1 sk-child" />
      <div className="sk-circle2 sk-child" />
      <div className="sk-circle3 sk-child" />
      <div className="sk-circle4 sk-child" />
      <div className="sk-circle5 sk-child" />
      <div className="sk-circle6 sk-child" />
      <div className="sk-circle7 sk-child" />
      <div className="sk-circle8 sk-child" />
      <div className="sk-circle9 sk-child" />
      <div className="sk-circle10 sk-child" />
      <div className="sk-circle11 sk-child" />
      <div className="sk-circle12 sk-child" />
    </div>
  );
};
