import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import * as Yup from 'yup';
import filterChangedValues from 'lib/filterChangedValues';
import field from 'lib/field';
import { crudMutate } from 'features/common/helpers';
import { minValidator, stringValidator } from 'lib/validators';
import BaseSubscriptionForm from './BaseSubscriptionForm';
import createSubscriptionMutation from '../../queries/createSubscription.gql';
import editSubscriptionMutation from '../../queries/editSubscription.gql';
import { makeSubscriptionUrl } from '../url';
import { ISubscription } from '../../types';

interface ISubscriptionFormProps extends WithTranslation {
  subscription?: ISubscription;
  isCreateForm?: boolean;
}

class SubscriptionForm extends React.Component<ISubscriptionFormProps> {
  getScheme() {
    const {
      t,
    } = this.props;
    const required = true;
    const validators: any = {
      name: stringValidator({ required, field: t('name') }),
      cost: minValidator({ field: t('cost'), min: 1, required: true }),
      expire_days: minValidator({ field: t('expire_days'), min: 1, required: true }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: ISubscription) {
    const formData = this.buildFormData();
    return filterChangedValues(formData, values);
  }

  onSubmit = (
    values: ISubscription,
    formActions: FormikActions<ISubscription>,
  ) => {
    let subscription = { ...values };
    let id;
    if (this.props.subscription) {
      subscription = this.getChangedValues(values);
      id = this.props.subscription.id;
    }

    crudMutate({
      id,
      formActions,
      variables: id ? { id, subscription } : { subscription },
      mutation: id ? editSubscriptionMutation : createSubscriptionMutation,
      redirect: makeSubscriptionUrl(),
      check: !!Object.keys(subscription).length,
    });
  };

  buildFormData() {
    const { subscription } = this.props;
    return {
      name: field(subscription, 'name', ''),
      title: field(subscription, 'title', ''),
      cost: field(subscription, 'cost', 0),
      old_cost: field(subscription, 'old_cost', 0),
      have_trial: field(subscription, 'have_trial', false),
      trial_days: field(subscription, 'trial_days', 0),
      expire_days: field(subscription, 'expire_days', 0),
    };
  }

  render() {
    const { subscription } = this.props;
    return (
      <Formik
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getScheme()}
      >
        {(props: FormikProps<ISubscription>) => <BaseSubscriptionForm {...props} subscription={subscription} />}
      </Formik>
    );
  }
}

export default withTranslation('subscriptions')(SubscriptionForm);
