import React from 'react';
import { Link } from 'react-router-dom';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { IInfoRowOptions } from './types';

export const InfoRowLink = (
  ({
    label,
    value,
    id,
    text,
    options,
    target,
  }: {
    label: string,
    value: string | null | undefined,
    id?: string,
    options?: IInfoRowOptions,
    text?: string,
    target?: string,
  }) => {
    if (value === null || value === undefined) {
      return <InfoRowNotSet label={label} options={options} />;
    }
    if (/^http/.test(value)) {
      return (
        <InfoRow label={label} {...options}>
        <a href={value} target={target}>
          {text === undefined ? value : text}
        </a>
        </InfoRow>
      );
    }
    const idComponent = id ? <b>{id}</b> : null;
    return (
      <InfoRow label={label} {...options}>
        <Link
          to={value}
          target={target}
        >
          {idComponent} {text === undefined ? value : text}
        </Link>
      </InfoRow>
    );
  }
);
