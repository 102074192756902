import React from 'react';
import { Alert } from 'reactstrap';
import { get } from 'lodash';
import gql from 'graphql-tag';
import {
    WithTranslation,
    withTranslation,
} from 'react-i18next';
import { client } from 'features/graphql';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import LinkToItem from 'features/items/lib/LinkToItem';
import { makeCategoryUrlTo } from 'features/categories/lib/url';
import { IBanner } from '../../types';

const NOT_LOADED = 'NOT_LOADED';
const LOADING = 'LOADING';
const LOADED = 'LOADED';

const getCreatorQuery = gql`
  query getCreatorsForSelect($id: [ID], $pageNum: Int, $perPage: Int, $type: String, $searchByFields: CreatorSearchByFields) {
    CreatorQuery(
      id: $id,
      page: $pageNum,
      perPage: $perPage,
      filterByFields: { type: { operator: "=", value: $type } },
      searchByFields: $searchByFields,
    ) {
      items {
        id
        full_name
        short_name
      }
    }
  }
`;

const getCategoryQuery = gql`
  query getCategoryForBannerView($id: [ID]) {
    CategoryQuery(id: $id) {
      items {
        id
        name
      }
    }
  }
`;

const getItemQuery = gql`
  query getItemForBannerView($id: [ID]) {
    ItemQuery(id: $id) {
      items {
        id
        name
        type
      }
    }
  }
`;

interface IResourceLinkProps extends WithTranslation {
  banner: IBanner;
}

interface IResourceLinkState {
  resourceQueryStatus: string;
  resourceLink: string;
  resourceType: string;
  resourceComponent?: () => JSX.Element;
}

class ResourceLink extends React.Component<IResourceLinkProps, IResourceLinkState> {
  static getDerivedStateFromProps(props: IResourceLinkProps, state: IResourceLinkState) {
    if (
      props.banner.resource_link === state.resourceLink &&
      props.banner.resource_type === state.resourceType
    ) {
      return state;
    }
    return {
      resourceQueryStatus: NOT_LOADED,
      resourceLink: props.banner.resource_link,
      resourceType: props.banner.resource_type,
    };
  }

  private _isCanceled = false;

  constructor(props: IResourceLinkProps) {
    super(props);
    this.state = {
      resourceQueryStatus: NOT_LOADED,
      resourceLink: props.banner.resource_link,
      resourceType: props.banner.resource_type,
    };
  }

  componentDidMount() {
    this._isCanceled = false;
    this.handleResourceLink();
  }

  componentDidUpdate() {
    this.handleResourceLink();
  }

  componentWillUnmount() {
    this._isCanceled = true;
  }

  handleResourceLinkError() {
    if (this._isCanceled) return;
    this.setState({
      resourceQueryStatus: LOADED,
      resourceComponent: () => {
        const { t } = this.props;
        return (
          <Alert color="danger">
            {t('errors:can_not_create_inner_link')}.
            &nbsp;
            {t('errors:data_is_not_found')}.
          </Alert>
        );
      },
    });
  }

  handleResourceLinkItem() {
    const { banner } = this.props;
    client.query({
      query: getItemQuery,
      variables: { id: banner.resource_link },
    }).then((data: any) => {
      if (this._isCanceled) return;
      const item = get(data, 'data.ItemQuery.items[0]', null);
      if (item) {
        this.setState({
          resourceQueryStatus: LOADED,
          resourceComponent: () => <LinkToItem value={item} action="view" />,
        });
      } else {
        this.handleResourceLinkError();
      }
    }).catch(() => this.handleResourceLinkError());
  }

  handleResourceLinkCreator() {
    const { banner } = this.props;
    client.query({
      query: getCreatorQuery,
      variables: { id: banner.resource_link },
    }).then((data: any) => {
      const creator = get(data, 'data.CreatorQuery.items[0]');
      if (creator) {
        this.setState({
          resourceQueryStatus: LOADED,
          resourceComponent: () => (
            <LinkToEntityInLists
              to={`/creator/${creator.id}`}
              action="view"
              id={creator.id}
              name={`${creator.full_name}`}
            />),
        });
      } else {
        this.handleResourceLinkError();
      }
    }).catch(() => this.handleResourceLinkError());
  }

  handleResourceLink() {
    if (this._isCanceled) return;
    const {
      banner,
      t,
    } = this.props;
    if (this.state.resourceQueryStatus === NOT_LOADED && !!banner.id) {
      const {
        resource_link,
        resource_type,
      } = banner;
      this.setState({ resourceQueryStatus: LOADING }, () => {
        if (resource_type === 'category') {
          this.handleResourceLinkCategory();
        }
        if (resource_type === 'item') {
          this.handleResourceLinkItem();
        }
        if (resource_type === 'creator') {
          this.handleResourceLinkCreator();
        }
        if (resource_type === 'url') {
          if (this._isCanceled) return;
          let link = resource_link;
          if (!/^http/.test(resource_link)) {
            link = `https://${link}`;
          }
          this.setState({
            resourceQueryStatus: LOADED,
            resourceComponent: () => (
              <a href={link} target="_blank">
                [{t('dict:external_link')}] {link}
              </a>
            ),
          });
        }
      });
    }
  }

  handleResourceLinkCategory() {
    const { banner } = this.props;
    client.query({
      query: getCategoryQuery,
      variables: { id: banner.resource_link },
    }).then((data: any) => {
      if (this._isCanceled) return;
      const category = get(data, 'data.CategoryQuery.items[0]', null);
      if (category) {
        this.setState({
          resourceQueryStatus: LOADED,
          resourceComponent: () => (
            <LinkToEntityInLists
              to={makeCategoryUrlTo(category, 'view')}
              id={category.id}
              name={category.name}
            />
          ),
        });
      } else {
        this.handleResourceLinkError();
      }
    }).catch(() => this.handleResourceLinkError());
  }

  render() {
    const { resourceComponent } = this.state;
    if (resourceComponent) {
      return resourceComponent();
    }
    return null;
  }
}

export default withTranslation('categories')(ResourceLink);
