import React from 'react';
import moment from 'moment';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { InfoRowText } from './InfoRowText';

interface IDate {
  updated_at: string;
  created_at: string;
}

export const InfoRowDateBlock = withTranslation('dict')(
  ({ t, value }: WithTranslation & { value: IDate }) => {
    return (
      <React.Fragment>
        <InfoRowText label={t('updated')} value={moment(value.updated_at).format('lll')} />
        <InfoRowText label={t('created')} value={moment(value.created_at).format('lll')} />
      </React.Fragment>
    );
  },
);
