import React from 'react';
import moment from 'moment';
import i18n from 'features/intl/i18n';
import { filterInputHandler } from 'features/common';

import OffersSelect from 'features/offers/lib/OffersSelect';
import { IOffer } from 'features/types';
import DeleteButton from 'features/ui/DeleteButton';
import { client } from 'features/graphql';
import { toast } from 'features/ui/Toast';
import { Confirm } from 'features/ui/Modal';
import { RowRenderProps } from 'react-table';
import { toNumber } from 'lodash';
import ListOfOffers from 'features/offers/lib/ListOfOffers';
import deletePushMutation from '../../queries/deletePushMutation.gql';

const CreateModal = ({ status, id }: { status: string, id: ID }) => {
  const [isModal, setIsModal] = React.useState(false);
  const handleClick = () => {
    setIsModal(true);
  };
  const onDelete = () => {
    client.mutate({
      mutation: deletePushMutation,
      variables: {
        id: toNumber(id),
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        toast.error(i18n.t('dict:error'));
      });
  };

  if (status !== 'pending') {
    return null;
  }
  return (
    <>
      <DeleteButton onClick={handleClick} />
      <Confirm
        isOpened={isModal}
        title={i18n.t('pushes:delete_push')}
        description={i18n.t('pushes:delete_push', { id })}
        onClose={() => setIsModal(false)}
        onResolve={onDelete}
        type="danger"
      />
    </>
  );
};

export default () => ([
  {
    accessor: 'title',
    Header: 'Заголовок',
    Filter: filterInputHandler,
  },
  {
    accessor: 'message',
    Header: 'Сообщение',
    filterable: false,
  },
  {
    accessor: 'status',
    Cell: ({ value }: { value: string }) => i18n.t(`pushes:${value}`),
    Header: 'Статус',
    maxWidth: 200,
    filterable: false,
  },
  {
    accessor: 'created_at',
    Cell: ({ row }: { row: { created_at: string } }) => moment(row.created_at).format('lll'),
    Header: 'Дата создания',
    maxWidth: 200,
    filterable: false,
  },
  {
    id: 'offers',
    accessor: 'offers',
    Header: 'Предложения',
    maxWidth: 250,
    Cell: ({ row }: { row: { offers: IOffer[] } }) => <ListOfOffers offers={row.offers} />,
    Filter: ({ filter, onChange }: any) => (
      <OffersSelect
        onChange={onChange}
        value={filter && filter.value}
        isMulti={true}
      />
    ),
  },
  {
    maxWidth: 50,
    id: 'delete',
    filterable: false,
    accessor: (row: RowRenderProps) => row,
    Cell: ({ value }: RowRenderProps) => <CreateModal status={value.status} id={value.id} />,
  },
]);
