import React from 'react';
import { get } from 'lodash';
import {
  Input,
  Table,
} from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikErrors } from 'formik';
import Switch from 'features/ui/Switch';
import DeleteButton from 'features/ui/DeleteButton';
import AddButton from 'features/ui/AddButton';
import IconButton from 'features/ui/IconButton';
import { IOfferSettingsBuildExternalFormValues } from '../types';

interface IExternalsTableProps extends WithTranslation {
  externals: IOfferSettingsBuildExternalFormValues[];
  errors?: Array<FormikErrors<IOfferSettingsBuildExternalFormValues | undefined>>;
  onChange?: (values: IOfferSettingsBuildExternalFormValues[]) => void;
}

interface IExternalsTableState {
  externals: IOfferSettingsBuildExternalFormValues[];
}

class ExternalsTable extends React.Component<IExternalsTableProps, IExternalsTableState> {
  constructor(props: IExternalsTableProps) {
    super(props);
    this.state = {
      externals: props.externals.map(row => ({ ...row })),
    };
  }

  initialExternal() {
    return {
      platform: 'android',
      type: '',
      enabled: true,
      key: '',
      secret: '',
      domain: '',
    };
  }

  copyStateExternals() {
    return this.state.externals.map(row => ({ ...row }));
  }

  onChange = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(this.copyStateExternals());
    }
  }

  onDeleteRow = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const idStr = e.currentTarget.getAttribute('data-id');
    if (idStr) {
      const id = parseInt(idStr, 10);
      this.setState(
        {
          externals: this.state.externals.filter((elem, order) => id !== order),
        },
        this.onChange,
      );
    }
  }

  onChangeInput = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const idStr = e.currentTarget.getAttribute('data-id');
    const {
      name,
      value,
    } = e.currentTarget;
    if (idStr) {
      const id = parseInt(idStr, 10);
      const externals = this.copyStateExternals();
      externals[id][name] = value;
      this.setState({ externals }, this.onChange);
    }
  }

  onChangeCheckbox = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const idStr = e.currentTarget.getAttribute('data-id');
    const {
      name,
      checked,
    } = e.currentTarget;
    if (idStr) {
      const id = parseInt(idStr, 10);
      const externals = this.copyStateExternals();
      externals[id][name] = checked;
      this.setState({ externals }, this.onChange);
    }
  }

  onCopyRow = (e: React.MouseEvent<any>) => {
    const { externals } = this.state;
    const idStr = e.currentTarget.getAttribute('data-id');
    if (idStr) {
      this.setState(
        {
          externals: [
            ...externals,
            { ...externals[parseInt(idStr, 10)] },
          ],
        },
        this.onChange,
      );
    }
  }

  onAddRow = () => {
    const {
      externals,
    } = this.state;
    this.setState(
      {
        externals: [
          ...externals,
          { ...this.initialExternal() },
        ],
      },
      this.onChange,
    );
  }

  renderRow(row: IOfferSettingsBuildExternalFormValues, id: number) {
    const { errors } = this.props;
    return (
      <tr key={id}>
        <td>
          <Input
            type="select"
            name="platform"
            value={row.platform}
            data-id={id}
            onChange={this.onChangeInput}
          >
            <option value="android">android</option>
            <option value="ios">ios</option>
          </Input>
        </td>
        <td>
          <Input
            type="text"
            name="type"
            value={row.type}
            data-id={id}
            invalid={Boolean(get(errors, `[${id}].type`, false))}
            onChange={this.onChangeInput}
          />
        </td>
        <td>
          <Input
            type="text"
            name="key"
            value={row.key}
            data-id={id}
            onChange={this.onChangeInput}
          />
        </td>
        <td>
          <Input
            type="text"
            name="secret"
            value={row.secret}
            data-id={id}
            onChange={this.onChangeInput}
          />
        </td>
        <td>
          <Input
            type="text"
            name="domain"
            value={row.domain}
            data-id={id}
            onChange={this.onChangeInput}
          />
        </td>
        <td>
          <Switch
            name="enabled"
            checked={row.enabled}
            data-id={id}
            onChange={this.onChangeCheckbox}
          />
        </td>
        <td>
          <IconButton
            icon="fa fa-copy"
            type="button"
            data-id={id}
            onClick={this.onCopyRow}
          />
        </td>
        <td>
          <DeleteButton
            type="button"
            data-id={id}
            onClick={this.onDeleteRow}
          />
        </td>
      </tr>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <Table size="sm">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>{t('build_externals_type')}</th>
            <th>{t('build_externals_key')}</th>
            <th>{t('build_externals_secret')}</th>
            <th>{t('build_externals_domain')}</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>
              <AddButton
                type="button"
                onClick={this.onAddRow}
                title={t('dict:add_row')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.externals.map((row, id: number) => this.renderRow(row, id))}
        </tbody>
      </Table>
    );
  }
}

export default withTranslation('offers')(ExternalsTable);
