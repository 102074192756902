import React from 'react';
import IconButton from 'features/ui/IconButton';
import i18n from 'features/intl/i18n';

type AddButtonProps = React.HTMLProps<HTMLButtonElement> & {
  caption?: string;
};

export default (props: AddButtonProps) => {
  return (
    <IconButton
      title={i18n.t('dict:add')}
      {...props}
      icon={'icon-plus icons font-lg'}
    />
  );
};
