import React from 'react';
import { Link } from 'react-router-dom';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { toast } from 'features/ui/Toast';
import {
  difference,
  flow,
  get,
  union,
} from 'lodash';
import { client } from 'features/graphql';
import deepOmit from 'lib/deepOmit';
import { makeItemUrl } from 'features/items/lib/url';
import ItemsByCreatorsSelect from 'features/offers/TabItems/ItemsByCreatorsSelect';
import ItemsIdsTextarea from 'features/items/lib/ItemsIdsTextarea';
import {
  TYPE_PROVIDER,
  TYPE_PUBLISHER,
} from 'features/creators/consts';

import getOfferForTabItems from 'features/offers/TabItems/getOfferForTabItems.gql';
import editOfferMutation from 'features/offers/queries/editOffer.gql';

import {
  ICreator,
  IItem,
  IItemQueryResponse,
  IListQueryVariables,
  IOffer,
} from 'features/types';
import { IOfferQueryResponse } from '../types';
import { IAmountMap } from './types';

interface ICreators {
  [key: string]: Set<number>;
}

interface ITabItemsProps extends WithTranslation {
  offer: IOffer;
  creators: ICreators;
  creatorsCounts: IAmountMap;
  creatorsCountsAll: IAmountMap;
  items: number[];
  isLoading: boolean;
  refetch: () => Promise<IItemQueryResponse>;
}

class TabItems extends React.Component<ITabItemsProps, {}> {
  handleAddItems = (newIds: number[]) => {
    const {
      t,
      items,
      offer,
      refetch,
    } = this.props;
    const ids = union(items, newIds);
    const amount = ids.length - items.length;

    return client.mutate({
      mutation: editOfferMutation,
      context: { isGlobalLoading: true },
      variables: {
        id: offer.id,
        offer: { items: ids },
      },
    }).then(() => {
      toast.info(t('forms:amount_of_added_records', { amount }));
      return refetch();
    }).catch((result) => {
      const errorText = get(result, 'graphQLErrors[0].validation["offer.items"][0]', null);
      if (errorText) {
        toast.error(errorText);
      }
      return result;
    });
  };

  handleRemoveItems = (removeIds: number[]) => {
    const {
      t,
      items,
      offer,
      refetch,
    } = this.props;

    const ids = difference(items, removeIds);
    const removedIds = difference(items, ids);
    const amount = removedIds.length;

    return client.mutate({
      mutation: editOfferMutation,
      context: { isGlobalLoading: true },
      variables: {
        id: offer.id,
        offer: { items: ids },
      },
    }).then(() => {
      toast.info(t('forms:amount_of_deleted_records', { amount }));
      return refetch();
    });
  };

  render() {
    const {
      creators,
      creatorsCounts,
      creatorsCountsAll,
      offer,
      isLoading,
      t,
    } = this.props;

    if (isLoading) {
      return null;
    }

    return (
      <React.Fragment>
        <Link to={makeItemUrl({ query: { offers: offer.id } })}>
          {t('go_to_items')}
        </Link>
        <br /><br />
        <ItemsIdsTextarea
          onAdd={this.handleAddItems}
          onRemove={this.handleRemoveItems}
        />
        <br /><br />
        <h3>Издательства</h3>
        <ItemsByCreatorsSelect
          value={Array.from(creators[TYPE_PUBLISHER])}
          type={TYPE_PUBLISHER}
          creatorsCounts={creatorsCounts}
          creatorsCountsAll={creatorsCountsAll}
          onAdd={this.handleAddItems}
          onRemove={this.handleRemoveItems}
        />
        <br /><br />
        <h3>Провайдеры</h3>
        <ItemsByCreatorsSelect
          value={Array.from(creators[TYPE_PROVIDER])}
          type={TYPE_PROVIDER}
          creatorsCounts={creatorsCounts}
          creatorsCountsAll={creatorsCountsAll}
          onAdd={this.handleAddItems}
          onRemove={this.handleRemoveItems}
        />
      </React.Fragment>
    );
  }
}

const mapResultsToProps = ({ data, ownProps }: OptionProps<{}, IOfferQueryResponse, IListQueryVariables>) => {
  const isValidCreator = (type: string) => [TYPE_PUBLISHER, TYPE_PROVIDER].includes(type);
  const defaultCreators = {
    [TYPE_PUBLISHER]: new Set(),
    [TYPE_PROVIDER]: new Set(),
  };
  const creatorsCounts: IAmountMap = {};
  if (!data || !data.OfferQuery) {
    return {
      creatorsCounts,
      items: [],
      creators: defaultCreators,
      isLoading: data ? data.loading : false,
      ...ownProps,
    };
  }
  const itemsData = deepOmit(data.OfferQuery.items[0].items, '__typename');
  const items = itemsData.map((item: IItem) => +item.id);
  const creators = itemsData.reduce(
    (acc: ICreators, item: IItem) => {
      item.creators!.forEach((creator) => {
        if (creatorsCounts[creator.id]) {
          creatorsCounts[creator.id] += 1;
        } else {
          creatorsCounts[creator.id] = 1;
        }
        if (isValidCreator(creator.type)) {
          acc[creator.type].add(+creator.id);
        }
      });
      return acc;
    },
    defaultCreators,
  );
  const creatorsCountsAll = data
    .OfferQuery
    .items[0]
    ._calc_CreatorsViaItems
    .reduce(
      (acc: any, creator: ICreator) => {
        if (!creator) {
          return acc;
        }
        if (isValidCreator(creator.type) && creator.items) {
          creator.items.forEach((item: any) => {
            if (creator.id in acc) {
              acc[creator.id] += 1;
            } else {
              acc[creator.id] = 1;
            }
          });
        }
        return acc;
      },
      {},
    );
  return {
    items,
    creators,
    creatorsCounts,
    creatorsCountsAll,
    isLoading: data.loading,
    refetch: data.refetch,
    ...ownProps,
  };
};

export default flow([
  graphql(getOfferForTabItems, {
    props: mapResultsToProps,
    options: ({ offer }: { offer: IOffer }) => {
      const { id } = offer;
      return {
        errorPolicy: 'all',
        context: { isGlobalLoading: true },
        variables: { id },
      };
    },
  }),
  withTranslation('offers'),
])(TabItems);
