import React from 'react';
import { components } from 'react-select';
import { SingleValueProps } from 'react-select/lib/components/SingleValue';
import { MultiValueGenericProps } from 'react-select/lib/components/MultiValue';
import { itemTypeTranslator } from 'features/ui/ReactSelect/helpers';
import ReactSelect, { IReactSelectProps } from './ReactSelect';

import styles from './styles.module.scss';

export interface IOptionType {
  image?: string;
  label: string;
  value: number;
  type: string;
}

export default class ReactSelectWithId extends React.PureComponent<IReactSelectProps> {
  static STYLES = {
    singleValue: (style: any) => ({
      ...style,
      width: '100%',
      paddingRight: 10,
    }),
  };

  renderImage = (image?: string) => {
    if (image) {
      return (
        <img
          className={styles.optionImage}
          src={image}
          alt={image}
        />
      );
    }
    return <div className={styles.optionImage} />;
  };

  renderImageBlock = (data: IOptionType) => {
    const { image } = data;
    if (image === undefined) {
      return null;
    }
    return (
      <React.Fragment>
        <span className={styles.optionSpliter} />
        {this.renderImage(image)}
      </React.Fragment>
    );
  };

  renderComponent = (data: IOptionType) => {
    const { responsive } = this.props;
    const {
      label,
      value,
      type,
    } = data;
    return (
      <div className={[styles.optionRoot, responsive ? styles.optionRootResponsive : null].join(' ')}>
        <span className={styles.optionId}>{value}</span>
        <span className={styles.optionSpliter} />
        <span className={styles.optionLabel}>{label}</span>
        {this.renderImageBlock(data)}
        {type ? (
          <>
            <span className={styles.optionSpliter} />
            <span className={styles.optionId}>{itemTypeTranslator(type)}</span>
          </>
        ) : null }
      </div>
    );
  };

  Option = (props: any) => (
    <components.Option {...props}>
      {this.renderComponent(props.data)}
    </components.Option>
  );

  SingleValue = (props: SingleValueProps<IOptionType>) => {
    const {
      children,
      getValue,
    } = props;
    const value = getValue();
    let renderedComponent: any = children;
    if (Array.isArray(value) && value.length) {
      const data = value[0];
      renderedComponent = this.renderComponent(data);
    }
    return (
      <div title={props.data.label}>
        <components.SingleValue {...props}>
          {renderedComponent}
        </components.SingleValue>
      </div>
    );
  };

  MultiValueLabel = (props: MultiValueGenericProps<IOptionType>) => {
    const {
      label,
      value,
      type,
    } = props.data;
    return (
      <components.MultiValueLabel {...props}>
        <b>{value}</b> | {label} {type ? `| ${itemTypeTranslator(type)}` : null}
      </components.MultiValueLabel>
    );
  };

  render() {
    const renderedComponents = Object.assign(
      {
        Option: this.Option,
        SingleValue: this.SingleValue,
        MultiValueLabel: this.MultiValueLabel,
      },
      this.props.renderedComponents,
    );
    return (
      <ReactSelect
        {...this.props}
        styles={ReactSelectWithId.STYLES}
        components={renderedComponents}
      />
    );
  }
}
