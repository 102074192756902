import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { toast } from 'features/ui/Toast';
import AuthService from 'features/auth/AuthService';

interface ILoginState {
  isLogged: boolean;
}

class Login extends React.PureComponent<{}, ILoginState> {
  private readonly loginRef: React.RefObject<HTMLInputElement>;
  private readonly passwordRef: React.RefObject<HTMLInputElement>;
  private isComponentMounted: boolean;

  constructor(props: {}) {
    super(props);

    this.state = { isLogged: AuthService.isLogged() };

    this.loginRef = React.createRef();
    this.passwordRef = React.createRef();
    this.isComponentMounted = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    if (AuthService.isLogged()) {
      this.setState({ isLogged: true });
      return;
    }

    const login = this.loginRef.current ? this.loginRef.current.value : null;
    const password = this.passwordRef.current ? this.passwordRef.current.value : null;

    if (!login) {
      toast.error('Пожалуйста, заполните поле логина');
      return;
    }

    if (!password) {
      toast.error('Пожалуйста, заполните поле пароля');
      return;
    }

    if (!AuthService.isLogged()) {
      AuthService.login(login, password).then((res) => {
        if (res && this.isComponentMounted) {
          this.setState({ isLogged: true });
        }
      });
    }
  };

  render() {
    const { isLogged } = this.state;

    if (isLogged) {
      return <Redirect to="/" />;
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup className="mb-4">
                <Card className="p-4">
                  <CardBody>
                    <Form
                      onSubmit={this.onSubmit}
                      data-test="login-form"
                    >
                      <h1>Вход</h1>
                      <p className="text-muted">Авторизуйтесь, пожалуйста:</p>
                      <InputGroup className="mb-3">
                        <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon-user" />
                        </span>
                        </div>
                        <Input
                          type="text"
                          placeholder="Email"
                          innerRef={this.loginRef}
                          data-test="email"
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon-lock" />
                        </span>
                        </div>
                        <Input
                          type="password"
                          placeholder="Пароль"
                          innerRef={this.passwordRef}
                          data-test="password"
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4">Войти</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
