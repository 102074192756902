import React from 'react';
import {
  Button,
  Col,
  Row,
} from 'reactstrap';
import { FormikProps } from 'formik';
import FileUploader from 'features/ui/FileUploader';
import FormFrame from 'features/ui/FormFrame';
import FormField from 'features/autoimport/lib/Form/FormField';
import { APP_URL } from 'features/app/consts';

interface IBaseFormProps extends FormikProps<{}> {
  validated: boolean;
}

interface IBaseFormState {
  validated: boolean;
}

class BaseForm extends React.PureComponent<IBaseFormProps, IBaseFormState> {
  state = {
    validated: false,
  };

  componentDidUpdate(prevProps: IBaseFormProps) {
    if (this.props.validated !== prevProps.validated) {
      this.setState({
        validated: this.props.validated,
      });
    }
  }

  onSubmit = (upload: boolean) => (e: any) => {
    e.preventDefault();
    const {
      validateForm,
      setFieldValue,
      submitForm,
    } = this.props;
    setFieldValue('upload', upload);
    validateForm().then(submitForm);
    return false;
  };

  onChangeFileContent = (data: ISyntheticEventTarget<HTMLInputElement>) => {
    if (data.target.validity.valid) {
      this.props.setFieldValue('content', data.target.files![0]);
    }
  };

  onChangeFileData = (data: ISyntheticEventTarget<HTMLInputElement>) => {
    this.setState({ validated: false });
    if (data.target.validity.valid) {
      this.props.setFieldValue('data', data.target.files![0]);
    }
  };

  renderButton() {
    const { isSubmitting } = this.props;
    const { validated } = this.state;
    if (validated) {
      return <Button type="submit" color="success" disabled={true}>Проверено</Button>;
    }
    return <Button type="submit" color="warning" disabled={isSubmitting} onClick={this.onSubmit(false)}>Проверить</Button>;
  }

  render() {
    const { isSubmitting } = this.props;

    return (
      <FormFrame
        {...this.props}
        submitBtn={false}
      >
        <Row>
          <FormField label="Архив с книгами" id="content">
            &nbsp;
            (<a href={`${APP_URL}/defaults/autoimport/autoimport-content.zip`}>Пример файла</a>)
            <FileUploader
              id="content"
              name="content"
              onChange={this.onChangeFileContent}
            />
          </FormField>

          <FormField label="XLS-файл" id="data">
            &nbsp;
            (<a href={`${APP_URL}/defaults/autoimport/autoimport-data.xlsx`}>Пример файла</a>)
            <FileUploader
              id="data"
              name="data"
              onChange={this.onChangeFileData}
            />
          </FormField>
        </Row>
        <Row>
          <Col md="3">
            <Button type="submit" color="primary" disabled={isSubmitting} onClick={this.onSubmit(true)}>Залить</Button>
          </Col>
          <Col md="3">
            {this.renderButton()}
          </Col>
        </Row>
      </FormFrame>
    );
  }
}

export default BaseForm;
