import React, { Component } from 'react';
import { match as IMatch } from 'react-router';
import {
    WithTranslation,
    withTranslation,
} from 'react-i18next';
import { History } from 'history';
import { set } from 'lodash';
import moment from 'moment';
import IndexTableBase, {
  IControlFields,
} from 'features/common/IndexTableBase';
import CardPage from 'features/ui/Page/CardPage';
import { makeOfferUrl } from '../lib/url';
import getOffersQuery from '../queries/getOffers.gql';
import { makeOffersColumns } from './columns';
import { DATETIME_FORMAT_DEFAULT } from 'features/app/consts';

interface IOffersTableProps extends WithTranslation {
  data: any;
  fetchMore: any;
  isLoading: boolean;
  items: any;
  history: History;
  match: IMatch<object>;
  pagination: any;
}

class OffersTable extends Component<IOffersTableProps> {
  private _columns: object[];

  constructor(props: IOffersTableProps) {
    super(props);
    const { t } = props;
    this._columns = makeOffersColumns(t);
  }

  prepareRequestData(variables: any, fields: IControlFields) {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.name) {
      set(variables, 'filterByFields.name', {
        operator: 'like',
        value: fields.name,
      });
    }
    if (fields.prefix) {
      set(variables, 'filterByFields.prefix', {
        operator: 'like',
        value: fields.prefix,
      });
    }
    if (fields.expire_date) {
      set(variables, 'filterByFields.expire_date', {
        operator: '<=',
        value: moment(fields.expire_date).format(DATETIME_FORMAT_DEFAULT),
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon="icon-layers"
        title={t('offers')}
        buttons={[{ link: '/offer/create', name: t('offers:new_offer') }]}
      >
        <IndexTableBase
          columns={this._columns}
          filterFields={['id', 'name', 'prefix', 'expire_date']}
          sortFields={['expire_date']}
          makeCrudUrl={makeOfferUrl}
          prepareRequestData={this.prepareRequestData}
          query={getOffersQuery}
          queryResultName="OfferQuery"
          showAutoEditIconColumn={true}
          showViewIconColumn={true}
          showAutoIdColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('offers')(OffersTable);
