import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import BaseItemForm from 'features/items/lib/Form/Form';

import { TYPE_ARTICLE } from 'features/items/consts';

import { IFormValues } from 'features/courses/types';
import { IItem } from 'features/types';
import FormRow from 'features/ui/FormRow';
import ContentEditor from 'features/articles/lib/ContentEditor';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IItem;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onContentChange = (value: string) => {
    const { setFieldValue } = this.props;
    setFieldValue('content[0].article.text', value);
  }

  resolveFields = (fields: any) => {
    const { values, t } = this.props;
    fields.splice(5, 0, (
      <FormRow
        key="content"
        label={t('content')}
        id="content"
        errorPath="content|content[0].file"
      >
        <ContentEditor onContentChange={this.onContentChange} value={values.content[0].article.text} />
      </FormRow>
    ));

    return fields;
  };

  render() {
    return (
      <BaseItemForm
        {...this.props}
        resolveFields={this.resolveFields}
        cancelLink="/articles"
        type={TYPE_ARTICLE}
      />
    );
  }
}

export default withTranslation('article')(BaseForm);
