export const readTextFile = (file: File, handler: (data: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    if (reader.result) {
      handler(reader.result as string);
    }
  });
  reader.readAsText(file);
};

export const readImageFile = (file: File, handler: (image: HTMLImageElement) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', (r) => {
    if (reader.result) {
      const image = new Image();
      image.src = reader.result.toString();
      image.addEventListener('load', () => handler(image));
    }
  });
  reader.readAsDataURL(file);
};
