import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import {
  IAutoImport,
  IItem,
  RowRenderProps,
} from 'features/types';

interface IColumnsProps {
  onEnable: (id: number) => void;
  onDisable: (id: number) => void;
}

export default (props: IColumnsProps) => ([
  {
    accessor: 'id',
    Header: 'ID',
    width: 100,
    className: 'text-right',
    filterable: false,
  },
  {
    accessor: 'status',
    Header: 'Статус',
    filterable: false,
  },
  {
    accessor: 'created_at',
    Header: 'Дата создания',
    filterable: false,
  },
  {
    id: 'items',
    accessor: (data: IAutoImport) =>
      data.items!.map(item => (
        <div key={item.id}>
          <Link to={`/${item.type}/edit/${item.id}`}>
            {`${item.type}.${item.id} ${item.name}`}
          </Link>
        </div>
      )),
    Header: 'Итемы',
    expanded: true,
    expandable: true,
    maxWidth: 400,
    filterable: false,
  },
  {
    id: 'dataFile',
    accessor: (data: IAutoImport) => {
      const path = get(data, 'dataFile.path', null);
      if (path) {
        return (
          <a href={path} className="btn btn-link">
            <i className="fa fa-file-o fa-lg"/>
          </a>
        );
      }
      return null;
    },
    Header: 'Файл',
    className: 'text-center',
    filterable: false,
    maxWidth: 60,
  },
  {
    id: 'actionsColumn',
    accessor: (data: IAutoImport) => data,
    Header: '',
    maxWidth: 200,
    filterable: false,
    Cell: (row: RowRenderProps) => {
      const hasActiveItems = row.value.items.filter((item: IItem) => item.is_active).length;
      const hasNonActiveItems = row.value.items.filter((item: IItem) => !item.is_active).length;
      return (
        <React.Fragment>
          <Button
            className="btn btn-success"
            onClick={() => props.onEnable(row.value.id)}
            disabled={!hasNonActiveItems}
            title={!hasNonActiveItems ? 'Все итемы уже включены' : 'Включить все итемы'}
          >
            Включить
          </Button>
          <Button
            className="btn btn-danger"
            onClick={() => props.onDisable(row.value.id)}
            disabled={!hasActiveItems}
            title={!hasActiveItems ? 'Все итемы уже выключены' : 'Выключить все итемы'}
          >
            Выключить
          </Button>
        </React.Fragment>
      );
    },
  },
]);
