import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';

interface IDNDStagesProps {
  items: Array<{
    id: string,
    [key: string]: any,
  }>;
  children: React.ReactElement;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  childPropName: string;
}
const DragNDropComponent = ({
  items,
  children,
  onDragEnd,
  childPropName,
}: IDNDStagesProps) => {
  const renderChildren = (field: string, value: any) => React.Children.map(children, child => React.cloneElement(child, { [field]: value }));

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {provided => (
                  <div
                    className="mb-2"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {renderChildren(childPropName, item)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragNDropComponent;
