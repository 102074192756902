import React from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { minValidator } from 'lib/validators';
import { contentValidator } from 'features/items/lib/validators';
import ItemForm from 'features/items/lib/Form';
import BaseForm from './Form';

import getBookQuery from '../../queries/getBookQuery.gql';

import {
  IFormValues,
} from 'features/books/types';
import {
  IFormValues as IItemFormValues,
  IItemMutationInput,
} from 'features/items/types';
import {
  IItem,
  IYupValidationFields,
} from 'features/types';

interface IBooksFormProps extends WithTranslation {
  data?: IItem;
}

class BooksForm extends React.PureComponent<IBooksFormProps> {
  resolveMutationData = (values: IItemMutationInput) => values;

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: IYupValidationFields) => {
    const {
      t,
      data,
    } = this.props;
    const required = true;
    const isEdit = !!(data && data.id);
    return {
      ...shape,
      content: contentValidator(t, isEdit, {
        ext: '*.epub',
        formats: ['application/epub+zip', 'application/zip', 'application/x-zip-compressed'],
      }),
      book: Yup.object().shape({
        paper_pages: minValidator({
          required,
          min: 0,
          field: t('paper_pages'),
        }),
      }),
    };
  };

  resolveInitialValues = (values: IItemFormValues) => {
    const { data } = this.props;

    const defaultValues = {
      book: {
        isbn: '',
        paper_pages: 0,
        type: 'full',
      },
    };

    return data ? {
      ...defaultValues,
      ...values,
      ...{
        book: {
          isbn: data.book!.isbn || '',
          paper_pages: data.book!.paper_pages || 0,
          type: data.book!.type || 'full',
        },
      },
    } : {
      ...defaultValues,
      ...values,
    };
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="book"
        data={data}
        getItemQuery={getBookQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        renderForm={this.renderForm}
      />
    );
  }
}

export default withTranslation('books')(BooksForm);
