import React from 'react';
import {
  Button,
  Form,
} from 'reactstrap';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import i18n from 'features/intl/i18n';
import flattenKeys from 'lib/flattenKeys';
import FormRow from 'features/ui/FormRow';
import { toast } from 'features/ui/Toast';

interface IFormFrameProps extends FormikProps<{}>, WithTranslation {
  id?: number | string;
  cancelLink?: string;
  submitBtn?: boolean | string;
}

class FormFrame extends React.PureComponent<IFormFrameProps> {
  static defaultProps = {
    submitBtn: i18n.t('dict:save'),
  };

  componentDidUpdate(prevProps: IFormFrameProps) {
    const {
      isValidating,
      isSubmitting,
      errors,
    } = this.props;
    if (isSubmitting && Object.values(errors).length && !isValidating) {
      flattenKeys(errors).forEach((key) => {
        const error = get(errors, key);
        // FIX При валидации элементов массивов может встречаться undefined
        // который говорит что ошибки в этой позиции нет. Но toast не умеет
        // обрабатывать undefined бросая ошибки в dev tools.
        if (error !== undefined) {
          toast.error(error, { autoClose: 7000 });
        }
      });
    }
  }

  renderId() {
    const { id } = this.props;
    if (id) {
      return (
        <FormRow label="ID" id="id">
          <p className="form-control-static">{id}</p>
        </FormRow>
      );
    }
    return null;
  }

  render() {
    const {
      children,
      cancelLink,
      submitBtn,
      t,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <Form
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
      >
        {this.renderId()}
        {children}
        {submitBtn ?
          <div className="form-actions">
            <Button type="submit" color="primary" disabled={isSubmitting}>{submitBtn}</Button>
            {cancelLink ?
              <Link to={cancelLink} className="btn btn-secondary">{t('dict:cancel')}</Link>
              : null}
          </div>
          : null}
      </Form>
    );
  }
}

export default withTranslation()(FormFrame);
