import React from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import filterChangedValues from 'lib/filterChangedValues';
import field from 'lib/field';
import { crudMutate } from 'features/common/helpers';

import editUsersWithNotifications from 'features/offers/queries/editUsersWithNotifications.gql';
import getUsersForNotificationQuery from 'features/offers/queries/getUsersForNotificationQuery.gql';
import getNotifications from 'features/offers/queries/getNotifications.gql';
import { graphql, OptionProps } from 'react-apollo';
import { ISharedSelectProps } from 'features/ui/ReactSelect/types';
import { IUser, IUserQueryResponse } from 'features/users/types';
import { IListQueryVariables } from 'features/types';
import { client, gql } from 'features/graphql';
import { IOffer, IOfferSettingsContentFormValues } from '../types';
import BaseForm from './BaseForm';

interface IFormProps extends WithTranslation {
  offer: IOffer;
  refetch?: (vars?: any) => Promise<any>;
  notifications: Array<{
    description: string;
    id: string;
    key: string;
    name: string;
  }>;
}
interface IFormState {
  users: Array<{
    id: string;
    user: IUser[];
  }>;
}

class Form extends React.Component<IFormProps, IFormState> {
  constructor(props: IFormProps) {
    super(props);
    this.state = {
      users: [],
    };
  }

  async componentDidUpdate(prevProps: IFormProps) {
    const { offer } = this.props;
    const { users } = this.state;
    if (prevProps.notifications.length !== this.props.notifications.length || (!users.length && this.props.notifications.length)) {
      await Promise.all(this.props.notifications.map((notification) => client.query({
        query: getUsersForNotificationQuery,
        variables: {
          filterByFields: {
            rule_id: {
              operator: '=',
              value: notification.id,
            },
            offer_id: {
              operator: '=',
              value: offer.id.toString(),
            },
          },
        },
      }).then(res => ({
        [notification.key]: res.data.NotificationRuleUsersByOfferQuery.items,
      })))).then((res) => {
        this.setState({ users: res });
      });
    }
  }

  getScheme() {
    return Yup.object().shape({
      users: Yup.array(),
    });
  }

  getFormData() {
    const { offer, notifications } = this.props;
    const { users } = this.state;
    const obj = {};
    users.forEach((elem) => {
      const name = Object.keys(elem);
      obj[name] = Object.values(elem).flatMap(elem => elem.map(item => item.user.id));
    });
    return {
      ...obj,
      notifications,
      offerId: field(offer, 'id', null),
    };
  }

  getChangedValues(values: any) {
    const formData = this.getFormData();
    if (formData) {
      return filterChangedValues(formData, values);
    }
    return values;
  }

  onSubmit = (
    values: IOfferSettingsContentFormValues,
    formActions: FormikActions<IOfferSettingsContentFormValues>,
  ) => {
    const { notifications, offer: { id: offerId } } = this.props;
    let newFormData = { ...values };
    newFormData = this.getChangedValues(newFormData);

    Object.values(newFormData).map((users, index) => {
      const notyKey = Object.keys(newFormData)[index];
      const notyId = notifications.filter(notification => notification.key === notyKey)[0].id;
      crudMutate({
        formActions,
        id: notyId,
        variables: { offer: offerId, id: notyId, include_users: users.map(v => +v) },
        mutation: editUsersWithNotifications,
      });
    });
  };

  render() {
    return (
      <Formik
        initialValues={this.getFormData()}
        validationSchema={this.getScheme()}
        enableReinitialize
        onSubmit={this.onSubmit}
      >
        {(props: FormikProps<IOfferSettingsContentFormValues>) => <BaseForm {...props} />}
      </Formik>
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, IUserQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    notifications: data && data.NotificationRuleQuery ? data.NotificationRuleQuery.items.filter(item => item.key.includes('mails_hr')) : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, IUserQueryResponse, IListQueryVariables, any>(
  getNotifications,
  {
    props: mapResultsToProps,
    options: () => ({
      variables: {
        pageNum: 1,
        perPage: 100,
      },
    }),
  },
)(withTranslation('offers')(Form));
