import React from 'react';
import { Input } from 'reactstrap';
import { IRect } from 'banner-editor-component';
import Property from './Property';
import {
  ColorInput,
  ColorLineInput,
  IWithFormikProps,
  MarginInput,
  NameInput,
  RoundInput,
  withFormik,
} from './inputs';

class RectForm extends React.Component<IWithFormikProps<IRect>> {
  onChangeLineColor = (color: string) => {
    const {
      submitForm,
      setFieldValue,
    } = this.props;
    setFieldValue('lineColor', color);
    setTimeout(submitForm, 0);
  };

  render() {
    const {
      values,
      handleSubmit,
      onChangeColor,
      onChange,
      colors,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <NameInput value={values.name} onChange={onChange} />

        <RoundInput value={values.round} onChange={onChange} />

        <ColorInput
          value={values.color}
          onChange={onChangeColor}
          colors={colors}
        />

        <Property name="Прозрачность" id="transparent">
          <Input
            id="transparent"
            name="transparent"
            type="range"
            min="0"
            max="1"
            step="0.01"
            onChange={onChange}
            value={values.transparent}
          />
        </Property>

        <Property name="Толщина линии" id="lineWidth">
          <Input
            id="lineWidth"
            name="lineWidth"
            type="number"
            onChange={onChange}
            value={values.lineWidth}
          />
        </Property>

        <ColorLineInput
          value={values.lineColor}
          onChange={this.onChangeLineColor}
          colors={colors}
        />

        <Property name="Прозрачность линии" id="lineTransparent">
          <Input
            id="lineTransparent"
            name="lineTransparent"
            type="range"
            min="0"
            max="1"
            step="0.01"
            onChange={onChange}
            value={values.lineTransparent}
          />
        </Property>

        <MarginInput value={values.margin} onChange={onChange} />
      </form>
    );
  }
}

export default withFormik<IRect>(RectForm);
