import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageAudiobooks from 'features/audiobooks/PageAudiobooks';
import PageCreate from 'features/audiobooks/PageCreate';
import PageEdit from 'features/audiobooks/PageEdit';
import PageView from 'features/items/PageView';

export default () => {
  return (
    <AccessControl permission="audiobooks:access">
      <Switch>
        <AppRoute path="/audiobooks" menuId="audiobooks" component={PageAudiobooks} exact />
        <AppRoute path="/audiobook/create" menuId="audiobooks" component={PageCreate} exact />
        <AppRoute path="/audiobook/edit/:id" menuId="audiobooks" component={PageEdit} exact />
        <AppRoute path="/audiobook/:id" menuId="audiobooks" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
