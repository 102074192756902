import { IUser } from '../../types';

export default (user: IUser) => {
  if (!user) {
    return '';
  }
  if (user.last_name && user.first_name) {
    return `${user.last_name} ${user.first_name}`;
  }

  if (user.last_name && !user.first_name) {
    return user.last_name;
  }

  if (!user.last_name && user.first_name) {
    return user.first_name;
  }

  return '';
};
