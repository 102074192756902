export default (timestamp: number) => {
  const secondsRemainder = Math.round(timestamp) % 60;
  const minutes = (timestamp - secondsRemainder) / 60;
  const minutesRemainder = minutes % 60;
  const hours = (minutes - minutesRemainder) / 60;
  const supplementedSeconds = secondsRemainder < 10 ? `0${secondsRemainder}` : String(secondsRemainder);
  const supplementedMunutes = minutesRemainder < 10 ? `0${minutesRemainder}` : String(minutesRemainder);
  const supplementedHours = hours < 10 ? `0${hours}` : String(hours);
  return `${supplementedHours}:${supplementedMunutes}:${supplementedSeconds}`;
};
