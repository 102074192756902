import React from 'react';
import moment from 'moment';
import TimeInput from 'react-time-picker';
import { Moment } from 'features/types';

import './styles.scss';

interface ITimePicker {
  value: number | string | null;
  onChange: (date: Moment | null) => void;
}

class TimePicker extends React.PureComponent<ITimePicker> {
  handleChange = (time: string) => {
    const { value, onChange } = this.props;
    const hours = time ? time.split(':')[0] : '00';
    const minutes = time ? time.split(':')[1] : '00';
    const updateValue = moment(value ? value : 0)
      .set('hours', hours)
      .set('minutes', minutes)
      .format('YYYY-MM-DD HH:mm:ss');

    onChange(updateValue);
  }

  render() {
    const {
      value,
    } = this.props;
    const time = moment(value ? value : 0).format('HH:mm');
    return (
      <TimeInput
        onChange={this.handleChange}
        value={time}
        clearIcon={false}
        clockIcon={null}
        disableClock={true}
        className="timePicker"
      />
    );
  }
} export default TimePicker;
