import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { flow } from 'lodash';
import {
  match as IMatch,
  Redirect,
} from 'react-router';

import CardPage from 'features/ui/Page/CardPage';
import LinkBlock from 'features/ui/LinkBlock';
import { IListQueryVariables } from 'features/types';
import deepOmit from 'lib/deepOmit';
import Spinner from 'features/ui/Spinner';
import { ILayout, ILayoutResponse } from '../types';
import LayoutForm from '../lib/LayoutForm';
import {
  makeLayoutBlockUrl,
  makeLayoutBlockUrlTo,
} from '../lib/url';
import getLayoutBlockQuery from '../queries/getLayoutBlockQuery.gql';
import editLayoutBlockMutation from '../queries/editLayoutBlock.gql';
import { FEATURE_ICON } from '../consts';

interface IPageEditProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  block: ILayout;
}

class PageEdit extends React.Component<IPageEditProps> {
  render() {
    const {
      block,
      isLoading,
      t,
    } = this.props;

    if (!isLoading && !block) {
      return <Redirect to="/404" />;
    }

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <CardPage
        icon={FEATURE_ICON}
        title={block ? `${t('edit_block')}: ${block.name}` : ''}
        crumbs={[{ link: makeLayoutBlockUrl(), name: t('blocks') }]}
      >
        <LinkBlock viewLink={makeLayoutBlockUrlTo(block, 'view')} />
        <LayoutForm
          block={block}
          mutation={editLayoutBlockMutation}
        />
      </CardPage>
    );
  }
}

const mapResultsToProps = (props: OptionProps<IPageEditProps, ILayoutResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  if (data && data.LayoutBlockQuery) {
    const block = deepOmit(data.LayoutBlockQuery.items[0], '__typename');
    return {
      block,
      isLoading: data && data.loading,
      ...ownProps,
    };
  }
  return {
    block: null,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphql(getLayoutBlockQuery, {
    props: mapResultsToProps,
    options: ({ match }: { match: IMatch<any> }) => {
      const { params } = match;
      const { id } = params || { id: 0 };
      return {
        context: {
          isGlobalLoading: true,
        },
        variables: {
          id: [id],
        },
      };
    },
  }),
  withTranslation('blocks'),
)(PageEdit);
