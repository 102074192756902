import {
  updateState,
} from 'features/graphql/helpers';

import {
  IState,
} from 'features/types';

export const updateSideMenuActiveId = (id: string) => updateState((state: IState) => {
  return Object.assign({}, state, {
    components: {
      ...state.components,
      sidemenu: {
        ...state.components.sidemenu,
        activeId: id,
      },
    },
  });
});

export const sideMenuActiveIdSelector = (state: IState): string => state.components.sidemenu.activeId;
