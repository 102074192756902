import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';

import ReactSelect from 'features/ui/ReactSelect';
import getLanguageQuery from 'features/offers/queries/getLanguageQuery.gql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  ILanguage,
  ILanguageQueryResponse,
} from 'features/languages/types';

interface ILanguagesSelectProps extends ISharedSelectProps {
  data: ILanguage[];
}

interface ILanguagesSelectState {
  options: ISelectOption[];
}

class LanguagesSelect extends React.PureComponent<ILanguagesSelectProps, ILanguagesSelectState> {
  static getDerivedStateFromProps(props: any) {
    return {
      options: props.data.map((entity: ILanguage) => ({
        value: +entity.id,
        label: entity.name,
      })),
    };
  }

  state = {
    options: [],
  };

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        isMulti={true}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ILanguageQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;

  return {
    data: data && data.LanguageQuery ? data.LanguageQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ILanguageQueryResponse, IListQueryVariables, any>(getLanguageQuery, {
  props: mapResultsToProps,
  options: () => ({
    variables: {
      pageNum: 1,
      perPage: 5000,
      filterByFields: {
        is_interface: {
          operator: '=',
          value: '1',
        },
      },
    },
  }),
})(LanguagesSelect);
