import React from 'react';
import PageItems from 'features/items/PageItems';
import { FEATURE_ICON } from 'features/courses/consts';

import { Column } from 'features/types';

class PageCourses extends React.PureComponent<{}> {
  resolveColumns = (columns: Column[]) => {
    return columns.filter(column => !['provider', 'publisher'].includes(column.id!));
  };

  render() {
    return (
      <PageItems
        title="Тесты"
        type="test"
        resolveColumns={this.resolveColumns}
        icon={FEATURE_ICON}
      />
    );
  }
}

export default PageCourses;
