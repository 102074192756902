import field from 'lib/field';
import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import filterChangedValues from 'lib/filterChangedValues';
import {
  colorValidator,
  imageFileValidator,
} from 'lib/validators';
import { toast } from 'features/ui/Toast';
import i18n from 'features/intl/i18n';

import {
  get,
  pick,
  set,
} from 'lodash';
import { crudMutate } from 'features/common/helpers';
import BaseForm from './BaseForm';
import editOfferQuery from '../queries/editOffer.gql';
import getOfferQuery from '../queries/getOffer.gql';
import {
  IOffer,
  IOfferSettingsBrandingCustomTitlesLangInput,
  IOfferSettingsBrandingFormValues,
  IOfferSettingsBrandingInput,
} from '../types';

import * as Yup from 'yup';

// tslint:disable-next-line:max-line-length
const EMAIL_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

interface IFormProps extends WithTranslation {
  offer: IOffer;
}

class Form extends React.Component<IFormProps> {
  getScheme() {
    const { t } = this.props;
    return Yup.object().shape({
      color_main: colorValidator({ field: t('settingsBranding.color_main') }),
      color_unpressed: colorValidator({ field: t('settingsBranding.color_unpressed') }),
      color_pressed: colorValidator({ field: t('settingsBranding.color_pressed') }),
      color_selected: colorValidator({ field: t('settingsBranding.color_selected') }),
      color_banner_arrow: colorValidator({ field: t('settingsBranding.color_banner_arrow') }),
      color_banner_bar: colorValidator({ field: t('settingsBranding.color_banner_bar') }),
      translations: [],
      logoBottom: imageFileValidator({
        field: t('settingsBranding.logoBottom'),
      }),
      logoTop: imageFileValidator({
        field: t('settingsBranding.logoTop'),
      }),
      appIcon: imageFileValidator({
        field: t('settingsBranding.appIcon'),
      }),
      splashLogoTop: imageFileValidator({
        field: t('settingsBranding.splashLogoTop'),
      }),
      splashLogoBottom: imageFileValidator({
        field: t('settingsBranding.splashLogoBottom'),
      }),
    });
  }

  getFormData(): IOfferSettingsBrandingFormValues {
    const { offer } = this.props;
    let brand;
    if (offer) {
      brand = offer.settingsBranding;
    }
    return {
      color_main: field(brand, 'color_main', ''),
      color_unpressed: field(brand, 'color_unpressed', ''),
      color_pressed: field(brand, 'color_pressed', ''),
      color_selected: field(brand, 'color_selected', ''),
      color_banner_arrow: field(brand, 'color_banner_arrow', ''),
      color_banner_bar: field(brand, 'color_banner_bar', ''),
      support_email: field(brand, 'support_email', 'support@alpina.help'),
      translations: field(offer, 'translations', []),
      custom_titles: {
        ru: {
          title_registration: field(brand, 'custom_titles.ru.title_registration', ''),
          title_login: field(brand, 'custom_titles.ru.title_login', ''),
          title_latest: field(brand, 'custom_titles.ru.title_latest', ''),
          title_most_readable: field(brand, 'custom_titles.ru.title_most_readable', ''),
          title_books: field(brand, 'custom_titles.ru.title_books', ''),
          title_hybrids: field(brand, 'custom_titles.ru.title_hybrids', ''),
          title_collections: field(brand, 'custom_titles.ru.title_collections', ''),
          title_videos: field(brand, 'custom_titles.ru.title_videos', ''),
          title_audiobooks: field(brand, 'custom_titles.ru.title_audiobooks', ''),
        },
        en: {
          title_registration: field(brand, 'custom_titles.en.title_registration', ''),
          title_login: field(brand, 'custom_titles.en.title_login', ''),
          title_latest: field(brand, 'custom_titles.en.title_latest', ''),
          title_most_readable: field(brand, 'custom_titles.en.title_most_readable', ''),
          title_books: field(brand, 'custom_titles.en.title_books', ''),
          title_hybrids: field(brand, 'custom_titles.en.title_hybrids', ''),
          title_collections: field(brand, 'custom_titles.en.title_collections', ''),
          title_videos: field(brand, 'custom_titles.en.title_videos', ''),
          title_audiobooks: field(brand, 'custom_titles.en.title_audiobooks', ''),
        },
      },
    };
  }

  getCustomTitles(values: IOfferSettingsBrandingFormValues, lang: string) {
    return [
      'title_registration',
      'title_login',
      'title_latest',
      'title_most_readable',
      'title_books',
      'title_hybrids',
      'title_collections',
      'title_videos',
      'title_audiobooks',
    ].reduce(
      (acc: { [key: string]: string }, key: string) => {
        const val = get(values, `custom_titles.${lang}.${key}`, '');
        if (val !== undefined) {
          set(acc, key, val);
        }
        return acc;
      },
      {},
    );
  }

  getChangedValues(values: IOfferSettingsBrandingFormValues) {
    const formData = this.getFormData();
    if (formData) {
      const filtered = filterChangedValues(formData, values);
      if (JSON.stringify(formData.custom_titles) === JSON.stringify(values.custom_titles)) {
        delete filtered.custom_titles;
      }
      if (JSON.stringify(formData.translations) === JSON.stringify(values.translations)) {
        delete filtered.translations;
      }
      return filtered;
    }
    return { ...values };
  }

  getSendingValues(values: IOfferSettingsBrandingFormValues): IOfferSettingsBrandingInput {
    if (Object.keys(values).length === 0) {
      return {};
    }
    const { offer } = this.props;
    const settingsBranding = {
      ...pick(values, [
        'color_main',
        'color_unpressed',
        'color_pressed',
        'color_selected',
        'color_banner_arrow',
        'color_banner_bar',
        'logoTop',
        'logoBottom',
        'appIcon',
        'splashLogoTop',
        'splashLogoBottom',
        'support_email',
      ]) as any,
    } as IOfferSettingsBrandingInput;
    const translations = values.translations || [];
    const customTitles: any = {};
    let hasNewTitles = false;
    if (values.custom_titles) {
      ['ru', 'en'].forEach((lang) => {
        const titles = this.getCustomTitles(values, lang);
        if (Object.keys(titles).length) {
          hasNewTitles = true;
        }
        customTitles[lang] = {
          ...get(offer, `settingsBranding.custom_titles.${lang}`, {}) as IOfferSettingsBrandingCustomTitlesLangInput,
          ...titles as IOfferSettingsBrandingCustomTitlesLangInput,
        };
      });
      if (hasNewTitles) {
        settingsBranding.custom_titles = customTitles;
      }
    }
    if (values.support_email) {
      if (!EMAIL_REGEXP.test(values.support_email)) {
        return toast.error(i18n.t('forms:wrong_hr_email', { field: 'Контактный e-mail' }))
      }
    }
    return {
      translations,
      settingsBranding,
    };
  }

  onSubmit = (
    formData: IOfferSettingsBrandingFormValues,
    formActions: FormikActions<IOfferSettingsBrandingFormValues>,
  ) => {
    const { id } = this.props.offer;
    const changedValues = this.getChangedValues({ ...formData });
    const settingsBranding = this.getSendingValues(changedValues);
    crudMutate({
      id,
      formActions,
      variables: { id, offer: settingsBranding },
      mutation: editOfferQuery,
      redirect: '/offers',
      check: !!Object.keys(settingsBranding).length,
      updateRefetchQuery: getOfferQuery,
    });
  };

  render() {
    const { offer } = this.props;
    return (
      <Formik
        initialValues={this.getFormData()}
        validationSchema={this.getScheme()}
        enableReinitialize
        onSubmit={this.onSubmit}
      >
        {(props: FormikProps<IOfferSettingsBrandingFormValues>) => <BaseForm {...props} offer={offer} />}
      </Formik>
    );
  }
}

export default withTranslation('offers')(Form);
