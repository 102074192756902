import React from 'react';
import cn from 'classnames';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import {
  ElementType,
  ElementTypeEnum,
  IOutlinerComponentProps,
} from 'banner-editor-component';
import style from './style.module.scss';

interface IMappingType {
  [key: string]: {
    icon: string;
    create: string;
  };
}

const mapping: IMappingType = {
  [ElementTypeEnum.text]: {
    icon: 'fa fa-font fa-lg',
    create: 'Добавить текст',
  },
  [ElementTypeEnum.rect]: {
    icon: 'fa fa-square fa-lg',
    create: 'Добавить подложку',
  },
  [ElementTypeEnum.button]: {
    icon: 'fa fa-toggle-right fa-lg',
    create: 'Добавить кнопку',
  },
  [ElementTypeEnum.image]: {
    icon: 'fa fa-image fa-lg',
    create: 'Добавить изображение',
  },
  [ElementTypeEnum.scene]: {
    icon: 'fa fa-globe fa-lg',
    create: '',
  },
};

export default class Outliner extends React.PureComponent<IOutlinerComponentProps> {
  getItemStyle(draggableStyle: any) {
    return {
      userSelect: 'none',
      ...draggableStyle,
    };
  }

  onDragEnd = (dropResult: DropResult) => {
    if (dropResult.destination && 'index' in dropResult.destination &&
      dropResult.source && 'index' in dropResult.source
    ) {
      const { onDragEnd } = this.props;
      if (onDragEnd) {
        onDragEnd(dropResult.destination.index, dropResult.source.index);
      }
    }
  };

  renderRoster() {
    const {
      onCreateElement,
      onUndo,
    } = this.props;
    const list = [
      ElementTypeEnum.text,
      ElementTypeEnum.rect,
      ElementTypeEnum.button,
      ElementTypeEnum.image,
    ].map((elementType) => {
      const handler = () => onCreateElement(elementType);
      return (
        <div key={elementType} onClick={handler}>
          <i
            title={mapping[elementType].create}
            className={cn(mapping[elementType].icon, style.createButton, 'fa-lg')}
          />
          <label>{mapping[elementType].create}</label>
        </div>
      );
    });
    return (
      <div className={style.roster}>
        <i
          title={'Отменить последнее действие'}
          style={{ float: 'right', lineHeight: 'normal' }}
          className={cn('fa fa-undo fa-lg')}
          onClick={onUndo}
        />
        {list}
      </div>
    );
  }

  renderElement = (child: ElementType, id: number) => {
    const {
      currentElementId,
      onChoose,
      onRemoveElement,
      scene,
    } = this.props;
    const options = mapping[child._type];
    if (!options) {
      return null;
    }
    const rowClassName = cn({
      [style.outlinerRow]: true,
      [style.elementRow]: true,
      [style.choosen]: currentElementId === id,
    });
    const chooseHandler = () => onChoose(id);
    const removeHandler = (e: React.SyntheticEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
      onRemoveElement(id);
    };
    const icon = 'image' in child && child.image ?
      <img src={child.image} width={30} height={20} style={{ background: '#ccc' }} /> :
      <i className={options.icon} />;

    const text = (child as any).text;

    const line = (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={this.getItemStyle(provided.draggableProps.style)}
        onClick={chooseHandler}
        className={cn(rowClassName, { [style.dragging]: snapshot.isDragging })}
        title={scene.children.length > 1 ? 'Потяните за элемент чтобы изменить порядок' : ''}
      >
        <i className={cn(style.dragIcon, 'fa fa-ellipsis-v', { [style.disabled]: scene.children.length <= 1 })} />

        {icon}

        <i
          className={cn(style.removeIcon, 'fa fa-close')}
          onClick={removeHandler}
        />
        &nbsp;
        {`${child.name}${text ? ` - ${text}` : ''}`}
      </div>
    );
    return (
      <Draggable
        key={id}
        draggableId={String(id)}
        index={id}
      >
        {line}
      </Draggable>
    );
  };

  render() {
    const {
      scene,
      currentElementId,
      onChoose,
    } = this.props;
    const list = scene.children.map(this.renderElement);
    const sceneOptions = mapping[ElementTypeEnum.scene];
    const onChooseScene = () => onChoose(-1);
    const sceneClassName = cn({
      [style.outlinerRow]: true,
      [style.outlinerRowScene]: true,
      [style.choosen]: currentElementId === -1,
    });
    const droppable = (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
      >
        {list}
        {provided.placeholder}
      </div>
    );
    return (
      <div className={style.outliner}>
        {this.renderRoster()}
        <div
          onClick={onChooseScene}
          className={sceneClassName}
        >
          <i className={sceneOptions.icon} />
          &nbsp;
          {scene.name}
        </div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {droppable}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}
