import React, { useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import {
  Column,
  RowRenderProps,
} from 'react-table';
import { Input } from 'reactstrap';
import i18next from 'i18next';
import today from 'lib/today';
import { filterInputHandler } from 'features/common';
import { DATETIME_FORMAT_DEFAULT } from 'features/app/consts';
import { IOfferSettingsRegistration } from '../types';

import styles from './styles.module.scss';

export const makeOffersColumns = (t: i18next.TFunction): Column[] => {
  return [
    {
      Header: 'name',
      accessor: 'name',
      minWidth: 100,
      Filter: filterInputHandler,
    },
    {
      Header: 'prefix',
      accessor: 'prefix',
      minWidth: 100,
      Filter: filterInputHandler,
    },
    {
      Header: 'amount_of_users',
      accessor: ({ settingsRegistration }: { settingsRegistration: IOfferSettingsRegistration }) => {
        const registered = settingsRegistration.users_registered || 0;
        const allowed = settingsRegistration.users_allowed || 0;
        return `${registered}/${allowed}`;
      },
      filterable: false,
      id: 'users_allowed',
      minWidth: 100,
    },
    {
      Cell: (row: RowRenderProps) => (
        <ContactCell contact={row.value} />
      ),
      Header: 'contacts',
      filterable: false,
      accessor: 'contacts',
      minWidth: 100,
    },
    {
      Header: 'offer_expire_date',
      Cell: (row: RowRenderProps) => {
        const date = moment(row.value, DATETIME_FORMAT_DEFAULT);
        const bulletClasses = cn(
          styles.expireDateBullet,
          'badge',
          date.valueOf() >= today() ? 'badge-success' : 'badge-danger',
        );
        return (
          <React.Fragment>
            <span className={bulletClasses}>&nbsp;</span>
            &nbsp;
            <span>{date.format('ll')}</span>
          </React.Fragment>
        );
      },
      Filter: ({ filter, onChange }: { filter: any, onChange: any }) => {
        const handler = (e: any) => onChange(e.target.value);
        return (
          <Input
            type="date"
            value={filter ? filter.value : ''}
            onChange={handler}
            style={{ width: '100%' }}
          />
        );
      },
      id: 'expire_date',
      accessor: ({ expire_date }: { expire_date: string }) => {
        return expire_date;
      },
      minWidth: 120,
    },
  ].map((item) => {
    return {
      ...item,
      Header: item.Header ? t(item.Header) : '',
    };
  });
};

const ContactCell = ({ contact }: { contact: string | null }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  if (contact && contact.length > 30) {
    const handleToggle = () => {
      setOpen(prevState => !prevState);
    };

    return (
      <div className={styles.wrapperCell}>
        <span className={isOpen ? styles.open : null}>
          {isOpen ? contact : `${contact.slice(0, 30)}...`}
        </span>
        <button type="button" className={styles.button} onClick={handleToggle}>{isOpen ? 'Меньше' : '...Еще'}</button>
      </div>
    );
  }
  return <span>{contact}</span>;
};
