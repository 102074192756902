import {
  isObject,
  keys,
} from 'lodash';

function fn<T extends {[key: string]: any}>(obj: T, path: string[], store: string[]) {
  if (isObject(obj)) {
    keys(obj).forEach(key => fn(obj[key], path.slice().concat([key]), store));
  } else {
    store.push(path.join('.'));
  }
}

export default (obj: {[key: string]: any}) => {
  const store: string[] = [];
  fn(obj, [], store);
  return store;
};
