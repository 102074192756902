import React from 'react';
import LocalStorage from 'features/store/LocalStorage';
import { TEAMCITY_TOKEN_NAME } from 'features/auth/consts';
import Login from './Login';
import Page from './Page';

interface IPageBuildsState {
  isLogged: boolean;
}

export default class PageBuilds extends React.Component<{}, IPageBuildsState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isLogged: Boolean(LocalStorage.getString(TEAMCITY_TOKEN_NAME)),
    };
  }

  onLogged = () => {
    this.setState({ isLogged: true });
  }

  render() {
    const { isLogged } = this.state;
    if (isLogged) {
      return <Page />;
    }
    return <Login onLogged={this.onLogged} />;
  }
}
