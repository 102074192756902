import React from 'react';
import CardPage from 'features/ui/Page/CardPage';
import ItemsDataTable from 'features/items/lib/ItemsTable';
import { AccessControl } from 'features/acl';

import {
  Column,
  ICardPageProps,
} from 'features/types';

interface IPageItemsProps extends ICardPageProps {
  type?: string;
  resolveColumns?: (columns: Column[]) => Column[];
}

class PageItems extends React.PureComponent<IPageItemsProps> {
  render() {
    const {
      type,
      resolveColumns,
      ...basePageProps
    } = this.props;
    return (
      <AccessControl permission="items:access">
        <CardPage
          {...basePageProps}
          buttons={type ? [{ link: `/${type}/create`, name: 'Создать' }] : []}
        >
          <ItemsDataTable
            type={type}
            resolveColumns={resolveColumns}
          />
        </CardPage>
      </AccessControl>
    );
  }
}

export default PageItems;
