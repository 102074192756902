import React from 'react';
import { get } from 'lodash';
import cn from 'classnames';
import { Input } from 'reactstrap';
import { FormikProps } from 'formik';
import i18next from 'i18next';
import FormRow from 'features/ui/FormRow';
import FieldSwitcher from 'features/ui/FieldSwitcher';
import {
  makeLabel,
  makeName,
} from './helpers';
import styles from './styles.module.scss';

const MB = 1048576;

type FormPropsType = FormikProps<any> & {
  t: i18next.TFunction,
};

type LabelPrefixFunc = (val: string) => string;

interface IBytesInputProps {
  name: string;
  form: FormPropsType;
  placeholder?: string;
  labelPrefix?: string | LabelPrefixFunc;
  text?: string;
  hasSwitcher?: boolean;
  required?: boolean;
}

interface IBytesInputState {
  innerValue: string;
}

export default class BytesInput extends React.Component<IBytesInputProps, IBytesInputState> {
  static conv(bytes: number): number {
    return bytes / MB;
  }

  constructor(props: IBytesInputProps) {
    super(props);
    const value = get(this.props.form.values, this.props.name);
    if (value === null || value === '') {
      this.state = { innerValue: '' };
    } else {
      const innerValue = BytesInput.conv(parseInt(value, 10));
      this.state = {
        innerValue: innerValue.toString(),
      };
    }
  }

  setField = () => {
    const { setFieldValue } = this.props.form;
    const { name } = this.props;
    const { innerValue } = this.state;
    let value;
    if (innerValue === '') {
      value = '';
    } else {
      const num = parseFloat(innerValue);
      value = Math.floor(num * MB);
    }
    setFieldValue(name, value);
  };

  onChange = (e: React.SyntheticEvent) => {
    const value = (e.currentTarget as any).value;
    this.setState(
      { innerValue: value },
      this.setField,
    );
  };

  render() {
    const {
      t,
      handleBlur,
    } = this.props.form;
    const {
      text,
      placeholder,
      hasSwitcher,
      required,
      form,
    } = this.props;
    const { innerValue } = this.state;
    const name = makeName(this.props);

    let component = (
      <div className={styles.wrapper}>
        <Input
          type="number"
          id={name}
          name={name}
          placeholder={placeholder}
          value={innerValue}
          onBlur={handleBlur}
          onChange={this.onChange}
        />
        <div className={cn(styles.dimention, 'form-control')}>
          <span className={styles.value}>{innerValue}</span> MB
        </div>
      </div>
    );

    if (hasSwitcher) {
      component = (
        <FieldSwitcher
          name={name}
          offValue=""
          form={form}
        >
          {component}
        </FieldSwitcher>
      );
    }

    return (
      <FormRow
        id={name}
        label={t(makeLabel(this.props))}
        text={text}
        required={required}
      >
        {component}
      </FormRow>
    );
  }
}
