import React from 'react';
import { set } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import getUserGroupsQuery from 'features/usergroups/queries/getUserGroupsQuery.gql';
import resolveColumns from './columns';
import deleteUserGroupMutation from 'features/usergroups/queries/deleteUserGroupMutation.gql';
import { makeUserGroupUrl } from 'features/usergroups/lib/url';

import { Column } from 'features/types';

type IDataTableProps = WithTranslation;

class DataTable extends React.PureComponent<IDataTableProps> {
  columns: Column[];

  constructor(props: IDataTableProps) {
    super(props);
    this.columns = resolveColumns().filter(col => !!col) as Column[];
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.name) {
      set(variables, 'filterByFields.name', {
        operator: 'like',
        value: fields.name,
      });
    }
    if (fields.offer_id) {
      set(variables, 'filterByFields.offer_id', {
        operator: '=',
        value: String(fields.offer_id),
      });
    }
  };

  render() {
    return (
      <IndexTableBase
        columns={this.columns}
        filterFields={['id', 'name', 'offer_id']}
        prepareRequestData={this.prepareRequestData}
        query={getUserGroupsQuery}
        queryResultName="UserGroupQuery"
        deleteMutation={deleteUserGroupMutation}
        makeCrudUrl={makeUserGroupUrl}
        showViewIconColumn={true}
      />
    );
  }
}

export default withTranslation()(DataTable);
