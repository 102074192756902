import React from 'react';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import { makeCreatorUrlTo } from '../url';
import { ICreator } from '../../types';

export default ({
  value,
  action,
}: {
  value: ICreator,
  action?: string,
}) => (
  <LinkToEntityInLists
    id={value.id}
    name={value.full_name}
    to={makeCreatorUrlTo(value, action)}
  />
);
