import React from 'react';
import Color from 'features/ui/Color';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { IInfoRowOptions } from './types';

export const InfoRowColor = (
  ({
    label,
    value,
    options,
  }: {
    label: string,
    value: string,
    options?: IInfoRowOptions,
  }) => {
    if (!value) {
      return <InfoRowNotSet label={label} options={options} />;
    }
    return (
      <InfoRow label={label} {...options}>
        <Color value={value} />
      </InfoRow>
    );
  }
);
