import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageVideos from 'features/videos/PageVideos';
import PageCreate from 'features/videos/PageCreate';
import PageEdit from 'features/videos/PageEdit';
import PageView from 'features/items/PageView';

export default () => {
  return (
    <AccessControl permission="videos:access">
      <Switch>
        <AppRoute path="/videos" menuId="videos" component={PageVideos} exact />
        <AppRoute path="/video/create" menuId="videos" component={PageCreate} exact />
        <AppRoute path="/video/edit/:id" menuId="videos" component={PageEdit} exact />
        <AppRoute path="/video/:id" menuId="videos" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
