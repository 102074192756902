import crudUrlBuilder from 'lib/crudUrlBuilder';
import { IBanner } from '../../types';

export const makeBannerUrl = crudUrlBuilder('banner', 'banners');

export const makeBannerUrlTo = (banner: IBanner, action: string = 'view') => {
  return makeBannerUrl({
    action,
    id: banner.id,
  });
};
