import { get } from 'lodash';
import { toast } from 'features/ui/Toast';
import i18n from 'features/intl/i18n';

export default function formErrorHandler(data: any, handleErrors: (v: any) => void) {
  const errorMessage = get(data, 'graphQLErrors[0].message', null);
  if (errorMessage) {
    toast.error(
      i18n.exists(`backend:errors.${errorMessage}`) ? i18n.t(`backend:errors.${errorMessage}`) : errorMessage,
      { autoClose: 15000 },
    );
  }
  const serverErrors = get(data, 'graphQLErrors[0].validation', null);
  if (serverErrors) {
    const clientErrors = Object
      .keys(serverErrors)
      .reduce(
        (acc: any, key: string) => {
          const field = key.split('.').slice(1).join('.');
          acc[field] = serverErrors[key][0];
          return acc;
        },
        {},
      );
    handleErrors(clientErrors);
  }
}
