import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { gql } from 'features/graphql';
import {
  IListQueryVariables,
  ISharedSelectProps,
} from 'features/types';
import { IOfferQueryResponse } from 'features/offers/types';

const QUERY = gql`
  query getOffersForSelect($pageNum: Int, $perPage: Int) {
    OfferQuery(page: $pageNum, perPage: $perPage) {
      items {
        id
        name
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, IOfferQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;

  return {
    data: data && data.OfferQuery ? data.OfferQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, IOfferQueryResponse, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: () => {
    return {
      variables: {
        pageNum: 1,
        perPage: 10000,
      },
    };
  },
});
