import React from 'react';
import { difference } from 'lodash';
import { Link } from 'react-router-dom';
import { FormikProps } from 'formik';
import {
  Button,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import Switch from 'features/ui/Switch';
import { makeOfferUrlTo } from 'features/offers/lib/url';
import { IOffer } from 'features/offers/types';
import { IFormValues } from '../types';

interface IOffersProps {
  formikProps: FormikProps<IFormValues>;
  enabledOffers: IOffer[];
  disabledOffers: IOffer[];
}

interface IOfferState {
  areDisabledOffersShown: boolean;
}

export default class Offers extends React.Component<IOffersProps, IOfferState> {
  constructor(props: IOffersProps) {
    super(props);
    this.state = {
      areDisabledOffersShown: false,
    };
  }

  onSwithChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const {
      values,
      setFieldValue,
    } = this.props.formikProps;
    const attrId = e.currentTarget.getAttribute('data-id');
    const checked = e.currentTarget.checked;
    if (attrId) {
      if (checked) {
        if (!values.offers.includes(attrId)) {
          setFieldValue('offers', [...values.offers, attrId]);
        }
      } else {
        if (values.offers.includes(attrId)) {
          setFieldValue('offers', values.offers.filter((id: ID) => id !== attrId));
        }
      }
    }
  };

  onShowDisabledOffers = () => {
    this.setState(
      {
        areDisabledOffersShown: true,
      },
      () => {
        const { disabledOffers } = this.props;
        const {
          values,
          setFieldValue,
        } = this.props.formikProps;
        const disabledOffersIds = disabledOffers.map(o => o.id);
        setFieldValue('offers', difference(values.offers, disabledOffersIds));
      },
    );
  }

  onSelectAll = () => {
    const {
      enabledOffers,
      disabledOffers,
    } = this.props;
    const { setFieldValue } = this.props.formikProps;
    const { areDisabledOffersShown } = this.state;
    let ids = enabledOffers.map(o => o.id);
    if (areDisabledOffersShown) {
      ids = ids.concat(disabledOffers.map(o => o.id));
    }
    setFieldValue('offers', ids);
  }

  renderRow(offer: IOffer, isBanIconShown: boolean = false) {
    const { values } = this.props.formikProps;
    const key = `offer_item_${offer.id}`;
    const url = makeOfferUrlTo(offer, 'edit', 'build');
    return (
      <FormGroup check className="checkbox" key={key}>
        <Switch
          id={key}
          data-id={offer.id}
          name="offers"
          onChange={this.onSwithChange}
          checked={values.offers.includes(offer.id)}
        />
        &emsp;
        <Label
          check
          className="form-check-label"
          htmlFor={key}
        >
          [<Link to={url}>{offer.id}</Link>] {offer.name}
          &nbsp;
          {isBanIconShown ? <i className="fa fa-ban fa-lg" /> : null}
        </Label>
      </FormGroup>
    );
  }

  renderShowDisabledOffersButton() {
    return (
      <FormGroup row>
        <Col md="12" xs="12">
          <Button
            onClick={this.onShowDisabledOffers}
            color="secondary"
          >
            Показать неактивные офферы
          </Button>
        </Col>
      </FormGroup>
    );
  }

  renderSelectAllButton() {
    return (
      <FormGroup row>
        <Col md="12" xs="12">
          <Button onClick={this.onSelectAll} color="secondary">Выбрать всё</Button>
        </Col>
      </FormGroup>
    );
  }

  render() {
    const {
      enabledOffers,
      disabledOffers,
    } = this.props;
    const { areDisabledOffersShown } = this.state;
    const enabledOffersList = enabledOffers.map(o => this.renderRow(o));
    const disabledOffersList = disabledOffers.map(o => this.renderRow(o, true));
    return (
      <React.Fragment>
        {this.renderSelectAllButton()}
        {enabledOffersList}
        {areDisabledOffersShown ? disabledOffersList : this.renderShowDisabledOffersButton()}
      </React.Fragment>
    );
  }
}
