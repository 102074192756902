import React from 'react';
import style from '../style.module.scss';

export default ({
  color,
  onClick,
  caption,
}: {
  color: string,
  onClick: () => void,
  caption?: string,
}) => {
  let captionElement = null;
  if (caption) {
    captionElement = <div className={style.caption}>{caption}</div>;
  }
  return (
    <div
      className={style.color}
      style={{ backgroundColor: color }}
    >
      <div
        className={style.bigColor}
        onClick={onClick}
        style={{ backgroundColor: color }}
      />
      {captionElement}
    </div>
  );
};
