import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { makeLinkListValue } from 'lib/makeLinkListValue';
import {
  InfoRowLinkList,
  InfoRowSwitch,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { createView } from 'features/common';
import { makeOfferUrl } from 'features/offers/lib/url';
import {
  makeFeatureUrl,
  makeFeatureUrlTo,
} from '../lib/url';
import getEntity from '../queries/getFeature.gql';

import { FEATURE_ICON } from '../consts';

import { IFeaure } from '../types';
import moment from 'moment';

const View = createView<IFeaure>(getEntity, 'FeatureQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderChildren = (feature: IFeaure) => {
    const { t } = this.props;
    const offersLinkListValue = makeLinkListValue(feature.offers, 'name', makeOfferUrl, 'view');
    return (
      <React.Fragment>
        <LinkBlock editLink={makeFeatureUrlTo(feature, 'edit')} />
        <InfoRowText label="ID" value={feature.id} />
        <InfoRowSwitch label={t('is_active')} value={feature.is_active} on="dict:yes" off="dict:no" />
        <InfoRowText label={t('name')} value={feature.name} />
        <InfoRowText label={t('key')} value={feature.key} />
        <InfoRowText label={t('description')} value={feature.description} />
        <InfoRowText label={t('build_versions')} value={feature.build_versions} />
        <InfoRowText label={t('platforms')} value={feature.platforms.map(platform => platform.name).join(', ')} />
        <strong>Период тестирования</strong>
        <InfoRowText label={t('begin_date')} value={moment(feature.begin_date).format('YYYY-MM-DD')} />
        <InfoRowText label={t('end_date')} value={moment(feature.end_date).format('YYYY-MM-DD')} />
        <strong>Выборка</strong>
        <InfoRowLinkList
          label={t('offers')}
          value={offersLinkListValue}
          byLine={true}
          max={10}
        />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField="name"
        icon={FEATURE_ICON}
        crumbs={[{ link: makeFeatureUrl(), name: t('features') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('features')(PageView);
