import React from 'react';
import { Input } from 'reactstrap';
import { get } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import ColorPicker from 'features/ui/ColorPicker';
import Switch from 'features/ui/Switch';
import ImageUploader from 'features/ui/ImageUploader';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import { InfoRowNotSet } from 'features/ui/InfoRow';
import OffersSelectWithButtons from 'features/offers/lib/OffersSelectWithButtons';
import CategoriesSelect from 'features/categories/lib/CategoriesSelect';
import { makeOfferUrl } from 'features/offers/lib/url';
import ItemsIdsTextarea from 'features/items/lib/ItemsIdsTextarea';

import getCategoryQuery from 'features/categories/queries/getCategory.gql';
import editCategoryMutation from 'features/categories/queries/editCategory.gql';

import CreatedBy from '../CreatedBy';
import LinkToItems from '../LinkToItems';
import { makeCategoryUrl } from '../url';
import {
  ICategory,
  ICategoryFormValues,
} from '../../types';

interface ICategoryFormProps extends FormikProps<ICategoryFormValues>, WithTranslation {
  category?: ICategory;
}

class BaseCategoryForm extends React.Component<ICategoryFormProps> {

  onBlur = (name: string) => {
    const { setFieldTouched } = this.props;
    setFieldTouched(name, true);
  };

  onChange = (name: string, value: number | string | null | string[]) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, value);
  };

  onChangeImage = (name: string, data: React.ChangeEvent<any> | null) => {
    const { setFieldValue } = this.props;
    if (data === null) {
      setFieldValue(name, data);
    } else {
      if (data.target.validity.valid) {
        setFieldValue(name, data.target.files[0]);
      }
    }
  };

  onChangeImageTop = (data: React.ChangeEvent<any> | null) => {
    this.onChangeImage('imageTop', data);
  };

  onChangeImageBottom = (data: React.ChangeEvent<any> | null) => {
    this.onChangeImage('imageBottom', data);
  };

  onChangeParentCategory = (id: number) => {
    this.onChange('parentCategory', id);
  };

  onChangeChildrenCategories = (id: number) => {
    this.onChange('childrenCategories', id);
  };

  onChangeBadge = (id: number) => {
    this.onChange('badge', id);
  };

  onChangeColor = (color: string) => {
    this.onChange('color', color);
  };

  onBlurParentCategory = () => {
    this.onBlur('parentCategory');
  };

  onBlurChildrenCategories = () => {
    this.onBlur('childrenCategories');
  };

  onBlurBadge = () => {
    this.onBlur('badge');
  };

  onChangeOffers = (ids: ID[]) => {
    this.onChange('offers', ids.slice());
  };

  renderCreatedBy() {
    const {
      category,
      t,
    } = this.props;
    if (category) {
      return (
        <FormRow label={t('created_by')} id="created_by">
          <CreatedBy category={category} />
        </FormRow>
      );
    }
    return null;
  }

  renderOfferOwner() {
    const {
      category,
      t,
    } = this.props;
    const ownerOffer = get(category, 'ownerOffer', null);
    if (ownerOffer) {
      return (
        <FormRow label={t('ownerOffer')} id="">
          <LinkToEntityInLists
            to={makeOfferUrl(ownerOffer)}
            id={ownerOffer.id}
            name={ownerOffer.name}
          />
        </FormRow>
      );
    }
    return <InfoRowNotSet label={t('ownerOffer')} />;
  }

  renderParentCategory() {
    const {
      category,
      t,
    } = this.props;
    const parentCategory = get(category, 'parentCategory', null);
    if (parentCategory) {
      return (
        <FormRow label={t('parentCategory')} id="parentCategory">
          <LinkToEntityInLists
            to={makeCategoryUrl(parentCategory)}
            id={parentCategory.id}
            name={parentCategory.name}
          />
        </FormRow>
      );
    }
    return <InfoRowNotSet label={t('parentCategory')} />;
  }

  renderItems() {
    const { category } = this.props;
    if (category && category.id) {
      return (
        <React.Fragment>
          <hr />
          <FormRow
            label="Добавить/Удалить итемы"
            id="ItemsIdsTextarea"
          >
            <LinkToItems category={category} />
            <ItemsIdsTextarea
              query={getCategoryQuery}
              mutation={editCategoryMutation}
              data={category}
              entityName="category"
              fieldName="items"
            />
          </FormRow>
        </React.Fragment>
      );
    }
    return null;
  }

  render() {
    const {
      category,
      handleBlur,
      handleChange,
      t,
      values,
    } = this.props;
    return (
      <FormFrame
        id={category ? category.id : undefined}
        cancelLink={makeCategoryUrl()}
        {...this.props}
      >
        {this.renderCreatedBy()}
        {category ? this.renderOfferOwner() : null}

        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder={t('name')}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        <FormRow label={t('color')} id="color" text={t('forms:example', { text: '#3a4b5c' })}>
          <ColorPicker
            id="color"
            name="color"
            placeholder={t('color')}
            color={values.color}
            onChange={this.onChangeColor}
          />
        </FormRow>

        <FormRow label={t('weight')} id="weight">
          <Input
            type="number"
            id="weight"
            name="weight"
            placeholder={t('weight')}
            value={values.weight}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        {category ? this.renderParentCategory() : null}

        <FormRow label={t('childrenCategories')} id="childrenCategories">
          <CategoriesSelect
            id="childrenCategories"
            currentCategory={category ? category.id : undefined}
            name="childrenCategories"
            value={values.childrenCategories}
            onChange={this.onChangeChildrenCategories}
            onBlur={handleBlur}
            isMulti
            considerParents
          />
        </FormRow>
        <FormRow label={t('is_default')} id="is_default">
          <Switch
            id="is_default"
            name="is_default"
            checked={values.is_default}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow
          label={t('imageTop')}
          id="imageTop"
          text={t('forms:expected_size', { width: 2048, height: 200 })}
        >
          <ImageUploader
            id="imageTop"
            name="imageTop"
            value={values.imageTop}
            onChange={this.onChangeImageTop}
            onBlur={handleBlur}
            imageWidth={400}
            canBeDeleted={true}
          />
        </FormRow>

        <FormRow
          label={t('imageBottom')}
          id="imageBottom"
          text={t('forms:expected_size', { width: 220, height: 144 })}
        >
          <ImageUploader
            id="imageBottom"
            name="imageBottom"
            value={values.imageBottom}
            onChange={this.onChangeImageBottom}
            onBlur={handleBlur}
            canBeDeleted={true}
          />
        </FormRow>

        <FormRow key="offers" label={t('offers:offers')} id="offers">
          <OffersSelectWithButtons
            id="offers"
            name="offers"
            value={values.offers}
            onChange={this.onChangeOffers}
          />
        </FormRow>

        {this.renderItems()}
      </FormFrame>
    );
  }
}

export default withTranslation('categories')(BaseCategoryForm);
