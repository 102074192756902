import React from 'react';
import { Redirect } from 'react-router';
import Spinner from 'features/ui/Spinner';
import LinkBlock from 'features/ui/LinkBlock';
import CardPage from 'features/ui/Page/CardPage';
import { graphqlById } from 'features/graphql/helpers';
import {
  makePromocodeUrl,
  makePromocodeUrlTo,
} from 'features/promocodes/lib/url';
import PromocodesForm from 'features/promocodes/lib/Form';
import getPromocodeQuery from 'features/promocodes/queries/getPromocodeQuery.gql';
import { FEATURE_ICON } from 'features/promocodes/consts';

import {
  IBasePageEditProps,
  IPromocode,
} from 'features/types';
import { IPromocodeQueryResponse } from 'features/promocodes/types';

class BasePageEdit extends React.PureComponent<IBasePageEditProps<IPromocode>> {
  renderForm(data: IPromocode) {
    return (
      <React.Fragment>
        <LinkBlock viewLink={makePromocodeUrlTo(data, 'view')} />
        <PromocodesForm data={data} />
      </React.Fragment>
    );
  }

  render() {
    const {
      isLoading,
      data,
    } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (!data) {
      return <Redirect to="/404" />;
    }

    const code = data.code ? data.code : '';
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={`Редактирование: ${code}`}
        crumbs={[{ link: makePromocodeUrl(), name: 'Промокоды' }]}
      >
        {data ? this.renderForm(data) : null}
      </CardPage>
    );
  }
}

export default graphqlById<IPromocodeQueryResponse, IBasePageEditProps<IPromocode>>({
  query: getPromocodeQuery,
  queryType: 'PromoCodeQuery',
})(BasePageEdit);
