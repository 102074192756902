import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageHybrids from 'features/hybrids/PageHybrids';
import PageCreate from 'features/hybrids/PageCreate';
import PageEdit from 'features/hybrids/PageEdit';
import PageView from 'features/items/PageView';

export default () => {
  return (
    <AccessControl permission="hybrids:access">
      <Switch>
        <AppRoute path="/hybrids" menuId="hybrids" component={PageHybrids} exact />
        <AppRoute path="/hybrid/create" menuId="hybrids" component={PageCreate} exact />
        <AppRoute path="/hybrid/edit/:id" menuId="hybrids" component={PageEdit} exact />
        <AppRoute path="/hybrid/:id" menuId="hybrids" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
