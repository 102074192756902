import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import FormRow from 'features/ui/FormRow';
import BaseItemForm from 'features/items/lib/Form/Form';
import FileInfoLink from 'features/ui/FileInfoLink';
import FileUploader from 'features/ui/FileUploader';
import changeFileContentHandler from 'features/items/lib/changeFileContentHandler';
import { getContentByType } from 'features/items/lib/getContent';

import {
  CONTENT_TYPE_VIDEO,
  TYPE_VIDEO,
} from 'features/items/consts';

import { IFormValues } from 'features/videos/types';
import { IItem } from 'features/types';
import VideoTypeSelect from 'features/videos/lib/VideoTypeSelect';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IItem;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onChangeFileContent = (event: any) => {
    changeFileContentHandler(event, CONTENT_TYPE_VIDEO, this.props.setFieldValue);
  };

  resolveFields = (fields: any) => {
    const {
      t,
      values,
      item,
      handleBlur,
      handleChange,
    } = this.props;

    const video = item ? getContentByType(item, CONTENT_TYPE_VIDEO) : null;
    fields.splice(0, 0, (
      <FormRow
        key="video.type"
        label={t('type')}
        id="video.type"
      >
        <VideoTypeSelect
          id="video.type"
          name="video.type"
          value={values.video.type}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ));
    fields.splice(4, 0, (
      <FormRow
        key="content"
        label={t('content')}
        id="content"
        errorPath="content|content[0].file"
        required
      >
        <FileUploader
          id="content"
          name="content"
          onChange={this.onChangeFileContent}
          onBlur={handleBlur}
        />
        <div>
          <FileInfoLink
            itemId={item && item.id}
            file={video && video.file}
            name={t('content')}
            awaitLoadingText={t('awaitLoading')}
          />
        </div>
      </FormRow>
    ));

    fields.splice(19, 0, [(
      <FormRow
        key="video.duration"
        label={t('duration')}
        id="video.duration"
        required
      >
        <Input
          type="number"
          id="video.duration"
          name="video.duration"
          value={values.video.duration}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    )]);

    return fields;
  };

  render() {
    return (
      <BaseItemForm
        {...this.props}
        resolveFields={this.resolveFields}
        cancelLink={'/videos'}
        type={TYPE_VIDEO}
      />
    );
  }
}

export default withTranslation('video')(BaseForm);
