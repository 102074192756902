import React from 'react';
import { RowRenderProps } from 'react-table';
import { Badge } from 'reactstrap';
import i18n from 'features/intl/i18n';

export default (props: RowRenderProps) => (
  <Badge color={props.value ? 'success' : 'danger'}>
    {props.value ? i18n.t('dict:active') : i18n.t('dict:not_active')}
  </Badge>
);
