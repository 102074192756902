export const TYPE_BOOK = 'book';
export const TYPE_DOC = 'document';
export const TYPE_VIDEO = 'video';
export const TYPE_AUDIO = 'audiobook';
export const TYPE_COURSE = 'course';
export const TYPE_TEST = 'test';
export const TYPE_EVENT = 'event';
export const TYPE_COLLECTION = 'collection';
export const TYPE_HYBRID = 'hybrid';
export const TYPE_ARTICLE = 'article';

export const CONTENT_TYPE_EPUB = 'book_epub';
export const CONTENT_TYPE_HYBRID = 'book_hybrid';
export const CONTENT_TYPE_PDF = 'document_pdf';
export const CONTENT_TYPE_VIDEO = 'video_mp4';
export const CONTENT_TYPE_AUDIOBOOK = 'audiobook_mp3';

export const FEATURE_ICON = 'icon-list';
