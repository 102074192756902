import React from 'react';
import { get } from 'lodash';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import field from 'lib/field';
import filterChangedValues from 'lib/filterChangedValues';
import { toStr } from 'lib/crudConvert';
import { crudMutate } from 'features/common/helpers';
import {
  TYPE_AUTHOR,
  TYPE_PROVIDER,
  TYPE_PUBLISHER,
} from 'features/creators/consts';
import Form from './Form';

import getCreatorQuery from '../../queries/getCreatorQuery.gql';
import updateCreatorMutation from '../../queries/updateCreatorMutation.gql';
import createCreatorMutation from '../../queries/createCreatorMutation.gql';

import {
  ICreator,
  IFormValues,
  ICreatorInput,
} from 'features/creators/types';

interface ICreatorsFormProps extends WithTranslation {
  data?: ICreator;
}

class CreatorsForm extends React.PureComponent<ICreatorsFormProps> {
  validationSchema = () => {
    const { t } = this.props;
    return Yup.lazy((data) => {
      const values = data as IFormValues;
      if (values.type === TYPE_PROVIDER || values.type === TYPE_PUBLISHER) {
        return Yup.object().shape({
          full_name: Yup.string().required(t('forms:required_field', { field: t('title') })),
        });
      }
      return Yup.object().shape(Object.assign({
        last_name: Yup.string().required(t('forms:required_field', { field: t('last_name') })),
        first_name: Yup.string().required(t('forms:required_field', { field: t('first_name') })),
      }));
    });
  };

  onSubmit = (values: IFormValues, formActions: FormikActions<IFormValues>) => {
    const { data } = this.props;
    const id = get(data, 'id', undefined);
    const valuesCopy = { ...values };
    if (valuesCopy.type === TYPE_PROVIDER || valuesCopy.type === TYPE_PUBLISHER) {
      valuesCopy.short_name = valuesCopy.full_name;
      valuesCopy.first_name = '';
      valuesCopy.last_name = '';
    }
    const formValues = id ? this.getChangedValues(valuesCopy) : valuesCopy;
    const creatorKeys = Object.keys(formValues);
    const creator: ICreatorInput = creatorKeys.reduce(
      (acc: ICreatorInput, name: string) => {
        acc[name] = name === 'avatar' ? formValues[name] : toStr(formValues[name]);
        return acc;
      },
      {},
    );

    crudMutate({
      id,
      formActions,
      mutation: id ? updateCreatorMutation : createCreatorMutation,
      variables: id ? { id, creator } : { creator },
      redirect: '/creators',
      updateRefetchQuery: getCreatorQuery,
      check: !!Object.keys(creator).length,
    });
  };

  getChangedValues(values: IFormValues) {
    const formData = this.buildFormData();
    if (formData) {
      return filterChangedValues(formData, values);
    }
    return {...values};
  }

  buildFormData = () => {
    const { data } = this.props;
    return {
      type: field(data, 'type', TYPE_AUTHOR),
      full_name: field(data, 'full_name', ''),
      short_name: field(data, 'short_name', ''),
      first_name: field(data, 'first_name', ''),
      last_name: field(data, 'last_name', ''),
      description: field(data, 'description', ''),
      avatar: field(data, 'avatar.path', null),
    };
  };

  renderForm = (props: FormikProps<IFormValues>) => (<Form {...props} item={this.props.data} />);

  render() {
    return (
      <Formik
        validationSchema={this.validationSchema()}
        enableReinitialize
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('creators')(CreatorsForm);
