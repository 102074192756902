import React from 'react';
import {
  Col,
  FormGroup,
  FormText,
  Label,
} from 'reactstrap';

export const InfoRow = (
  {
    children,
    label,
    text,
  }: {
    children: React.ReactNode| React.ReactNode[],
    label: string | React.ReactNode,
    text?: string,
  },
) => (
  <FormGroup row>
    <Col md="3">
      <Label>{label}</Label>
    </Col>
    <Col xs="12" md="9">
      {children}
      {text ? <FormText color="muted">{text}</FormText> : null}
    </Col>
  </FormGroup>
);
