import { toast } from 'features/ui/Toast';
import LocalStorage from 'features/store/LocalStorage';
import { TEAMCITY_TOKEN_NAME } from 'features/auth/consts';

function throwError(text: string) {
  toast.error(text);
  throw(text);
}

function errorHandling(exception: Error) {
  toast.error('Произошла сетевая ошибка');
  throw(exception);
}

function responseHandling(response: Response | void) {
  if (!response) {
    throwError('Произошла незвестная ошибка');
    return;
  }
  if (response.status === 401) {
    LocalStorage.remove(TEAMCITY_TOKEN_NAME);
    throwError('Неверный логин или пароль');
  }
  if (response.status === 403) {
    throwError('Доступ запрещён');
  }
  if (response.status === 500) {
    throwError('Произошла ошибка на сервере');
  }
  if (response.status !== 200) {
    throwError('Произошла неизвестная ошибка');
  }
}

export default (body?: object) => {
  const auth = LocalStorage.getString(TEAMCITY_TOKEN_NAME);
  const params = {
    body: JSON.stringify({
      auth,
      ...body,
    }),
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch('/api', params)
    .catch(errorHandling)
    .then(responseHandling);
};
