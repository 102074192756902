import React from 'react';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/promocodes/consts';
import { makePromocodeUrl } from 'features/promocodes/lib/url';
import PromocodesForm from 'features/promocodes/lib/Form';

class PageCreate extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Создание нового промокода"
        crumbs={[{ link: makePromocodeUrl(), name: 'Промокоды' }]}
      >
        <PromocodesForm />
      </CardPage>
    );
  }
}

export default PageCreate;
