import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { flow } from 'lodash';
import {
  match as IMatch,
  Redirect,
} from 'react-router';
import CardPage from 'features/ui/Page/CardPage';
import Spinner from 'features/ui/Spinner';
import LinkBlock from 'features/ui/LinkBlock';
import deepOmit from 'lib/deepOmit';
import SetForm from '../lib/SetForm';
import {
  makeSetUrl,
  makeSetUrlTo,
} from '../lib/url';
import getSetQuery from '../queries/getSet.gql';
import { FEATURE_ICON } from '../consts';

import {
  ISet,
} from '../types';

interface IPageSetProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  set: ISet;
}

interface IPageSetState {
  set: ISet;
}

class PageEdit extends React.Component<IPageSetProps, IPageSetState> {
  render() {
    const {
      t,
      set,
      isLoading,
    } = this.props;
    if (!isLoading && !set) {
      return <Redirect to="/404" />;
    }
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={set ? `${t('edit_set')}: ${set.name}` : ''}
        crumbs={[{ link: makeSetUrl(), name: t('set') }]}
      >
        <LinkBlock viewLink={makeSetUrlTo(set, 'view')} />
        <SetForm set={set} />
      </CardPage>
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISet>) => {
  const { data, ownProps } = props;

  const set = data && data.SetQuery ? deepOmit(data.SetQuery.items[0], '__typename') as ISet : null;
  return {
    set,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphql(getSetQuery, {
    props: mapResultsToProps,
    options: ({ match }: { match: IMatch<any> }) => {
      const { params } = match;
      const { id } = params || { id: 0 };
      return {
        context: {
          isGlobalLoading: true,
        },
        variables: {
          id: [id],
        },
      };
    },
  }),
  withTranslation('sets'),
)(PageEdit);
