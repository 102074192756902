import React from 'react';
import 'react-dates/initialize';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import { ColorResult } from 'react-color';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import Picker from './Picker';
import styles from './style.module.scss';

interface IColorPickerProps {
  color: string;
  id?: string;
  invalid?: boolean;
  name?: string;
  left?: number;
  onBlur?: (e: any) => void;
  onChange?: (color: string) => void;
  placeholder?: string;
}

interface IColorPickerState {
  color: string;
  displayColorPicker: boolean;
}

class ColorPicker extends React.Component<IColorPickerProps, IColorPickerState> {
  // static getDerivedStateFromProps(props: IColorPickerProps, state: IColorPickerState) {
  //   return {
  //     ...state,
  //     color: props.color,
  //   };
  // }

  constructor(props: IColorPickerProps) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: props.color,
    };
  }

  shouldComponentUpdate(nextProps: IColorPickerProps, nextState: IColorPickerState) {
    return (
      this.state.color !== nextState.color ||
      this.state.displayColorPicker !== nextState.displayColorPicker
    );
  }

  onChange = (e: any) => {
    const value = e.target.value;
    if (value !== this.state.color) {
      let color = value.trim();
      if (color === '#') {
        color = '';
      } else if (color.length > 0) {
        color = value.replace(/^#*/, '#');
      }
      this.setState(
        { color },
        () => {
          const { onChange } = this.props;
          if (onChange) {
            onChange(color);
          }
        },
      );
    }
  };

  onChangeColorPicker = (colorResult: ColorResult) => {
    const value = colorResult.hex;
    this.setState(
      { color: value },
      () => {
        const { onChange } = this.props;
        if (onChange) {
          onChange(value);
        }
      },
    );
  };

  onClickClearButton = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.setState(
      { color: '' },
      () => {
        const { onChange } = this.props;
        if (onChange) {
          onChange('');
        }
      },
    );
  }

  onToggleColorPicker = () => {
    this.setState({
      displayColorPicker: !this.state.displayColorPicker,
    });
  }

  onOpenColorPicker = () => {
    this.setState({ displayColorPicker: true });
  }

  onCloseColorPicker = () => {
    this.setState({ displayColorPicker: false });
  }

  onBlur = (event: React.SyntheticEvent) => {
    event.persist();
    this.setState({ displayColorPicker: false }, () => {
      const { onBlur } = this.props;
      if (onBlur) {
        onBlur(event);
      }
    });
  }

  renderCover() {
    if (this.state.displayColorPicker) {
      return (
        <div
          className={styles.cover}
          onClick={this.onCloseColorPicker}
        />
      );
    }
    return null;
  }

  renderColorPicker() {
    const {
      color,
      displayColorPicker,
    } = this.state;
    if (displayColorPicker) {
      const { left } = this.props;
      const style: {[key: string]: any} = {};
      if (left) {
        style.left = left;
      }
      return (
        <div style={style} className={styles.popOver}>
          <Picker
            color={color}
            onChange={this.onChangeColorPicker}
            onAccept={this.onCloseColorPicker}
            onCancel={this.onCloseColorPicker}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      id,
      invalid,
      placeholder,
    } = this.props;
    const { color } = this.state;

    return (
      <OutsideClickHandler onOutsideClick={this.onCloseColorPicker}>
        <InputGroup>
          <InputGroupAddon addonType="prepend" style={{ position: 'relative' }}>
            <span
              className={cn(styles.swatch, 'form-control')}
              onClick={this.onToggleColorPicker}
            >
              <div
                className={cn({ [styles.transparentImage]: !color }, styles.swatchColor)}
                style={{ backgroundColor: color }}
              />
              <i className={cn('fa', 'fa-angle-down', styles.icon)} />
            </span>
            {this.renderColorPicker()}
          </InputGroupAddon>
          <Input
            id={id}
            value={color}
            invalid={invalid}
            placeholder={placeholder}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
          <InputGroupAddon addonType="append">
            <Button
              outline
              color="secondary"
              type="button"
              onClick={this.onClickClearButton}
            >
              <i className="fa fa-close" />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </OutsideClickHandler>
    );
  }
}

export default ColorPicker;
