import React from 'react';
import DragNDropComponent from 'features/ui/DragNDropComponent';
import { reorder } from 'features/ui/DragNDropComponent/methods';
import QuestionComponent from './QuestionComponent';

interface IStageContainerProps {
  values: IQuestion[];
  onChange: (value: any) => void;
}

const QuestionsContainer = ({ values, onChange }: IStageContainerProps) => {
  const addQuestion = () => {
    const hash = Date.now();
    const newItem = {
      id: `temp-${hash}`, // удаляем при отправке, ориентируясь на 'temp'
      text: '',
      type: 'text',
      sort: values.length,
      answer: {
        id: `temp-other-${hash}`, // удаляем при отправке, ориентируясь на 'temp'
        type: 'default',
        points: 1,
        options: [],
      },
    };
    onChange([...values, newItem]);
  };

  const remove = (id: string) => onChange(values.filter(itm => itm.id !== id));

  const changeParam = (id: string, param: string, value: IAnswerOption[]) => {
    onChange(values.map((itm) => {
      if (itm.id !== id) {
        return itm;
      }
      const pathArray = Array.isArray(param) ? param : param.match(/([^[.\]])+/g);
      pathArray.reduce((acc, key, i) => {
        if (acc[key] === undefined) {
          acc[key] = {};
        };
        if (i === pathArray.length - 1) {
          acc[key] = value
        };
        return acc[key];
      }, itm);
      return { ...itm };
    }));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newList = reorder(values, result.source.index, result.destination.index);
    onChange(newList);
  };

  const renderQuestions = () => (
    <DragNDropComponent items={values} onDragEnd={onDragEnd} childPropName="item">
      <QuestionComponent changeParam={changeParam} remove={remove} />
    </DragNDropComponent>
  );

  return (
    <div>
      <div className="card m-0 border-0">
        {renderQuestions()}
      </div>
      <div>
        <button type="button" className="btn btn-secondary" onClick={addQuestion}>
          Добавить вопрос
        </button>
      </div>
    </div>
  );
};

export default QuestionsContainer;
