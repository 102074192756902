import React from 'react';
import { Redirect } from 'react-router';
import LinkBlock from 'features/ui/LinkBlock';
import CardPage from 'features/ui/Page/CardPage';
import Spinner from 'features/ui/Spinner';
import { FEATURE_ICON } from 'features/usergroups/consts';
import {
  makeUserGroupUrl,
  makeUserGroupUrlTo,
} from 'features/usergroups/lib/url';
import { graphqlById } from 'features/graphql/helpers';
import UserGroupsForm from 'features/usergroups/lib/Form';
import getUserGroupQuery from 'features/usergroups/queries/getUserGroupQuery.gql';

import { IUserGroupQueryResponse } from 'features/usergroups/types';
import {
  IBasePageEditProps,
  IUserGroup,
} from 'features/types';

class BasePageEdit extends React.PureComponent<IBasePageEditProps<IUserGroup>> {
  renderForm(data: IUserGroup) {
    return (
      <React.Fragment>
        <LinkBlock viewLink={makeUserGroupUrlTo(data, 'view')} />
        <UserGroupsForm data={data} />
      </React.Fragment>
    );
  }

  render() {
    const {
      data,
      isLoading,
    } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (!data) {
      return <Redirect to="/404" />;
    }

    const name = data.name ? data.name : '';
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={data ? `Редактирование: ${name}` : ''}
        crumbs={[{ link: makeUserGroupUrl(), name: 'Группы пользователей' }]}
      >
        {data ? this.renderForm(data) : null}
      </CardPage>
    );
  }
}

export default graphqlById<IUserGroupQueryResponse, IBasePageEditProps<IUserGroup>>({
  query: getUserGroupQuery,
  queryType: 'UserGroupQuery',
})(BasePageEdit);
