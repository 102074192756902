import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageFeature from '../PageFeature';
import PageCreate from '../PageCreate';
import PageEdit from '../PageEdit';
import PageView from '../PageView';

export default () => (
  <AccessControl permission="offers:access">
    <div className="animated">
      <Switch>
        <AppRoute path="/feature-toggle" menuId="feature" component={PageFeature} exact />
        <AppRoute path="/feature-toggle/create" menuId="feature" component={PageCreate} exact />
        <AppRoute path="/feature-toggle/edit/:id" menuId="feature" component={PageEdit} exact />
        <AppRoute path="/feature-toggle/:id" menuId="feature" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </div>
  </AccessControl>
);
