import { createUploadLink } from 'apollo-upload-client';
import {
  APP_API_URL,
} from 'features/app/consts';

import { makeFetch } from 'lib/fetch';
import appStore from 'features/app/mobxStore';

const httpLink = createUploadLink({
  uri: APP_API_URL,
  fetch: makeFetch({
    onprogress: (event: any) => {
      const globalProgress = Math.round(event.loaded / event.total * 100);
      appStore.updateState({
        globalProgress,
      });
    },
  }),
});

export default httpLink;
