import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from 'reactstrap';
import LocalStorage from 'features/store/LocalStorage';
import request from '../lib/request';
import { TEAMCITY_TOKEN_NAME } from 'features/auth/consts';

interface ILoginProps {
  onLogged?: () => void;
}

interface ILoginState {
  login: string;
  password: string;
}

export default class Login extends React.Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props);
    this.state = {
      login: '',
      password: '',
    };
  }

  onLoginChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ login: e.currentTarget.value });
  }

  onPassworkChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value });
  }

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { onLogged } = this.props;
    const {
      login,
      password,
    } = this.state;
    const auth = window.btoa(`${login}:${password}`);
    request({ auth, action: 'login' })
      .then(() => {
        if (onLogged) {
          LocalStorage.setString(TEAMCITY_TOKEN_NAME, auth);
          onLogged();
        }
      })
      .catch(() => LocalStorage.remove('TEAMCITY_TOKEN_NAME'));
  }

  render() {
    const {
      login,
      password,
    } = this.state;
    return (
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <CardGroup className="mb-4">
              <Card className="p-4">
                <CardBody>
                  <Form
                    onSubmit={this.onSubmit}
                    data-test="login-form"
                  >
                    <h1>Вход</h1>
                    <p className="text-muted">Пожалуйста, введите логин и пароль для доступа к Развертыванию приложений</p>
                    <InputGroup className="mb-3">
                      <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-user" />
                      </span>
                      </div>
                      <Input
                        type="text"
                        value={login}
                        placeholder="Имя пользователя"
                        onChange={this.onLoginChange}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-lock" />
                      </span>
                      </div>
                      <Input
                        type="password"
                        value={password}
                        placeholder="Пароль"
                        onChange={this.onPassworkChange}
                      />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4">Войти</Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}
