import React from 'react';
import {
  Button,
  Input,
} from 'reactstrap';
import { get, isInteger, toNumber } from 'lodash';
import ReactTable from 'react-table';
import gql from 'graphql-tag';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { client } from 'features/graphql';
import { makeUserGroupUrlTo } from 'features/usergroups/lib/url';
import { Confirm } from 'features/ui/Modal';
import { toast } from 'features/ui/Toast';
import RenderIf from 'features/ui/RenderIf';
import AddButton from 'features/ui/AddButton';
import DeleteButton from 'features/ui/DeleteButton';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import { IOfferPin } from '../types';
import { InfoRow } from 'features/ui/InfoRow';
import getOfferPins from 'features/offers/queries/getOfferPins.gql';

const UI_STATE_TABLE = 'table';
const UI_STATE_ADDING_PINS = 'adding_pins';

const ADD_MUTATION = gql`
  mutation addOfferPin($offerPin: OfferPinInput) {
    OfferPinMutation(offerPin: $offerPin) {
      id
      pin
      user_groups {
        id
        name
      }
      user_ttl
    }
  }
`;

const DELETE_MUTATION = gql`
mutation deleteOfferPin($id: ID) {
  OfferPinMutation(id: $id, delete: true) {
    id
    pin
    user_groups {
      id
      name
    }
    user_ttl
  }
}
`;

interface IPinCodesProps extends WithTranslation {
  offerId: ID;
  hasControl?: boolean;
  refetch?: (vars?: any) => Promise<any>;
}

interface IPinCodesState {
  pins: IOfferPin[];
  page: number;
  perPage: number;
  pagination: {
    total?: number;
    perPage?: number;
    currentPage?: number;
    hasPages?: number;
  };
  amount: string;
  ttl: string | null;
  currentPin: number;
  uiState: string;
  pinForRemove: number | null;
  addTtlEnable: boolean;
  orderBy: {
    field: string;
    direction: string;
  };
}

class PinCodes extends React.Component<IPinCodesProps, IPinCodesState> {
  static defaultProps = {
    hasControl: true,
  };

  private _cancelAdding: boolean;
  private _isMounted: boolean;
  private _wasUpdated: boolean;

  constructor(props: IPinCodesProps) {
    super(props);
    this.state = {
      pins: [],
      amount: '1',
      page: 1,
      perPage: 20,
      ttl: null,
      currentPin: 0,
      uiState: UI_STATE_TABLE,
      pinForRemove: null,
      addTtlEnable: false,
      pagination: {},
      orderBy: {
        field: 'id',
        direction: 'desc',
      },
    };
    this._cancelAdding = false;
    this._isMounted = false;
    this._wasUpdated = false;
  }

  componentDidMount() {
    this.fetchData();
    this._isMounted = true;
  }

  componentDidUpdate(prevProps: Readonly<IPinCodesProps>, prevState: Readonly<IPinCodesState>, snapshot?: any) {
    if (this.state.perPage !== prevState.perPage || this.state.page !== prevState.page || this.state.orderBy !== prevState.orderBy) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._cancelAdding = true;
    this._isMounted = false;
    if (this._wasUpdated) {
      const { refetch } = this.props;
      if (refetch) {
        refetch();
      }
    }
  }

  fetchData() {
    const { page, perPage, orderBy } = this.state;
    const { offerId } = this.props;
    client.query({
      query: getOfferPins,
      fetchPolicy: 'network-only',
      variables: {
        page,
        perPage,
        orderBy,
        filterByFields: {
          offer: {
            operator: '=',
            value: offerId,
          },
          users: {
            operator: 'is',
            value: 'null',
          },
        },
      },
    }).then((res) => {
      const pins = get(res, 'data.OfferPinQuery.items');
      const pagination = get(res, 'data.OfferPinQuery.cursor');
      this.setState({ pins, pagination });
    });
  }
  onChangeAmount = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const amount = e.currentTarget.value;
    this.setState({ amount });
  };

  onChangeUsersTtl = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const ttl = e.currentTarget.value;
    this.setState({ ttl });
  };

  onAddPin = async () => {
    const {
      offerId,
      t,
    } = this.props;
    const {
      amount,
      ttl,
    } = this.state;
    const amountNum = parseInt(amount, 10);
    if (!isInteger(amountNum) || amountNum <= 0) {
      toast.warn(t('forms:must_be_greater_than_0'));
      return;
    }

    await this.setState({
      uiState: UI_STATE_ADDING_PINS,
      currentPin: 0,
    });
    let wereErrors = false;
    for (let i = 0; i < amountNum; i += 1) {
      if (this._cancelAdding) {
        break;
      }
      await client
        .mutate({
          mutation: ADD_MUTATION,
          variables: {
            offerPin: {
              offer: offerId,
              user_ttl: +ttl === 0 ? null : toNumber(ttl),
            },
          },
          context: { isGlobalLoading: true },
        })
        .catch(() => {
          wereErrors = true;
        });
      if (this._isMounted) {
        await this.setState({ currentPin: i + 1 });
      }
      if (wereErrors) {
        break;
      }
    }
    this._cancelAdding = false;
    this._wasUpdated = true;
    if (wereErrors) {
      toast.error(t('pin_erors_were_happend_while_adding_pins'));
    }
    const { currentPin } = this.state;
    if (currentPin > 0) {
      toast.info(t('pin_added', { count: this.state.currentPin }));
    }
    if (this._isMounted) {
      await this.setState({ uiState: UI_STATE_TABLE });
    }
    if (!wereErrors) {
      const { refetch } = this.props;
      if (refetch) {
        refetch();
      }
    }
  };

  onCancelAdding = () => {
    this._cancelAdding = true;
  };

  onDeletePin = () => {
    const foundPin = this.state.pins.find(p => p.pin === this.state.pinForRemove);
    if (!foundPin) {
      return;
    }
    const { id } = foundPin;
    this._wasUpdated = true;
    client
      .mutate({
        mutation: DELETE_MUTATION,
        variables: { id, delete: true },
        context: { isGlobalLoading: true },
      })
      .then(() => {
        const { t } = this.props;
        const pin = this.state.pins.find(p => p.id === id);
        if (pin) {
          this.setState(
            { pins: this.state.pins.filter(p => p.id !== id) },
            () => {
              toast.info(t('pin_deleted_successfully', { pin: pin.pin }));
            },
          );
        }
      });
  };

  onOpenRemoveModal = (pinForRemove: number) => {
    this.setState({ pinForRemove });
  };

  onCloseRemoveModal = () => {
    this.setState({ pinForRemove: null });
  };

  makeColumns() {
    const {
      t,
      hasControl,
    } = this.props;
    return [
      {
        Header: t('pin'),
        accessor: 'pin',
        className: 'text-right',
        maxWidth: 150,
      },
      {
        Header: t('pin_user_groups'),
        accessor: 'user_groups',
        Cell: (props: any) => props.value.map(
          (pin: any) => (
            <LinkToEntityInLists
              key={pin.id}
              id={pin.id}
              name={pin.name}
              to={makeUserGroupUrlTo(pin, 'view')}
            />
          ),
        ),
      },
      {
        Header: t('pin_user_ttl'),
        accessor: 'user_ttl',
      },
      {
        id: 'id',
        Header: '',
        Cell: (props: any) => {
          const handler = () => this.onOpenRemoveModal(props.row.pin);
          return <DeleteButton type="button" onClick={handler} />;
        },
        className: 'text-center',
        maxWidth: 60,
        show: hasControl,
      },
    ];
  }

  onSortedChange = (sortData: Array<{ id: string, desc?: boolean }>) => {
    if (sortData.length) {
      const { id: field, desc } = sortData[0];
      this.setState({
        orderBy: {
          field,
          direction: desc ? 'desc' : 'asc',
        },
      });
    }
  }
  renderTable = () => {
    const { pins, pagination, perPage, orderBy } = this.state;
    const currentPage = pagination.currentPage ? pagination.currentPage - 1 : 0;
    const pages = Math.round(pagination.total / pagination.perPage);
    const sorted = [{
      id: orderBy.field,
      desc: orderBy.direction === 'desc' || undefined,
    }];
    const onPageChange = (value: number) => this.setState({ page: value + 1 });
    const onPageSizeChange = (value: number) => this.setState({ perPage: value, page: 1 });
    return (
      <ReactTable
        manual
        data={pins}
        pageSize={perPage}
        page={currentPage}
        pages={pages}
        sorted={sorted}
        columns={this.makeColumns()}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortedChange={this.onSortedChange}
      />
    );
  }

  render() {
    const {
      t,
      hasControl,
    } = this.props;
    const {
      pins,
      ttl,
      amount,
      uiState,
      currentPin,
      pinForRemove,
    } = this.state;

    if (uiState === UI_STATE_ADDING_PINS) {
      return (
        <React.Fragment>
          <span>{t('pin_code_generation')}: {currentPin} / {amount}</span>
          <br />
          <Button color="secondary" onClick={this.onCancelAdding}>
            {t('forms:cancel')}
          </Button>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <RenderIf condition={Boolean(hasControl)}>
          <InfoRow
            label={t('add_pins_value')}
          >
            <Input
              type="number"
              style={{ maxWidth: 650 }}
              value={amount}
              onChange={this.onChangeAmount}
              onBlur={this.onChangeAmount}
            />
          </InfoRow>
            <InfoRow
              label={t('enable_ttl_value')}
              text={t('default_users_ttl')}
            >
              <Input
                type="number"
                style={{ maxWidth: 650 }}
                placeholder="0"
                min="0"
                value={ttl || ''}
                onChange={this.onChangeUsersTtl}
                onBlur={this.onChangeUsersTtl}
              />
            </InfoRow>
          <AddButton
            type="button"
            caption={t('pin_add_new')}
            onClick={this.onAddPin}
          />
        </RenderIf>
        {pins.length ? this.renderTable() : <p>{t('pins_not_found')}</p>}
        <Confirm
          title={'Удаление пина'}
          description={`Удалить пин №${pinForRemove} безвозвратно?`}
          type="danger"
          isOpened={pinForRemove !== null}
          onClose={this.onCloseRemoveModal}
          onResolve={this.onDeletePin}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation('offers')(PinCodes);
