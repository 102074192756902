import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  client,
  gql,
} from 'features/graphql';
import {
  IListQueryVariables,
  ISelectOption,
  ISelectOptionsByValue,
  ISharedSelectProps,
} from 'features/types';
import {
  ITag,
  ITagQueryResponse,
} from 'features/tags/types';
import ReactSelect from 'features/ui/ReactSelect';

export interface IOnChangeInput {
  labels: string[];
  ids: Array<number | string>;
}

interface IInputData extends ISharedSelectProps {
  value: any;
}

interface ITagsSelectProps extends IInputData {
  data: ITag[];
}

interface ITagsSelectState {
  options: ISelectOption[];
  optionsByValue: ISelectOptionsByValue;
  creatableOptions: ISelectOption[];
}

class TagsSelect extends React.PureComponent<ITagsSelectProps, ITagsSelectState> {
  static getDerivedStateFromProps(props: any) {
    const options = TagsSelect.mapCreatorsToOptions(props.data);
    const optionsByValue = options.reduce(
      (res: ISelectOptionsByValue, option: ISelectOption) => {
        res[option.value] = option;
        return res;
      },
      {});
    return {
      options,
      optionsByValue,
    };
  }

  static mapCreatorsToOptions = (data: ITag[]) => data.map(tag => ({
    value: +tag.id,
    label: tag.name,
  }));

  state = {
    options: [],
    optionsByValue: {},
    creatableOptions: [],
  };

  loadOptions = (value: string) => {
    return client.query<ITagQueryResponse>({
      query: QUERY,
      variables: {
        pageNum: 1,
        perPage: 100,
        name: value,
      },
    }).then((res) => {
      if (res.data && res.data.TagQuery) {
        return TagsSelect.mapCreatorsToOptions(res.data.TagQuery.items);
      }
      return [];
    });
  };

  onChange = ({ data, creatableOptions }: any) => {
    const realOptions = data.filter(
      (option: ISelectOption) => !creatableOptions.some((cur: ISelectOption) => cur.value === option.value),
    );

    this.props.onChange({
      labels: data.map((cur: ISelectOption) => cur.label),
      ids: realOptions.map((cur: ISelectOption) => cur.value),
    });
  };

  onCreateOption = ({ selectedOptions, creatableOptions }: any) => {
    const { value } = this.props;

    const labels = [...selectedOptions, ...creatableOptions].map((cur: ISelectOption) => cur.label);

    this.props.onChange({
      labels,
      ids: value,
    });
  };

  formatCreateLabel = (value: string) => `Создать новый тег "${value}"`;

  render() {
    const {
      id,
      name,
      value,
      onBlur,
    } = this.props;

    const {
      options,
      creatableOptions,
    } = this.state;

    const creatableValue = creatableOptions.map((option: ISelectOption) => option.value);

    return (
      <ReactSelect
        id={id}
        name={name}
        options={[...options, ...creatableOptions]}
        isMulti
        async
        creatable
        formatCreateLabel={this.formatCreateLabel}
        onCreateOption={this.onCreateOption}
        createOptionPosition="first"
        loadOptions={this.loadOptions}
        value={[...value, ...creatableValue]}
        onChange={this.onChange}
        onBlur={onBlur}
        onPaste={true}
      />
    );
  }
}

const QUERY = gql`
  query getTagsForSelect($id: [ID], $pageNum: Int, $perPage: Int, $name: String) {
    TagQuery(
      id: $id,
      page: $pageNum,
      perPage: $perPage,
      searchByFields: { name: $name }
    ) {
      items {
        id
        name
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<IInputData, ITagQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;

  return {
    data: data && data.TagQuery ? data.TagQuery.items : [],
    ...ownProps,
  };
};

export default graphql<IInputData, ITagQueryResponse, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: (ownProps) => {
    return {
      variables: {
        id: ownProps.value,
        pageNum: 1,
        perPage: 100,
      },
    };
  },
})(TagsSelect);
