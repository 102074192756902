import i18n from 'features/intl/i18n';
import * as Yup from 'yup';

export interface ICommonProps {
  field: string;
  required?: boolean;
}

export interface IFileProps {
  ext: string;
  formats: string[];
}

export const IMAGE_EXTENSIONS = '*.png, *.jpg, *.gif';
export const IMAGE_MIME_TYPES = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

function addRequire(validator: Yup.Schema<any>, field: string, required: boolean) {
  if (required) {
    return validator.required(i18n.t('forms:required_field', { field }));
  }
  return validator.nullable(true);
}

/**
 * Yup не дает корректно обработать пустую строку ('') выдавая NaN. Но пустая
 * строка может быть валидным значением если поле не является обязательным.
 *
 * Это обходной путь, который требует в формах ограничить пользовательский ввод
 * только цифрами.
 */
function nanToUndefined(num: number) {
  return isNaN(num) ? undefined : num;
}

export function weightValidator({
  field,
  min = -10000,
  max = 10000,
  required = false,
}: ICommonProps & {
  min?: number,
  max?: number,
}) {
  const validator = Yup
    .number()
    .transform(nanToUndefined)
    .min(min, i18n.t('forms:wrong_min_value', { field, value: min }))
    .max(max, i18n.t('forms:wrong_max_value', { field, value: max }));
  return addRequire(validator, field, required);
}

export function minValidator({ field, required = false, min }: ICommonProps & { min: number }) {
  const validator = Yup
    .number()
    .transform(nanToUndefined)
    .min(min, i18n.t('forms:wrong_min_value', { field, value: min }));
  return addRequire(validator, field, required);
}

export function minIntValidator({ field, required = false, min }: ICommonProps & { min: number }) {
  const validator = Yup
    .number()
    .transform(nanToUndefined)
    .min(min, i18n.t('forms:wrong_min_value', { field, value: min }))
    .integer(i18n.t('forms:wrong_integer', { field }));
  return addRequire(validator, field, required);
}

export function intValidator({ field, required = false }: ICommonProps) {
  const validator = Yup
    .number()
    .transform(nanToUndefined)
    .integer(i18n.t('forms:wrong_integer', { field }));
  return addRequire(validator, field, required);
}

export function stringValidator({ field, required = false }: ICommonProps) {
  const validator = Yup
    .string()
    .trim();
  return addRequire(validator, field, required);
}

export function colorValidator({ field, required = false }: ICommonProps) {
  let validator = Yup.string();
  validator = addRequire(validator, field, required) as Yup.StringSchema;
  return validator.matches(
    required ? /^\#[a-fA-F0-9]{6}$/ : /^(\#[a-fA-F0-9]{6})?$/,
    i18n.t('forms:wrong_format', { field }),
  );
}

export function fileValidator({
  ext,
  formats,
  field,
  required = false,
}: ICommonProps & {
  ext: string,
  formats: string[],
}) {
  let validator = Yup.mixed();
  validator = addRequire(validator, field, required);
  validator = validator.test(
    field,
    i18n.t('forms:supported_extentions', { ext }),
    (f: File | null | undefined) => {
      const type = (f && typeof f.type === 'string') ? f.type : '';
      const isFileSupported = formats.includes(type);
      if (required) {
        return isFileSupported;
      }
      return isFileSupported || f === null || f === undefined;
    },
  );
  return validator;
}

export function imageFileValidator({ field, required = false }: ICommonProps) {
  return Yup.lazy((value: File | string | null) => {
    if (typeof value === 'string') {
      return Yup.string().test('', '', () => true);
    }
    return fileValidator({
      field,
      required,
      ext: IMAGE_EXTENSIONS,
      formats: IMAGE_MIME_TYPES,
    });
  });
}

export function ipAddressValidator({ field, required = false }: ICommonProps) {
  const validator = Yup
    .string()
    .matches(
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
      i18n.t('forms:wrong_ip_address', { field }),
    );
  return addRequire(validator, field, required);
}

export function emailValidator({ field, required = false }: ICommonProps) {
  const validator = Yup
    .string()
    .matches(
      /^@?(([a-zA-Z0-9_+-]+\.)+[a-zA-Z]{2,})$/,
      i18n.t('forms:wrong_email', { field }),
    );
  return addRequire(validator, field, required);
}
