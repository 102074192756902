import React from 'react';
import { match as IMatch } from 'react-router';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PagePopularRequests from '../PagePopularRequests';
import PageCreate from '../PageCreate';
import PageEdit from '../PageEdit';
import PageView from '../PageView';

export default ({ match }: { match: IMatch<any> }) => {
  return (
    <AccessControl permission="offers:access">
      <div className="animated">
        <Switch>
          <AppRoute path="/popular_requests" menuId="popular_requests" component={PagePopularRequests} exact />
          <AppRoute path="/popular_request/create" menuId="popular_requests" component={PageCreate} exact />
          <AppRoute path="/popular_request/edit/:id" menuId="popular_requests" component={PageEdit} exact />
          <AppRoute path="/popular_request/:id" menuId="popular_requests" component={PageView} exact />
          <Route name="404" component={Page404} />
        </Switch>
      </div>
    </AccessControl>
  );
};
