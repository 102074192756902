import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import FormRow from 'features/ui/FormRow';
import Switch from 'features/ui/Switch';
import BaseItemForm from 'features/items/lib/Form/Form';
import FileInfoLink from 'features/ui/FileInfoLink';
import FileUploader from 'features/ui/FileUploader';
import changeFileContentHandler from 'features/items/lib/changeFileContentHandler';
import { getContentByType } from 'features/items/lib/getContent';
import { CONTENT_TYPE_PDF } from 'features/items/consts';

import { IFormValues } from 'features/documents/types';
import { IItem } from 'features/types';
import { Input } from 'reactstrap';
import { RETAIL_ANDROID_BUNDLE_ID, RETAIL_IOS_BUNDLE_ID, RETAIL_WEB_BUNDLE_ID } from 'features/offers/consts';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IItem;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onChangeFileContent = (event: any) => {
    changeFileContentHandler(event, CONTENT_TYPE_PDF, this.props.setFieldValue);
  };

  onBlurISBN = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { values } = this.props;

    if (!values.prices.filter(p => !p.delete).length) {
      this.props.setFieldValue('prices', [
        {
          reference_price: 379,
          bundle_id: RETAIL_ANDROID_BUNDLE_ID,
          in_app_id: `gph${e.target.value}`,
        },
        {
          reference_price: 379,
          bundle_id: RETAIL_IOS_BUNDLE_ID,
          in_app_id: `ru.alpinabook.reader.iphone.${e.target.value}.worldwide`,
        },
        {
          reference_price: 379,
          bundle_id: RETAIL_WEB_BUNDLE_ID,
          in_app_id: `ru.alpinabook.reader.web.${e.target.value}`,
        },
      ]);
    }
  };

  resolveFields = (fields: any) => {
    const {
      t,
      values,
      item,
      handleBlur,
      handleChange,
    } = this.props;

    const pdf = item ? getContentByType(item, CONTENT_TYPE_PDF) : null;

    fields.splice(4, 0, (
      <FormRow
        key="content"
        label={t('content')}
        id="content"
        errorPath="content|content[0].file"
        required
      >
        <FileUploader
          id="content"
          name="content"
          onChange={this.onChangeFileContent}
          onBlur={handleBlur}
        />
        <div>
          <FileInfoLink file={pdf && pdf.file} name={t('content')} />
        </div>
      </FormRow>
    ));

    fields.splice(1, 0, [(
      <FormRow
        key="document.is_important"
        label={t('is_important')}
        id="document.is_important"
      >
        <Switch
          id="document.is_important"
          name="document.is_important"
          value="1"
          checked={!!values.document.is_important}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    )]);

    fields.splice(21, 0, [(
      <FormRow
        key="document.isbn"
        label={t('isbn')}
        id="document.isbn"
      >
        <Input
          type="text"
          id="document.isbn"
          name="document.isbn"
          value={values.document.isbn}
          onChange={handleChange}
          onBlur={this.onBlurISBN}
        />
      </FormRow>
    )]);
    return fields;
  };

  render() {
    return (
      <BaseItemForm
        {...this.props}
        resolveFields={this.resolveFields}
        cancelLink={'/documents'}
      />
    );
  }
}

export default withTranslation('documents')(BaseForm);
