import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import CardPage from 'features/ui/Page/CardPage';
import { IPopularRequest } from '../types';
import PopularRequestForm from '../lib/PopularRequestsForm';
import { makePopularRequestUrl } from '../lib/url';
import { FEATURE_ICON } from '../consts';

interface IPageCreateProps extends WithTranslation {
  request: IPopularRequest;
}

class PageCreate extends React.Component<IPageCreateProps> {
  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('new_popular_request')}
        crumbs={[{ link: makePopularRequestUrl(), name: t('popular_requests') }]}
      >
        <PopularRequestForm />
      </CardPage>
    );
  }
}

export default withTranslation('popular_requests')(PageCreate);
