import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { set } from 'lodash';
import CardPage from 'features/ui/Page/CardPage';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import makeColumns from './columns';
import getSubscriptionsQuery from '../queries/getSubscriptions.gql';
import deleteSubscriptionQuery from '../queries/deleteSubscription.gql';
import { makeSubscriptionUrl } from '../lib/url';
import { SUBSCRIPTION_ICON } from '../consts';

import { ISubscription } from 'features/subscriptions/types';

class PageSubscription extends React.Component<ISubscription> {
  constructor(props: ISubscription) {
    super(props);
    this._columns = makeColumns();
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.name) {
      set(variables, 'filterByFields.name', {
        operator: '=',
        value: fields.name,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={SUBSCRIPTION_ICON}
        buttons={[{ link: '/subscriptions/create', name: t('add_new_subscription') }]}
        title={t('subscriptions')}
      >
        <IndexTableBase
          query={getSubscriptionsQuery}
          queryResultName="SubscriptionQuery"
          columns={this._columns}
          makeCrudUrl={makeSubscriptionUrl}
          filterFields={['id', 'name']}
          deleteMutation={deleteSubscriptionQuery}
          prepareRequestData={this.prepareRequestData}
          isFilterableById={true}
          showAutoEditIconColumn={true}
          showViewIconColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('subscriptions')(PageSubscription);
