import React from 'react';
import {
  Input,
} from 'reactstrap';
import { range } from 'lodash';

interface IYearSelectProps {
  id?: string;
  name?: string;
  value: number;
  onChange: any;
  onBlur: any;
}

interface IIYearSelectPropsState {
  options: number[];
}

class YearSelect extends React.PureComponent<IYearSelectProps, IIYearSelectPropsState> {
  state = {
    options: range((new Date()).getFullYear() + 1, 1945),
  };

  render() {
    const {
      id,
      name,
      value,
      onChange,
      onBlur,
    } = this.props;

    const { options } = this.state;

    return (
      <Input
        type="select"
        name={name || 'year'}
        id={id || 'year'}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      >
        <option value="0">Не выбрано</option>
        {options.map(year => (
          <option
            key={year}
            value={year}
          >
            {year}
          </option>
        ))}
      </Input>
    );
  }
}

export default YearSelect;
