import React from 'react';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import { makeItemUrlTo } from '../url';
import { IItem } from '../../types';

export default ({
  value,
  action,
}: {
  value: IItem,
  action?: string,
}) => (
  <LinkToEntityInLists
    id={value.id}
    name={`[${value.type}] ${value.name}`}
    to={makeItemUrlTo(value, action)}
  />
);
