import React from 'react';
import { get } from 'lodash';
import { ICategory } from '../../types';

export default ({ category }: { category: ICategory }) => {
  const id = get(category, 'ownerOffer.id', null);
  if (id) {
    return <span className="text-muted">HR</span>;
  }
  return <span className="text-success">ADMIN</span>;
};
