import React from 'react';
import {
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import ReactSelect from 'features/ui/ReactSelect/ReactSelectWithId';

interface ILocaleSelectState {
  options: ISelectOption[];
}

class LocaleSelect extends React.PureComponent<ISharedSelectProps, ILocaleSelectState> {
  constructor(props: ISharedSelectProps) {
    super(props);
    this.state = {
      options: [
        {
          value: 1, // это передаем в значение errors
          label: 'Русский',
        },
        {
          value: 2,
          label: 'Украинский',
        },
        {
          value: 3,
          label: 'Английский',
        },
      ],
    };
  }

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        isMulti={false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

export default LocaleSelect;
