import React from 'react';
import bytesFormatter from 'lib/bytesFormatter';

import { IFile } from 'features/types';
import Spinner from "features/ui/Spinner";

interface IFileInfoLinkProps {
  name: string;
  file: IFile | null | undefined;
  awaitLoadingText: string;
  itemId: string | null | undefined;
}

export default (props: IFileInfoLinkProps) => {
  const {
    name,
    file,
    awaitLoadingText,
    itemId,
  } = props;
  if (itemId && !file) {
    return (
      <>
        <Spinner />
        <p className="p-3 mb-2 bg-warning text-dark">{awaitLoadingText}</p>
      </>
    )
  }
  return file ? (
    <a href={file.path} target="_blank">
      {name} - {bytesFormatter(file.size)} - {file.updated_at}
    </a>
  ) : null;
};
