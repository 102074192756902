import React from 'react';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/users/consts';
import { makeUserUrl } from 'features/users/lib/url';
import UsersTable from 'features/users/lib/Table';

class PageUsers extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Пользователи"
        buttons={[{ link: makeUserUrl({ action: 'create' }), name: 'Создать пользователя' }]}
      >
        <UsersTable />
      </CardPage>
    );
  }
}

export default PageUsers;
