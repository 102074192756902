import React from 'react';
import {
  Route,
  Router,
  Switch,
} from 'react-router-dom';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import moment from 'moment';
import 'features/intl/i18n';
import history from 'features/app/history';

import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-table/react-table.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'css/prime/style.scss';
import 'css/prime/core/_dropdown-menu-right.scss';
import 'react-dates/lib/css/_datepicker.css';

import { ApolloProvider } from 'react-apollo';
import { client } from 'features/graphql';

import { withAuth } from 'features/auth/WithAuth';
import Layout from 'features/layout';
import PageLogin from 'features/auth/PageLogin';
import GlobalLoading from 'features/app/Loading';
import GlobalProgress from 'features/app/Progress';

export default class App extends React.Component {
  componentDidMount() {
    moment.locale('ru');
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <GlobalLoading />
        <GlobalProgress />
        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />
        <Router history={history}>
          <Switch>
            <Route exact path="/login" name="Login Page" component={PageLogin} />
            <Route path="/" name="Home" component={withAuth(Layout)} />
          </Switch>
        </Router>
      </ApolloProvider>
    );
  }
}
