import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';

import cache from 'features/graphql/cache';
import httpLink from 'features/graphql/links/http';
import loggerLink from 'features/graphql/links/logger';
import loadingLink from 'features/graphql/links/loading';
import authLink from 'features/graphql/links/auth';
import errorLink from 'features/graphql/links/error';

import { IDefaultOptions } from './types';

const defaultOptions: IDefaultOptions = {
  watchQuery: { // The React Apollo <Query /> component uses Apollo Client’s watchQuery
    fetchPolicy: 'cache-and-network',
  },
};

const client = new ApolloClient({
  defaultOptions,
  cache,
  connectToDevTools: true,
  link: ApolloLink.from([
    loggerLink,
    errorLink,
    authLink,
    loadingLink,
    httpLink,
  ]),
});

client.onResetStore(() => {
  window.location.href = '/login';
  return Promise.resolve();
});

export {
  client,
  gql,
};
