import React from 'react';
import i18n from 'features/intl/i18n';
import moment from 'moment';
import IsActiveColumn from 'features/ui/IsActiveColumn';
import ListOfOffers from 'features/offers/lib/ListOfOffers';
import OffersSelect from 'features/offers/lib/OffersSelect';
import { filterInputHandler } from 'features/common';
import { DATETIME_FORMAT_DEFAULT } from 'features/app/consts';
import { Input } from 'reactstrap';
import styles from './styles.module.scss';
import { IBanner } from '../types';

export default () => [
  {
    Header: i18n.t('banners:name'),
    accessor: 'name',
    filterable: true,
    minWidth: 100,
    Filter: filterInputHandler,
  },
  {
    Cell: IsActiveColumn,
    Header: i18n.t('banners:is_active'),
    accessor: (data: IBanner) => data.is_active,
    filterable: false,
    id: 'is_active',
    maxWidth: 100,
  },
  {
    Cell: ({ value }: { value: IBanner }) => {
      if (!value.started_at || !value.is_active) {
        return null;
      }
      return moment(value.started_at, DATETIME_FORMAT_DEFAULT).format('ll');
    },
    Header: i18n.t('banners:started_at'),
    id: 'started_at',
    maxWidth: 100,
    accessor: (data: any) => data,
    filterable: false,
  },
  {
    Cell: ({ value }: { value: IBanner }) => {
      if (!value.finished_at || !value.is_active) {
        return null;
      }
      return moment(value.finished_at, DATETIME_FORMAT_DEFAULT).format('ll');
    },
    Header: i18n.t('banners:finished_at'),
    id: 'finished_at',
    maxWidth: 100,
    accessor: (data: any) => data,
    filterable: false,
  },
  {
    Cell: ({ value }: { value: IBanner }) => <ListOfOffers offers={value.offers} />,
    Header: i18n.t('offers:offers'),
    accessor: (data: any) => data,
    expandable: true,
    id: 'offer',
    maxWidth: 300,
    Filter: ({ filter, onChange }: any) => (
        <OffersSelect
          onChange={onChange}
          value={filter && filter.value}
          isMulti={false}
        />
    ),
  },
  {
    Header: 'Формат',
    accessor: 'format',
    Cell: ({ value }: { value: IBanner }) => (i18n.t(`banners:${value}`)),
    Filter: ({ format, onChange }: any) => {
      const onchange = (e) => (onChange(e.target.value));
      return (
          <Input
            id="format"
            name="format"
            type="select"
            value={format}
            onChange={onchange}
          >
            <option value="">-</option>
            <option value="standard">{i18n.t('banners:standard')}</option>
            <option value="wide_5x2">{i18n.t('banners:wide_5x2')}</option>
            <option value="layout">{i18n.t('banners:layout')}</option>
            <option value="category">{i18n.t('banners:category')}</option>
            <option value="common">{i18n.t('banners:common')}</option>
            <option value="common_mobile">{i18n.t('banners:common_mobile')}</option>
          </Input>
      );
    },
  },
  {
    Cell: (props: any) => {
      if (props.value && props.value.length) {
        return (
            <a href={props.value[0].path} target="_blank">
              <img src={props.value[0].path} />
            </a>
        );
      }
      if (props.original.image && props.original.image.path) {
        return (
            <a href={props.original.images[0].path} target="_blank">
              <img src={props.original.images[0].path} />
            </a>
        );
      }
      return null;
    },
    className: styles.image,
    Header: i18n.t('banners:image'),
    accessor: 'images',
    filterable: false,
    id: 'image',
    minWidth: 200,
    maxWidth: 200,
  },
];
