import React from 'react';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { IInfoRowOptions } from './types';
import bytesFormatter from 'lib/bytesFormatter';

export const InfoRowBytes = ({
  label,
  value,
  options,
}: {
  label: string,
  value: number | null | undefined,
  options?: IInfoRowOptions,
}) => {
  if (value === null || value === undefined) {
    return <InfoRowNotSet label={label} options={options} />;
  }
  return (
    <InfoRow label={label} {...options}>
      <React.Fragment>
        {bytesFormatter(value)}
      </React.Fragment>
    </InfoRow>
  );
};
