import React from 'react';
import i18n from 'features/intl/i18n';
import { filterInputHandler } from 'features/common';
import ListOfOffers from 'features/offers/lib/ListOfOffers';
import OffersSelect from 'features/offers/lib/OffersSelect';
import CreatedBy from '../lib/CreatedBy';

import { ICategory } from 'features/types';

export default () => {
  return [
    {
      Header: i18n.t('categories:name'),
      accessor: 'name',
      minWidth: 100,
      Filter: filterInputHandler,
    },
    {
      Header: i18n.t('categories:weight'),
      accessor: 'weight',
      className: 'text-right',
      filterable: false,
      minWidth: 15,
    },
    {
      Cell: ({ value }: { value: ICategory }) => (
        <CreatedBy category={value} />
      ),
      Header: i18n.t('categories:created_by'),
      accessor: (data: any) => data,
      filterable: false,
      id: 'createdBy',
      maxWidth: 100,
    },
    {
      Cell: ({ value }: { value: ICategory }) => <ListOfOffers offers={value.offers} />,
      Header: i18n.t('offers:offers'),
      accessor: (data: any) => data,
      expandable: true,
      Filter: ({ filter, onChange }: any) => (
        <OffersSelect
          onChange={onChange}
          value={filter && filter.value}
          isMulti={false}
        />
      ),
      id: 'offers',
      maxWidth: 400,
    },
  ];
};
