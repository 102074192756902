function resize(points: number[], width: number, height: number) {
  const len = points.length;
  for (let i = 0; i < len; i += 2) {
    points[i] *= width;
    points[i + 1] *= height;
  }
}

export default (width: number, height: number) => {
  const padding = width * 0.02;
  const strokeWidth = height * 0.04;
  const fg = 'gray';
  const bg = 'white';
  const smallPick = [0.1, 0.86, 0.35, 0.5, 0.55, 0.86];
  const bigPick = [0.30, 0.86, 0.60, 0.35, 0.9, 0.86];
  resize(smallPick, width, height);
  resize(bigPick, width, height);
  const image = `<svg
    width="${width}" height="${height}"
    viewBox="0 0 ${width} ${height}"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="${padding}" y="${padding}"
      rx="15" ry="15"
      width="${width - padding * 2}" height="${height - padding * 2}"
      stroke="none"
      fill="${bg}"
    />
    <circle
      cx="${padding + width * 0.2}"
      cy="${padding + height * 0.25}"
      r="${height * 0.1}"
      fill="${fg}"
    />
    <polygon
      points="${smallPick.join(',')}"
      stroke-width="${strokeWidth}"
      stroke="${bg}"
      fill="${fg}"
    />
    <polygon
      points="${bigPick.join(',')}"
      stroke-width="${strokeWidth}"
      stroke="${bg}"
      fill="${fg}"
    />
    <rect
      x="${padding}" y="${padding}"
      rx="15" ry="15"
      width="${width - padding * 2}" height="${height - padding * 2}"
      stroke="${fg}"
      stroke-width="${strokeWidth}"
      fill="none"
    />
  </svg>`;
  // Перевожу в base64 для старых браузеров
  return `data:image/svg+xml;base64,${btoa(image)}`;
};
