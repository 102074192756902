import crudUrlBuilder from 'lib/crudUrlBuilder';
import { IUsersSubscription } from '../../types';

export const makeUsersSubscriptionUrl = crudUrlBuilder('user-subscriptions', 'user-subscriptions');

export const makeUsersSubscriptionUrlTo = (usersSubscription: IUsersSubscription, action: string = 'view') => {
  return makeUsersSubscriptionUrl({
    action,
    id: usersSubscription.id,
  });
};
