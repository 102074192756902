export default {
  grid: {
    create: '<0>Create</0>',
  },
  items: {
    grid: {
      title: 'All items',
    },
  },
  books: {
    grid: {
      title: 'Books',
    },
  },
  offers: {
    creation_date: 'Creation date',
    id: 'ID',
    main_title: 'All offers',
    name: 'Name',
    offer: 'Offer',
    prefix: 'Prefix',
    updating_date: 'Date last update',
  },
  header: {
    logout: 'Logout',
  },
  dict: {
    welcome: 'Welcome',
    updated: 'Updated',
    created: 'Created',
    deleted: 'Deleted',
    error: 'Ошибка',
    on: 'on',
    off: 'off',
  },
};
