import crudUrlBuilder from 'lib/crudUrlBuilder';
import { IOffer } from '../../types';

export const makeOfferUrl = crudUrlBuilder('offer', 'offers');

export const makeOfferUrlTo = (user: IOffer, action: string = 'view', part: string = '') => {
  return makeOfferUrl({
    action,
    part,
    id: user.id,
  });
};
