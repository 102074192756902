import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageDocuments from 'features/documents/PageDocuments';
import PageCreate from 'features/documents/PageCreate';
import PageEdit from 'features/documents/PageEdit';
import PageView from 'features/items/PageView';

export default () => {
  return (
    <AccessControl permission="documents:access">
      <Switch>
        <AppRoute path="/documents" menuId="documents" component={PageDocuments} exact />
        <AppRoute path="/document/create" menuId="documents" component={PageCreate} exact />
        <AppRoute path="/document/edit/:id" menuId="documents" component={PageEdit} exact />
        <AppRoute path="/document/:id" menuId="documents" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
