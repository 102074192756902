import React from 'react';
import { Link } from 'react-router-dom';
import { makeItemUrl } from 'features/items/lib/url';
import { IBadge } from '../../types';

export default ({ badge }: { badge: IBadge }) => {
  return (
    <Link to={makeItemUrl({ query: { badge: badge.id } })}>
      Перейти к итемам этого значка ({badge.items.length})
    </Link>
  );
};
