import React from 'react';
import { withTranslation } from 'react-i18next';
import { set } from 'lodash';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import getCreatorsQuery from 'features/creators/queries/getCreatorsQuery.gql';
import resolveColumns from './columns';
import deleteCreatorMutation from 'features/creators/queries/deleteCreatorMutation.gql';
import { makeCreatorUrl } from 'features/creators/lib/url';

import { Column } from 'features/types';
import { ICreatorsDataTableProps } from './types';

class CreatorsTable extends React.PureComponent<ICreatorsDataTableProps> {
  columns: Column[];

  constructor(props: ICreatorsDataTableProps) {
    super(props);

    this.columns = resolveColumns(props).filter(col => !!col) as Column[];
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.type) {
      set(variables, 'filters.type', { operator: '=', value: fields.type });
    }
    if (fields.full_name) {
      set(variables, 'searchByFields.full_name', fields.full_name);
    }
    if (fields.first_name) {
      set(variables, 'searchByFields.first_name', fields.first_name);
    }
    if (fields.last_name) {
      set(variables, 'searchByFields.last_name', fields.last_name);
    }
  };

  render() {
    return (
      <IndexTableBase
        columns={this.columns}
        filterFields={['id', 'type', 'full_name', 'first_name', 'last_name']}
        prepareRequestData={this.prepareRequestData}
        query={getCreatorsQuery}
        queryResultName="CreatorQuery"
        deleteMutation={deleteCreatorMutation}
        makeCrudUrl={makeCreatorUrl}
        showViewIconColumn={true}
      />
    );
  }
}

export default withTranslation()(CreatorsTable);
