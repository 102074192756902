import qs from 'qs';

export interface IMakeUrl {
  id?: string | number;
  action?: string;
  part?: string;
  query?: object;
}

export type IMakeUrlFunction = (values: IMakeUrl) => string;

/**
 * Помогает создавать CRUD ссылки.
 */
export default (single: string, plural: string) => {
  /**
   * @param {string} params.action create, view, read, edit, update или ничего
   * @param {string} params.id Id или ничего
   * @param {string} params.part Без id игнорируется
   */
  return (params: IMakeUrl = {}) => {
    const {
      action,
      id,
      part,
      query,
    } = params;

    const queryStr = query ? `?${qs.stringify(query)}` : '';

    if (action === 'create') {
      return `/${single}/create${queryStr}`;
    }
    if (!action && id) {
      return `/${single}/edit/${id}${queryStr}`;
    }
    if (!action && !id) {
      return `/${plural}${queryStr}`;
    }
    if (action === 'view' || action === 'read') {
      if (!id) {
        return `/${plural}${queryStr}`;
      }
      if (part) {
        return `/${single}/${id}/${part}${queryStr}`;
      }
      return `/${single}/${id}${queryStr}`;
    }
    if (action === 'edit' || action === 'update') {
      if (part) {
        return `/${single}/edit/${id}/${part}${queryStr}`;
      }
      return `/${single}/edit/${id}${queryStr}`;
    }
    return `/${plural}${queryStr}`;
  };
};
