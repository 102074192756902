import React from 'react';
import {
  omit,
  set,
} from 'lodash';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  asIs,
  toInt,
  toStr,
} from 'lib/crudConvert';
import field from 'lib/field';
import filterChangedValues from 'lib/filterChangedValues';
import { crudMutate } from 'features/common/helpers';
import BaseForm from './Form';

import getUserQuery from 'features/users/queries/getUserQuery.gql';
import updateUserMutation from 'features/users/queries/updateUserMutation.gql';
import createUserMutation from 'features/users/queries/createUserMutation.gql';

import { IFormValues } from 'features/users/types';
import {
  IUser,
  IUserInput,
  UserRoleEnum,
} from 'features/types';

interface IFormProps extends WithTranslation {
  data?: IUser;
  isCreateForm?: boolean;
}

class Form extends React.PureComponent<IFormProps> {
  validationSchema = () => {
    const {
      t,
      isCreateForm,
    } = this.props;
    const main = {
      email: Yup.string().required(t('forms:required_field', { field: t('email') })),
      role: Yup.string().required(t('forms:required_field', { field: t('role') })),
      offer: Yup.number().positive().min(1, t('forms:required_field', { field: t('offer') })).integer(),
    };
    const mode = isCreateForm
      ? { password: Yup.string().trim().required(t('forms:required_field', { field: t('password') })) }
      : { password: Yup.string().trim().nullable(true).min(1, t('forms:wrong_password_can_not_be_made_of_spaces')) };
    return Yup.object().shape({ ...main, ...mode });
  };

  getChangedValues(values: IFormValues) {
    const formData = this.resolveInitialValues();
    if (Array.isArray(values.groups)) {
      values.groups = values.groups.slice();
    }
    return filterChangedValues(formData, values);
  }

  getSendindValues(values: IFormValues) {
    const result = {} as IUserInput;
    [
      ['email', toStr],
      ['role', toStr],
      ['phone', asIs],
      ['expire_date', toStr],
      ['first_name', toStr],
      ['last_name', toStr],
      ['gender', toStr],
      ['birthday', toStr],
      ['city', toStr],
      ['position', toStr],
      ['department', toStr],
      ['password', toStr],
      ['is_activated', asIs],
      ['is_subscribed', asIs],
      ['is_auto_registered', asIs],
      ['is_deleted', asIs],
      ['can_be_restored', asIs],
      ['offer', toInt],
      ['labEnabled', asIs],
    ].forEach((val: any) => {
      const [name, cb] = val;
      if (name in values) {
        set(result, name, cb(values[name]));
      }
    });
    if ('phone' in result && !result.phone) {
      result.phone = null;
    }
    if (Array.isArray(values.groups)) {
      result.groups = values.groups.slice();
    }
    if (values.labEnabled !== undefined) {
      result.scopes = values.labEnabled ? [2] : [];
      delete result.labEnabled;
    }
    return result;
  }

  onSubmit = (values: IFormValues, formActions: FormikActions<IFormValues>) => {
    const id = values.id;
    const expectedData = {
      ...omit(values, [
        'id',
        'created_at',
      ]),
    } as IFormValues;
    const user = id ? this.getChangedValues(expectedData) : expectedData;
    const initialIsLab = this.resolveInitialValues().labEnabled;
    const changedIsLab = this.getChangedValues(expectedData).labEnabled;
    crudMutate({
      id,
      formActions,
      mutation: id ? updateUserMutation : createUserMutation,
      variables: id ? {
        id,
        user: this.getSendindValues(user),
        notificationType: !initialIsLab && changedIsLab ? 'add_lab_scope' : undefined,
      } : {
        user: {
          ...this.getSendindValues(user),
          is_auto_registered: false,
        },
        notificationType: !initialIsLab && changedIsLab ? 'add_lab_scope' : undefined,
      },
      redirect: '/users',
      updateRefetchQuery: getUserQuery,
      check: !!Object.keys(user).length,
    });
  };

  resolveInitialValues = (): IFormValues => {
    const { data } = this.props;
    return {
      id: field(data, 'id', 0),
      email: field(data, 'email', ''),
      password: '',
      first_name: field(data, 'first_name', ''),
      last_name: field(data, 'last_name', ''),
      role: field(data, 'role', UserRoleEnum.user),
      city: field(data, 'city', ''),
      position: field(data, 'position', ''),
      department: field(data, 'department', ''),
      is_activated: field(data, 'is_activated', false),
      is_deleted: field(data, 'is_deleted', false),
      is_subscribed: field(data, 'is_subscribed', false),
      can_be_restored: field(data, 'can_be_restored', true),
      gender: field(data, 'gender', ''),
      birthday: field(data, 'birthday', ''),
      expire_date: field(data, 'expire_date', ''),
      created_at: field(data, 'created_at', ''),
      offer: field(data, 'offer.id', 0),
      phone: field(data, 'phone', null),
      groups: data ? (data.groups ? data.groups.map(group => group.id.toString()) : []) : [],
      labEnabled: !!field(data, 'scopes', []).length,
    };
  };

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  render() {
    return (
      <Formik
        validationSchema={this.validationSchema()}
        enableReinitialize
        initialValues={this.resolveInitialValues()}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('users')(Form);
