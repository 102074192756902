import React, { SyntheticEvent, useMemo } from 'react';
import Markdowm from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

interface IMarkdownComponentProps {
  value: string;
  onChange: (v: string) => void;
  onBlur?: (v: SyntheticEvent<HTMLDivElement>) => void;
}

const MarkdownComponent = ({ value, onChange, onBlur }: IMarkdownComponentProps) => {
  const toolbar = [
    'bold',
    'italic',
    'strikethrough',
    '|',
    'heading',
    'heading-smaller',
    'heading-bigger',
    '|',
    'quote',
    'unordered-list',
    'ordered-list',
    'preview',
  ];
  const options = useMemo(() => ({
    toolbar,
    spellChecker: false,
  }), []);

  return (
    <Markdowm
      options={options as any}
      onChange={onChange}
      onBlur={onBlur || undefined}
      value={value}
    />
  );
};

export default MarkdownComponent;
