import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { makeLinkListValue } from 'lib/makeLinkListValue';
import {
  InfoRow,
  InfoRowColor,
  InfoRowImage,
  InfoRowLink,
  InfoRowLinkList,
  InfoRowSwitch,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { createView } from 'features/common';
import { makeOfferUrl } from 'features/offers/lib/url';
import {
  makeCategoryUrl,
  makeCategoryUrlTo,
} from '../lib/url';
import CreatedBy from '../lib/CreatedBy';
import LinkToItems from '../lib/LinkToItems';
import getEntity from '../queries/getCategory.gql';

import { FEATURE_ICON } from '../consts';

import { ICategory } from '../types';

const View = createView<ICategory>(getEntity, 'CategoryQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderChildren = (category: ICategory) => {
    const { t } = this.props;
    const ownerOfferId = get(category, 'ownerOffer.id', null);
    const parentCategory = get(category, 'parentCategory.id', null);
    const offersLinkListValue = makeLinkListValue(category.offers, 'name', makeOfferUrl, 'view');
    const categoriesLinkListValue = makeLinkListValue(category.childrenCategories, 'name', makeCategoryUrl, 'view');
    return (
      <React.Fragment>
        <LinkBlock editLink={makeCategoryUrlTo(category, 'edit')} />
        <InfoRowText label="ID" value={category.id} />
        <InfoRow label={t('created_by')}>
          <CreatedBy category={category} />
        </InfoRow>
        <InfoRowLink
          label={t('ownerOffer')}
          id={ownerOfferId}
          value={ownerOfferId && makeOfferUrl({ id: ownerOfferId, action: 'view' })}
          text={get(category, 'ownerOffer.name', '')}
        />
        <InfoRowText label={t('name')} value={category.name} />
        <InfoRowColor label={t('color')} value={category.color} />
        <InfoRowText label={t('weight')} value={category.weight} />
        <InfoRowLink
          label={t('parentCategory')}
          id={parentCategory}
          value={parentCategory && makeCategoryUrl({ id: parentCategory, action: 'view' })}
          text={get(category, 'parentCategory.name', '')}
        />
        <InfoRowLinkList
          label={t('categories:childrenCategories')}
          value={categoriesLinkListValue}
          byLine={true}
          max={50}
        />
        <InfoRowSwitch label={t('is_default')} value={category.is_default} />
        <InfoRowImage label={t('imageTop')} value={get(category, 'imageTop.path', null)} />
        <InfoRowImage label={t('imageBottom')} value={get(category, 'imageBottom.path', null)} />
        <InfoRowLinkList
          label={t('offers:offers')}
          value={offersLinkListValue}
          byLine={true}
          max={10}
        />
        <InfoRow label={t('items:items')}>
          <LinkToItems category={category} />
        </InfoRow>
        <hr />
        <InfoRowText label={t('dict:updated')} value={moment(category.updated_at).format('ll')} />
        <InfoRowText label={t('dict:created')} value={moment(category.created_at).format('ll')} />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField="name"
        icon={FEATURE_ICON}
        crumbs={[{ link: makeCategoryUrl(), name: t('categories') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('categories')(PageView);
