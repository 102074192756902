import { setContext } from 'apollo-link-context';
import AuthService from 'features/auth/AuthService';
import {
  APP_API_AUTH_URL,
  APP_API_URL,
} from 'features/app/consts';

const authLink = setContext((_, { headers }) => {
  const token = AuthService.getAuthToken();

  return {
    headers: {
      ...headers,
      'X-AD-AdminToken': token,
    },
    uri: token ? APP_API_URL : APP_API_AUTH_URL,
  };
});

export default authLink;
