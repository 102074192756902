import crudUrlBuilder from 'lib/crudUrlBuilder';
import { IBadge } from '../../types';

export const makeBadgeUrl = crudUrlBuilder('badge', 'badges');

export const makeBadgeUrlTo = (user: IBadge, action: string = 'view') => {
  return makeBadgeUrl({
    action,
    id: user.id,
  });
};
