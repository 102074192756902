import React from 'react';
import { get } from 'lodash';
import {
  Input,
  Table,
} from 'reactstrap';
import {
    WithTranslation,
    withTranslation,
} from 'react-i18next';
import { FormikErrors } from 'formik';
import Switch from 'features/ui/Switch';
import DeleteButton from 'features/ui/DeleteButton';
import IconButton from 'features/ui/IconButton';
import AddButton from 'features/ui/AddButton';

export interface IModel {
  platform: string;
  type: string;
  enabled: boolean;
  key: string;
  [key: string]: string | boolean;
}

interface IModelsTableProps extends WithTranslation {
  models: IModel[];
  serviceName: string;
  errors?: Array<FormikErrors<IModel | undefined>>;
  onChange?: (values: IModel[]) => void;
}

interface IModelsTableState {
  models: IModel[];
}

class ModelsTable extends React.Component<IModelsTableProps, IModelsTableState> {
  constructor(props: IModelsTableProps) {
    super(props);
    this.state = {
      models: props.models.map(row => ({ ...row })),
    };
  }

  initialModel() {
    return {
      platform: 'android',
      type: '',
      enabled: true,
      key: '',
    };
  }

  copyStateModels() {
    return this.state.models.map(row => ({ ...row }));
  }

  onChange = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(this.copyStateModels());
    }
  }

  onDeleteRow = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const idStr = e.currentTarget.getAttribute('data-id');
    if (idStr) {
      const id = parseInt(idStr, 10);
      this.setState(
        {
          models: this.state.models.filter((elem, order) => id !== order),
        },
        this.onChange,
      );
    }
  }

  onChangeInput = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const idStr = e.currentTarget.getAttribute('data-id');
    const {
      name,
      value,
    } = e.currentTarget;
    if (idStr) {
      const id = parseInt(idStr, 10);
      const models = this.copyStateModels();
      models[id][name] = value;
      this.setState({ models }, this.onChange);
    }
  }

  onChangeCheckbox = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const idStr = e.currentTarget.getAttribute('data-id');
    const {
      name,
      checked,
    } = e.currentTarget;
    if (idStr) {
      const id = parseInt(idStr, 10);
      const models = this.copyStateModels();
      models[id][name] = checked;
      this.setState({ models }, this.onChange);
    }
  }

  onAddRow = () => {
    const { models } = this.state;
    this.setState(
      {
        models: [
          ...models,
          { ...this.initialModel() },
        ],
      },
      this.onChange,
    );
  }

  onCopyRow = (e: React.MouseEvent<any>) => {
    const { models } = this.state;
    const idStr = e.currentTarget.getAttribute('data-id');
    if (idStr) {
      this.setState(
        {
          models: [
            ...models,
            { ...models[parseInt(idStr, 10)] },
          ],
        },
        this.onChange,
      );
    }
  }

  renderRow(row: IModel, id: number) {
    const { errors } = this.props;
    return (
      <tr key={id}>
        <td>
          <Input
            type="select"
            name="platform"
            value={row.platform}
            data-id={id}
            onChange={this.onChangeInput}
          >
            <option value="android">android</option>
            <option value="ios">ios</option>
          </Input>
        </td>
        <td>
          <Input
            type="text"
            name="type"
            value={row.type}
            data-id={id}
            invalid={Boolean(get(errors, `[${id}].type`, false))}
            onChange={this.onChangeInput}
          />
        </td>
        <td>
          <Input
            type="text"
            name="key"
            value={row.key}
            data-id={id}
            onChange={this.onChangeInput}
          />
        </td>
        <td>
          <Switch
            name="enabled"
            checked={row.enabled}
            data-id={id}
            onChange={this.onChangeCheckbox}
          />
        </td>
        <td>
          <IconButton
            icon="fa fa-copy"
            type="button"
            data-id={id}
            onClick={this.onCopyRow}
          />
        </td>
        <td>
          <DeleteButton
            type="button"
            data-id={id}
            onClick={this.onDeleteRow}
          />
        </td>
      </tr>
    );
  }

  render() {
    const {
      t,
      serviceName,
    } = this.props;
    return (
      <Table size="sm">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>{t(`build_${serviceName}_type`)}</th>
            <th>{t(`build_${serviceName}_key`)}</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>
              <AddButton
                type="button"
                onClick={this.onAddRow}
                title={t('dict:add_row')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.models.map((row, id: number) => this.renderRow(row, id))}
        </tbody>
      </Table>
    );
  }
}

export default withTranslation('offers')(ModelsTable);
