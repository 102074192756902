import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { makeLinkListValueTo } from 'lib/makeLinkListValue';
import {
  InfoRowLink,
  InfoRowLinkList,
  InfoRowSwitch,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { createView } from 'features/common';
import { makeItemUrlTo } from 'features/items/lib/url';
import { makeOfferUrl } from 'features/offers/lib/url';
import {
  makePromocodeUrl,
  makePromocodeUrlTo,
} from '../lib/url';
import { makeSubscriptionUrlTo } from 'features/subscriptions/lib/url';
import getEntity from '../queries/getPromocodeQuery.gql';

import { FEATURE_ICON } from '../consts';

import { IPromocode } from '../types';

const View = createView<IPromocode>(getEntity, 'PromoCodeQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderChildren = (promocode: IPromocode) => {
    const { t } = this.props;
    const offerId = get(promocode, 'offer.id', null);
    const itemsLinkListValue = makeLinkListValueTo(promocode.items, 'name', makeItemUrlTo, 'view');
    const subscriptionsLinkList = makeLinkListValueTo(promocode.subscriptions, 'name', makeSubscriptionUrlTo, 'view');
    const expireDate = promocode.expire_date && moment(promocode.expire_date).format('lll');
    return (
      <React.Fragment>
        <LinkBlock editLink={makePromocodeUrlTo(promocode, 'edit')} />
        <InfoRowText label="ID" value={promocode.id} />
        <InfoRowSwitch label={t('is_active')} value={promocode.is_active} on="dict:yes" off="dict:no" />
        <InfoRowText label={t('code')} value={promocode.code} />
        <InfoRowText label={t('count')} value={promocode.count} />
        <InfoRowText label={t('expire_date')} value={expireDate} />
        <InfoRowText label={t('used')} value={promocode.used} />
        <InfoRowLink
          label={t('offer')}
          id={offerId}
          value={offerId && makeOfferUrl({ id: offerId, action: 'view' })}
          text={get(promocode, 'offer.name', '')}
        />
        <InfoRowLinkList
          label={t('items:items')}
          value={itemsLinkListValue}
          byLine={true}
        />
        <hr />
        <InfoRowLinkList
          label={t('subscriptions')}
          value={subscriptionsLinkList}
          byLine={true}
        />
        {
          promocode.subscriptions.length && (
            <>
              <InfoRowText label={t('trial_days')} value={promocode.subscriptions[0].trial_days} />
              <InfoRowText label={t('expire_days')} value={promocode.subscriptions[0].expire_days} />
              <InfoRowText label={t('discount_price')} value={promocode.discount_price} />
              <InfoRowText label={t('discount_percent')} value={promocode.discount_percent} />
            </>
          )
        }
        <hr />
        <InfoRowText label={t('dict:updated')} value={moment(promocode.updated_at).format('ll')} />
        <InfoRowText label={t('dict:created')} value={moment(promocode.created_at).format('ll')} />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField={'code'}
        icon={FEATURE_ICON}
        crumbs={[{ link: makePromocodeUrl(), name: t('promocodes') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('promocodes')(PageView);
