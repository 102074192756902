import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthService from 'features/auth/AuthService';

interface IWithAuthProps {
  children: React.ReactNode;
}

interface IWithAuthState {
  isLogged: boolean;
}

export class WithAuth extends React.PureComponent<IWithAuthProps, IWithAuthState> {
  constructor(props: IWithAuthProps) {
    super(props);
    this.state = { isLogged: AuthService.isLogged() };
  }

  render() {
    const { children } = this.props;
    const { isLogged } = this.state;

    if (isLogged) {
      return children;
    }

    return <Redirect to="/login" />;
  }
}

export const withAuth = (Component: React.ComponentType<any>) => (props: object) => (
  <WithAuth>
    <Component {...props} />
  </WithAuth>
);
