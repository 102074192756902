import React from 'react';
import {
  ColorButtonInput,
  ColorTextInput,
  FontFamilyInput,
  FontSizeInput,
  HeightInput,
  IWithFormikProps,
  NameInput,
  RoundInput,
  TextInput,
  WidthInput,
  withFormik,
  XInput,
  YInput,
} from './inputs';
import { IButton } from 'banner-editor-component';

const ButtonForm = ({
  values,
  handleSubmit,
  onChange,
  onChangeButtonColor,
  onChangeColor,
  colors,
}: IWithFormikProps<IButton>) => (
  <form onSubmit={handleSubmit}>
    <NameInput value={values.name} onChange={onChange} />
    <TextInput value={values.text} onChange={onChange} />
    <FontSizeInput value={values.fontSize} onChange={onChange} />
    <FontFamilyInput value={values.fontFamily} onChange={onChange} />
    <RoundInput value={values.round} onChange={onChange} />

    <ColorTextInput
      value={values.color}
      onChange={onChangeColor}
      colors={colors}
    />

    <ColorButtonInput
      value={values.buttonColor}
      onChange={onChangeButtonColor}
      colors={colors}
    />

    <XInput value={values.x} onChange={onChange} />
    <YInput value={values.y} onChange={onChange} />
    <WidthInput value={values.width} onChange={onChange} />
    <HeightInput value={values.height} onChange={onChange} />
  </form>
);

export default withFormik<IButton>(ButtonForm);
