import i18next from 'i18next';
import { fileValidator } from 'lib/validators';
import * as Yup from 'yup';

export const contentValidator = (
  t: i18next.TFunction,
  isEditMode: boolean,
  params: { ext: string, formats: string[] },
) => {
  const isCreateMode = !isEditMode;
  let validator = Yup.array();
  if (isCreateMode) {
    validator = validator.required(t('forms:required_field', { field: t('content') }));
  }
  return validator.of(
    Yup.object().shape({
      file: fileValidator({
        ...params,
        field: t('content'),
        required: isCreateMode,
      }),
    }),
  );
};
