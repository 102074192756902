import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  client,
  gql,
} from 'features/graphql';
import {
  ICategory,
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  ICategoryQueryResponse,
} from 'features/categories/types';
import {
  mapEntityToOptions,
  ReactSelectWithId,
} from 'features/ui/ReactSelect';

const QUERY = gql`
  query getCategoriesForSelect(
    $id: [ID]
    $pageNum: Int
    $perPage: Int
    $filterByFields: CategoryFilterByFields
    $searchByFields: CategorySearchByFields
  ) {
    CategoryQuery(
      id: $id,
      page: $pageNum
      perPage: $perPage
      filterByFields: $filterByFields
      searchByFields: $searchByFields
    ) {
      items {
        id
        name
        parentCategory {
          id
          name
        }
      }
    }
  }
`;

interface ICategoriesSelectProps extends ISharedSelectProps {
  data: ICategory[];
}

interface ICategoriesSelectState {
  options: ISelectOption[];
}

class CategoriesSelect extends React.PureComponent<ICategoriesSelectProps, ICategoriesSelectState> {
  static getDerivedStateFromProps(props: any) {
    if (props.considerParents) {
      return {
        options: props.data
          .filter((category: ICategory) => category.id !== props.currentCategory)
          .filter((category: ICategory) => category.parentCategory === null || category.parentCategory.id === props.currentCategory)
          .map((category: ICategory) => ({
            value: +category.id,
            label: String(category.name),
          }))
          .sort((a: ISelectOption<number>, b: ISelectOption<number>) => (a.value - b.value)),
      };
    }
    return {
      options: mapEntityToOptions<ICategory>(props.data, 'name'),
    };
  }

  state = {
    options: [],
  };

  loadOptions = (value: string) => client.query<ICategoryQueryResponse>({
    query: QUERY,
    variables: {
      pageNum: 1,
      perPage: 1000,
      filterByFields: {
        id: {
          value,
          operator: 'like',
          group: '1',
          condition: 'or',
        },
        name: {
          value,
          operator: 'like',
          group: '1',
          condition: 'or',
        },
      },
    },
  }).then((res) => {
    const { considerParents } = this.props;
    if (res.data && res.data.CategoryQuery) {
      let categories = res.data.CategoryQuery.items;
      if (considerParents) {
        categories = res.data.CategoryQuery.items.filter((category: ICategory) => category.parentCategory === null);
      }
      return categories.map((category: ICategory) => ({
        value: +category.id,
        label: String(category.name),
      })).sort((a: ISelectOption<number>, b: ISelectOption<number>) => (a.value - b.value));
    }
    return [];
  });

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
      isMulti,
    } = this.props;

    const { options } = this.state;

    return (
      <ReactSelectWithId
        id={id}
        name={name}
        options={options}
        isMulti={!!isMulti}
        async
        loadOptions={this.loadOptions}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        minSearchLength={1}
      />
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ICategoryQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.CategoryQuery ? data.CategoryQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ICategoryQueryResponse, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: ownProps => ({
    variables: {
      id: ownProps.value || undefined,
      pageNum: 1,
      perPage: 1000,
      searchByFields: null,
    },
  }),
})(CategoriesSelect);
