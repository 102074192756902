import { ISelectOption } from 'features/types';

export  function itemTypeTranslator(type: string) {
  switch (type) {
    case 'book':
      return 'Книга';
    case 'audiobook':
      return 'Аудио';
    case 'hybrid':
      return 'Гибрид';
    case 'video':
      return 'Видео';
    case 'document':
      return 'Документ';
    case 'summary':
      return 'Саммари';
    case 'audiosummary':
      return 'Аудиосаммари';
    case 'videosummary':
      return 'Мини-курс';
    case 'article':
      return 'Статья';
    default:
      return '';
  }
}

export function mapEntityToOptions<TEntity extends { id: ID }>(entities: TEntity[], key: keyof TEntity) {
  return entities
    .map((entity: TEntity) => ({
      value: +entity.id,
      label: String(entity[key]),
    }))
    .sort((a: ISelectOption<number>, b: ISelectOption<number>) => (a.value - b.value));
}

export function mapFullEntityToOptions<TEntity extends { id: ID }>(entities: TEntity[], key: keyof TEntity) {
  return entities
    .map((entity: TEntity) => ({
      value: +entity.id,
      label: String(entity[key]),
      ...entity,
    }))
    .sort((a: ISelectOption<number>, b: ISelectOption<number>) => (a.value - b.value));
}
