import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageTests from 'features/tests/PageTests';
import PageCreate from 'features/tests/PageCreate';
import PageEdit from 'features/tests/PageEdit';
import PageView from 'features/items/PageView';

export default () => (
  <AccessControl permission="videos:access">
    <Switch>
      <AppRoute path="/tests" menuId="tests" component={PageTests} exact />
      <AppRoute path="/test/create" menuId="tests" component={PageCreate} exact />
      <AppRoute path="/test/edit/:id" menuId="tests" component={PageEdit} exact />
      <AppRoute path="/test/:id" menuId="tests" component={PageView} exact />
      <Route name="404" component={Page404} />
    </Switch>
  </AccessControl>
);
