import React from 'react';
import { RowRenderProps } from 'react-table';
import i18n from 'features/intl/i18n';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { filterInputHandler } from 'features/common';
import LinkToOffer from 'features/offers/lib/LinkToOffer';
import OffersSelect from 'features/offers/lib/OffersSelect';
import ListOfCreators from 'features/creators/lib/ListOfCreators';
import ItemTypeSelect from 'features/items/lib/ItemTypeSelect';
import YesNoSelect from 'features/ui/YesNoSelect';
import Expander from 'features/ui/Expander';

import styles from './style.module.scss';

import {
  IItem,
  IOffer,
} from 'features/types';
import { IItemsDataTableProps } from './types';

const OfferLink = ({ value }: { value: IOffer }) => <div><LinkToOffer value={value} action="view" /></div>;

export default (props: IItemsDataTableProps) => ([
  {
    id: 'id',
    Header: 'ID',
    maxWidth: 100,
    className: 'text-right',
    accessor: (data: IItem) => data,
    Cell: (row: RowRenderProps) => (
      <Link to={`/${row.value.type}/edit/${row.value.id}`}>
        {row.value.id}
      </Link>
    ),
    Filter: filterInputHandler,
  },
  {
    accessor: 'name',
    Header: i18n.t('items:name'),
    expanded: true,
    Filter: filterInputHandler,
  },
  {
    id: 'publisher',
    accessor: (data: IItem) => {
      const creators = data.creators!.filter(creator => creator.type === 'publisher');
      return <ListOfCreators creators={creators} />;
    },
    Header: i18n.t('creators:types:publisher'),
    maxWidth: 200,
    Filter: filterInputHandler,
  },
  {
    id: 'provider',
    accessor: (data: IItem) => {
      const creators = data.creators!.filter(creator => creator.type === 'provider');
      return <ListOfCreators creators={creators} />;
    },
    Header: i18n.t('creators:types:provider'),
    maxWidth: 150,
    Filter: filterInputHandler,
  },
  !props.type ? {
    accessor: 'type',
    Header: 'Тип',
    maxWidth: 100,
    Cell: ({ row }: { row: { type: string } }) => i18n.t(`items:types:${row.type}`),
    Filter: ({ filter, onChange }: any) => {
      const handler = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);
      return (
        <ItemTypeSelect
          onChange={handler}
          value={filter && filter.value}
        />
      );
    },
  } : null,
  {
    id: 'is_active',
    accessor: (data: IItem) => data.is_active,
    Header: 'Активность',
    maxWidth: 100,
    Filter: ({ filter, onChange }: any) => {
      const handler = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);
      return (
        <YesNoSelect
          onChange={handler}
          value={filter && filter.value}
        />
      );
    },
    Cell: (row: RowRenderProps) => (
      <Badge color={row.value ? 'success' : 'danger'}>{row.value ? 'Активен' : 'Неактивен'}</Badge>
    ),
  },
  {
    id: 'offers',
    accessor: 'offers',
    Header: 'Предложения',
    maxWidth: 400,
    Cell: ({ row }: { row: { offers: IOffer[] } }) => (
      <Expander
        max={2}
        className={styles.fit}
        data={row.offers}
        component={OfferLink}
      />
    ),
    Filter: ({ filter, onChange }: any) => {
      const handler = (id: number) => onChange(id);
      return (
        <OffersSelect
          onChange={handler}
          value={filter && filter.value}
          isMulti={false}
        />
      );
    },
  },
  {
    id: 'actionsView',
    Header: '',
    maxWidth: 60,
    className: 'text-center',
    accessor: (data: IItem) => data,
    Cell: (row: RowRenderProps) => (
      <Link className="btn btn-link" to={`/${row.value.type}/${row.value.id}`}>
        <i className="fa fa-eye fa-lg" />
      </Link>
    ),
    Filter: () => <div />,
  },
  {
    id: 'actionsEdit',
    Header: '',
    maxWidth: 60,
    className: 'text-center',
    accessor: (data: IItem) => data,
    Cell: (row: RowRenderProps) => (
      <Link className="btn btn-link" to={`/${row.value.type}/edit/${row.value.id}`}>
        <i className="icon-pencil icons font-lg" />
      </Link>
    ),
    Filter: () => <div />,
  },
]);
