import React from 'react';
import cn from 'classnames';
import {
  Input,
  InputProps,
} from 'reactstrap';

import styles from './styles.module.scss';

export default (props: InputProps) => {
  const { className } = props;
  return (
    <div className={styles.wrapper}>
      <Input
        {...props}
        className={cn(styles.fileInput, className)}
        type="file"
      />
    </div>
  );
};
