import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import AppRoute from 'features/app/Route';
import PageLayouts from '../PageLayouts';
import PageEdit from '../PageEdit';
import PageView from '../PageView';
import PageCreate from '../PageCreate';

export default () => (
  <AccessControl permission="categories:access">
    <Switch>
      <AppRoute path="/layouts" menuId="layouts" component={PageLayouts} exact />
      <AppRoute path="/layout/create" menuId="layouts" component={PageCreate} exact />
      <AppRoute path="/layout/edit/:id" menuId="layouts" component={PageEdit} exact />
      <AppRoute path="/layout/:id" menuId="layouts" component={PageView} exact />
      <Route name="404" component={Page404} />
    </Switch>
  </AccessControl>
);
