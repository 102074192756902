export default class LocalStorage {
  static fakeStorage: { [key: string]: string } = {};
  static isLocalStorageAvailable = Boolean(localStorage);

  static get(name: string) {
    const data = LocalStorage.getString(name);
    if (typeof data === 'string') {
      return JSON.parse(data);
    }
    return data;
  }

  static set(name: string, value: (object | string)) {
    const preparedValue = JSON.stringify(value);
    LocalStorage.setString(name, preparedValue);
  }

  static remove(name: string) {
    if (LocalStorage.isLocalStorageAvailable) {
      localStorage.removeItem(name);
    } else {
      delete LocalStorage.fakeStorage[name];
    }
  }

  static getString(name: string) {
    if (LocalStorage.isLocalStorageAvailable) {
      return localStorage.getItem(name);
    }
    const data = LocalStorage.fakeStorage[name];
    if (data === undefined) {
      return null;
    }
    return data;
  }

  static setString(name: string, value: string) {
    if (LocalStorage.isLocalStorageAvailable) {
      localStorage.setItem(name, value);
    } else {
      LocalStorage.fakeStorage[name] = value;
    }
  }
}
