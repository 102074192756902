import React from 'react';
import PageItems from 'features/items/PageItems';
import { FEATURE_ICON } from 'features/collections/consts';

import { Column } from 'features/types';

class PageCollections extends React.PureComponent<{}> {
  resolveColumns = (columns: Column[]) => {
    return columns.filter(column => column.id !== 'publisher');
  };

  render() {
    return (
      <PageItems
        title="Коллекции"
        type="collection"
        icon={FEATURE_ICON}
        resolveColumns={this.resolveColumns}
      />
    );
  }
}

export default PageCollections;
