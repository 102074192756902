import React from 'react';

export default ({ src }: { src: string }) => {
  return (
    <video
      style={{ width: '100%' }}
      src={src}
      controls
    />
  );
};
