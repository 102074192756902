import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { stubTrue } from 'lodash';
import { FormikProps } from 'formik';
import moment, { Moment } from 'moment';
import DatePicker from 'features/ui/DatePicker';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import CategoriesSelect from 'features/categories/lib/CategoriesSelect';
import BannersSelect from 'features/banners/lib/BannersSelect';
import { formFieldsBuilder } from 'features/ui/FormFields';
import { DATETIME_FORMAT_DATE } from 'features/app/consts';
import LanguagesSelect from 'features/offers/lib/LanguageSelect';
import DefaultLanguageSelect from 'features/offers/lib/DefaultLanguageSelect';
import Images from '../TabEditBranding/Images';
import { IOfferFormValues } from '../types';

interface IOfferFormProps extends FormikProps<IOfferFormValues>, WithTranslation {
  id?: string;
}

const Offer = formFieldsBuilder({ labelPrefix: 'offer_' });
const Brand = formFieldsBuilder({
  labelPrefix: 'settingsBranding.',
  namePrefix: 'settingsBranding.',
});

class BaseOfferForm extends React.Component<IOfferFormProps> {
  onChangeCategories = (ids: ID[]) => {
    this.props.setFieldValue('categories', ids.slice());
  };

  onChangeBanners = (ids: ID[]) => {
    this.props.setFieldValue('banners', ids.slice());
  };

  onChangeItems = (ids: ID[]) => {
    this.props.setFieldValue('items', ids.slice());
  };

  onChangeExpireDate = (date: Moment | null) => {
    const { setFieldValue } = this.props;
    setFieldValue('expire_date', date ? date.format(DATETIME_FORMAT_DATE) : '');
  };

  onChangeLanguages = (ids: ID[]) => {
    const { setFieldValue } = this.props;
    setFieldValue('languages', ids.slice());
  };

  onChangeDefaultLanguage = (defaultLanguage: ID) => {
    const { setFieldValue } = this.props;
    setFieldValue('defaultLanguage', defaultLanguage);
  };

  onChangeMailLanguage = (mailLanguage: ID | null) => {
    const { setFieldValue } = this.props;
    setFieldValue('mailLanguage', mailLanguage);
  };

  renderExpireDate() {
    const {
      t,
      values,
    } = this.props;
    return (
      <FormRow label={t('offer_expire_date')} id="expire_date">
        <DatePicker
          id="copyright_expire"
          value={values.expire_date ? moment(values.expire_date).format('YYYY-MM-DD') : ''}
          onChange={this.onChangeExpireDate}
        />
      </FormRow>
    );
  }

  renderBusinessModel() {
    const {
      t,
      values,
      handleBlur,
      handleChange,
    } = this.props;
    return (
      <FormRow
        id="business_model"
        label={t('offer_business_model')}
      >
        <Input
          type="select"
          id="business_model"
          name="business_model"
          value={values.business_model}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="library">{t('library')}</option>
          <option value="market">{t('market')}</option>
        </Input>
      </FormRow>
    );
  }

  renderBaseDomain() {
    const {
      t,
      handleBlur,
      handleChange,
      values,
    } = this.props;
    return (
      <FormRow
        id="base_domain"
        label={t('base_domain_text')}
      >
        <Input
          type="select"
          id="base_domain"
          name="base_domain"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.base_domain}
        >
          <option value="alpinadigital.ru">{t('base_domain_ru')}</option>
          <option value="alpinadigital.io">{t('base_domain_io')}</option>
        </Input>
      </FormRow>
    );
  }

  renderLanguages() {
    const {
      t,
      handleBlur,
      values,
    } = this.props;
    return (
      <>
        <FormRow
          required
          id="languages"
          label={t('offer_languages')}
        >
          <LanguagesSelect
            id="languages"
            name="languages"
            onChange={this.onChangeLanguages}
            onBlur={handleBlur}
            value={values.languages}
          />
        </FormRow>
      </>
    );
  }

  renderDefaultLanguage() {
    const {
      t,
      handleBlur,
      values,
    } = this.props;
    return (
      <FormRow
        id="defaultLanguage"
        label={t('offer_default_language')}
        shouldUpdate={stubTrue}
      >
        <DefaultLanguageSelect
          defaultOption={[{ value: 0, label: t('dict:not_selected') }]}
          id="defaultLanguage"
          name="defaultLanguage"
          onChange={this.onChangeDefaultLanguage}
          onBlur={handleBlur}
          value={values.defaultLanguage}
          languages={values.languages}
        />
      </FormRow>
    );
  }

  renderMailLanguages() {
    const {
      t,
      handleBlur,
      values,
    } = this.props;
    return (
      <FormRow
        required
        id="mailLanguage"
        label={t('offer_default_mail_language')}
        shouldUpdate={stubTrue}
      >
        <DefaultLanguageSelect
          id="mailLanguage"
          name="mailLanguage"
          onChange={this.onChangeMailLanguage}
          onBlur={handleBlur}
          value={values.mailLanguage}
          languages={values.languages}
        />
      </FormRow>
    );
  }

  renderCategories() {
    const {
      values,
      t,
    } = this.props;
    return (
      <FormRow
        id="categories"
        label={t('categories:categories')}
      >
        <CategoriesSelect
          id="offers"
          name="offers"
          isMulti={true}
          value={values.categories}
          onChange={this.onChangeCategories}
        />
      </FormRow>
    );
  }

  renderBanners() {
    const {
      values,
      t,
    } = this.props;
    return (
      <FormRow
        id="banners"
        label={t('banners:banners')}
      >
        <BannersSelect
          id="banners"
          name="banners"
          isMulti={true}
          value={values.banners}
          onChange={this.onChangeBanners}
        />
      </FormRow>
    );
  }

  renderTrial = () => {
    const { values } = this.props;
    const form = this.props;
    const expTrialDate = moment(values.created_at).add('days', 21).format('DD-MM-YYYY');
    return (
      <Offer.Switch
        name="is_trial"
        form={form}
        text={values.is_trial ? expTrialDate : '\u00A0'}
      />
    );
  }

  render() {
    const {
      id,
      values,
    } = this.props;
    const form = this.props;
    const isCreateMode = !id;
    const prefixPlaceholder = `https://${values.prefix || 'example'}.${values.base_domain || 'alpinadigital.ru'}`;
    return (
      <FormFrame
        id={id}
        cancelLink={'/offers'}
        {...this.props}
      >
        <Offer.Input
          type="text"
          name="name"
          form={form}
          required
        />
        <Offer.Input
          type="text"
          name="prefix"
          placeholder="example"
          text={prefixPlaceholder}
          form={form}
          required
        />
        {this.renderBaseDomain()}
        {this.renderLanguages()}
        {this.renderDefaultLanguage()}
        {this.renderMailLanguages()}
        {this.renderTrial()}
        {this.renderExpireDate()}
        <Offer.Input
          type="textarea"
          name="contacts"
          placeholder="контакты HR-менеджера"
          form={form}
        />
        <Offer.Input type="number" name="cost" form={form} />

        {this.renderBusinessModel()}
        <Offer.Input type="number" name="grossing_period" form={form} />
        <Offer.Input type="text" name="ios_prefix" form={form} />

        <Offer.Input
          type="text"
          name="ios_link"
          text="https://itunes.apple.com/ru/app/id0000000000?l=ru&ls=1&mt=8"
          form={form}
        />
        <Offer.Input type="text" name="android_prefix" form={form} />
        <Offer.Input
          type="text"
          name="android_link"
          text="https://play.google.com/store/apps/details?id=ru.alpina.example"
          form={form}
        />
        <Offer.Switch name="singleapp_ios_enabled" form={form} />
        <Offer.Switch name="singleapp_android_enabled" form={form} />

        <Offer.Input type="text" name="singleapp_title" form={form} />
        <Offer.Switch name="enable_banners" form={form} />
        <Offer.Switch name="enable_pushes" form={form} />
        <Offer.Switch name="enable_external_link" form={form} text="Например, ссылка на ИМАГ" />

        <Offer.Switch name="enable_comments" form={form} />
        <Offer.Switch name="new_items_by_default" form={form} />
        {isCreateMode ? null : this.renderCategories()}
        {isCreateMode ? null : this.renderBanners()}
        {isCreateMode ? <Images components={Brand} form={this.props} /> : null}
      </FormFrame>
    );
  }
}

export default withTranslation('offers')(BaseOfferForm);
