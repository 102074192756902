import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  gql,
} from 'features/graphql';
import ReactSelect from 'features/ui/ReactSelect';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  ILanguage,
  ILanguageQueryResponse,
} from 'features/languages/types';

interface ILanguagesSelectProps extends ISharedSelectProps {
  data: ILanguage[];
}

interface ILanguagesSelectState {
  options: ISelectOption[];
}

class LanguagesSelect extends React.PureComponent<ILanguagesSelectProps, ILanguagesSelectState> {
  state = {
    options: [],
  };

  static getDerivedStateFromProps(props: any) {
    return {
      options: [{
        label: 'Не выбрано',
        value: 0,
      }].concat(props.data.map((entity: ILanguage) => ({
        value: +entity.id,
        label: entity.name,
      }))),
    };
  }

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        isMulti={false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

const QUERY = gql`
  query getLanguage($pageNum: Int, $perPage: Int) {
    LanguageQuery(page: $pageNum, perPage: $perPage) {
      items {
        id
        name
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ILanguageQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;

  return {
    data: data && data.LanguageQuery ? data.LanguageQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ILanguageQueryResponse, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: () => {
    return {
      variables: {
        pageNum: 1,
        perPage: 5000,
      },
    };
  },
})(LanguagesSelect);
