import React from 'react';
import { get } from 'lodash';
import { Page403 } from 'features/http';
import { UserRoleEnum } from 'features/users/types';

const ACL = React.createContext({});

const ACL_CONFIG = {
  role: null,
  permissions: {
    [UserRoleEnum.administrator]: [],
    [UserRoleEnum.support]: [
      'dashboard:access',
      'categories:access',
      'offers:access',
      'offers:offer:edit',
      'offers:registration:edit',
      'orders:access',
      'users:access',
      'offers:offer:view',
      'offers:branding:view',
      'offers:registration:view',
      'offers:content:view',
      'promocodes:access',
      'badges:access',
      'banners:access',
      'items:access',
      'books:access',
      'hybrids:access',
      'documents:access',
      'audiobooks:access',
      'videos:access',
      'collections:access',
      'creators:access',
    ],
  },
};

interface IAccessControl {
  children: React.ReactNode[] | React.ReactNode;
  fallback?: React.ReactNode;
  permission?: string;
  shouldRenderComponent?: boolean;
}

export class AccessControl extends React.PureComponent<IAccessControl> {
  hasPermission(acl: object, role: UserRoleEnum, permission?: string) {
    const permissions = get(acl, `permissions.${role}`, []);
    return permissions.includes(permission);
  }

  renderChildren = (acl: object) => {
    const {
      children,
      permission,
      fallback,
      shouldRenderComponent,
    } = this.props;
    const role = get(acl, 'role', undefined);
    if (role === UserRoleEnum.administrator) {
      return children;
    }
    if (this.hasPermission(acl, role, permission)) {
      return children;
    }
    const shouldRender = typeof shouldRenderComponent === 'boolean' ? shouldRenderComponent : true;
    return shouldRender ? fallback || <Page403 /> : null;
  }

  render() {
    return (
      <ACL.Consumer>
        {this.renderChildren}
      </ACL.Consumer>
    );
  }
}

export const AccessControlProvider = ({
  role,
  children,
}: {
  role: string | undefined,
  children: React.ReactNode[] | React.ReactNode;
}) => {
  const aclWithRole = {
    ...ACL_CONFIG,
    role,
  };
  return (
    <ACL.Provider value={aclWithRole}>
      {children}
    </ACL.Provider>
  );
};
