import React from 'react';
import Form from 'features/hybrids/lib/Form';
import { FEATURE_ICON } from 'features/hybrids/consts';
import CardPage from 'features/ui/Page/CardPage';
import { makeHybridUrl } from 'features/hybrids/lib/url';

class PageCreate extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Создание новой гибридной книги"
        crumbs={[{ link: makeHybridUrl(), name: 'Гибриды' }]}
      >
        <Form />
      </CardPage>
    );
  }
}

export default PageCreate;
