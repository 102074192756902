import React from 'react';
import cn from 'classnames';
import CollapsibleText from 'features/ui/CollapsibleText';

import styles from './styles.module.scss';

const TdComponent =
  (props: any) => {
    const {
      toggleSort, className, children, column, rowInfo, ...rest
    } = props;

    const tdClassName = cn('rt-td', className, {
      [styles['expand-td']]: column.expanded,
    });

    return (
      <div className={tdClassName} role="gridcell" {...rest}>
        {column.expandable && rowInfo ? <CollapsibleText id={rowInfo.index} text={children} /> : children}
      </div>
    );
  };

export default TdComponent;
