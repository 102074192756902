import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageCollections from 'features/collections/PageCollections';
import PageCreate from 'features/collections/PageCreate';
import PageEdit from 'features/collections/PageEdit';
import PageView from 'features/items/PageView';

export default () => {
  return (
    <AccessControl permission="collections:access">
      <Switch>
        <AppRoute path="/collections" menuId="collections" component={PageCollections} exact />
        <AppRoute path="/collection/create" menuId="collections" component={PageCreate} exact />
        <AppRoute path="/collection/edit/:id" menuId="collections" component={PageEdit} exact />
        <AppRoute path="/collection/:id" menuId="collections" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
