import React from 'react';
import { set } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import CardPage from 'features/ui/Page/CardPage';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import makeColumns from './columns';
import { makeBadgeUrl } from '../lib/url';
import getBadgesQuery from '../queries/getBadges.gql';
import deleteBadgeQuery from '../queries/deleteBadge.gql';
import { FEATURE_ICON } from '../consts';

type IPageBadges = WithTranslation;

class PageBadges extends React.Component<IPageBadges> {
  private _columns: any;

  constructor(props: IPageBadges) {
    super(props);
    this._columns = makeColumns();
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.offer) {
      set(variables, 'filterByFields.ownerOffer', {
        operator: '=',
        value: String(fields.offer),
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('badges')}
        buttons={[{ link: makeBadgeUrl({ action: 'create' }), name: t('new_badge') }]}
      >
        <IndexTableBase
          query={getBadgesQuery}
          queryResultName="BadgeQuery"
          columns={this._columns}
          filterFields={['id', 'offer']}
          prepareRequestData={this.prepareRequestData}
          deleteMutation={deleteBadgeQuery}
          makeCrudUrl={makeBadgeUrl}
          isFilterableById={true}
          showAutoEditIconColumn={true}
          showViewIconColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('badges')(PageBadges);
