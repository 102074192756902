import { InMemoryCache } from 'apollo-cache-inmemory';

export const initialState = {
  appState: {
    __typename: 'State',
    components: {
      __typename: 'Components',
      breadcrumbs: {
        __typename: 'Breadcrumbs',
        data: [],
      },
      sidemenu: {
        __typename: 'Sidemenu',
        activeId: '',
      },
    },
  },
};

const cache = new InMemoryCache();
cache.writeData({ data: initialState });

export default cache;
