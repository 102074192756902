import crudUrlBuilder from 'lib/crudUrlBuilder';
import { ISubscription } from '../../types';

export const makeSubscriptionUrl = crudUrlBuilder('subscriptions', 'subscriptions');

export const makeSubscriptionUrlTo = (subscription: ISubscription, action: string = 'view') => {
  return makeSubscriptionUrl({
    action,
    id: subscription.id,
  });
};
