import React, { useEffect, useRef, useState } from 'react';
import { reorder } from 'features/ui/DragNDropComponent/methods';
import DnDIcon from 'features/ui/DragNDropComponent/DnDIcon';
import {
  ANSWER_TYPE_CUSTOM,
  ANSWER_TYPE_DEFAULT,
  ANSWER_TYPE_MAPPING,
  ANSWER_TYPE_MULTISELECT,
  ANSWER_TYPE_ORDERING,
  ANSWER_TYPE_SELECT,
  ANSWER_TYPES,
} from 'features/tests/consts';
import AnswerTypeSelect from 'features/tests/lib/Questions/AnswerTypeSelect';
import DragNDropComponent from 'features/ui/DragNDropComponent';
import AnswerSelectOptionComponent from '../AnswerSelectOptionComponent';
import AnswerMultiselectOptionComponent from '../AnswerMultiselectOptionComponent';
import AnswerOrderingOptionComponent from '../AnswerOrderingOptionComponent';
import AnswerMappingOptionComponent from '../AnswerMappingOptionComponent';
import styles from './styles.module.scss';

interface IQuestionComponentProps {
  item: IQuestion;
  changeParam: (id: string, param: string, value: IAnswerOption[] | string) => void;
  remove: (id: string) => void;
}

const QuestionComponent = ({
  item: {
    id,
    sort,
    text,
    answer: {
      type: answerType,
      options,
    },
  },
  changeParam,
  remove,
}: IQuestionComponentProps) => {
  const [leftOptionsList, setLeftOptionsList] = useState<IAnswerOption[]>([]);
  const [rightOptionsList, setRightOptionsList] = useState<IAnswerOption[]>([]);
  const textInputRef = useRef(null);
  const shouldRenderTypeSelect = answerType === ANSWER_TYPE_DEFAULT;
  const onSelectChange = (value: string) => changeParam(id, 'answer.type', value);
  const onNameChange = (e: any) => changeParam(id, 'text', e.target.value);
  const onRemove = () => remove(id);

  const resolveOptions = () => {
    if (answerType === ANSWER_TYPE_MAPPING) {
      setLeftOptionsList(options.filter(option => option.position === 'left').sort((a, b) => a.sort - b.sort));
      setRightOptionsList(options.filter(option => option.position === 'right').sort((a, b) => a.sort - b.sort));
    }
  };

  useEffect(() => {
    resolveOptions();
  }, [options]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const newList = reorder(options, result.source.index, result.destination.index);
    changeParam(id, 'answer.options', newList);
  };

  const onMappingItemDragEnd = (result: any, side: string) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const newList = reorder(side === 'right' ? rightOptionsList : leftOptionsList, result.source.index, result.destination.index);
    const newSortedList = newList.map((itm, index) => ({ ...itm, sort: index }));
    if (side === 'right') {
      setRightOptionsList(newSortedList);
    } else {
      setLeftOptionsList(newSortedList);
    }
    const parsedList = options.map((opt) => {
      const itm = newSortedList.find(nopt => nopt.id === opt.id);
      if (itm) {
        return itm;
      }
      return opt;
    });
    changeParam(id, 'answer.options', parsedList);
  };

  const onOptionChange = (optionId: string, field: string, value: number[] | string | boolean) => {
    if (answerType === ANSWER_TYPE_SELECT) {
      const newList = options.map((opt) => {
        if (field === 'is_correct') {
          return opt.id !== optionId ? { ...opt, [field]: false } : { ...opt, [field]: value };
        }
        return opt.id !== optionId ? opt : { ...opt, [field]: value };
      });
      changeParam(id, 'answer.options', newList);
    }
    if (answerType === ANSWER_TYPE_MULTISELECT) {
      const newList = options.map((opt) => {
        if (field === 'is_correct') {
          return opt.id !== optionId ? { ...opt } : { ...opt, [field]: value };
        }
        return opt.id !== optionId ? opt : { ...opt, [field]: value };
      });
      changeParam(id, 'answer.options', newList);
    }
    if (answerType === ANSWER_TYPE_ORDERING) {
      const newList = options.map(opt => (opt.id !== optionId ? opt : { ...opt, [field]: value }));
      changeParam(id, 'answer.options', newList);
    }
    if (answerType === ANSWER_TYPE_MAPPING) {
      const newList = options.map(opt => (opt.id !== optionId ? opt : { ...opt, [field]: value }));
      changeParam(id, 'answer.options', newList);
    }
  };

  const onOptionRemove = (optionId: string) => changeParam(id, 'answer.options', options.filter(option => option.id !== optionId));

  const addAnswer = () => {
    const hash = Date.now();
    if (answerType !== ANSWER_TYPE_MAPPING) {
      const newAnswer = {
        id: `temp-answer-id${hash}`,
        name: '',
        is_correct: false,
        sort: options.length,
        comment: '',
      };
      changeParam(id, 'answer.options', [...options, newAnswer]);
    } else {
      const newAnswerLeft = {
        id: `temp-answer-id${hash}-left`,
        name: '',
        is_correct: false,
        sort: leftOptionsList.length,
        position: 'left',
        comment: '',
      };
      const newAnswerRight = {
        id: `temp-answer-id${hash}-right`,
        name: '',
        is_correct: false,
        sort: rightOptionsList.length,
        position: 'right',
        comment: '',
      };
      changeParam(id, 'answer.options', [...options, newAnswerLeft, newAnswerRight]);
    }
  };

  useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.style.height = `${textInputRef.current.scrollHeight}px`;
    }
  }, [text]);

  const AnswerComponentResolver = () => {
    if (answerType === ANSWER_TYPE_SELECT) {
      return <AnswerSelectOptionComponent changeParam={onOptionChange} onRemove={onOptionRemove} />;
    }
    if (answerType === ANSWER_TYPE_MULTISELECT) {
      return <AnswerMultiselectOptionComponent changeParam={onOptionChange} onRemove={onOptionRemove} />;
    }
    if (answerType === ANSWER_TYPE_ORDERING) {
      return <AnswerOrderingOptionComponent changeParam={onOptionChange} onRemove={onOptionRemove} />;
    }
    if (answerType === ANSWER_TYPE_MAPPING) {
      return <AnswerMappingOptionComponent changeParam={onOptionChange} onRemove={onOptionRemove} />;
    }
    return <div />;
  };

  const renderTooltip = () => {
    if (answerType === ANSWER_TYPE_ORDERING || answerType === ANSWER_TYPE_MAPPING) {
      return <p>Правильный порядок определяется порядком расстановки вариантов ответов</p>;
    }
    return null;
  };

  const renderList = () => {
    if (answerType === ANSWER_TYPE_MAPPING) {
      return (
        <div className="d-flex" style={{ gap: '10px' }}>
          <DragNDropComponent items={leftOptionsList} onDragEnd={(data) => onMappingItemDragEnd(data, 'left')} childPropName="item">
            {AnswerComponentResolver()}
          </DragNDropComponent>
          <DragNDropComponent items={rightOptionsList} onDragEnd={(data) => onMappingItemDragEnd(data, 'right')} childPropName="item">
            {AnswerComponentResolver()}
          </DragNDropComponent>
        </div>
      );
    }

    return (
      <DragNDropComponent items={options} onDragEnd={onDragEnd} childPropName="item">
        {AnswerComponentResolver()}
      </DragNDropComponent>
    );
  };

  return (
    <div className="card-block border border-light bg-light d-flex">
      <DnDIcon />
      <div className={`w-100 ml-3 ${styles.main}`}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h6 className="m-0">{`Вопрос ${sort + 1} ${shouldRenderTypeSelect ? '' : ANSWER_TYPES.find(at => at.value === answerType).label}`}</h6>
          <p className="text-danger m-0" onClick={onRemove}>Удалить вопрос</p>
        </div>
        <div>
          {shouldRenderTypeSelect ? (
            <div className="form-group mb-0">
              <label htmlFor="ats">Тип вопроса</label>
              <AnswerTypeSelect id="ats" value={answerType} onChange={onSelectChange} />
            </div>
          ) : (
            <div className="form-group mb-0">
              {renderTooltip()}
              <label htmlFor="question-name" className="w-100">
                Текст вопроса
                <textarea ref={textInputRef} id="question-name" value={text} onChange={onNameChange} className="form-control mb-3 mt-1" rows={1} />
              </label>
              {renderList()}
              {answerType !== ANSWER_TYPE_CUSTOM ? (
                <button type="button" className="btn btn-secondary" onClick={addAnswer}>Добавить вариант ответа</button>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionComponent;
