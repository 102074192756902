import React, { Component } from 'react';
import { match as IMatch } from 'react-router';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { History } from 'history';
import { set } from 'lodash';

import CardPage from 'features/ui/Page/CardPage';
import IndexTableBase, {
  IControlFields,
} from 'features/common/IndexTableBase';
import { ICursor } from 'features/types';
import makeColumns from './columns';
import getLayoutsQuery from '../queries/getLayoutsQuery.gql';
import deleteLayoutQuery from '../queries/deleteLayout.gql';
import { makeLayoutUrl } from '../lib/url';
import { ILayout } from '../types';
import { FEATURE_ICON } from '../consts';

interface ICategoriesTableProps extends WithTranslation {
  data: any;
  fetchMore: any;
  isLoading: boolean;
  items: any;
  history: History;
  match: IMatch<object>;
  pagination: any;
}

interface ICategoriesTableState {
  id?: string;
  name?: string;
  page?: string;
  perPage?: string;
  items: ILayout[];
  isLoading: boolean;
  pagination: ICursor;
}

class PageCategories extends Component<ICategoriesTableProps, ICategoriesTableState> {
  private _columns: object[];

  constructor(props: ICategoriesTableProps) {
    super(props);
    this._columns = makeColumns();
  }

  prepareRequestData(variables: any, data: IControlFields) {
    if (data.id) {
      variables.id = [data.id];
    }
    if (data.name) {
      set(variables, 'filterByFields.name', {
        value: String(data.name),
        operator: 'like',
      });
    }
    if (data.offers) {
      set(variables, 'filterByFields.offers', {
        operator: '=',
        value: String(data.offers),
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('layouts')}
        buttons={[{ link: makeLayoutUrl({ action: 'create' }), name: t('new_layout') }]}
      >
        <IndexTableBase
          filterFields={['id', 'name', 'offers']}
          query={getLayoutsQuery}
          queryResultName="LayoutQuery"
          columns={this._columns}
          prepareRequestData={this.prepareRequestData}
          deleteMutation={deleteLayoutQuery}
          makeCrudUrl={makeLayoutUrl}
          showAutoEditIconColumn={true}
          showViewIconColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('layouts')(PageCategories);
