import React from 'react';
import { match as IMatch } from 'react-router';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageBanners from '../PageBanners';
import PageCreate from '../PageCreate';
import PageEdit from '../PageEdit';
import PageView from '../PageView';

export default ({ match }: { match: IMatch<any> }) => {
  return (
    <AccessControl permission="banners:access">
      <div className="animated">
        <Switch>
          <AppRoute path={'/banners'} menuId="banners" component={PageBanners} exact />
          <AppRoute path={'/banner/create'} menuId="banners" component={PageCreate} exact />
          <AppRoute path={'/banner/edit/:id'} menuId="banners" component={PageEdit} exact />
          <AppRoute path={'/banner/:id'} menuId="banners" component={PageView} exact />
          <Route name="404" component={Page404} />
        </Switch>
      </div>
    </AccessControl>
  );
};
