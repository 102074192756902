import React from 'react';
import { omit } from 'lodash';
import {
  Input as ReactstrapInput,
  InputProps as ReactstrapInputProps,
} from 'reactstrap';

export type InputProps = ReactstrapInputProps & {
  onEnter?: React.KeyboardEventHandler;
  onKeyUp?: React.KeyboardEventHandler;
};

export default class extends React.PureComponent<InputProps> {
  onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 || event.which === 13) {
      const { onEnter } = this.props;
      if (onEnter) {
        onEnter(event);
      }
    }
  }

  onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { onKeyUp } = this.props;
    if (onKeyUp) {
      onKeyUp(event);
    }
    this.onEnter(event);
  }

  render() {
    const reactstrapInputProps: ReactstrapInputProps = omit(this.props, 'onEnter');
    return (
      <ReactstrapInput {...reactstrapInputProps} onKeyUp={this.onKeyUp} />
    );
  }
}
