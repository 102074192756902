import React from 'react';
import {
  Button,
  Input,
} from 'reactstrap';
import imagePlaceholder from 'lib/imagePlaceholder';
import backgroundTile from 'static/img/background_tile.png';

import styles from './styles.module.scss';

const DEFAUTL_WIDTH = 200;
const DEFAUTL_HEIGHT = 150;

interface IImageUploaderProps {
  id?: string;
  tooltip?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<any> | null) => void;
  onBlur?: (e: any) => void;
  // Может принимать File, но ничего с ним не делает. Это упрощает вызывающий код.
  value: string | File| null;
  imageWidth?: number;
  imageHeight?: number;
  imageBackground?: string;
  canBeDeleted?: boolean;
}

interface IImageUploaderState {
  cachedImage?: string;
  changed: boolean;
}

export default class ImageUploader extends React.Component<IImageUploaderProps, IImageUploaderState> {
  static defaultProps: {
    canBeDeleted: false,
  };

  constructor(props: IImageUploaderProps) {
    super(props);
    this.state = {
      cachedImage: this.buildImagePlaceholder(),
      changed: false,
    };
  }

  buildImagePlaceholder() {
    const {
      imageWidth,
      imageHeight,
    } = this.props;
    return imagePlaceholder(imageWidth || DEFAUTL_WIDTH, imageHeight || DEFAUTL_HEIGHT);
  }

  onChangeImage = (imageEvent: React.ChangeEvent<any>) => {
    const { onChange } = this.props;
    imageEvent.persist();
    if (imageEvent.target.validity.valid) {
      const file = imageEvent.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = ((e: any) => {
          this.setState(
            {
              cachedImage: e.target.result,
              changed: true,
            },
            () => {
              if (onChange) {
                onChange(imageEvent);
              }
            },
          );
        });
        reader.readAsDataURL(file);
      }
    }
  };

  onClearImage = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(null);
    }
    this.setState({
      cachedImage: this.buildImagePlaceholder(),
      changed: true,
    });
  };

  renderDeleteButton() {
    const {
      canBeDeleted,
      value,
    } = this.props;
    if (canBeDeleted && value !== null) {
      return (
        <Button
          outline
          className={styles.deleteButton}
          color="secondary"
          type="button"
          onClick={this.onClearImage}
        >
          <i className="fa fa-close" />
        </Button>
      );
    }
    return null;
  }

  renderImage() {
    const {
      imageWidth,
      imageBackground,
      value,
      tooltip,
    } = this.props;
    const {
      cachedImage,
      changed,
    } = this.state;
    const imgStyle: { [key: string]: string | number } = { maxWidth: imageWidth || DEFAUTL_WIDTH };
    if (value) {
      imgStyle.backgroundImage = `url(${backgroundTile})`;
    }
    if (typeof value === 'string' && !changed) {
      return (
        <span className={styles.imageContainer} style={imageBackground ? { background: imageBackground } : {}}>
          <a href={value} target="_blank">
            <img
              className={styles.image}
              style={imgStyle}
              src={value}
            />
          </a>
          {this.renderDeleteButton()}
        </span>
      );
    }
    return (
      <span className={styles.imageContainer} style={imageBackground ? { background: imageBackground } : {}}>
        <img
          className={styles.image}
          style={imgStyle}
          src={cachedImage}
        />
        {this.renderDeleteButton()}
        {tooltip ? <div className="form-text text-muted">{tooltip}</div> : null}
      </span>
    );
  }

  render() {
    const {
      id,
      name,
      onBlur,
    } = this.props;
    return (
      <React.Fragment>
        <Input
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={this.onChangeImage}
          className={styles.fileInput}
          type="file"
        />
        <div>
          {this.renderImage()}
        </div>
      </React.Fragment>
    );
  }
}
