import React from 'react';
import i18n from 'features/intl/i18n';
import IsActiveColumn from 'features/ui/IsActiveColumn';
import ListOfOffers from 'features/offers/lib/ListOfOffers';
import OffersSelect from 'features/offers/lib/OffersSelect';
import { filterInputHandler } from 'features/common';
import { IPopularRequest } from '../types';

export default () => {
  return [
    {
      Header: i18n.t('popular_requests:label'),
      accessor: 'label',
      filterable: true,
      minWidth: 100,
      Filter: filterInputHandler,
    },
    {
      Cell: IsActiveColumn,
      Header: i18n.t('popular_requests:is_active'),
      accessor: (data: IPopularRequest) => data.is_active,
      filterable: false,
      id: 'is_active',
      maxWidth: 100,
    },
    {
      Cell: ({ value }: { value: IPopularRequest }) => <ListOfOffers offers={value.offers} />,
      Header: i18n.t('popular_requests:offers'),
      accessor: (data: any) => data,
      expandable: true,
      id: 'offer',
      maxWidth: 300,
      Filter: ({ filter, onChange }: any) => (
        <OffersSelect
          onChange={onChange}
          value={filter && filter.value}
          isMulti={false}
        />
      ),
    },
  ];
};
