import React from 'react';
import InputSelect from 'features/ui/InputSelect';

import {
  UserGenderEnum,
} from 'features/users/types';

interface IUserGenderSelectProps {
  id: string;
  name: string;
  value?: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
}

const options: { [type: string]: string } = {
  ['']: '-',
  [UserGenderEnum.male]: 'муж.',
  [UserGenderEnum.female]: 'жен.',
};

const UserGenderSelect = (props: IUserGenderSelectProps) => {
  return (
    <InputSelect
      options={options}
      {...props}
    />
  );
};

export default UserGenderSelect;
