import crudUrlBuilder from 'lib/crudUrlBuilder';
import { IPopularRequest } from '../../types';

export const makePopularRequestUrl = crudUrlBuilder('popular_request', 'popular_requests');

export const makePopularRequestUrlTo = (popularRequest: IPopularRequest, action: string = 'view') => {
  return makePopularRequestUrl({
    action,
    id: popularRequest.id,
  });
};
