import React from 'react';
import i18n from 'features/intl/i18n';
import InputSelect, { IInputSelectProps } from 'features/ui/InputSelect';

import { OrderPaymentStatus } from '../../types';

const options: { [type: string]: string } = {
  ['']: '-',
  [OrderPaymentStatus.failed]: i18n.t('orders:failed'),
  [OrderPaymentStatus.paid]: i18n.t('orders:paid'),
  [OrderPaymentStatus.pending]: i18n.t('orders:pending'),
};

export default (props: Omit<IInputSelectProps, 'options'>) => {
  return (
    <InputSelect
      options={options}
      {...props}
    />
  );
};
