import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  client,
  gql,
} from 'features/graphql';
import ReactSelect from 'features/ui/ReactSelect/ReactSelectWithId';
import {
  TYPE_PROVIDER,
  TYPE_PUBLISHER,
} from 'features/creators/consts';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  ICreator,
  ICreatorQueryResponse,
} from 'features/creators/types';

const singleValueTypes = [TYPE_PROVIDER, TYPE_PUBLISHER];

interface IInputProps extends ISharedSelectProps {
  type: string;
}

interface ICreatorsSelectProps extends IInputProps {
  data: ICreator[];
}

interface ICreatorsSelectState {
  options: ISelectOption[];
}

const QUERY = gql`
  query getCreatorsForSelect($id: [ID], $pageNum: Int, $perPage: Int, $type: String, $searchByFields: CreatorSearchByFields) {
    CreatorQuery(
      id: $id,
      page: $pageNum,
      perPage: $perPage,
      filterByFields: { type: { operator: "=", value: $type } },
      searchByFields: $searchByFields,
    ) {
      items {
        id
        short_name
        full_name
      }
    }
  }
`;

class CreatorsSelect extends React.PureComponent<ICreatorsSelectProps, ICreatorsSelectState> {
  static getDerivedStateFromProps(props: ICreatorsSelectProps) {
    return {
      options: CreatorsSelect.mapCreatorsToOptions(props.data),
    };
  }

  static mapCreatorsToOptions = (data: ICreator[]) => data.map((creator: ICreator) => ({
    value: +creator.id,
    label: creator.full_name,
  }));

  state = {
    options: [],
  };

  loadOptions = (value: string) => {
    return client.query<ICreatorQueryResponse>({
      query: QUERY,
      variables: {
        pageNum: 1,
        perPage: 100,
        type: this.props.type,
        searchByFields: {
          full_name: value,
        },
      },
    }).then((res) => {
      if (res.data && res.data.CreatorQuery) {
        return CreatorsSelect.mapCreatorsToOptions(res.data.CreatorQuery.items);
      }
      return [];
    });
  };

  noOptionsMessage = () => 'Ничего не найдено';

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
      type,
      isMulti,
      renderedComponents,
    } = this.props;

    const {
      options,
    } = this.state;

    const isMultiSelect = isMulti === undefined ? !singleValueTypes.includes(type) : isMulti;

    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        isMulti={isMultiSelect}
        async={isMultiSelect}
        loadOptions={isMultiSelect ? this.loadOptions : undefined}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        noOptionsMessage={this.noOptionsMessage}
        minSearchLength={1}
        renderedComponents={renderedComponents}
      />
    );
  }
}

interface IQueryVariables extends IListQueryVariables {
  type?: string;
}

const mapResultsToProps = (props: OptionProps<IInputProps, ICreatorQueryResponse, IQueryVariables>) => {
  const { data, ownProps } = props;

  return {
    data: data && data.CreatorQuery ? data.CreatorQuery.items : [],
    ...ownProps,
  };
};

export default graphql<IInputProps, ICreatorQueryResponse, IQueryVariables, ICreatorsSelectProps>(QUERY, {
  props: mapResultsToProps,
  options: (ownProps) => {
    const isMultiSelect = ownProps.isMulti === undefined ? !singleValueTypes.includes(ownProps.type) : ownProps.isMulti;
    return {
      variables: {
        id: !isMultiSelect ? undefined : ownProps.value,
        pageNum: 1,
        perPage: !isMultiSelect ? 5000 : 100,
        type: ownProps.type,
        searchByFields: null,
      },
    };
  },
})(CreatorsSelect);
