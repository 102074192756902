import crudUrlBuilder from 'lib/crudUrlBuilder';
import { ILayout } from '../../types';

export const makeLayoutBlockUrl = crudUrlBuilder('layout-block', 'layout-blocks');

export const makeLayoutBlockUrlTo = (user: ILayout, action: string = 'view') => {
  return makeLayoutBlockUrl({
    action,
    id: user.id,
  });
};
