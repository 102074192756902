import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageArticles from 'features/articles/PageArticles';
import PageCreate from 'features/articles/PageCreate';
import PageEdit from 'features/articles/PageEdit';
import PageView from 'features/items/PageView';

export default () => (
  <AccessControl permission="videos:access">
    <Switch>
      <AppRoute path="/articles" menuId="articles" component={PageArticles} exact />
      <AppRoute path="/article/create" menuId="articles" component={PageCreate} exact />
      <AppRoute path="/article/edit/:id" menuId="articles" component={PageEdit} exact />
      <AppRoute path="/article/:id" menuId="articles" component={PageView} exact />
      <Route name="404" component={Page404} />
    </Switch>
  </AccessControl>
);
