import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  client,
  gql,
} from 'features/graphql';
import {
  ICategory,
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  ICategoryQueryResponse,
} from 'features/categories/types';
import {
  mapFullEntityToOptions,
  ReactSelectWithId,
} from 'features/ui/ReactSelect';
import { IBlock } from 'features/blocks/types';

const QUERY = gql`
  query getBlockTypes($page: Int, $perPage: Int) {
    LayoutBlockTypeQuery(page: $page, perPage: $perPage) {
      items {
        id
        name
        slug
      }
    }
  }
`;

interface ICategoriesSelectProps extends ISharedSelectProps {
  data: ICategory[];
}

interface ICategoriesSelectState {
  options: ISelectOption[];
}

class CategoriesSelect extends React.PureComponent<ICategoriesSelectProps, ICategoriesSelectState> {
  static getDerivedStateFromProps(props: any) {
    return {
      options: mapFullEntityToOptions<IBlock>(props.data, 'name'),
    };
  }

  state = {
    options: [],
  };

  loadOptions = (value: string) => client.query<ICategoryQueryResponse>({
    query: QUERY,
    variables: {
      page: 1,
      perPage: 50,
    },
  }).then((res) => {
    const { considerParents } = this.props;
    if (res.data && res.data.CategoryQuery) {
      let categories = res.data.CategoryQuery.items;
      if (considerParents) {
        categories = res.data.CategoryQuery.items.filter((category: ICategory) => category.parentCategory === null);
      }
      return categories.map((category: ICategory) => ({
        value: +category.id,
        label: String(category.name),
      })).sort((a: ISelectOption<number>, b: ISelectOption<number>) => (a.value - b.value));
    }
    return [];
  });

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
      isMulti,
    } = this.props;

    const { options } = this.state;

    // TODO filter sets_items from options, await for ready ADTASK-5306
    const filteredOptions = options.filter(opt => opt.slug !== 'sets_items');

    return (
      <ReactSelectWithId
        id={id}
        name={name}
        options={filteredOptions}
        isMulti={!!isMulti}
        async
        loadOptions={this.loadOptions}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        minSearchLength={1}
      />
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ICategoryQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.LayoutBlockTypeQuery ? data.LayoutBlockTypeQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ICategoryQueryResponse, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: () => ({
    variables: {
      page: 1,
      perPage: 50,
    },
  }),
})(CategoriesSelect);
