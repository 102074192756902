import React from 'react';
import PageItems from 'features/items/PageItems';
import i18n from 'features/intl/i18n';
import { FEATURE_ICON } from 'features/events/consts';
import { Column } from 'features/types';
import moment from 'moment';
import { CellInfo } from 'react-table';

class PageEvents extends React.PureComponent<{}> {
  resolveColumns = (columns: Column[]) => {
    columns.splice(2, 0, {
      id: 'duration_time',
      Header: i18n.t('events:duration_time'),
      Cell: (data: CellInfo) => `${moment(data.value.event.started_at).format('DD.MM.YYYY')} – ${moment(data.value.event.finished_at).format('DD.MM.YYYY')}`,
      accessor: data => data,
    });
    return columns.map(column => column)
      .filter(column => column.id !== 'publisher');
  };

  render() {
    return (
      <PageItems
        title="Мероприятия"
        type="event"
        icon={FEATURE_ICON}
        resolveColumns={this.resolveColumns}
      />
    );
  }
}

export default PageEvents;
