import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  match as IMatch,
  Redirect,
} from 'react-router';

import CardPage from 'features/ui/Page/CardPage';
import { ILayout } from 'features/layouts/types';
import LayoutForm from '../lib/LayoutForm';
import createLayoutBlock from '../queries/createLayoutBlock.gql';
import { makeLayoutBlockUrl } from '../lib/url';
import { FEATURE_ICON } from '../consts';

interface IPageCreateProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  block: ILayout;
}

interface IPageCreateState {
  id?: string;
}

class PageCreate extends React.Component<IPageCreateProps, IPageCreateState> {
  constructor(props: IPageCreateProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const { id } = this.state;
    if (id) {
      return <Redirect to={makeLayoutBlockUrl({ id })} />;
    }

    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('new_block')}
        crumbs={[{ link: makeLayoutBlockUrl(), name: t('blocks') }]}
      >
        <LayoutForm mutation={createLayoutBlock} />
      </CardPage>
    );
  }
}

export default withTranslation('blocks')(PageCreate);
