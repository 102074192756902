import React, { useRef, useState } from 'react';
import { useAbortiveQuery } from 'features/courses/lib/Stages/helpers';
import useOutside from 'lib/useOutside';
import Spinner from 'features/ui/Spinner';
import DragNDropComponent from 'features/ui/DragNDropComponent';
import { reorder } from 'features/ui/DragNDropComponent/methods';
import styles from 'features/courses/lib/Stages/style.module.scss';
import { useTranslation } from 'react-i18next';

const SelectedOption = (props: any) => {
  const { t } = useTranslation('');
  const { id, name, type } = props.childItems;
  const { onRemove } = props;
  return (
    <div className={styles['selectable-list-item']}>
      <div>{id} | {name} | {t(`items:types:${type}`)}</div>
      <div className={`close ${styles['close-container']}`} onClick={() => onRemove(id)}>
        <i className="icon-close" />
      </div>
    </div>
  );
};

interface IStageItem {
  id: string;
  name: string;
  type: string;
}

interface ISearchResultProps {
  item: IStageItem;
  selectedItems: IStageItem[];
  onSelect: (e: any, item: IStageItem) => void;
}

const SearchResult = ({ item, selectedItems, onSelect }: ISearchResultProps) => {
  const { t } = useTranslation('');
  return (
    <div className={selectedItems.find(sItem => sItem.id === item.id) ? styles.disabled : undefined} onClick={e => onSelect(e, item)}>
      <span>{item.id} | {item.name} | {t(`items:types:${item.type}`)}</span>
    </div>
  );
};

interface IStageItemSelectProps {
  id: string;
  items: IStageItem[];
  changeStageParam: (id: string, field: string, value: any) => void;
}
const StageItemSelect = ({ id, items, changeStageParam }: IStageItemSelectProps) => {
  const [stageName, setStageName] = useState<string>('');
  const { data, loading } = useAbortiveQuery(stageName);
  const wrapperRef = useRef(null);

  const onContentSelect = (e: any, item: IStageItem) => {
    e.stopPropagation();
    if (items.find(sItem => sItem.id === item.id)) {
      changeStageParam(item.id, 'childItems', items.filter(pItem => pItem.id !== item.id));
    } else {
      changeStageParam(id, 'childItems', [...items, item]);
    }
    setStageName('');
  };

  const onContentRemove = (itemId: string) => {
    changeStageParam(id, 'childItems', items.filter(pItem => pItem.id !== itemId));
  };

  const onBlur = () => {
    setStageName('');
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newList = reorder(items, result.source.index, result.destination.index);
    changeStageParam(id, 'childItems', newList);
  };

  const renderResults = () => {
    if (loading) {
      return (
        <div className={`card ${styles['selectable-options']}`}>
          <Spinner />
        </div>
      );
    }

    if (data && data.length) {
      return (
        <div className={`card ${styles['selectable-options']}`}>
          {data.map((item: IStageItem) => <SearchResult key={item.id} item={item} selectedItems={items} onSelect={onContentSelect} />)}
        </div>
      );
    }
    return null;
  };

  const renderSelectedItems = () => {
    if (!items.length) {
      return null;
    }
    return (
      <div className={styles['selected-items-container']}>
        <DragNDropComponent items={items} onDragEnd={onDragEnd} childPropName="childItems">
          <SelectedOption onRemove={onContentRemove} />
        </DragNDropComponent>
      </div>
    );
  };

  useOutside(wrapperRef, onBlur);
  return (
    <div className={styles['wrap-container']}>
      {renderSelectedItems()}
      <div ref={wrapperRef}>
        <input
          onBlur={onBlur}
          className={`input ${styles['selectable-input']}`}
          type="text"
          placeholder="Начните вводить название"
          onChange={(e) => setStageName(e.target.value)}
          value={stageName}
        />
        {renderResults()}
      </div>
    </div>
  );
};

export default StageItemSelect;
