import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { flow } from 'lodash';
import {
  match as IMatch,
  Redirect,
} from 'react-router';
import { IListQueryVariables } from 'features/types';
import deepOmit from 'lib/deepOmit';
import LinkBlock from 'features/ui/LinkBlock';
import CardPage from 'features/ui/Page/CardPage';
import Spinner from 'features/ui/Spinner';
import BadgeForm from '../lib/BadgeForm';
import {
  makeBadgeUrl,
  makeBadgeUrlTo,
} from '../lib/url';
import getBadgeQuery from '../queries/getBadge.gql';

import { FEATURE_ICON } from '../consts';

import {
  IBadge,
  IBadgeQueryResponse,
} from '../types';

interface IPageBadgeProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  badge: IBadge;
}

const PageEdit = (props: IPageBadgeProps) => {
  const {
    badge,
    isLoading,
    t,
  } = props;
  if (!isLoading && !badge) {
    return <Redirect to="/404" />;
  }
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <CardPage
      icon={FEATURE_ICON}
      title={badge ? `${t('edit_badge')}: ${badge.name}` : ''}
      crumbs={[{ link: makeBadgeUrl(), name: t('badges') }]}
    >
      <LinkBlock viewLink={makeBadgeUrlTo(badge, 'view')} />
      <BadgeForm badge={badge} />
    </CardPage>
  );
};

const mapResultsToProps = (props: OptionProps<IPageBadgeProps, IBadgeQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  const badge = data && data.BadgeQuery ? deepOmit(data.BadgeQuery.items[0], '__typename') : null;
  return {
    badge,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphql(getBadgeQuery, {
    props: mapResultsToProps,
    options: ({ match }: { match: IMatch<any> }) => {
      const { params } = match;
      const { id } = params || { id: 0 };
      return {
        context: { isGlobalLoading: true },
        variables: { id: [id] },
      };
    },
  }),
  withTranslation('badges'),
)(PageEdit);
