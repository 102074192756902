import crudUrlBuilder from 'lib/crudUrlBuilder';
import { ILayout } from '../../types';

export const makeLayoutUrl = crudUrlBuilder('layout', 'layouts');

export const makeLayoutUrlTo = (user: ILayout, action: string = 'view') => {
  return makeLayoutUrl({
    action,
    id: user.id,
  });
};
