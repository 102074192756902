import React from 'react';
import Form from 'features/videos/lib/Form';
import CardPage from 'features/ui/Page/CardPage';
import { makeVideoUrl } from 'features/videos/lib/url';
import { FEATURE_ICON } from 'features/videos/consts';

class PageCreate extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        title="Создание нового Видео"
        icon={FEATURE_ICON}
        crumbs={[{ link: makeVideoUrl(), name: 'Видео' }]}
      >
        <Form />
      </CardPage>
    );
  }
}

export default PageCreate;
