import React from 'react';
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap';
import HeaderDropdownAccount from './HeaderDropdownAccount';
import Logo from './Icon';

interface IHeaderProps {
  email: string;
  onLogout: () => void;
}

class BaseHeader extends React.PureComponent<IHeaderProps> {
  sidebarToggle(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  render() {
    const { email } = this.props;
    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
          <Logo />
        <NavbarToggler className="d-md-down-none mr-auto" onClick={this.sidebarToggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <Nav className="ml-auto mr-3" navbar>
          <HeaderDropdownAccount
            email={email}
            onLogout={this.props.onLogout}
          />
        </Nav>
      </header>
    );
  }
}

export default BaseHeader;
