import {
  updateState,
} from 'features/graphql/helpers';

import {
  IBreadcrumb,
  IState,
} from 'features/types';

export const updateBreadcrumbsData = (breadcrumbs: IBreadcrumb[]) => updateState((state: IState) => {
  return Object.assign({}, state, {
    components: {
      ...state.components,
      breadcrumbs: {
        ...state.components.breadcrumbs,
        data: breadcrumbs,
      },
    },
  });
});

export const breadcrumbsDataSelector = (state: IState): IBreadcrumb[] => state.components.breadcrumbs.data;
