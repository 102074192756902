import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { client } from 'features/graphql';
import { toast } from 'features/ui/Toast';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import getAutoImportsQuery from 'features/autoimport/queries/getAutoImportsQuery.gql';
import updateAutoImportMutation from 'features/autoimport/queries/updateAutoImportMutation.gql';
import resolveColumns from './columns';

import { Column } from 'features/types';

type IDataTableProps = WithTranslation;

class DataTable extends React.PureComponent<IDataTableProps> {
  columns: Column[];

  constructor(props: IDataTableProps) {
    super(props);

    this.columns = resolveColumns({
      onEnable: this.onToggleEnableDisable(true),
      onDisable: this.onToggleEnableDisable(false),
    }).filter(col => !!col) as Column[];
  }

  onToggleEnableDisable = (enable: boolean) => (id: number) => {
    const { t } = this.props;
    return client.mutate({
      mutation: updateAutoImportMutation,
      variables: {
        id,
        enable,
      },
      context: {
        isGlobalLoading: true,
      },
    }).then(() => {
      toast.info(t(enable ? 'enabled' : 'disabled'));
    }).catch(() => {
      toast.error('dict:error');
    });
  };

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
  };

  render() {
    return (
      <IndexTableBase
        columns={this.columns}
        filterFields={['id']}
        prepareRequestData={this.prepareRequestData}
        query={getAutoImportsQuery}
        queryResultName="AutoImportQuery"
        showAutoIdColumn={false}
        showAutoEditIconColumn={false}
        graphqlQuery={getAutoImportsQuery}
        graphqlConfig={{
          options: () => {
            return {
              variables: {},
            };
          },
        }}
      />
    );
  }
}

export default withTranslation('autoimport')(DataTable);
