import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  match as IMatch,
  Redirect,
} from 'react-router';

import CardPage from 'features/ui/Page/CardPage';
import { ICategory } from '../types';
import CategoryForm from '../lib/CategoryForm';
import createCategoryMutation from '../queries/createCategory.gql';
import { makeCategoryUrl } from '../lib/url';
import { FEATURE_ICON } from '../consts';

interface IPageCreateProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  category: ICategory;
}

interface IPageCreateState {
  id?: string;
}

class PageCreate extends React.Component<IPageCreateProps, IPageCreateState> {
  constructor(props: IPageCreateProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const { id } = this.state;
    if (id) {
      return <Redirect to={makeCategoryUrl({ id })} />;
    }

    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('new_category')}
        crumbs={[{ link: makeCategoryUrl(), name: t('categories') }]}
      >
        <CategoryForm mutation={createCategoryMutation} />
      </CardPage>
    );
  }
}

export default withTranslation('categories')(PageCreate);
