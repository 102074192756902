import React from 'react';
import { client, gql } from 'features/graphql';
import {
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import { IUserGroup } from 'features/usergroups/types';
import ReactSelect from 'features/ui/ReactSelect/ReactSelectWithId';

interface IItemsSelectState {
  options: ISelectOption[];
}

const QUERY = gql`
  query getUserGroupsForSelect($pageNum: Int, $perPage: Int, $filterByFields: UserGroupFilterByFields) {
    UserGroupQuery(
      page: $pageNum,
      perPage: $perPage,
      filterByFields: $filterByFields
    ) {
      items {
        id
        name
      }
    }
  }
`;

class UserGroupsSelect extends React.PureComponent<ISharedSelectProps, IItemsSelectState> {
  constructor(props: ISharedSelectProps) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.getUserGroups();
  }

  componentDidUpdate(prevProps: Readonly<ISharedSelectProps>) {
    const { offer, value, onChange } = this.props;

    if (prevProps.offer !== offer) {
      if (value && value.length) {
        onChange([]);
      }
      this.getUserGroups();
    }
  }

  getUserGroups = () => {
    const { offer } = this.props;
    client.query({
      query: QUERY,
      variables: {
        pageNum: 1,
        perPage: 5000,
        filterByFields: {
          offer_id: {
            operator: 'in',
            value: String(offer),
          },
        },
      },
    }).then((response: any) => {
      this.setState({
        options: response.data.UserGroupQuery.items.map((item: IUserGroup) => ({
          value: +item.id,
          label: item.name,
        })),
      });
    });
  }

  render() {
    const {
      id,
      isMulti,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        isMulti={isMulti === undefined ? true : isMulti}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

export default UserGroupsSelect;
