import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import ReactSelect from 'features/ui/ReactSelect';
import getLanguageQuery from 'features/offers/queries/getLanguageQuery.gql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  ILanguage,
  ILanguageQueryResponse,
} from 'features/languages/types';

interface ILanguagesSelectProps extends ISharedSelectProps {
  data: ILanguage[];
}

interface ILanguagesSelectState {
  options: ISelectOption[];
}

class DefauleLanguageSelect extends React.PureComponent<ILanguagesSelectProps, ILanguagesSelectState> {
  static getDerivedStateFromProps(props: any) {
    return {
      options: props.data.map((entity: ILanguage) => ({
        value: +entity.id,
        label: entity.name,
      }))
        .filter(entity => props.languages.some(language => +language === +entity.value)),
    };
  }

  state = {
    options: [],
    defaultOption: this.props.defaultOption || [],
  };

  componentDidUpdate(prevProps: Readonly<ILanguagesSelectProps>, prevState: Readonly<ILanguagesSelectState>, snapshot?: any) {
    const { options } = this.state;
    const { value } = this.props;
    if (options.length !== prevState.options.length && this.props.id === 'mailLanguage') {
      if (value && value.id && !options.find(opt => opt.value == value.id)) {
        this.props.onChange(null);
      }
    }
  }

  onChangeDefault = (id: ID) => {
    const { onChange } = this.props;
    const { options } = this.state;
    if (id === 0) {
      return onChange(null);
    }
    const currentLanguageObject: ISelectOption = options.filter((language: ISelectOption) => language.value === id)[0];
    onChange({
      id: currentLanguageObject.value,
      name: currentLanguageObject.label,
    });
  }

  render() {
    const {
      id,
      name,
      value,
      onBlur,
    } = this.props;
    const {
      options,
      defaultOption,
    } = this.state;

    const mutatedOptions = [...options, ...defaultOption];
    return (
        <ReactSelect
          id={id}
          name={name}
          options={mutatedOptions}
          isMulti={false}
          value={value ? value.id : 0}
          onChange={this.onChangeDefault}
          onBlur={onBlur}
        />
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ILanguageQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;

  return {
    data: data && data.LanguageQuery ? data.LanguageQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ILanguageQueryResponse, IListQueryVariables, any>(getLanguageQuery, {
  props: mapResultsToProps,
  options: () => ({
    variables: {
      pageNum: 1,
      perPage: 5000,
    },
  }),
})(DefauleLanguageSelect);
