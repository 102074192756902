import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageSets from '../PageSets';
import PageCreate from '../PageCreate';
import PageEdit from '../PageEdit';
// import PageView from '../PageView';

export default () => (
    <AccessControl permission="sets:access">
      <div className="animated">
        <Switch>
          <AppRoute path="/sets" menuId="sets" component={PageSets} exact />
          <AppRoute path="/set/create" menuId="sets" component={PageCreate} exact />
          <AppRoute path="/set/edit/:id" menuId="sets" component={PageEdit} exact />
          <AppRoute path="/set/:id" menuId="sets" component={PageSets} exact />
          <Route name="404" component={Page404} />
        </Switch>
      </div>
    </AccessControl>
);
