import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { InfoRowText } from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { createView } from 'features/common';
import {
  makeLayoutBlockUrl,
  makeLayoutBlockUrlTo,
} from '../lib/url';
import getEntity from '../queries/getLayoutBlockQuery.gql';

import { FEATURE_ICON } from '../consts';

import { ILayout } from '../types';

const View = createView<ILayout>(getEntity, 'LayoutBlockQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderChildren = (block: ILayout) => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <LinkBlock editLink={makeLayoutBlockUrlTo(block, 'edit')} />
        <InfoRowText label="ID" value={block.id} />
        <InfoRowText label={t('name')} value={block.name} />
        <hr />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField="name"
        icon={FEATURE_ICON}
        crumbs={[{ link: makeLayoutBlockUrl(), name: t('layouts') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('blocks')(PageView);
