import React from 'react';
import { Link } from 'react-router-dom';

export default ({ id, name, to }: { id?: ID | number, name: string, to: string }) => {
  let idBlock = null;
  if (id) {
    idBlock = (
      <React.Fragment>
        <b>{id}</b>&nbsp;
      </React.Fragment>
    );
  }
  return (
    <Link to={to}>
      {idBlock}{name}
    </Link>
  );
};
