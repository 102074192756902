import { DocumentNode } from 'graphql';
import { client } from 'features/graphql';
import { toast } from 'features/ui/Toast';
import history from 'features/app/history';
import i18n from 'features/intl/i18n';
import formErrorHandler from 'features/graphql/formErrorHandler';
import { FormikActions } from 'formik';

interface ICrudMutateProps {
  id?: number | ID;
  variables: object;
  mutation: DocumentNode;
  redirect?: string;
  updateRefetchQuery?: DocumentNode;
  createRefetchQuery?: DocumentNode;
  formActions: FormikActions<any>;
  preventDefaultOnCreation?: boolean;
  check?: boolean;
}

export const crudMutate = (props: ICrudMutateProps) => {
  const {
    id,
    variables,
    mutation,
    redirect,
    updateRefetchQuery,
    createRefetchQuery,
    formActions,
    preventDefaultOnCreation,
    check,
  } = props;
  if (check !== undefined && !check) {
    formActions.setSubmitting(false);
    return Promise.resolve();
  }
  formActions.setSubmitting(true);
  if (id) {
    return client.mutate({
      variables,
      mutation,
      context: { isGlobalLoading: true },
      refetchQueries: updateRefetchQuery ? [{ query: updateRefetchQuery, variables: { id } }] : [],
    }).then((response) => {
      toast.info(i18n.t('dict:updated'));
      formActions.setSubmitting(false);
      if (redirect) {
        history.push(redirect);
      }
      return response;
    }).catch((errors) => {
      toast.error(i18n.t('dict:error'));
      formErrorHandler(errors, formActions.setErrors);
      formActions.setSubmitting(false);
      return errors;
    });
  }
  return client.mutate({
    variables,
    mutation,
    context: { isGlobalLoading: true },
    refetchQueries: createRefetchQuery ? [{ query: createRefetchQuery }] : [],
  }).then((res) => {
    if (preventDefaultOnCreation) {
      formActions.setSubmitting(false);
      return res;
    }
    toast.info(i18n.t('dict:created'));
    if (redirect) {
      history.push(redirect);
    }
    formActions.setSubmitting(false);
    return res;
  }).catch((errors) => {
    toast.error(i18n.t('dict:error'));
    formErrorHandler(errors, formActions.setErrors);
    formActions.setSubmitting(false);
    return errors;
  });
};
