import React from 'react';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import LinkToUser from 'features/users/lib/LinkToUser';
import Spinner from 'features/ui/Spinner';
import NotSet from 'features/ui/NotSet';
import {
  IUser,
  IUserQueryResponse,
  UserRoleEnum,
} from 'features/users/types';

import getUsersQuery from 'features/users/queries/getUsersQuery.gql';

interface IExternalProps {
  users: IUser[];
  isLoading: boolean;
}

interface IListOfUsersByRoleProps {
  offerId: ID;
  role: UserRoleEnum | string;
  showOnlyActive?: boolean;
}

type IProps = IExternalProps & IListOfUsersByRoleProps;

const ListOfUsersByRole = ({ users, isLoading, showOnlyActive }: IProps) => {
  if (isLoading) {
    return <Spinner width={20} height={20} isInline={true} />;
  }
  if (users.length === 0) {
    return <NotSet />;
  }

  const filteredUsers = showOnlyActive ? users.filter(user => !user.is_deleted && user.is_activated) : users;
  const list = filteredUsers.filter(user => !user.is_deleted).map(user => (
    <div key={user.id}>
      <LinkToUser action="view" value={user} />
    </div>
  ));
  return (
    <React.Fragment>
      {list}
    </React.Fragment>
  );
};

export default graphql<IListOfUsersByRoleProps, IUserQueryResponse, {}, IExternalProps>(
  getUsersQuery,
  {
    props: props => ({
      users: get(props, 'data.UserQuery.items', []),
      isLoading: get(props, 'data.loading', true),
    }),
    options: ({ role, offerId }) => ({
      variables: {
        page: 1,
        perPage: 100000,
        filterByFields: {
          offer_id: {
            operator: '=',
            value: String(offerId),
          },
          role: {
            operator: '=',
            value: role,
          },
        },
      },
    }),
  },
)(ListOfUsersByRole);
