
import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import PageEvents from 'features/events/PageEvents';
import PageCreate from 'features/events/PageCreate';
import PageEdit from 'features/events/PageEdit';
import PageView from 'features/items/PageView';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';

export default () => (
    <AccessControl permission="books:access">
      <Switch>
        <AppRoute path="/events" menuId="events" component={PageEvents} exact />
        <AppRoute path="/event/create" menuId="events" component={PageCreate} exact />
        <AppRoute path="/event/edit/:id" menuId="events" component={PageEdit} exact />
        <AppRoute path="/event/:id" menuId="events" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
);
