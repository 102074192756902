import React from 'react';
import { get, omit } from 'lodash';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { crudMutate } from 'features/common/helpers';
import BaseForm from './Form';
import { STATUS_VALIDATED } from 'features/autoimport/consts';

import getAutoImportQuery from 'features/autoimport/queries/getAutoImportQuery.gql';
import getAutoImportsQuery from 'features/autoimport/queries/getAutoImportsQuery.gql';
import createAutoImportMutation from 'features/autoimport/queries/createAutoImportMutation.gql';

import { IAutoImportFormValues } from 'features/autoimport/types';

type IFormProps = WithTranslation;

class Form extends React.PureComponent<IFormProps> {
  state = {
    validated: false,
  };

  onSubmit = (values: IAutoImportFormValues, formActions: FormikActions<IAutoImportFormValues>) => {
    const { upload } = values;
    crudMutate({
      formActions,
      mutation: createAutoImportMutation,
      variables: { autoImport: upload ? omit(values, 'upload') : omit(values, ['upload', 'content']) },
      updateRefetchQuery: getAutoImportQuery,
      createRefetchQuery: getAutoImportsQuery,
      preventDefaultOnCreation: true,
    }).then((res) => {
      const status = get(res, 'data.AutoImportMutation.status', null);
      this.setState({ validated: status === STATUS_VALIDATED });
    });
  };

  renderForm = (props: FormikProps<IAutoImportFormValues>) => (<BaseForm {...props} validated={this.state.validated} />);

  validationSchema = () => {
    return Yup.object().shape(Object.assign({
      data: Yup.mixed().required('Поле XLS-файл обязательно!'),
    }));
  };

  resolveInitialValues = () => ({});

  render() {
    return (
      <Formik
        validationSchema={this.validationSchema()}
        enableReinitialize
        initialValues={this.resolveInitialValues()}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation()(Form);
