import React from 'react';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';

export default ({
  code,
  title,
  description,
}: {
  code: number | string,
  title: string,
  description: string,
}) => {
  return (
    <div className="flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">{code}</h1>
              <h4 className="pt-3">{title}</h4>
              <p className="text-muted float-left">{description}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
