import React from 'react';
import ReactTable, {
  Column,
  Filter,
  FilteredChangeFunction,
  PageChangeFunction,
  SortedChangeFunction,
  SortingRule,
} from 'react-table';
import { History } from 'history';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router';
import { ICursor } from 'features/types';
import TdComponent from './TdComponent';

const DEFAULT_PAGE_SIZE = 20;

interface IDataTableProps extends RouteComponentProps<{}> {
  columns: object[];
  history: History;
  items: object[];
  pagination: ICursor;
  loading: boolean;
  pageSize?: number;
  filtered?: Filter[];
  sorted?: SortingRule[];
  defaultSorted?: SortingRule[];
  multiSort?: boolean;
  onFilteredChange?: FilteredChangeFunction;
  onPageChange?: PageChangeFunction;
  onSortedChange?: SortedChangeFunction;
}

class DataTable extends React.PureComponent<IDataTableProps> {
  static defaultProps = {
    multiSort: false,
  };

  getTheadThProps = (finalState: any, rowInfo: any, column?: Column, instance?: any) => {
    return {
      // По умолчанию по клику на заголовке колонки в таблице сортируются в
      // asc и desc порядке, попеременно. Функция ниже добавляет третье
      // состояние, когда сортировка снята. При multiSort === true это же
      // поведение идет из коробки.
      toggleSort: () => {
        if (column && column.sortable) {
          instance.sortColumn(column, true);
        }
      },
    };
  }

  getTdProps = (state: any, rowInfo: any, column: any, instance: any) => {
    return {
      column,
      rowInfo,
    };
  };

  getTheadFilterThProps = () => ({
    // иначе в ячейках фильтров таблиц не видно выпадающих списков
    style: {
      overflow: 'visible',
    },
  });

  render() {
    const {
      items,
      pagination,
      loading,
      columns,
      pageSize,
      filtered,
      sorted,
      defaultSorted,
      multiSort,
      onPageChange,
      onFilteredChange,
      onSortedChange,
    } = this.props;
    const isLoading = loading || false;

    const currentPage = pagination.currentPage ? pagination.currentPage - 1 : 0;
    const pSize = pageSize || DEFAULT_PAGE_SIZE;

    return (
      <ReactTable
        filterable
        manual
        loading={isLoading}
        page={currentPage}
        pageSize={pSize}
        pages={Math.ceil(pagination.total / pSize)}
        showPageSizeOptions={false}
        showPageJump={true}
        data={items}
        columns={columns}
        filtered={filtered}
        sorted={sorted}
        defaultSorted={defaultSorted}
        onPageChange={onPageChange}
        onFilteredChange={onFilteredChange}
        onSortedChange={onSortedChange}
        getTheadThProps={this.getTheadThProps}
        getTdProps={this.getTdProps}
        getTheadFilterThProps={this.getTheadFilterThProps}
        className="-striped -highlight"
        TdComponent={TdComponent}
        multiSort={multiSort}
      />
    );
  }
}

export default withRouter(DataTable);
