import React from 'react';
import { match as IMatch } from 'react-router';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import PageBuilds from '../PageBuilds';

export default ({ match }: { match: IMatch<any> }) => {
  return (
    <AccessControl permission="builds:access">
      <div className="animated">
        <Switch>
          <AppRoute path={'/deploy'} menuId="deploy" component={PageBuilds} exact />
          <Route name="404" component={Page404} />
        </Switch>
      </div>
    </AccessControl>
  );
};
