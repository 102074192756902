import React, { useEffect, useRef } from 'react';
import DnDIcon from 'features/ui/DragNDropComponent/DnDIcon';

interface IAnswerOptionProps {
  item: IAnswerOption;
  changeParam: (id: string, param: string, value: number[] | string | boolean) => void;
  onRemove: (id: string) => void;
}

const AnswerOptionComponent = ({
  item: {
    id,
    name,
    is_correct: correct,
    comment,
  },
  changeParam,
  onRemove,
}: IAnswerOptionProps) => {
  const textInputRef = useRef(null);
  const commentRef = useRef(null);
  const onNameChange = (e: any) => changeParam(id, 'name', e.target.value);
  const onCommentChange = (e: any) => changeParam(id, 'comment', e.target.value);
  const onCorrectChange = (e: any) => changeParam(id, 'is_correct', e.target.value === 'on');
  const onRemoveOption = () => onRemove(id);

  useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.style.height = `${textInputRef.current.scrollHeight}px`;
    }
  }, [name]);
  useEffect(() => {
    if (commentRef.current) {
      commentRef.current.style.height = `${commentRef.current.scrollHeight}px`;
    }
  }, [comment]);

  return (
    <div className="form-group bg-white p-3 border border-light d-flex mb-3">
      <DnDIcon />
      <div className="w-100 ml-3">
        <label htmlFor="answer-text" className="w-100 mb-3">
          Вариант ответа
          <textarea ref={textInputRef} id="answer-text" value={name} onChange={onNameChange} className="form-control mt-1" rows={1} />
        </label>
        <div className="form-check mb-3">
          <input className="form-check-input" onChange={onCorrectChange} type="radio" id="correct-check" checked={correct} />
          Правильный ответ
        </div>
        <label htmlFor="comment-text" className="w-100 mb-3">
          Комментарий
          <textarea ref={commentRef} id="comment-text" value={comment} onChange={onCommentChange} className="form-control mt-1" rows={1} />
        </label>
        <div>
          <p className="text-danger text-right m-0" onClick={onRemoveOption}>Удалить вариант ответа</p>
        </div>
      </div>
    </div>
  );
};

export default AnswerOptionComponent;
