import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { makeLinkListValue } from 'lib/makeLinkListValue';
import {
  InfoRowLinkList,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { createView } from 'features/common';
import { makeOfferUrl } from 'features/offers/lib/url';
import {
  makeLayoutUrl,
  makeLayoutUrlTo,
} from '../lib/url';
import getEntity from '../queries/getLayoutQuery.gql';

import { FEATURE_ICON } from '../consts';

import { ILayout } from '../types';

const View = createView<ILayout>(getEntity, 'LayoutQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderChildren = (layout: ILayout) => {
    const { t } = this.props;
    const offersLinkListValue = makeLinkListValue(layout.offers, 'name', makeOfferUrl, 'view');
    return (
      <React.Fragment>
        <LinkBlock editLink={makeLayoutUrlTo(layout, 'edit')} />
        <InfoRowText label="ID" value={layout.id} />
        <InfoRowText label={t('name')} value={layout.name} />
        <InfoRowLinkList
          label={t('offers:offers')}
          value={offersLinkListValue}
          byLine={true}
          max={10}
        />
        <hr />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField="name"
        icon={FEATURE_ICON}
        crumbs={[{ link: makeLayoutUrl(), name: t('layouts') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('layouts')(PageView);
