import { omit } from 'lodash';

export default function deepOmit(obj: null, prop: string | string[]): null;
export default function deepOmit(obj: number, prop: string | string[]): number;
export default function deepOmit(obj: string, prop: string | string[]): string;
export default function deepOmit(obj: undefined, prop: string | string[]): undefined;
export default function deepOmit(obj: {[key: string]: any}, prop: string | string[]): {[key: string]: any};
export default function deepOmit(obj: any, prop: string | string[]): any {
  if (typeof obj === 'string' || typeof obj === 'number' || obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    const newArr = obj.slice();
    return newArr.map(value => deepOmit(value, prop));
  }

  const newObj: {[key: string]: any} = omit(obj, prop);
  Object.keys(obj).forEach((key: string) => {
    if (typeof newObj[key] === 'object' && newObj[key] !== null) {
      newObj[key] = deepOmit(newObj[key], prop);
    }
  });
  return newObj;
}
