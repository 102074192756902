import React from 'react';
import {Nav, NavItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import {FEATURE_ICON} from "features/usersmanagement/consts";
import Empty from "features/ui/Empty";
import CardPage from "features/ui/Page/CardPage";
import Form from 'features/usersmanagement/lib/Form';

class PageIndex extends React.Component<any, any>{

  render() {
    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink className="nav-link active" to="/usersmanagement">
              <i className={FEATURE_ICON} />
              &nbsp;
              Удаление архивных пользователей
            </NavLink>
          </NavItem>
        </Nav>
        <CardPage
          icon={FEATURE_ICON}
          title="Удаление архивных пользователей"
          renderHeader={Empty}
        >
          <Form />
        </CardPage>
      </React.Fragment>
    )
  }
}

export default PageIndex;
