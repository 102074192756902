import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import * as Yup from 'yup';
import filterChangedValues from 'lib/filterChangedValues';
import { minValidator } from 'lib/validators';
import field from 'lib/field';
import { crudMutate } from 'features/common/helpers';
import { toInt } from 'lib/crudConvert';
import BaseForm from './BaseForm';
import {
  IOffer,
  IOfferSettingsRegistrationFormValues,
  IOfferSettingsRegistrationInput,
} from '../types';

import editOfferQuery from '../queries/editOffer.gql';
import i18n from "features/intl/i18n";

interface IFormProps extends WithTranslation {
  offer: IOffer;
}

class Form extends React.Component<IFormProps> {
  getScheme() {
    const { t } = this.props;
    return Yup.object().shape({
      users_allowed: minValidator({ field: t('reg_users_allowed'), min: 0 }),
      alpina_lab_users_allowed: Yup
        .number()
        .min(0, t('forms:wrong_min_value', { field: t('reg_alpina_lab_users_allowed'), value: 0 }))
        .test({
          name: 'alpina_lab_users_allowed',
          exclusive: false,
          params: { },
          test(value) {
            return value > this.parent.users_allowed ? this.createError({
              message: `Значение этого поля не может быть больше чем «Максимальное количество пользователей» (${this.parent.users_allowed})`,
              path: 'alpina_lab_users_allowed',
            }) : true;
          },
        }),
      default_users_ttl: minValidator({ field: t('default_users_ttl'), min: 0 }),
    });
  }

  getFormData(): IOfferSettingsRegistrationFormValues {
    const { offer } = this.props;
    let reg;
    if (offer) {
      reg = offer.settingsRegistration;
    }
    return {
      alpina_lab_enabled: field(reg, 'alpina_lab_enabled', false),
      registration_enabled: field(reg, 'registration_enabled', false),
      users_import_enabled: field(reg, 'users_import_enabled', false),
      registration_unlimited: field(reg, 'registration_unlimited', false),
      registration_welcome_mail: field(reg, 'registration_welcome_mail', false),
      send_b2b_registration_mail: field(reg, 'send_b2b_registration_mail', false),
      users_registered: field(reg, 'users_registered', ''),
      users_allowed: field(reg, 'users_allowed', ''),
      alpina_lab_users_allowed: field(reg, 'alpina_lab_users_allowed', ''),
      groups_enabled: field(reg, 'groups_enabled', false),
      group_required: field(reg, 'group_required', false),
      confirmation_required: field(reg, 'confirmation_required', false),
      pins_enabled: field(reg, 'pins_enabled', false),
      registration_by_email_enabled: field(reg, 'registration_by_email_enabled', false),
      alpina_lab_registration_enabled: field(reg, 'alpina_lab_registration_enabled', false),
      default_users_ttl: field(reg, 'default_users_ttl', 0),
      ip_mask_filter: field(reg, 'ip_mask_filter', []),
      email_mask_filter: field(reg, 'email_mask_filter', []),
      password_change_enabled: field(reg, 'password_change_enabled', true),
    };
  }

  getChangedValues(values: IOfferSettingsRegistrationFormValues) {
    const formData = this.getFormData();
    return filterChangedValues(formData, values);
  }

  getSendindValues(values: IOfferSettingsRegistrationFormValues): IOfferSettingsRegistrationInput {
    const result: any = { ...values };
    const handle = (key: string, cb: (v: string) => string | number | null) => {
      if (key in values) {
        result[key] = cb(values[key] as string);
      }
    };
    handle('users_allowed', toInt);
    handle('alpina_lab_users_allowed', toInt);
    return result as IOfferSettingsRegistrationInput;
  }

  onSubmit = (
    values: IOfferSettingsRegistrationFormValues,
    formActions: FormikActions<IOfferSettingsRegistrationFormValues>,
  ) => {
    const changedValues = this.getChangedValues({ ...values });
    const settingsRegistration = this.getSendindValues(changedValues);
    const { id } = this.props.offer;
    const offer = { settingsRegistration };
    crudMutate({
      id,
      formActions,
      variables: { id, offer },
      mutation: editOfferQuery,
      check: !!Object.keys(settingsRegistration).length,
    });
  };

  render() {
    const { offer } = this.props;
    return (
      <Formik
        initialValues={this.getFormData()}
        validationSchema={this.getScheme()}
        enableReinitialize
        onSubmit={this.onSubmit}
      >
        {(props: FormikProps<IOfferSettingsRegistrationFormValues>) => <BaseForm {...props} offer={offer} />}
      </Formik>
    );
  }
}

export default withTranslation('offers')(Form);
