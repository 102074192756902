import React from 'react';
import {
  client,
  gql,
} from 'features/graphql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
  IUser,
} from 'features/types';

import ReactSelect from 'features/ui/ReactSelect';
import { IUserQueryResponse } from 'features/users/types';
import { graphql, OptionProps } from 'react-apollo';

interface IUserSelectState {
  options: ISelectOption[];
}

const USER_QUERY = gql`
  query getUsersForSelect(
    $id: [ID]
    $pageNum: Int
    $perPage: Int
    $filterByFields: UserFilterByFields
  ) {
    UserQuery(
      id: $id
      page: $pageNum
      perPage: $perPage
      filterByFields: $filterByFields
    ) {
      items {
        id
        email
        offer {
          id
        }
      }
    }
  }
`;
class UserSelect extends React.PureComponent<ISharedSelectProps, IUserSelectState> {
  static getDerivedStateFromProps(props: any) {
    const { data } = props;
    return {
      options: UserSelect.mapUsersToOptions(data),
    };
  }

  static mapUsersToOptions = (data: IUser[]) => {
    interface IEmails { [key: string]: ID[]; }

    const emails: IEmails = data.reduce(
      (acc: IEmails, user: IUser) => {
        const { email } = user;
        if (email in acc) {
          acc[email].push(user.id);
        } else {
          acc[email] = [user.id];
        }
        return acc;
      },
      {},
    );
    return Object.keys(emails).map((email: string) => ({
      value: emails[email].join(','),
      label: email,
    }));
  }

  state = {
    options: [],
  };

  loadOptions = (value: string) => {
    const { offer } = this.props;
    if (!offer) {
      return null;
    }

    return client.query<IUserQueryResponse>({
      query: USER_QUERY,
      variables: {
        pageNum: 1,
        perPage: 100,
        filterByFields: {
          email: {
            value,
            operator: 'like',
          },
          offer_id: {
            value: offer.toString(),
            operator: '=',
          },
          role: {
            value: 'hr',
            operator: '=',
          },
        },
      },
    }).then((res) => {
      if (res.data && res.data.UserQuery) {
        return UserSelect.mapUsersToOptions(res.data.UserQuery.items);
      }
      return [];
    });
  };

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
      isMulti,
    } = this.props;
    const { options } = this.state;
    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        isMulti={!!isMulti}
        value={value}
        async
        loadOptions={this.loadOptions}
        minSearchLength={1}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

const QUERY = gql`
  query getUsersForSelect(
    $id: [ID]
    $pageNum: Int
    $perPage: Int
    $filterByFields: UserFilterByFields
  ) {
    UserQuery(
      id: $id
      page: $pageNum
      perPage: $perPage
      filterByFields: $filterByFields
    ) {
      items {
        id
        email
        offer {
          id
        }
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, IUserQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.UserQuery ? data.UserQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, IUserQueryResponse, IListQueryVariables, any>(
  QUERY,
  {
    props: mapResultsToProps,
    options: ({ value }) => ({
      variables: {
        id: value && value.length ? String(value).split(',') : undefined,
        pageNum: 1,
        perPage: 100,
      },
    }),
  },
)(UserSelect);
