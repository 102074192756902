import React from 'react';
import Message from '../helpers/Message';

export default () => {
  return (
    <Message
      code={404}
      title={'Oops! You are lost.'}
      description={'The page you are looking for was not found.'}
    />
  );
};
