import React from 'react';
import { flow } from 'lodash';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import Spinner from 'features/ui/Spinner';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/creators/consts';
import { makeCreatorUrl } from 'features/creators/lib/url';
import { graphqlById } from 'features/graphql/helpers';
import CreatorsForm from 'features/creators/lib/CreatorsForm';
import LinkToItems from 'features/creators/lib/LinkToItems';
import getCreatorQuery from 'features/creators/queries/getCreatorQuery.gql';

import { FEATURE_ICON as ITEM_FEATURE_ICON } from 'features/items/consts';

import {
  IBasePageEditProps,
  ICreator,
} from 'features/types';
import { ICreatorQueryResponse } from 'features/creators/types';

type IProps = IBasePageEditProps<ICreator> & WithTranslation;

class BasePageEdit extends React.PureComponent<IProps> {
  renderForm(creator: ICreator) {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div style={{ textAlign: 'right' }}>
          <LinkToItems creator={creator}>
            <i className={ITEM_FEATURE_ICON} />
          </LinkToItems>
          &nbsp;&nbsp;
          <Link
            to={makeCreatorUrl({ id: creator.id, action: 'view' })}
            title={t('dict:view')}
          >
            <i className="fa fa-eye" />
          </Link>
        </div>
        <CreatorsForm data={creator} />
      </React.Fragment>
    );
  }

  render() {
    const {
      data,
      isLoading,
    } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (!data) {
      return <Redirect to="/404" />;
    }

    return (
      <CardPage
        icon={FEATURE_ICON}
        title={data ? `Редактирование: ${data.full_name}` : ''}
        crumbs={[{ link: makeCreatorUrl(), name: 'Creators' }]}
      >
        {data ? this.renderForm(data) : null}
      </CardPage>
    );
  }
}

export default flow(
  graphqlById<ICreatorQueryResponse, IProps>({
    query: getCreatorQuery,
    queryType: 'CreatorQuery',
  }),
  withTranslation(),
)(BasePageEdit);
