import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { makeLinkListValue } from 'lib/makeLinkListValue';
import {
  InfoRow,
  InfoRowDateBlock,
  InfoRowLinkList,
  InfoRowNotSet,
  InfoRowSwitch,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { createView } from 'features/common';
import { makeOfferUrl } from 'features/offers/lib/url';
import ResourceLink from 'features/popularRequests/lib/ResourceLink';
import {
  makePopularRequestUrl,
  makePopularRequestUrlTo,
} from '../lib/url';
import getEntity from '../queries/getPopularRequest.gql';

import { FEATURE_ICON } from '../consts';

import { IPopularRequest } from '../types';

const View = createView<IPopularRequest>(getEntity, 'PopularRequestQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderContent(request: IPopularRequest) {
    const { t } = this.props;
    if (!request.content_link || !request.content_type) {
      return <InfoRowNotSet label={t('content_link')} />;
    }
    return (
      <InfoRow label={t('content_link')}>
        <ResourceLink request={request} />
      </InfoRow>
    );
  }

  renderChildren = (request: IPopularRequest) => {
    const { t } = this.props;
    const contentType = request.content_type && t(`content_type_${request.content_type}`);
    const offersLinkListValue = makeLinkListValue(request.offers, 'name', makeOfferUrl, 'view');
    return (
      <React.Fragment>
        <LinkBlock editLink={makePopularRequestUrlTo(request, 'edit')} />
        <InfoRowText label="ID" value={request.id} />
        <InfoRowText label={t('label')} value={request.label} />
        <InfoRowText label={t('content_type')} value={contentType} />
        {this.renderContent(request)}
        <InfoRowText label={t('weight')} value={request.weight} />
        <InfoRowSwitch label={t('is_active')} value={request.is_active} on="dict:yes" off="dict:no" />
        <InfoRowLinkList
          label={t('offers')}
          value={offersLinkListValue}
          byLine={true}
          max={10}
        />
        <InfoRowDateBlock value={request} />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField="label"
        icon={FEATURE_ICON}
        crumbs={[{ link: makePopularRequestUrl(), name: t('popular_requests') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('popular_requests')(PageView);
