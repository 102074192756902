import React from 'react';
import FixedOnTheRight from 'features/ui/FixedOnTheRightContainer';
import OffersSelect from '../OffersSelect';
import getOffers from '../getOffers';

interface IOffersSelectWithButtons {
  id: string;
  name: string;
  value: ID[];
  onChange: (ids: ID[]) => void;
}

class OffersSelectWithButtons extends React.Component<IOffersSelectWithButtons> {
  onClearOffers = () => {
    const { onChange } = this.props;
    onChange([]);
  };

  onAddAllOffers = () => {
    getOffers({})
      .then((res) => {
        if (res.data && res.data.OfferQuery) {
          const { onChange } = this.props;
          onChange(res.data.OfferQuery.items.map(offer => offer.id));
        }
      });
  };

  render() {
    const {
      id,
      name,
      value,
      onChange,
    } = this.props;
    const side = (
      <React.Fragment>
        <a
          className="btn btn-warning"
          onClick={this.onAddAllOffers}
          title="Добавляет все Предложения"
        >
          Добавить Все
        </a>
        <a
          className="btn btn-warning"
          onClick={this.onClearOffers}
        >
          Очистить
        </a>
      </React.Fragment>
    );
    return (
      <FixedOnTheRight side={side}>
        <OffersSelect
          id={id}
          name={name}
          value={value}
          onChange={onChange}
        />
      </FixedOnTheRight>
    );
  }
}

export default OffersSelectWithButtons;
