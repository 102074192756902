import gql from 'graphql-tag';
import { client } from 'features/graphql';
import { IOfferQueryResponse } from '../../types';

const QUERY = gql`query getOffersHelper(
  $perPage: Int
  $filterByFields: OfferFilterByFields
  $searchByFields: OfferSearchByFields
) {
  OfferQuery(
    perPage: $perPage
    filterByFields: $filterByFields
    searchByFields: $searchByFields
  ) {
    items {
      id
      name
      prefix
      expire_date
    }
  }
}`;

export default (subVariables = {}) => {
  return client.query<IOfferQueryResponse>({
    query: QUERY,
    fetchPolicy: 'network-only',
    context: {
      isGlobalLoading: true,
    },
    variables: {
      page: 1,
      perPage: 500000,
      ...subVariables,
    },
  });
};
