import React from 'react';
import { Badge } from 'reactstrap';
import { filterInputHandler } from 'features/common';
import YesNoSelect from 'features/ui/YesNoSelect';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import { makeItemUrlTo } from 'features/items/lib/url';
import { makeOfferUrl } from 'features/offers/lib/url';
import OffersSelect from 'features/offers/lib/OffersSelect';

import {
  IPromocode,
  RowRenderProps,
} from 'features/types';

export default () => ([
  {
    accessor: 'code',
    Header: 'Код',
    Filter: filterInputHandler,
  },
  {
    accessor: 'count',
    Header: 'Макс. кол-во использований',
    maxWidth: 100,
    Filter: filterInputHandler,
  },
  {
    accessor: 'used',
    Header: 'Кол-во использований',
    maxWidth: 100,
    Filter: filterInputHandler,
  },
  {
    id: 'is_active',
    accessor: (data: IPromocode) => data.is_active,
    Header: 'Активность',
    maxWidth: 100,
    Filter: ({ filter, onChange }: any) => {
      const event = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
      return (
        <YesNoSelect
          onChange={event}
          value={filter && filter.value}
        />
      );
    },
    Cell: (row: RowRenderProps) => (
      <Badge color={row.value ? 'success' : 'danger'}>{row.value ? 'Активен' : 'Неактивен'}</Badge>
    ),
  },
  {
    accessor: 'expire_date',
    Header: 'Дата истечения',
    maxWidth: 200,
    filterable: false,
  },
  {
    accessor: 'updated_at',
    Header: 'Дата обновления',
    maxWidth: 200,
    filterable: false,
  },
  {
    id: 'offer',
    accessor: (data: IPromocode) => {
      if (data.offer) {
        return (
          <LinkToEntityInLists
            id={data.offer.id}
            name={data.offer.name}
            to={makeOfferUrl({ id: data.offer.id })}
          />
        );
      }
      return null;
    },
    Header: 'Предложение',
    maxWidth: 200,
    filterable: false,
    Filter: ({ filter, onChange }: any) => (
      <OffersSelect
        onChange={onChange}
        value={filter && filter.value}
        isMulti={false}
      />
    ),
  },
  {
    id: 'item',
    accessor: (data: IPromocode) => {
      if (data.items.length) {
        return data.items.map((item => (
          <div key={item.id}>
            <LinkToEntityInLists
              id={item.id}
              name={`[${item.type}] ${item.name}`}
              to={makeItemUrlTo(item, 'view')}
            />
          </div>
        )));
      }
      return null;
    },
    Header: 'Итем',
    maxWidth: 200,
    minWidth: 150,
    expandable: true,
    Filter: ({ filter, onChange }: any) => (
      <ItemsSelect
        onChange={onChange}
        isClearable={true}
        value={filter && filter.value}
        isMulti={false}
      />
    ),
  },
]);
