import {
  ApolloLink,
  NextLink,
} from 'apollo-link';
import appStore from 'features/app/mobxStore';

const loadingLink = new ApolloLink((operation, forward?: NextLink) => {
  const context = operation.getContext();
  if (context.isGlobalLoading) {
    // используется mobx, так как проблема с локальным graphql context.cache.writeData, в том что graphql обновит
    // компоненты только после завершения прохода по всем линкам, а тогда isLoading уже снова станет false
    // и обновления компонентов не будет, а нам нужно обновить компонент моментально еще до того как запрос к апи завершится
    // context.cache.writeData({ data: { isGlobalLoading: true } });
    appStore.updateState({ isLoading: true });
  }
  return forward ? forward(operation) : null;
});

export default loadingLink;
