import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import OffersSelect from 'features/offers/lib/OffersSelect';

import { IFormValues } from 'features/usergroups/types';
import { IUserGroup } from 'features/types';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IUserGroup;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onOfferChange = (value: number) => this.props.setFieldValue('offer', value);

  render() {
    const {
      t,
      item,
      values,
      handleBlur,
      handleChange,
    } = this.props;

    return (
      <FormFrame
        id={item && item.id}
        cancelLink="/usergroups"
        {...this.props}
      >
        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Название"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('alias')} id="alias" required>
          <Input
            type="text"
            id="alias"
            name="alias"
            placeholder="Алиас"
            value={values.alias}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('offers:offer')} id="offer" required>
          <OffersSelect
            id="offer"
            name="offer"
            value={parseInt(values.offer, 10)}
            isMulti={false}
            onChange={this.onOfferChange}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('usergroups')(BaseForm);
