import React from 'react';
import { observer } from 'mobx-react';
import { Progress } from 'reactstrap';
import appStore from 'features/app/mobxStore';
import styles from './styles.module.scss';

class GlobalLoading extends React.Component {
  render() {
    const { globalProgress } = appStore.state;

    if (!globalProgress) {
      return null;
    }

    return (
      <Progress className={styles.globalProgress} value={globalProgress}>
        {globalProgress}%
      </Progress>
    );
  }
}

export default observer(GlobalLoading);
