import React from 'react';
import { Button } from 'reactstrap';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { IInfoRowOptions } from './types';

interface IInfoRowLinkListProps {
  label: string;
  value: Array<{ id: ID, to: string, text: string }> | null | undefined;
  max?: number;
  byLine?: boolean;
  options?: IInfoRowOptions;
}

interface IInfoRowLinkListState {
  isExpanded: boolean;
}

export class InfoRowLinkList extends React.PureComponent<IInfoRowLinkListProps, IInfoRowLinkListState> {
  constructor(props: IInfoRowLinkListProps) {
    super(props);
    this.state = { isExpanded: false };
  }

  handleExpanding = (event: React.SyntheticEvent<HTMLElement>) => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  }

  render() {
    const {
      label,
      value,
      max,
      byLine,
      options,
    } = this.props;
    const { isExpanded } = this.state;
    if (value === null || value === undefined || value.length === 0) {
      return <InfoRowNotSet label={label} options={options} />;
    }
    let sliced = value;
    if (typeof max === 'number' && isExpanded === false) {
      sliced = value.slice(0, max);
    }
    const list = sliced.map(item => (
      <div
        key={item.id}
        style={{ display: Boolean(byLine) ? 'block' : 'inline' }}
      >
        <LinkToEntityInLists id={item.id} to={item.to} name={item.text} />
        <span>&ensp;</span>
      </div>
    ));
    let button = null;
    if (max !== undefined && max < value.length) {
      button = (
        <Button
          color="info"
          size="sm"
          onClick={this.handleExpanding}
        >
          {isExpanded ? 'Скрыть' : `Показать все ${value.length}`}
        </Button>
      );
    }
    return (
      <InfoRow label={label} {...options}>
        {button}
        {list}
      </InfoRow>
    );
  }
}
