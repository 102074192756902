import React from 'react';
import moment from 'moment';
import { filterInputHandler } from 'features/common';
import LinkToOffer from 'features/offers/lib/LinkToOffer';
import OffersSelect from 'features/offers/lib/OffersSelect';

import { IOffer } from 'features/types';

export default () => ([
  {
    accessor: 'name',
    Header: 'Название',
    Filter: filterInputHandler,
  },
  {
    accessor: 'alias',
    Header: 'Алиас',
    filterable: false,
  },
  {
    accessor: 'created_at',
    Cell: ({ row }: { row: { created_at: string } }) => moment(row.created_at).format('lll'),
    Header: 'Дата создания',
    maxWidth: 200,
    filterable: false,
  },
  {
    id: 'offer_id',
    accessor: 'offer',
    Header: 'Предложение',
    maxWidth: 250,
    Cell: ({ row }: { row: { offer_id: IOffer } }) => <LinkToOffer value={row.offer_id} />,
    Filter: ({ filter, onChange }: any) => (
      <OffersSelect
        onChange={onChange}
        value={filter && filter.value}
        isMulti={false}
      />
    ),
  },
]);
