import moment from 'moment';
import i18n from 'features/intl/i18n';
import { RowRenderProps } from 'react-table';

export default () => [
  {
    Header: i18n.t('subscriptions:name_column'),
    accessor: 'name',
    filterable: true,
    minWidth: 100,
  },
  {
    Cell: (row: RowRenderProps) => moment(row.value).format('ll'),
    Header: i18n.t('subscriptions:created_at'),
    accessor: 'created_at',
  },
  {
    Cell: (row: RowRenderProps) => moment(row.value).format('ll'),
    Header: i18n.t('subscriptions:updated_at'),
    accessor: 'updated_at',
  },
];
