import React from 'react';
import { set } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import moment from 'moment';
import { DATETIME_FORMAT_DATE } from 'features/app/consts';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import getUsersQuery from 'features/users/queries/getUsersQuery.gql';
import resolveColumns from './columns';
import deleteUserMutation from 'features/users/queries/deleteUserMutation.gql';
import { makeUserUrl } from 'features/users/lib/url';

import { Column } from 'features/types';

type IDataTableProps = WithTranslation;

class DataTable extends React.PureComponent<IDataTableProps> {
  columns: Column[];

  constructor(props: IDataTableProps) {
    super(props);
    this.columns = resolveColumns().filter(col => !!col) as Column[];
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (fields.email) {
      set(variables, 'filterByFields.email', {
        operator: 'like',
        value: fields.email,
      });
    }
    if (fields.role) {
      set(variables, 'filterByFields.role', {
        operator: '=',
        value: fields.role,
      });
    }
    if (fields.is_activated) {
      set(variables, 'filterByFields.is_activated', {
        operator: '=',
        value: fields.is_activated,
      });
    }
    if (fields.is_deleted) {
      set(variables, 'filterByFields.is_deleted', {
        operator: '=',
        value: fields.is_deleted,
      });
    }
    if (fields.created_at) {
      set(variables, 'filterByFields.created_at', {
        operator: '<=',
        value: moment(fields.created_at).add(1, 'day').format(DATETIME_FORMAT_DATE),
      });
    }
    if (fields.offer) {
      set(variables, 'filterByFields.offer_id', {
        operator: '=',
        value: String(fields.offer),
      });
    }
    if (fields.phone) {
      set(variables, 'filterByFields.phone', {
        operator: '=',
        value: String(fields.phone),
      });
    }
  };

  render() {
    return (
      <IndexTableBase
        columns={this.columns}
        filterFields={['id', 'email', 'phone', 'role', 'is_activated', 'is_deleted', 'created_at', 'offer']}
        prepareRequestData={this.prepareRequestData}
        query={getUsersQuery}
        queryResultName="UserQuery"
        deleteMutation={deleteUserMutation}
        makeCrudUrl={makeUserUrl}
        showViewIconColumn={true}
      />
    );
  }
}

export default withTranslation()(DataTable);
