import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ru from 'translations/ru';
import en from 'translations/en';

i18n
  .use(initReactI18next) // if not using I18nextProvider
  .init({
    lng: 'ru',
    fallbackLng: 'ru',
    // ns: ['translations'],
    defaultNS: 'dict',
    // debug: true,
    interpolation: {
      escapeValue: false,
    }, // React already does escaping
    resources: {
      ru,
      en,
    },
    // react: {
      // nsMode: 'dict',
      // omitBoundRerender: false,
      // wait: false,
    // },
  });

export default i18n;
