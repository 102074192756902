import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import moment from 'moment';
import LinkToEntityInLists from "features/ui/LinkToEntityInLists";
import LinkBlock from 'features/ui/LinkBlock';
import {
  InfoRow,
  InfoRowSwitch,
  InfoRowText,
} from 'features/ui/InfoRow';
import { createView } from 'features/common';
import {
  makeSubscriptionUrl,
  makeSubscriptionUrlTo,
} from '../lib/url';
import getEntity from '../queries/getSubscriptions.gql';

import { SUBSCRIPTION_ICON } from '../consts';

import { makeItemUrlTo } from "features/items/lib/url";
import { ISubscription } from '../types';
import { IItem } from "features/items/types";

const View = createView<ISubscription>(getEntity, 'SubscriptionQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderChildren = (subscription: ISubscription) => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <LinkBlock editLink={makeSubscriptionUrlTo(subscription, 'edit')} />
        <InfoRowText label="ID" value={subscription.id} />
        <InfoRowText label={t('name')} value={subscription.name} />
        <InfoRowText label={t('title')} value={subscription.title} />
        <InfoRowText label={t('created_at')} value={moment(subscription.created_at).format('YYYY-MM-DD')} />
        <InfoRowText label={t('updated_at')} value={moment(subscription.updated_at).format('YYYY-MM-DD')} />


        <InfoRowText label={t('cost')} value={subscription.cost} />
        <InfoRowText label={t('old_cost')} value={subscription.old_cost} />
        <InfoRowText label={t('trial_days')} value={subscription.trial_days} />
        <InfoRowText label={t('expire_days')} value={subscription.expire_days} />
        <InfoRowSwitch label={t('have_trial')} value={subscription.have_trial} />

        <InfoRow label={t('items')}>
          {
            subscription.items.map((item) => {
              return (
                <div key={item.id}>
                  <LinkToEntityInLists
                    id={item.id}
                    name={item.name}
                    to={makeItemUrlTo(item as IItem, 'view')}
                  />
                </div>
              )
            })
          }
        </InfoRow>
      </React.Fragment>
    );
  };

  render() {
    const {t} = this.props;
    return (
      <View
        titleField="name"
        icon={SUBSCRIPTION_ICON}
        crumbs={[{link: makeSubscriptionUrl(), name: t('subscriptions') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('subscriptions')(PageView);
