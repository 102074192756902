import React from 'react';
import { match as IMatch } from 'react-router';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { Page404 } from 'features/http';
import AppRoute from 'features/app/Route';
import { AccessControl } from 'features/acl';
import PageBadges from '../PageBadges';
import PageCreate from '../PageCreate';
import PageEdit from '../PageEdit';
import PageView from '../PageView';

export default ({ match }: { match: IMatch<any> }) => {
  return (
    <AccessControl permission="badges:access">
      <div className="animated">
        <Switch>
          <AppRoute
            path={'/badges'}
            component={PageBadges}
            menuId="badges"
            exact
          />
          <AppRoute
            path={'/badge/create'}
            component={PageCreate}
            menuId="badges"
            exact
          />
          <AppRoute
            path={'/badge/edit/:id'}
            component={PageEdit}
            menuId="badges"
            exact
          />
          <AppRoute
            path={'/badge/:id'}
            component={PageView}
            menuId="badges"
            exact
          />
          <Route name="404" component={Page404} />
        </Switch>
      </div>
    </AccessControl>
  );
};
