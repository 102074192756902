import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ReactTable from 'react-table';
import { cloneDeep, isEqual, toNumber } from 'lodash';
import { Button, Input } from 'reactstrap';
import Switch from 'features/ui/Switch';
import ReactSelect from 'features/ui/ReactSelect/ReactSelectWithId';
import { gql } from 'apollo-boost';
import { client } from 'features/graphql';
import { toast } from 'features/ui/Toast';
import { Column, RowRenderProps } from 'features/types';
import i18next from 'i18next';

const WEBHOOK_MUTATION = gql`
  mutation addWebhook($webhook: WebhookInput) {
    WebhookMutation(webhook: $webhook) {
      id
    }
  }
`;

const DELETE_WEBHOOK_MUTATION = gql`
  mutation deleteWebhook($id: ID!) {
    WebhookMutation(id: $id, delete: true) {
      id
      __typename
    }
  }
`;

const UPDATE_WEBHOOK_MUTATION = gql`
  mutation WebhookMutation($id: ID!, $webhook: WebhookInput) {
    WebhookMutation(id: $id, webhook: $webhook) {
      id
      __typename
    }
  }
`;

interface IWebHookType {
  id?: null | ID;
  title: string;
  callback_url: string;
  entity: 'item' | 'user';
  is_active: boolean;
}

interface IWebHooksProps {
  webhooks: IWebHookType[];
  offerId: ID;
  refetch: () => void;
  t: i18next.TFunction;
}

interface IWebHooksState extends WithTranslation {
  webhooks: IWebHookType[];
  isUpdateButtonEnabled: boolean;
}

class WebHooks extends React.Component<IWebHooksProps, IWebHooksState> {
  columns: Column[];
  entityOptions: [{value: number, label: string, key: string}, {value: number, label: string, key: string}];

  constructor(props: IWebHooksProps) {
    super(props);
    this.columns = this.resolveColumns().filter(col => !!col) as Column[];
    this.entityOptions = [
      { value: 1, label: 'Пользователь', key: 'user' },
      { value: 2, label: 'Итем', key: 'item' },
    ];
    this.state = {
      webhooks: cloneDeep(props.webhooks),
      isUpdateButtonEnabled: false,
    };
  }

  componentDidUpdate(prevProps: IWebHooksProps, prevState: IWebHooksState) {
    const { isUpdateButtonEnabled, webhooks } = this.state;
    // const canUpdate = this.resolveDataToSave();
    if (!isEqual(webhooks, this.props.webhooks) && !isUpdateButtonEnabled) {
      this.setState({ isUpdateButtonEnabled: true });
    } else
    if (isEqual(webhooks, this.props.webhooks) && isUpdateButtonEnabled) {
      this.setState({ isUpdateButtonEnabled: false });
    }
  }

  getChangedWebhooks = () => {
    const { webhooks } = this.props;
    return this.state.webhooks.filter((webhook, index) => {
      return !isEqual(webhook, webhooks[index]);
    });
  }

  onSubmit = () => {
    const { refetch, t } = this.props;
    const changedWebhooks = this.getChangedWebhooks();
    if (!changedWebhooks.length || !changedWebhooks.some(wh => !!wh.id)) {
      return toast.error(t('No any data to update'));
    }
    changedWebhooks.forEach((webhook: IWebHookType) => {
      if (!webhook.id) return null;
      client.mutate({
        mutation: UPDATE_WEBHOOK_MUTATION,
        variables: {
          id: webhook.id,
          webhook: {
            is_active: webhook.is_active,
            title: webhook.title,
            entity: webhook.entity,
            callback_url: webhook.callback_url,
          },
        },
      })
        .then(() => {
          toast.info(t('dict:updated'));
          refetch();
        })
        .catch(() => toast.error('dict:error'));
    });
  }

  onAddRow = () => {
    this.setState(
      {
        webhooks: [...this.state.webhooks, {
          id: null,
          title: '',
          callback_url: '',
          entity: 'item',
          is_active: false,
        }],
      },
    );
  };
  onSave = (variables) => {
    const { offerId, refetch, t } = this.props;
    delete variables.id;
    client.mutate({
      mutation: WEBHOOK_MUTATION,
      variables: {
        webhook: {
          ...variables,
          offer: toNumber(offerId),
        },
      },
    })
      .then(() => {
        toast.info(t('dict:created'));
        refetch();
      })
      .catch(() => toast.error('dict:error'));
  }
  onDelete = (id: number) => {
    const { t, refetch } = this.props;
    client.mutate({
      mutation: DELETE_WEBHOOK_MUTATION,
      variables: {
        id,
      },
    })
      .then(() => {
        toast.info(t('dict:deleted'));
        refetch();
      })
      .catch(() => {
        toast.error(t('dict:error'));
      });
  }
  onDeleteRow = (index: number) => {
    const { webhooks } = this.state;
    if (!webhooks[index].id) {
      const filteredWebhooks = [...webhooks].filter((wh, i) => i !== index);
      this.setState({ webhooks: filteredWebhooks });
    } else {
      this.onDelete(webhooks[index].id);
    }
  }

  onResetState = () => {
    this.setState({ webhooks: cloneDeep(this.props.webhooks) });
  }

  renderInput = (row) => {
    const webhooks = [...this.state.webhooks];
    return (
      <Input
        type="textarea"
        onChange={(e) => {
          webhooks[row.index][row.column.id] = e.target.value;
          this.setState(() => ({ webhooks }));
        }}
        value={webhooks[row.index][row.column.id]}
      />
    );
  }
  renderSwitch = (row) => {
    const webhooks = [...this.state.webhooks];
    return (
      <Switch
        id="is_active"
        name="is_active"
        checked={row.value}
        onChange={() => {
          webhooks[row.index][row.column.id] = !webhooks[row.index][row.column.id];
          this.setState(() => ({ webhooks }));
        }}
      />
    );
  }

  resolveColumns = () => ([
    {
      accessor: 'id',
      Header: () => (
        <span>
          ID
          <button type="button" className="btn btn-link" onClick={this.onAddRow}>
            <i className="icon-plus icons font-lg" />
          </button>
        </span>
      ),
      minWidth: 80,
      maxWidth: 100,
      Cell: (cell) => {
        if (cell.value) {
          return cell.value;
        }
        const { title, callback_url } = this.state.webhooks[cell.index];
        if (title && callback_url) {
          return <Button
            color="primary"
            onClick={() => this.onSave(this.state.webhooks[cell.index])}
          >
            Сохранить
          </Button>;
        }
      },
    },
    {
      accessor: 'title',
      Header: 'Название',
      minWidth: 100,
      Cell: this.renderInput,
    },
    {
      accessor: 'callback_url',
      Header: 'Webhook url',
      minWidth: 100,
      Cell: this.renderInput,
    },
    {
      accessor: 'entity',
      Header: 'Событие по',
      maxWidth: 200,
      style: {
        overflow: 'visible',
      },
      Cell: (row: RowRenderProps) => {
        const webhooks = [...this.state.webhooks];
        const option = this.entityOptions.find(el => el.key === row.value);
        return (
          <ReactSelect
            styles={{
              overflow: 'visible',
            }}
            options={this.entityOptions}
            isMulti={false}
            value={option.value}
            onChange={(entity) => {
              webhooks[row.index!].entity = this.entityOptions.find(el => el.value === entity).key;
              this.setState({ webhooks });
            }}
          />
        );
      },
    },
    {
      Header: 'Активность',
      accessor: (data) => data.is_active,
      filterable: false,
      id: 'is_active',
      maxWidth: 100,
      Cell: this.renderSwitch,
    },
    {
      Header: 'Удалить?',
      accessor: (data) => data,
      filterable: false,
      id: 'delete',
      maxWidth: 100,
      Cell: (row: RowRenderProps) => {
        const onClick = () => this.onDeleteRow(row.index!);
        return (
          <button type="button" className="btn btn-link" onClick={onClick}>
            <i className="icon-trash icons font-lg" />
          </button>
        );
      },
      Filter: () => <div />,
    },
  ])

  getTableStyleProps = () => ({
    style: {
      overflow: 'visible',
    },
  });

  render() {
    const { webhooks, isUpdateButtonEnabled } = this.state;
    return (
      <React.Fragment>
        <ReactTable
          sortable={false}
          showPagination={false}
          columns={this.columns}
          getTableProps={this.getTableStyleProps}
          getTbodyProps={this.getTableStyleProps}
          minRows={0}
          data={webhooks}
        />
        {isUpdateButtonEnabled ?
            <React.Fragment>
              <Button color="primary" onClick={this.onSubmit}>Обновить</Button>
              {' '}
              <Button color="secondary" onClick={this.onResetState}>Отмена</Button>
            </React.Fragment>
            : null}
      </React.Fragment>
    );
  }
}

export default withTranslation('offers')(WebHooks);
