import React from 'react';
import moment from 'moment';
import { Input } from 'reactstrap';
import { isNumber, stubTrue } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import DatePicker from 'features/ui/DatePicker';
import TimePicker from 'features/ui/TimePicker';
import Switch from 'features/ui/Switch';
import FormRow from 'features/ui/FormRow';
import { IFormValues } from 'features/events/types';
import { IItem, Moment } from 'features/types';
import { InfoRowText } from 'features/ui/InfoRow';
import BaseItemForm from 'features/items/lib/Form/Form';
import UserSelect from 'features/users/lib/UserSelect';
import SourceSelect from 'features/events/lib/SourceSelect';

import 'easymde/dist/easymde.min.css';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IItem;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onChangeDate = (date: Moment | null, field: string) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '');
  };

  onChangeUsers = (users: string[], field: string) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, users);
  }

  renderChatSection = () => {
    const {
      t,
      values,
      handleBlur,
      handleChange,
    } = this.props;

    return (
      <div>
        <FormRow
          key="chat.moderators"
          id="chat.moderators"
          label={t('moderators')}
        >
          <UserSelect
            id="chat.moderators"
            name="chat.moderators"
            withOfferId
            isMulti
            value={values.chat.moderators}
            onChange={(data: string[]) => this.onChangeUsers(data, 'chat.moderators')}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow
          key="chat.speakers"
          id="chat.speakers"
          label={t('speakers')}
        >
          <UserSelect
            id="chat.speakers"
            name="chat.speakers"
            withOfferId
            isMulti
            value={values.chat.speakers}
            onChange={(data: string[]) => this.onChangeUsers(data, 'chat.speakers')}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow
          id="event.is_all_speakers"
          key="event.is_all_speakers"
          label={t('is_all_speakers')}
        >
          <Switch
            id="event.is_all_speakers"
            name="event.is_all_speakers"
            checked={values.event.is_all_speakers}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow
          id="event.is_autorecord"
          key="event.is_autorecord"
          label={t('is_autorecord')}
          text={t('autorecord_text')}
        >
          <Switch
            id="event.is_autorecord"
            name="event.is_autorecord"
            checked={values.event.is_autorecord}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow
          key="chat.blockedUsers"
          id="chat.blockedUsers"
          label={t('blockedUsers')}
        >
          <UserSelect
            id="chat.blockedUsers"
            name="chat.blockedUsers"
            withOfferId
            isMulti
            value={values.chat.blockedUsers}
            onChange={(data: string[]) => this.onChangeUsers(data, 'chat.blockedUsers')}
            onBlur={handleBlur}
          />
        </FormRow>
      </div>
    );
  }

  resolveFields = (fields: React.ReactNode[]) => {
    const {
      t,
      values,
      handleBlur,
      handleChange,
      setFieldValue,
    } = this.props;
    const {
      finished_at,
      link,
      started_at,
      source,
    } = values.event;
    const startedAt = moment(started_at).format('YYYY-MM-DDTHH:mm:ss');
    const finishedAt = moment(finished_at).format('YYYY-MM-DDTHH:mm:ss');
    const duration = moment.duration(Date.parse(finishedAt) - Date.parse(startedAt)).asSeconds() || '';
    isNumber(duration) && setFieldValue('event.duration', duration);
    const tooltip: string = t('tooltip');

    fields.splice(4, 0, [
      (
        <FormRow
          key="event.duration"
          label={t('duration')}
          id="event.duration"
          shouldUpdate={stubTrue}
        >
          <Input
            type="text"
            id="event.duration"
            name="event.duration"
            value={duration}
            disabled
          />
        </FormRow>
      ),
      (
        <InfoRowText label="" value={tooltip} />
      ),
      (
        <FormRow
          key="event.started_at"
          label={t('started_at')}
          id="event.started_at"
          required
        >
          <DatePicker
            id="event.started_at"
            value={Date.parse(startedAt)}
            onChange={(date: Moment | null) => this.onChangeDate(date, 'event.started_at')}
          />
          <TimePicker
            onChange={(date: Moment | null) => this.onChangeDate(date, 'event.started_at')}
            value={Date.parse(startedAt)}
          />
        </FormRow>
      ),
      (
        <FormRow
          key="event.finished_at"
          label={t('finished_at')}
          id="event.finished_at"
          required
          text={t('recommended_time')}
        >
          <DatePicker
            id="finished_at"
            onChange={(date: Moment | null) => this.onChangeDate(date, 'event.finished_at')}
            value={Date.parse(finishedAt)}
          />
          <TimePicker
            onChange={(date: Moment | null) => this.onChangeDate(date, 'event.finished_at')}
            value={Date.parse(finishedAt)}
          />
        </FormRow>
      ),
      (
        <FormRow
          id="event.source"
          label={t('source')}
          required
        >
          <SourceSelect
            id="event.source"
            name="event.source"
            value={source}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>
      ),
      (
        <FormRow
          key="event.link"
          label={t('link')}
          required
          id="event.link"
        >
          <Input
            type="text"
            id="event.link"
            name="event.link"
            value={link}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>
      ),
      (
        this.renderChatSection()
      ),
    ]);
    return fields;
  };

  render() {
    return (
      <BaseItemForm
        {...this.props}
        resolveFields={this.resolveFields}
        cancelLink="/events"
      />
    );
  }
}

export default withTranslation('events')(BaseForm);
