export * from './build';
import {
  IOfferSettingsBuild,
  IOfferSettingsBuildInput,
  IOfferSettingsIntegration,
} from './build';
import { IImage } from 'features/app/types';
import { ICategory } from 'features/categories/types';
import { ICreator } from 'features/creators/types';
import { IBadge } from 'features/badges/types';
import { IBanner } from 'features/banners/types';
import { IItem } from 'features/items/types';
import { IUserGroup } from 'features/usergroups/types';

export enum OfferBusinessModelEnum {
  Library = 'library',
  Market = 'market',
}

export interface IOfferQueryData {
  items: IOffer[];
}

export interface IOfferQueryResponse {
  OfferQuery: IOfferQueryData;
}

export interface IOfferPin {
  id: ID;
  pin: number;
  user_groups: IUserGroup;
  user_ttl: number;
}

export interface IOfferToken {
  id: ID;
  token: string;
  password: string;
}

export interface ITranslation {
  id: number;
  translation: number;
  language: number;
  value: string;
}
/* ===== Pivot Types ===== */

export interface IOfferHasCategory {
  name: string;
  color: string;
  weight: number;
  no_image: boolean;
  is_pinned: boolean;
  offer: IOffer;
  category: ICategory;
  badge: IBadge;
}

export interface IOfferHasBanner {
  weight: number;
  offer: IOffer;
  banner: IBanner;
}

export interface IOfferHasItem {
  name: string;
  original_name: string;
  description: string;
  note: string;
  position_bestsellers: number;
  position_latest: number;
  offer: IOffer;
  item: IItem;
  badge: IBadge;
}

/* ===== Offer ===== */

export interface IOffer {
  id: ID;
  name: string;
  prefix: string;
  base_domain: string;
  contacts: string;
  languages: IOfferLanguage[];
  defaultLanguage: IOfferLanguage;
  mailLanguage: IOfferLanguage;
  expire_date: string;
  cost: number;
  business_model: OfferBusinessModelEnum;
  grossing_period: number;
  new_items_by_default: boolean;
  ios_prefix: string;
  ios_link: string;
  android_prefix: string;
  android_link: string;
  singleapp_ios_enabled: boolean;
  singleapp_android_enabled: boolean;
  singleapp_title: string;
  enable_banners: boolean;
  enable_pushes: boolean;
  enable_external_link: boolean;
  enable_comments: boolean;
  is_trial: boolean;
  created_at: string;
  updated_at: string;
  settingsBranding: IOfferSettingsBranding;
  settingsRegistration: IOfferSettingsRegistration;
  settingsContent: IOfferSettingsContent;
  settingsBuildIos: IOfferSettingsBuild;
  settingsBuildAndroid: IOfferSettingsBuild;
  pins: IOfferPin[];
  tokens: IOfferToken[];
  banners: IBanner[];
  bannersPivots: IOfferHasBanner[];
  categories: ICategory[];
  categoriesPivots: IOfferHasCategory[];
  items: IItem[];
  itemsPivots: IOfferHasItem[];
  _calc_CreatorsViaItems: ICreator[];
  translations: ITranslation[];
  [key: string]:
    ID |
    boolean |
    number |
    string |
    IBanner[] |
    ICategory[] |
    ICreator[] |
    IItem[] |
    IOfferHasCategory[] |
    IOfferHasBanner[] |
    IOfferHasItem[] |
    IOfferPin[] |
    IOfferSettingsBranding |
    IOfferSettingsBuild |
    IOfferSettingsContent |
    IOfferSettingsRegistration |
    IOfferToken[] |
    IOfferLanguage |
    IOfferLanguage[] |
    ITranslation[] |
    OfferBusinessModelEnum;
}

export interface IOfferFormValues {
  name: string;
  prefix: string;
  expire_date: string;
  is_trial: boolean;
  base_domain: string;
  languages: IOfferLanguage[];
  defaultLanguage: IOfferLanguage;
  mailLanguage: IOfferLanguage;
  cost: string;
  created_at: string;
  business_model: OfferBusinessModelEnum;
  grossing_period: number;
  new_items_by_default: boolean;
  ios_prefix: string;
  ios_link: string;
  android_prefix: string;
  android_link: string;
  singleapp_ios_enabled: boolean;
  singleapp_android_enabled: boolean;
  singleapp_title: string;
  enable_banners: boolean;
  enable_pushes: boolean;
  enable_external_link: boolean;
  enable_comments: boolean;
  categories: ID[];
  banners: ID[];
  [key: string]: string | number | boolean | ID[] | IOfferLanguage | IOfferLanguage[];
}

export interface IOfferInput {
  name?: string;
  prefix?: string;
  expire_date?: string | null;
  base_domain?: string;
  cost?: number;
  business_model?: OfferBusinessModelEnum;
  grossing_period?: number;
  new_items_by_default?: boolean;
  ios_prefix?: string | null;
  ios_link?: string | null;
  android_prefix?: string | null;
  android_link?: string | null;
  singleapp_ios_enabled: boolean;
  singleapp_android_enabled: boolean;
  singleapp_title?: string | null;
  enable_banners?: boolean;
  enable_pushes?: boolean;
  enable_external_link?: boolean;
  enable_comments?: boolean;
  settingsBranding?: IOfferSettingsBrandingInput;
  settingsContent?: IOfferSettingsContentInput;
  settingsRegistration?: IOfferSettingsRegistrationInput;
  settingsBuildIos?: IOfferSettingsBuildInput;
  settingsBuildAndroid?: IOfferSettingsBuildInput;
  settingsIntegration?: IOfferSettingsIntegration;
  banners?: ID[];
  categories?: ID[];
  items?: ID[];
  [key: string]:
    null |
    undefined |
    string |
    number |
    boolean |
    ID[] |
    OfferBusinessModelEnum |
    IOfferSettingsBrandingInput |
    IOfferSettingsContentInput |
    IOfferSettingsRegistrationInput |
    IOfferSettingsIntegration |
    IOfferSettingsBuildInput |
    IOfferSettingsBuildInput;
}

export interface IOfferLanguage {
  id: ID;
  name: string;
  code: string;
  sort: number;
}

/* ===== Registration ===== */

export interface IOfferSettingsRegistration {
  id: ID;
  alpina_lab_enabled: boolean;
  registration_enabled: boolean;
  users_import_enabled: boolean;
  registration_unlimited: boolean;
  registration_welcome_mail: boolean;
  send_b2b_registration_mail: boolean;
  users_registered: number;
  users_allowed: number;
  alpina_lab_users_allowed: number;
  groups_enabled: boolean;
  group_required: boolean;
  confirmation_required: boolean;
  pins_enabled: boolean;
  registration_by_email_enabled: boolean;
  alpina_lab_registration_enabled: boolean;
  ip_mask_filter: string[];
  email_mask_filter: string[];
  password_change_enabled: boolean;
  default_users_ttl: number;
  offer: IOffer;
}

export interface IOfferSettingsRegistrationFormValues {
  registration_enabled: boolean;
  users_import_enabled: boolean;
  registration_unlimited: boolean;
  registration_welcome_mail: boolean;
  send_b2b_registration_mail: boolean;
  users_registered: string;
  users_allowed: string;
  groups_enabled: boolean;
  group_required: boolean;
  confirmation_required: boolean;
  pins_enabled: boolean;
  registration_by_email_enabled: boolean;
  alpina_lab_registration_enabled: boolean;
  ip_mask_filter: string[];
  email_mask_filter: string[];
  [key: string]: boolean | string | string[];
}

export interface IOfferSettingsRegistrationInput {
  registration_enabled: boolean;
  users_import_enabled: boolean;
  registration_unlimited: boolean;
  registration_welcome_mail: boolean;
  send_b2b_registration_mail: boolean;
  users_registered: number | null;
  users_allowed: number | null;
  groups_enabled: boolean;
  group_required: boolean;
  confirmation_required: boolean;
  pins_enabled: boolean;
  registration_by_email_enabled: boolean;
  alpina_lab_registration_enabled: boolean;
  ip_mask_filter: string[];
  email_mask_filter: string[];
  [key: string]: boolean | number | string | string[] | null;
}
/* ===== Branding ===== */

export interface IOfferSettingsBranding {
  color_main: string;
  color_unpressed: string;
  color_pressed: string;
  color_selected: string;
  color_banner_arrow: string;
  color_banner_bar: string;
  custom_titles: IOfferSettingsBrandingCustomTitles;
  offer: IOffer;
  logoTop: IImage;
  logoBottom: IImage;
  appIcon: IImage;
  splashLogoTop: IImage;
  splashLogoBottom: IImage;
}

export interface IOfferSettingsBrandingCustomTitlesLang {
  title_registration: string;
  title_login: string;
  title_latest: string;
  title_most_readable: string;
  title_books: string;
  title_hybrids: string;
  title_documents: string;
  title_collections: string;
  title_videos: string;
  title_audiobooks: string;
}

export interface IOfferSettingsBrandingCustomTitles {
  en: IOfferSettingsBrandingCustomTitlesLang;
  ru: IOfferSettingsBrandingCustomTitlesLang;
  [key: string]: IOfferSettingsBrandingCustomTitlesLang;
}

export interface IOfferSettingsBrandingFormValues {
  color_main: string;
  color_unpressed: string;
  color_pressed: string;
  color_selected: string;
  color_banner_arrow: string;
  color_banner_bar: string;
  custom_titles: {[key: string]: IOfferSettingsBrandingCustomTitlesLang};
  [key: string]:
    string |
    {[key: string]: IOfferSettingsBrandingCustomTitlesLang};
}

export interface IOfferSettingsBrandingInput {
  color_main?: string | null;
  color_unpressed?: string | null;
  color_pressed?: string | null;
  color_selected?: string | null;
  color_banner_arrow?: string | null;
  color_banner_bar?: string | null;
  custom_titles?: IOfferSettingsBrandingCustomTitlesInput;
  logoTop?: File | null;
  logoBottom?: File | null;
  appIcon?: File | null;
  splashLogoTop?: File | null;
  splashLogoBottom?: File | null;
}

export interface IOfferSettingsBrandingCustomTitlesLangInput {
  title_registration: string;
  title_login: string;
  title_latest: string;
  title_most_readable: string;
  title_books: string;
  title_hybrids: string;
  title_documents: string;
  title_collections: string;
  title_videos: string;
  title_audiobooks: string;
}

export interface IOfferSettingsBrandingCustomTitlesInput {
  en: IOfferSettingsBrandingCustomTitlesLangInput;
  ru: IOfferSettingsBrandingCustomTitlesLangInput;
  [key: string]: IOfferSettingsBrandingCustomTitlesLangInput;
}

/* ===== Content ===== */

export interface IOfferSettingsContent {
  id: ID;
  latest_enabled: boolean;
  popular_enabled: boolean;
  books_enabled: boolean;
  books_max_size: number;
  books_download_limit: number;
  books_download_epub_enabled: boolean;
  books_download_pdf_enabled: boolean;
  books_download_mobi_enabled: boolean;
  events_calendar_enabled: boolean;
  hybrids_enabled: boolean;
  hybrids_max_count: number;
  hybrids_max_size: number;
  documents_enabled: boolean;
  documents_max_count: number;
  documents_max_size: number;
  collections_enabled: boolean;
  collections_max_count: number;
  videos_enabled: boolean;
  videos_max_count: number;
  videos_max_size: number;
  courses_enabled: boolean;
  articles_enabled: boolean;
  audiobooks_enabled: boolean;
  audiobooks_max_count: number;
  audiobooks_max_size: number;
  games_enabled: boolean;
  tests_enabled: boolean;
  events_enabled: boolean;
  offer: IOffer;
}

export interface IOfferSettingsContentFormValues {
  latest_enabled: boolean;
  popular_enabled: boolean;
  books_enabled: boolean;
  books_max_size: string;
  books_download_limit: string;
  books_download_epub_enabled: boolean;
  books_download_pdf_enabled: boolean;
  books_download_mobi_enabled: boolean;
  hybrids_enabled: boolean;
  hybrids_max_count: string;
  hybrids_max_size: string;
  documents_enabled: boolean;
  documents_max_count: string;
  documents_max_size: string;
  collections_enabled: boolean;
  collections_max_count: string;
  videos_enabled: boolean;
  videos_max_count: string;
  videos_max_size: string;
  courses_enabled: boolean;
  audiobooks_enabled: boolean;
  audiobooks_max_count: string;
  audiobooks_max_size: string;
  games_enabled: boolean;
  tests_enabled: boolean;
  events_enabled: boolean;
  [key: string]: string | boolean;
}

export interface IOfferSettingsContentInput {
  latest_enabled?: boolean;
  popular_enabled?: boolean;
  books_enabled?: boolean;
  books_max_size?: number | null;
  books_download_limit?: number | null;
  books_download_epub_enabled?: boolean;
  books_download_pdf_enabled?: boolean;
  books_download_mobi_enabled?: boolean;
  hybrids_enabled?: boolean;
  hybrids_max_count?: number | null;
  hybrids_max_size?: number | null;
  documents_enabled?: boolean;
  documents_max_count?: number | null;
  documents_max_size?: number | null;
  collections_enabled?: boolean;
  collections_max_count?: number | null;
  videos_enabled?: boolean;
  videos_max_count?: number | null;
  videos_max_size?: number | null;
  courses_enabled?: boolean;
  audiobooks_enabled?: boolean;
  audiobooks_max_count?: number | null;
  audiobooks_max_size?: number | null;
  games_enabled?: boolean;
  tests_enabled?: boolean;
  events_enabled?: boolean;
  [key: string]: string | boolean | number | null | undefined;
}
