import React from 'react';
import cn from 'classnames';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { IInfoRowOptions } from './types';

export const InfoRowText = ({
  label,
  value,
  options,
  className,
}: {
  label: string,
  value: string | number | null | undefined,
  options?: IInfoRowOptions,
  className?: ClassNamesValueBaseType,
}) => {
  if (value === null || value === undefined || value === '') {
    return <InfoRowNotSet label={label} options={options} />;
  }
  return (
    <InfoRow label={label} {...options}>
      <div className={cn(className)}>
        {value}
      </div>
    </InfoRow>
  );
};
