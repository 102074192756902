import { gql } from 'apollo-boost';
import { get } from 'lodash';
import LocalStorage from 'features/store/LocalStorage';
import { AUTH_TOKEN_NAME } from 'features/auth/consts';
import { client } from 'features/graphql';
import { toast } from 'features/ui/Toast';
import i18n from 'features/intl/i18n';

const authQuery = gql`
  query AuthQuery($login: String!, $password: String!) {
    Authentication(
      login: $login,
      password: $password
    )
  }
`;

interface ILoginQueryResultData {
  Authentication: object;
}

interface ILoginQueryResult {
  data: ILoginQueryResultData;
}

const onLogin = ({ data }: ILoginQueryResult) => {
  const isSuccess = !!data.Authentication;
  if (isSuccess) {
    const timestamp = Math.round(new Date().getTime() / 1000);

    const token = {
      timestamp,
      token: data.Authentication,
    };
    LocalStorage.set(AUTH_TOKEN_NAME, token);
    client.resetStore().then(() => {
      window.location.href = '/';
    });
  } else {
    toast.error(i18n.t('errors:wrong_login_or_password'));
  }

  return isSuccess;
};

const login = (name: string, password: string) => {
  return client
    .query<ILoginQueryResultData>({
      query: authQuery,
      variables: {
        password,
        login: name,
      },
    }).then(onLogin)
      .catch((res: ILoginQueryResult) => {
        const message = get(res, 'graphQLErrors[0].message', null);
        if (message === 'validation') {
          toast.error(i18n.t('errors:wrong_login_or_password'));
        } else {
          toast.error(i18n.t('errors:unexpected_network_error_occurred'));
        }
      });
};

const logout = () => {
  LocalStorage.remove(AUTH_TOKEN_NAME);
  return client.resetStore().then(() => {
    window.location.href = '/login';
  });
};

const isLogged = () => !!LocalStorage.getString(AUTH_TOKEN_NAME);

const getToken = () => {
  if (!isLogged()) {
    return null;
  }

  let token = null;
  try {
    token = LocalStorage.get(AUTH_TOKEN_NAME);
  } catch (e) {
    //
  }

  return token;
};

const getAuthToken = () => {
  const token = getToken();
  return token ? token.token : '';
};

export default {
  getAuthToken,
  getToken,
  isLogged,
  login,
  logout,
};
