import React from 'react';
import { Redirect } from 'react-router';
import Spinner from 'features/ui/Spinner';

export default ({
  children,
  data,
  isLoading,
}: {
  children: React.ReactNode[] | React.ReactNode,
  data: any,
  isLoading: boolean,
}) => {
  if (!isLoading && !data) {
    return <Redirect to="/404" />;
  }
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};
