import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import CardPage from 'features/ui/Page/CardPage';
import { IBanner } from '../types';
import BannerForm from '../lib/BannerForm';
import { makeBannerUrl } from '../lib/url';
import { FEATURE_ICON } from '../consts';

interface IPageCreateProps extends WithTranslation {
  banner: IBanner;
}

class PageCreate extends React.Component<IPageCreateProps> {
  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('new_banner')}
        crumbs={[{ link: makeBannerUrl(), name: t('banners') }]}
      >
        <BannerForm isCreateForm={true} />
      </CardPage>
    );
  }
}

export default withTranslation('banners')(PageCreate);
