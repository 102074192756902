import React from 'react';
import { FormikProps } from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { contentValidator } from 'features/items/lib/validators';
import ItemForm from 'features/items/lib/Form';

import getDocumentQuery from 'features/documents/queries/getDocumentQuery.gql';

import {
  IFormValues,
} from 'features/documents/types';
import { IItem } from 'features/types';
import field from 'lib/field';
import BaseForm from './Form';

interface IFormProps extends WithTranslation {
  data?: IItem;
}

class Form extends React.PureComponent<IFormProps> {
  resolveMutationData = (values: any) => values;

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: any) => {
    const {
      t,
      data,
    } = this.props;
    const isEdit = !!(data && data.id);
    return {
      ...shape,
      content: contentValidator(t, isEdit, {
        ext: '*.pdf',
        formats: ['application/pdf', 'application/x-pdf', 'application/x-bzpdf', 'application/x-gzpdf'],
      }),
    };
  };

  resolveInitialValues = (values: any) => {
    const { data } = this.props;

    const defaultValues = {
      document: {
        is_important: false,
      },
    };

    return data ? {
      ...defaultValues,
      ...values,
      ...{
        document: {
          is_important: field(data, 'document.is_important', false),
          isbn: field(data, 'document.isbn', ''),
        },
      },
    } : {
      ...defaultValues,
      ...values,
    };
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="document"
        data={data}
        getItemQuery={getDocumentQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        renderForm={this.renderForm}
      />
    );
  }
}

export default withTranslation('documents')(Form);
