import React from 'react';
import PageItems from 'features/items/PageItems';
import { FEATURE_ICON } from 'features/audiobooks/consts';

class PageAudiobooks extends React.PureComponent<{}> {
  render() {
    return (
      <PageItems
        type="audiobook"
        title="Аудиокниги"
        icon={FEATURE_ICON}
      />
    );
  }
}

export default PageAudiobooks;
