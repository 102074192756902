import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  client,
  gql,
} from 'features/graphql';
import { ApolloQueryResult } from 'apollo-client';
import { get } from 'lodash';
import Spinner from 'features/ui/Spinner';
import Header from 'features/layout/Header';
import Sidebar from 'features/layout/Sidebar';
import Breadcrumb from 'features/breadcrumbs/Breadcrumb';
import Aside from 'features/layout/Aside';
import Footer from 'features/layout/Footer';
import { AccessControlProvider } from 'features/acl';

import Dashboard from 'features/dashboard';
import BooksRoutes from 'features/books/Routes';
import BadgesRoutes from 'features/badges/Routes';
import BannersRoutes from 'features/banners/Routes';
import PopularRequests from 'features/popularRequests/Routes';
import FeatureToggleRoutes from 'features/featureToggle/Routes';
import DeployRoutes from 'features/deploy/Routes';
import CategoriesRoutes from 'features/categories/Routes';
import PageItems from 'features/items/PageItems';
import SetsRoutes from 'features/sets/Routes';
import DocumentsRoutes from 'features/documents/Routes';
import AudiobooksRoutes from 'features/audiobooks/Routes';
import HybridsRoutes from 'features/hybrids/Routes';
import CollectionsRoutes from 'features/collections/Routes';
import OffersRoutes from 'features/offers/Routes';
import SubscriptionsRoutes from 'features/subscriptions/Routes';
import UserSubscriptions from 'features/userSubscriptions/Routes';
import OrdersRoutes from 'features/orders/Routes';
import CreatorsRoutes from 'features/creators/Routes';
import VideoRoutes from 'features/videos/Routes';
import CoursesRoutes from 'features/courses/Routes';
import TestsRoutes from 'features/tests/Routes';
import ArticlesRoutes from 'features/articles/Routes';
import PromocodeRoutes from 'features/promocodes/Routes';
import UsersRoutes from 'features/users/Routes';
import UserGroupsRoutes from 'features/usergroups/Routes';
import UsersManagementRoutes from 'features/usersmanagement/Routes';
import AutoImportsRoutes from 'features/autoimport/Routes';
import AutoPricesRoutes from 'features/autoprice/Routes';
import PushRoutes from 'features/pushes/Routes';
import EventsRoutes from 'features/events/Routes';
import LayoutRoutes from 'features/layouts/Routes';
import BlocksRoutes from 'features/blocks/Routes';
import {
  Page403,
  Page404,
} from 'features/http';

import { RequestState } from 'features/types';
import { UserRoleEnum } from 'features/users/types';

const LoggedInUserQuery = gql`
query checkApiAvailability {
  Profile {
    id
    role
  }
}`;

interface ILayoutProps {
  location: ILocation;
}

interface ILayoutState {
  apiAvailability: RequestState;
  role?: UserRoleEnum;
}

class Layout extends React.Component<ILayoutProps, ILayoutState> {
  constructor(props: ILayoutProps) {
    super(props);
    this.state = { apiAvailability: RequestState.init };
  }

  onFetchProfile = (data: ApolloQueryResult<any>) => {
    const role = get(data, 'data.Profile.role', undefined);
    this.setState({
      role,
      apiAvailability: RequestState.success,
    });
  };

  componentDidMount() {
    this.setState(
      { apiAvailability: RequestState.pending },
      () => {
        client
          .query({ query: LoggedInUserQuery })
          .then(this.onFetchProfile);
      },
    );
  }

  componentDidUpdate(prevProps: ILayoutProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  renderMain() {
    const { role } = this.state;
    return (
      <AccessControlProvider role={role}>
        <div className="app">
          <Header />
          <div className="app-body">
            <Sidebar />
            <main className="main">
              <Breadcrumb />
              <Container fluid>
                <Switch>
                  <Route path="/" name="Dashboard" component={Dashboard} exact />
                  <Route path="/items" component={PageItems} exact />
                  <Route path="/set(s?)" component={SetsRoutes} />
                  <Route path="/badge(s?)" component={BadgesRoutes} />
                  <Route path="/banner(s?)" component={BannersRoutes} />
                  <Route path="/popular_request(s?)" component={PopularRequests} />
                  <Route path="/feature-toggle(s?)" component={FeatureToggleRoutes} />
                  <Route path="/book(s?)" component={BooksRoutes} />
                  <Route path="/event(s?)" component={EventsRoutes} />
                  <Route path="/deploy" component={DeployRoutes} />
                  <Route path="/categor(y|ies)" component={CategoriesRoutes} />
                  <Route path="/document(s?)" component={DocumentsRoutes} />
                  <Route path="/video(s?)" component={VideoRoutes} />
                  <Route path="/audiobook(s?)" component={AudiobooksRoutes} />
                  <Route path="/collection(s?)" component={CollectionsRoutes} />
                  <Route path="/course(s?)" component={CoursesRoutes} />
                  <Route path="/test(s?)" component={TestsRoutes} />
                  <Route path="/article(s?)" component={ArticlesRoutes} />
                  <Route path="/hybrid(s?)" component={HybridsRoutes} />
                  <Route path="/offer(s?)" component={OffersRoutes} />
                  <Route path="/subscription(s?)" component={SubscriptionsRoutes} />
                  <Route path="/user-subscription(s?)" component={UserSubscriptions} />
                  <Route path="/order(s?)" component={OrdersRoutes} />
                  <Route path="/creator(s?)" component={CreatorsRoutes} />
                  <Route path="/promocode(s?)" component={PromocodeRoutes} />
                  <Route path="/user(s?)" component={UsersRoutes} />
                  <Route path="/usergroup(s?)" component={UserGroupsRoutes} />
                  <Route path="/usersmanagement(s?)" component={UsersManagementRoutes} />
                  <Route path="/autoimport(s?)" component={AutoImportsRoutes} />
                  <Route path="/autoprice(s?)" component={AutoPricesRoutes} />
                  <Route path="/pushe(s?)" component={PushRoutes} />
                  <Route path="/layout(s?)" component={LayoutRoutes} />
                  <Route path="/layout-block(s?)" component={BlocksRoutes} />
                  <Route path="/403" component={Page403} />
                  <Route name="404" component={Page404} />
                </Switch>
              </Container>
            </main>
            <Aside />
          </div>
          <Footer />
        </div>
      </AccessControlProvider>
    );
  }

  renderSpinner() {
    return <Spinner />;
  }

  render() {
    const { apiAvailability } = this.state;
    if (apiAvailability === RequestState.success) {
      return this.renderMain();
    }
    if (apiAvailability === RequestState.pending || apiAvailability === RequestState.init) {
      return this.renderSpinner();
    }
    // RequestState.fail реализовывать не нужно, т.к. редирект на страницу
    // логина осуществляется в graphql link-ах.
    return null;
  }
}

export default Layout;
