import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import PageBooks from 'features/books/PageBooks';
import PageCreate from 'features/books/PageCreate';
import PageEdit from 'features/books/PageEdit';
import PageView from 'features/items/PageView';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';

export default () => {
  return (
    <AccessControl permission="books:access">
      <Switch>
        <AppRoute path="/books" menuId="books" component={PageBooks} exact />
        <AppRoute path="/book/create" menuId="books" component={PageCreate} exact />
        <AppRoute path="/book/edit/:id" menuId="books" component={PageEdit} exact />
        <AppRoute path="/book/:id" menuId="books" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
