import React from 'react';
import { noop } from 'lodash';
import moment from 'moment';
import {
  Badge,
  Input,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import i18n from 'features/intl/i18n';
import LinkToOffer from 'features/offers/lib/LinkToOffer';
import OffersSelect from 'features/offers/lib/OffersSelect';
import { makeUserUrl } from 'features/users/lib/url';
import RoleText from 'features/users/lib/RoleText';
import UserRoleSelect from 'features/users/lib/UserRoleSelect';
import { AccessControl } from 'features/acl';
import { filterInputHandler } from 'features/common';
import ActiveSelector from 'features/ui/ActiveSelector';
import NotSet from 'features/ui/NotSet';
import DeletedSelector from 'features/ui/DeletedSelector';
import Entity from 'features/ui/Entity';
import { DATETIME_FORMAT_DEFAULT } from 'features/app/consts';
import { INVENTORY_ICON } from 'features/users/consts';

import {
  IOffer,
  IUser,
  RowRenderProps,
} from 'features/types';

export default () => ([
  {
    accessor: 'email',
    Header: 'Email',
    Filter: filterInputHandler,
  },
  {
    accessor: 'phone',
    Header: 'Телефон',
    Filter: filterInputHandler,
  },
  {
    accessor: 'role',
    Header: 'Роль',
    maxWidth: 100,
    Cell: ({ value }: { value: string }) => (
      <RoleText role={value} />
    ),
    Filter: ({ filter, onChange }: any) => {
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);
      return (
        <UserRoleSelect
          onChange={handleChange}
          onBlur={noop}
          value={filter && filter.value}
        />
      );
    },
  },
  {
    id: 'is_activated',
    accessor: (data: IUser) => data.is_activated,
    Header: 'Активность',
    maxWidth: 100,
    Filter: ({ filter, onChange }: any) => {
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);
      return (
        <ActiveSelector
          onChange={handleChange}
          value={(filter && filter.value) || ''}
        />
      );
    },
    Cell: (row: RowRenderProps) => (
      <Badge color={row.value ? 'success' : 'danger'}>{row.value ? 'Активен' : 'Неактивен'}</Badge>
    ),
  },
  {
    id: 'is_deleted',
    accessor: (data: IUser) => data.is_deleted,
    Header: 'Удален?',
    maxWidth: 100,
    Filter: ({ filter, onChange }: any) => {
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);
      return (
        <DeletedSelector
          onChange={handleChange}
          value={(filter && filter.value) || ''}
        />
      );
    },
    Cell: (row: RowRenderProps) => (
      <Badge color={row.value ? 'danger' : 'success'}>{row.value ? 'Удален' : 'Доступен'}</Badge>
    ),
  },
  {
    accessor: 'created_at',
    Header: 'Дата создания',
    maxWidth: 200,
    Cell: (row: RowRenderProps) => moment(row.value, DATETIME_FORMAT_DEFAULT).format('lll'),
    Filter: ({ filter, onChange }: { filter: any, onChange: any }) => {
      const handleEvent = (e: any) => onChange(e.target.value);
      return (
        <Input
          type="date"
          value={filter ? filter.value : ''}
          onChange={handleEvent}
          style={{ width: '100%' }}
        />
      );
    },
  },
  {
    id: 'offer',
    accessor: 'offer',
    Header: 'Предложение',
    maxWidth: 200,
    Cell: ({ value }: { value: IOffer }) => {
      if (!value) {
        return <NotSet />;
      }
      return (
        <AccessControl
          permission="offers:access"
          fallback={<Entity value={value} />}
        >
          <LinkToOffer value={value} />
        </AccessControl>
      );
    },
    Filter: ({ filter, onChange }: any) => (
      <OffersSelect
        onChange={onChange}
        value={filter && filter.value}
        isMulti={false}
      />
    ),
  },
  {
    id: 'toInventory',
    Header: '',
    maxWidth: 60,
    className: 'text-center',
    accessor: (data: any) => data,
    sortable: false,
    Cell: (row: any) => (
      <Link
        className="btn btn-link"
        title={i18n.t('users:inventory')}
        to={makeUserUrl({ action: 'view', id: row.value.id, part: 'inventory' })}
      >
        <i className={`${INVENTORY_ICON} font-lg`} />
      </Link>
    ),
    Filter: () => <div />,
  },
]);
