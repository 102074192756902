import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { crudMutate } from 'features/common/helpers';
import { stringValidator } from 'lib/validators';
import filterChangedValues from 'lib/filterChangedValues';
import field from 'lib/field';
import * as Yup from 'yup';
import BasePopularRequestForm from './PopularRequestForm';
import { makePopularRequestUrl } from '../url';
import createPopularRequest from '../../queries/createPopularRequest.gql';
import editPopularRequest from '../../queries/editPopularRequest.gql';
import {
  IPopularRequest,
  IPopularRequestFormValues,
} from '../../types';

interface IPopularRequestFormProps extends WithTranslation {
  request?: IPopularRequest;
}

class PopularRequestForm extends React.Component<IPopularRequestFormProps> {
  getScheme() {
    const {
      t,
    } = this.props;
    const required = true;
    const validators: any = {
      label: stringValidator({ required, field: t('label') }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: IPopularRequestFormValues) {
    const formData = this.buildFormData();
    return filterChangedValues(formData, values);
  }

  onSubmit = (
    values: IPopularRequestFormValues,
    formActions: FormikActions<IPopularRequestFormValues>,
  ) => {
    let popular_request = { ...values };
    let id;
    if (this.props.request) {
      popular_request = this.getChangedValues(values);
      id = this.props.request.id;
    }
    crudMutate({
      id,
      formActions,
      variables: id ? { id, popular_request } : { popular_request },
      mutation: id ? editPopularRequest : createPopularRequest,
      redirect: makePopularRequestUrl(),
      check: !!Object.keys(popular_request).length,
    });
  };

  buildFormData(): IPopularRequestFormValues {
    const { request } = this.props;
    return {
      label: field(request, 'label', ''),
      is_active: field(request, 'is_active', false),
      content_type: field(request, 'content_type', 'category'),
      content_link: field(request, 'content_link', null),
      content_id: field(request, 'content_id', null),
      offers: request ? request.offers.map(o => o.id) : [],
      weight: field(request, 'weight', 0),
    };
  }

  render() {
    const { request } = this.props;
    return (
      <Formik
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getScheme()}
      >
        {(props: FormikProps<IPopularRequestFormValues>) => <BasePopularRequestForm {...props} request={request} />}
      </Formik>
    );
  }
}

export default withTranslation('popular_requests')(PopularRequestForm);
