import React from 'react';
import { get } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeLinkListValueToItem } from 'lib/makeLinkListValue';
import {
  InfoRowDateBlock,
  InfoRowImage,
  InfoRowLinkList,
  InfoRowText,
} from 'features/ui/InfoRow';
import { makeItemUrlTo } from 'features/items/lib/url';
import { makeCreatorUrl } from 'features/creators/lib/url';
import { createView } from 'features/common';
import LinkToItems from '../lib/LinkToItems';
import getEntity from '../queries/getCreatorForPageViewQuery.gql';

import { FEATURE_ICON as ITEM_FEATURE_ICON } from 'features/items/consts';
import { FEATURE_ICON } from '../consts';

import { ICreator } from '../types';

const View = createView<ICreator>(getEntity, 'CreatorQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderLinkBar(creator: ICreator) {
    const { t } = this.props;
    return (
      <div style={{ textAlign: 'right' }}>
        <LinkToItems creator={creator}>
          <i className={ITEM_FEATURE_ICON} />
        </LinkToItems>
        &nbsp;&nbsp;
        <Link
          to={makeCreatorUrl({ id: creator.id, action: 'edit' })}
          title={t('dict:edit')}
        >
          <i className="icon-pencil" />
        </Link>
      </div>
    );
  }

  renderChildren = (creator: ICreator) => {
    const { t } = this.props;
    const itemsLinkListValue = makeLinkListValueToItem(creator.items || [], 'name', makeItemUrlTo, 'view');
    const type = creator.type && t(`creators:types:${creator.type}`);
    return (
      <React.Fragment>
        {this.renderLinkBar(creator)}
        <InfoRowText label="ID" value={creator.id} />
        <InfoRowText label={t('type')} value={type} />
        <InfoRowText label={t('first_name')} value={creator.first_name} />
        <InfoRowText label={t('last_name')} value={creator.last_name} />
        <InfoRowText label={t('short_name')} value={creator.short_name} />
        <InfoRowText label={t('full_name')} value={creator.full_name} />
        <InfoRowText label={t('description')} value={creator.description} />
        <InfoRowImage label={t('avatar')} value={get(creator, 'avatar.path', null)} />
        <InfoRowLinkList
          label={t('items:items')}
          value={itemsLinkListValue}
          byLine={true}
          max={10}
        />
        <hr />
        <InfoRowDateBlock value={creator} />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField={'full_name'}
        icon={FEATURE_ICON}
        crumbs={[{ link: makeCreatorUrl(), name: t('creators') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('creators')(PageView);
