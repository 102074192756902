import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  gql,
} from 'features/graphql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  ITranslation,
  ITranslationQueryResponse,
} from '../types';
import ReactSelect from 'features/ui/ReactSelect/ReactSelectWithId';

interface IErrorCodeSelectProps extends ISharedSelectProps {
  data: ITranslation[];
}

interface IErrorCodeSelectState {
  options: ISelectOption[];
}

class ErrorCodeSelect extends React.PureComponent<IErrorCodeSelectProps, IErrorCodeSelectState> {
  static getDerivedStateFromProps(props: any) {
    return {
      options: ErrorCodeSelect.mapDataToOptions(props.data),
    };
  }

  static mapDataToOptions = (data: ITranslation[]) => data.map((translation: ITranslation) => ({
    value: +translation.id!,
    label: translation.value,
  }));

  state = {
    options: [],
  };

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelect
        responsive
        id={id}
        name={name}
        options={options}
        isMulti={false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

const QUERY = gql`
  query TranslationQuery($pageNum: Int, $perPage: Int) {
    TranslationQuery(page: $pageNum, perPage: $perPage) {
      items {
        id
        key
        value
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ITranslationQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.TranslationQuery ? data.TranslationQuery.items.reverse() : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ITranslationQueryResponse, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: () => {
    return {
      variables: {
        pageNum: 1,
        perPage: 10000,
      },
      context: {
        isGlobalLoading: true,
      },
    };
  },
})(ErrorCodeSelect);
