import React from 'react';
import { WithTranslation, withTranslation, } from 'react-i18next';
import { FormikProps } from 'Formik';
import FormRow from 'features/ui/FormRow';
import BaseItemForm from 'features/items/lib/Form/Form';
import { TYPE_TEST } from 'features/items/consts';
import { IFormValues } from 'features/courses/types';
import { IItem } from 'features/types';
import Questions from 'features/tests/lib/Questions';
import { Input } from 'reactstrap';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IItem;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onContentChange = (questions: IQuestion[]) => {
    const {
      setFieldValue,
      values,
    } = this.props;
    const defaultModel = {
      file: null,
      sort: 0,
      title: null,
      type: 'test_answers_total',
      test: {
        id: undefined,
      },
    };

    const initialValues = values.content && values.content[0] ? values.content[0] : defaultModel;

    const value = {
      ...initialValues,
      test: {
        questions,
        id: initialValues.test.id,
      },
    };

    setFieldValue('content', [value]);
  };

  resolveFields = (fields: any) => {
    const { values, t, handleChange, handleBlur } = this.props;
    const questions = values.content && values.content[0] && values.content[0].test.questions ? values.content[0].test.questions : [];

    fields.splice(15, 0, (
      <FormRow
        key="content"
        label={t('test_content')}
        id="content"
      >
        <Questions values={questions} onChange={this.onContentChange} />
      </FormRow>
    ));

    fields.splice(14, 0, (
      <FormRow
        key="test.attempts_count"
        label={t('attempts_count')}
        id="test.attempts_count"
      >
        <Input
          type="number"
          id="test.attempts_count"
          name="test.attempts_count"
          value={values.test!.attempts_count}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ));

    return fields;
  };

  render() {
    return (
      <BaseItemForm
        {...this.props}
        resolveFields={this.resolveFields}
        cancelLink="/tests"
        type={TYPE_TEST}
      />
    );
  }
}

export default withTranslation('test')(BaseForm);
