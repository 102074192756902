import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { throttle } from 'lodash';
import Switch from 'features/ui/Switch';
import { client } from 'features/graphql';
import { toast } from 'features/ui/Toast';
import { InfoRow } from 'features/ui/InfoRow';
import ApiTokens from './ApiTokens';
import WebHooks from './WebHooks';
import { IOffer } from '../types';
import editOfferQuery from '../queries/editOffer.gql';

interface ITabOthersProps extends WithTranslation {
  offer: IOffer;
  hasControl?: boolean;
  refetch?: (vars?: any) => Promise<any>;
}

class TabOthers extends React.Component<ITabOthersProps> {
  static defaultProps = {
    hasControl: true,
  };

  handleChange = () => {
    const { offer, refetch, t } = this.props;
    client.mutate({
      mutation: editOfferQuery,
      variables: {
        id: offer.id,
        offer: {
          settingsIntegration: {
            is_active: !offer.settingsIntegration.is_active,
          },
        },
      },
    })
      .then((res) => {
        toast.info(t('dict:updated'));
        refetch();
      })
      .catch(() => toast.error('dict:error'));
  }

  render() {
    const {
      t,
      offer,
      refetch,
      hasControl,
    } = this.props;

    if (!offer) {
      return null;
    }

    return (
      <React.Fragment>
        <InfoRow label={t('settings_integration_is_active')}>
          <Switch
            id="webhooks"
            checked={offer.settingsIntegration.is_active}
            onChange={throttle(this.handleChange, 5000)}
          />
        </InfoRow>
        <h1>{t('tokens')}</h1>
        <ApiTokens
          tokens={offer.tokens}
          refetch={refetch}
          offerId={offer.id}
          hasControl={hasControl}
        />
        <h1>{t('webhooks')}</h1>
        <WebHooks
          webhooks={offer.webhooks}
          refetch={refetch}
          offerId={offer.id}
          hasControl={hasControl}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation('offers')(TabOthers);
