import React, { useEffect, useRef, useState } from 'react';
import Spinner from 'features/ui/Spinner';
import DragNDropComponent from 'features/ui/DragNDropComponent';
import { reorder } from 'features/ui/DragNDropComponent/methods';
import styles from 'features/courses/lib/Stages/style.module.scss';
import { client, gql } from 'features/graphql';
import { IBlock } from 'features/blocks/types';

interface ISelectedOptionProps {
  block: IBlock;
  onRemove: (id: string) => void;
}

const SelectedOption = ({ block: { id, name }, onRemove }: ISelectedOptionProps) => (
  <div className={styles['selectable-list-item']}>
    <div>{id} | {name}</div>
    <div className={`close ${styles['close-container']}`} onClick={() => onRemove(id)}>
      <i className="icon-close" />
    </div>
  </div>
);

interface IStageItem {
  id: string;
  name: string;
  type: string;
}

interface ISearchResultProps {
  item: IStageItem;
  isDisabled: boolean;
  onSelect: (e: any, item: IStageItem) => void;
}

const SearchResult = ({ item, onSelect, isDisabled }: ISearchResultProps) => (
  <div onClick={e => onSelect(e, item)} className={isDisabled ? styles.disabled : undefined}>
    <span>{item.id} | {item.name}</span>
  </div>
);
const useGetBlocksQuery = (value: string) => {
  const query = gql`
  query LayoutBlocksQuery(
    $id: [ID]
    $page: Int
    $perPage: Int
    $filterByFields: LayoutBlockFilterByFields
  ) {
    LayoutBlockQuery(
      id: $id
      page: $page
      perPage: $perPage
      filterByFields: $filterByFields
    ) {
      items {
        id
        name
      }
      cursor {
        total
        perPage
        currentPage
        hasPages
      }
    }
  }
`;
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const watchedQuery = client.watchQuery({
      query,
      variables: {
        pageNum: 1,
        perPage: 50,
        searchByFields: null,
        filterByFields: {
          name: {
            value,
            operator: 'like',
          },
        },
      },
    });

    const sub = watchedQuery.subscribe({
      next(x) {
        if (!x.partial) {
          setData(x.data ? x.data.LayoutBlockQuery.items.map((item: IBlock) => ({ id: item.id, name: item.name })) : []);
          setError(null);
          setLoading(false);
        }
      },
      error(err) {
        setError(err);
        setLoading(false);
      },
      complete() {
        setLoading(false);
      },
    });

    return () => {
      sub.unsubscribe();
    };
  }, [value]);

  return { data, error, loading };
};

interface IStageItemSelectProps {
  id: string;
  value: number[];
  blocks: IBlock[];
  onChange: (values: number[]) => void;
}
const StageItemSelect = ({ blocks, onChange }: IStageItemSelectProps) => {
  const [inputData, setInputData] = useState<string>('');
  const { data, loading } = useGetBlocksQuery(inputData);
  const wrapperRef = useRef(null);

  const onContentSelect = (e: any, item: IStageItem) => {
    e.stopPropagation();
    onChange([...blocks, item]);
    setInputData('');
  };

  const renderResults = () => {
    if (loading) {
      return (
        <div className={`card ${styles['selectable-options']}`}>
          <Spinner />
        </div>
      );
    }
    return (
      <div className={`card ${styles['selectable-options-opened']}`}>
        {data.map((item: IStageItem) => (
          <SearchResult
            isDisabled={!!blocks.find((block) => block.id === item.id)}
            key={item.id}
            item={item}
            onSelect={onContentSelect}
          />
        ))}
      </div>
    );
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newList = reorder(blocks, result.source.index, result.destination.index);
    onChange(newList);
  };

  const onContentRemove = (itemId: string) => {
    onChange(blocks.filter(pItem => pItem.id !== itemId));
  };

  const renderSelectedItems = () => {
    if (!blocks.length) {
      return null;
    }
    return (
      <div className={styles['selected-items-container']}>
        <span className="text-muted">Выбранные блоки</span>
        <DragNDropComponent items={blocks} onDragEnd={onDragEnd} childPropName="block">
          <SelectedOption onRemove={onContentRemove} />
        </DragNDropComponent>
      </div>
    );
  };

  return (
    <div className={styles['wrap-container']}>
      {renderSelectedItems()}
      <div ref={wrapperRef}>
        <input
          className={`input ${styles['selectable-input']}`}
          type="text"
          placeholder="Начните вводить название блока"
          onChange={(e) => setInputData(e.target.value)}
          value={inputData}
        />
        {renderResults()}
      </div>
    </div>
  );
};

export default StageItemSelect;
