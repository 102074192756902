import React from 'react';
import {
  Col,
  FormGroup,
  FormText,
  Label,
} from 'reactstrap';
import { Field } from 'formik';
import FormFieldFeedback from 'features/ui/FormFieldFeedback';

interface IFormRowProps {
  id: string;
  label: string;
  text?: string;
  error?: string | boolean;
}

class FormField extends React.PureComponent<IFormRowProps> {
  renderRow = ({ field, form }: any) => {
    const {
      id,
      label,
      text,
      children,
    } = this.props;

    const {
      errors,
      submitCount,
    } = form;

    return (
      <Col md="3">
        <FormGroup>
          <Label htmlFor={id}>{label}</Label>
          <React.Fragment>
            {children}
            {errors[id] && submitCount ?
              <FormFieldFeedback color="danger" text={errors[id]} />
              : null}
          </React.Fragment>
          {text ? <FormText color="muted">{text}</FormText> : null}
        </FormGroup>
      </Col>
    );
  };

  render() {
    const {
      id,
    } = this.props;

    return (
      <Field name={id} render={this.renderRow} />
    );
  }
}

export default FormField;
