import React from 'react';
import CardPage from 'features/ui/Page/CardPage';
import CreatorsForm from 'features/creators/lib/CreatorsForm';
import { FEATURE_ICON } from 'features/creators/consts';
import { makeCreatorUrl } from 'features/creators/lib/url';

class PageCreate extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Создание нового Создателя!"
        crumbs={[{ link: makeCreatorUrl(), name: 'Creators' }]}
      >
        <CreatorsForm />
      </CardPage>
    );
  }
}

export default PageCreate;
