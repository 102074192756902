import React from 'react';
import {
  ColorResult,
  PhotoshopPicker,
} from 'react-color';

interface IPickerProps {
  onChange: (color: ColorResult) => void;
  onAccept: (color: ColorResult) => void;
  onCancel: (color: ColorResult) => void;
  color: string;
}

export default class Picker extends React.Component<IPickerProps> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <PhotoshopPicker
        {...this.props}
      />
    );
  }
}
