import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import CardPage from 'features/ui/Page/CardPage';
import { IUserSubscription } from '../types';
import UserSubscriptionForm from '../lib/UserSubscriptionForm';
import { makeUsersSubscriptionUrl } from '../lib/url';
import { FEATURE_ICON } from '../consts';

interface IPageCreateProps extends WithTranslation {
  feature: IUserSubscription;
}

class PageCreate extends React.Component<IPageCreateProps> {
  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('new_user_subscription')}
        crumbs={[{ link: makeUsersSubscriptionUrl(), name: t('user_subscriptions') }]}
      >
        <UserSubscriptionForm isCreateForm={true} />
      </CardPage>
    );
  }
}

export default withTranslation('user_subscriptions')(PageCreate);
