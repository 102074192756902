import React from 'react';
import moment from 'moment';
import i18n from 'features/intl/i18n';
import StatusSelect from 'features/orders/lib/StatusSelect';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import LinkToUser from 'features/users/lib/LinkToUser';
import UserSelect from 'features/users/lib/UserSelect';
import OrderStatus from 'features/orders/lib/OrderStatus';
import GoodsList from '../lib/GoodsList';

import { DATETIME_FORMAT_DEFAULT } from 'features/app/consts';

import { RowRenderProps } from 'features/types';
import { IOrder } from '../types';

export default () => ([
  {
    accessor: 'status',
    maxWidth: 150,
    Header: i18n.t('orders:status'),
    Cell: ({ value }: { value: string }) => <OrderStatus value={value} />,
    Filter: ({ filter, onChange }: any) => {
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);
      return (
        <StatusSelect
          onChange={handleChange}
          value={filter && filter.value}
        />
      );
    },
  },
  {
    accessor: (data: any) => data,
    expandable: true,
    id: 'email',
    maxWidth: 250,
    Header: i18n.t('users:email'),
    Cell: ({ value }: { value: IOrder }) => value.user ? <LinkToUser value={value.user} /> : null,
    Filter: ({ filter, onChange }: any) => {
      const handleChange = (value: string) => onChange(value);
      return (
        <UserSelect
          onChange={handleChange}
          value={filter && filter.value || ''}
        />
      );
    },
  },
  {
    id: 'items',
    minWidth: 120,
    expandable: true,
    Header: i18n.t('items:items'),
    accessor: (data: IOrder) => data.items && data.items.length ? <GoodsList items={data.items} /> : null,
    Filter: ({ filter, onChange }: any) => (
      <ItemsSelect
        onChange={onChange}
        value={filter && filter.value || ''}
        isMulti={false}
      />
    ),
  },
  {
    id: 'created_at',
    maxWidth: 170,
    filterable: false,
    Header: i18n.t('dict:created_at'),
    accessor: ({ created_at }: { created_at: string }) => created_at,
    Cell: (row: RowRenderProps) => {
      const date = moment(row.value, DATETIME_FORMAT_DEFAULT);
      return date.format('lll');
    },
  },
]);
