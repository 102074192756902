import React from 'react';
import OnOffIndicator from 'features/ui/OnOffIndicator';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { IInfoRowOptions } from './types';

export const InfoRowSwitch = (
  ({
    label,
    value,
    on,
    off,
    options,
  }: {
    label: string,
    value: boolean | null | undefined,
    on?: string,
    off?: string,
    options?: IInfoRowOptions,
  }) => {
    if (value === null || value === undefined) {
      return <InfoRowNotSet label={label} options={options} />;
    }
    return (
      <InfoRow label={label} {...options}>
        <OnOffIndicator value={value} on={on} off={off} />
      </InfoRow>
    );
  }
);
