import React from 'react';
import {
} from 'react-i18next';
import NotSet from 'features/ui/NotSet';
import { InfoRow } from './InfoRow';
import { IInfoRowOptions } from './types';

export const InfoRowNotSet = ({
  id,
  label,
  options,
}: {
  id?: string,
  label: string,
  options?: IInfoRowOptions,
}) => {
  return (
    <InfoRow label={label}>
      <NotSet />
    </InfoRow>
  );
};
