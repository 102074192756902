import React from 'react';
import { match as IMatch } from 'react-router';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import { AccessControl } from 'features/acl';
import { Page404 } from 'features/http';
import PageCreate from 'features/pushes/PageCreate';
import PageView from 'features/pushes/PageView';
import PagePushView from 'features/pushes/PagePushView';

export default ({ match }: { match: IMatch<any> }) => {
  return (
    <AccessControl permission="offers:access">
      <Switch>
        <AppRoute path="/pushes" menuId="pushes" component={PageView} exact />
        <AppRoute path="/pushes/create" menuId="pushes" component={PageCreate} exact />
        <AppRoute path="/pushes/:id" menuId="pushes" component={PagePushView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
  );
};
