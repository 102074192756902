import React from 'react';
import {
  Button,
  FormGroup,
  Input,
} from 'reactstrap';
import {
  difference,
  get,
  union,
} from 'lodash';
import { DocumentNode } from 'graphql';
import { toast } from 'features/ui/Toast';
import { Confirm } from 'features/ui/Modal';
import { client } from 'features/graphql';
import i18n from 'features/intl/i18n';
import { IItem } from 'features/types';

interface IItemsIdsTextareaProps {
  onAdd?: (ids: number[]) => void;
  onRemove?: (ids: number[]) => void;
  onAfterAddition?: () => void;
  onAfterRemoving?: () => void;
  showRemoveButton?: boolean;
  data?: any;
  mutation?: DocumentNode;
  query?: DocumentNode;
  entityName?: string;
  fieldName?: string;
}

interface IItemsIdsTextareaState {
  isRemoveModalOpened: boolean;
  areButtonsDisabled: boolean;
}

class ItemsIdsTextarea extends React.PureComponent<IItemsIdsTextareaProps, IItemsIdsTextareaState> {
  static defaultProps = {
    showRemoveButton: true,
  };

  inputRef: React.RefObject<HTMLInputElement>;

  private _isMounted: boolean;

  constructor(props: IItemsIdsTextareaProps) {
    super(props);
    this.inputRef = React.createRef();
    this._isMounted = false;
    this.state = {
      isRemoveModalOpened: false,
      areButtonsDisabled: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get ids() {
    return this.inputRef.current ? this.inputRef.current.value.split('\n').map(id => +id) : [];
  }

  format = (value: string) => {
    return value
      .replace(/[^\d,;\r\n ]/g, '')
      .split(/,| |;|\n/g)
      .map(id => id.replace(/[^\d]/g, ''))
      .filter(id => id)
      .join('\n');
  };

  onBlurTextarea = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.currentTarget.value = this.format(e.currentTarget.value);
  };

  onAdd = () => {
    const {
      onAdd,
    } = this.props;
    onAdd ? onAdd(this.ids) : this.handleAddItems(this.ids);
  };

  onRemove = () => {
    const { onRemove } = this.props;
    onRemove ? onRemove(this.ids) : this.handleRemoveItems(this.ids);
  };

  onOpenRemoveModal = () => {
    this.setState({ isRemoveModalOpened: true });
  };

  onCloseRemoveModal = () => {
    this.setState({ isRemoveModalOpened: false });
  }

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = e.target.value;
    this.setState({ areButtonsDisabled: !data });
  }

  handleAddItems = (newIds: number[]) => {
    const {
      mutation,
      query,
      data,
      entityName,
      fieldName,
      onAfterAddition,
    } = this.props;
    if (!(query && mutation && data && entityName && fieldName)) {
      return;
    }
    const items = data[fieldName].map((item: IItem) => +item.id);
    const ids = union(items, newIds);
    const amount = ids.length - items.length;

    return client.mutate({
      mutation,
      context: { isGlobalLoading: true },
      variables: {
        id: data.id,
        [entityName]: { [fieldName]: ids },
      },
      refetchQueries: [{ query, variables: { id: data.id } }],
    }).then(() => {
      toast.info(i18n.t('forms:amount_of_added_records', { amount }));
      if (this._isMounted && onAfterAddition) {
        onAfterAddition();
      }
    }).catch((result) => {
      const errorText = get(result, `graphQLErrors[0].validation["${entityName}.${fieldName}"][0]`, null);
      if (errorText) {
        toast.error(errorText);
      }
    });
  };

  handleRemoveItems = (removeIds: number[]) => {
    const {
      mutation,
      query,
      data,
      entityName,
      fieldName,
      onAfterRemoving,
    } = this.props;
    if (!(query && mutation && data && entityName && fieldName)) {
      return;
    }
    const items = data[fieldName].map((item: IItem) => +item.id);
    const ids = difference(items, removeIds);
    const amount = difference(items, ids).length;

    return client.mutate({
      mutation,
      context: { isGlobalLoading: true },
      variables: {
        id: data.id,
        [entityName]: { [fieldName]: ids },
      },
      refetchQueries: [{ query, variables: { id: data.id } }],
    }).then(() => {
      toast.info(i18n.t('forms:amount_of_deleted_records', { amount }));
      if (this._isMounted && onAfterRemoving) {
        onAfterRemoving();
      }
    });
  };

  renderRemoveButton() {
    const { showRemoveButton } = this.props;
    const { areButtonsDisabled } = this.state;
    if (showRemoveButton) {
      return (
        <Button
          className="btn-danger"
          onClick={this.onOpenRemoveModal}
          disabled={areButtonsDisabled}
        >
          Удалить
        </Button>
      );
    }
    return null;
  }

  render() {
    const {
      isRemoveModalOpened,
      areButtonsDisabled,
    } = this.state;
    return (
      <React.Fragment>
        <FormGroup>
          <Input
            innerRef={this.inputRef}
            style={{ height: 200 }}
            type="textarea"
            placeholder="Например: 123, 234, 345"
            onChange={this.onChangeInput}
            onBlur={this.onBlurTextarea}
          />
        </FormGroup>
        <Button
          className="btn-success"
          onClick={this.onAdd}
          disabled={areButtonsDisabled}
        >
          Добавить
        </Button>
        {this.renderRemoveButton()}
        <Confirm
          title={'Удалить Итемы?'}
          type="warning"
          isOpened={isRemoveModalOpened}
          onClose={this.onCloseRemoveModal}
          onResolve={this.onRemove}
        />
      </React.Fragment>
    );
  }
}

export default ItemsIdsTextarea;
