import React from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import ItemForm from 'features/items/lib/Form';
import BaseForm from './Form';

import getEventQuery from 'features/events/queries/getEventQuery.gql';

import {
  IFormValues,
} from 'features/books/types';
import {
  IFormValues as IItemFormValues,
  IItemMutationInput,
} from 'features/items/types';
import {
  IItem,
  IYupValidationFields,
} from 'features/types';

interface IEventsFormProps extends WithTranslation {
  data?: IItem;
}

class EventsForm extends React.PureComponent<IEventsFormProps> {
  resolveMutationData = (values: IItemMutationInput) => values;

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: IYupValidationFields) => {
    const { t } = this.props;
    return {
      ...shape,
      event: Yup.object().shape({
        duration: Yup.number().positive(t('positive_value')),
        finished_at: Yup.string().required(t('forms:required_field', { field: t('finished_at') })),
        source: Yup.string().required(t('forms:required_filed', { field: t('source') })),
        link: Yup.string().required(t('forms:required_field', { field: t('event_link_required') })),
        started_at: Yup.string().required(t('forms:required_field', { field: t('started_at') })),
        is_all_speakers: Yup.boolean(),
        is_autorecord: Yup.boolean(),
      }),
      chat: Yup.object(),
    };
  };

  resolveInitialValues = (values: IItemFormValues) => {
    const { data } = this.props;

    const defaultValues = {
      event: {
        duration: 0,
        finished_at: '',
        link: '',
        source: '',
        started_at: '',
        is_all_speakers: false,
        is_autorecord: false,
      },
      chat: {
        moderators: [],
        speakers: [],
        blockedUsers: [],
      },
    };

    return data ? {
      ...defaultValues,
      ...values,
      ...{
        event: {
          duration: data.event!.duration || 0,
          finished_at: data.event!.finished_at || '',
          link: data.event!.link || '',
          source: data.event!.source || '',
          started_at: data.event!.started_at || '',
          is_all_speakers: data.event!.is_all_speakers || false,
          is_autorecord: data.event!.is_autorecord || false,
        },
        chat: {
          id: data.chat.id || null,
          moderators: data.chat.moderators.length ? data.chat.moderators.map((moder: { id: number; }) => moder.id) : null,
          speakers: data.chat.speakers.length ? data.chat.speakers.map((speaker: { id: number; }) => speaker.id) : null,
          blockedUsers: data.chat.blockedUsers.length ? data.chat.blockedUsers.map((blocked: { id: number; }) => blocked.id) : null,
        },
      },
    } : {
      ...defaultValues,
      ...values,
    };
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="event"
        data={data}
        getItemQuery={getEventQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        renderForm={this.renderForm}
      />
    );
  }
}

export default withTranslation('events')(EventsForm);
