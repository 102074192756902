import React from 'react';
import { Input } from 'reactstrap';

export interface IYesNoSelectProps {
  onChange: any;
  value: any;
  yesCaption?: string;
  noCaption?: string;
}

const YesNoSelect: React.StatelessComponent<IYesNoSelectProps> = (props: IYesNoSelectProps) => {
  const {
    value,
    onChange,
    yesCaption,
    noCaption,
  } = props;
  return (
    <Input
      type="select"
      onChange={onChange}
      value={value}
    >
      <option value="">-</option>
      <option value={1}>{yesCaption}</option>
      <option value={0}>{noCaption}</option>
    </Input>
  );
};

YesNoSelect.defaultProps = {
  yesCaption: 'да',
  noCaption: 'нет',
};

export default YesNoSelect;
