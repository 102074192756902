import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  InfoRow,
  InfoRowImage,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import NotSet from 'features/ui/NotSet';
import LinkToOffer from 'features/offers/lib/LinkToOffer';
import { createView } from 'features/common';
import LinkToItems from '../lib/LinkToItems';
import {
  makeBadgeUrl,
  makeBadgeUrlTo,
} from '../lib/url';
import getEntity from '../queries/getBadge.gql';

import { FEATURE_ICON } from 'features/badges/consts';

import { IBadge } from '../types';

const View = createView<IBadge>(getEntity, 'BadgeQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderOwnerOffer(entity: IBadge) {
    if (entity.ownerOffer) {
      return <LinkToOffer value={entity.ownerOffer} action="view" />;
    }
    return <NotSet />;
  }

  renderChildren = (entity: IBadge) => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <LinkBlock editLink={makeBadgeUrlTo(entity, 'edit')} />
        <InfoRowText label="ID" value={entity.id} />
        <InfoRowText label={t('name')} value={entity.name} />
        <InfoRowImage label={t('image')} value={get(entity, 'image.path', null)} />
        <InfoRow label={t('ownerOffer')}>
          {this.renderOwnerOffer(entity)}
        </InfoRow>
        <InfoRow label={t('items:items')}>
          <LinkToItems badge={entity} />
        </InfoRow>
        <hr />
        <InfoRowText label={t('dict:updated')} value={moment(entity.updated_at).format('ll')} />
        <InfoRowText label={t('dict:created')} value={moment(entity.created_at).format('ll')} />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField="name"
        icon={FEATURE_ICON}
        crumbs={[{ link: makeBadgeUrl(), name: t('badges') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('badges')(PageView);
