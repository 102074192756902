/**
 * Нормализует данные для отправки на сервер.
 *
 * Пустые строки должны преобразовываться в null.
 */

export function toStr(v: string) {
  return v === '' ? null : v.trim();
}

export function toInt(v: string) {
  return (v === '') ? null : parseInt(v, 10);
}

export function asIs(v: any) {
  return v;
}
