import React from 'react';
import CardPage from 'features/ui/Page/CardPage';
import CreatorsTable from 'features/creators/lib/CreatorsTable';
import { FEATURE_ICON } from 'features/creators/consts';
import { makeCreatorUrl } from 'features/creators/lib/url';

class PageCreators extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        title="Creators"
        icon={FEATURE_ICON}
        buttons={[{ link: makeCreatorUrl({ action: 'create' }), name: 'Создать Создателя!' }]}
      >
        <CreatorsTable />
      </CardPage>
    );
  }
}

export default PageCreators;
