import React from 'react';
import LinkToEntityInLists from 'features/ui/LinkToEntityInLists';
import { makeUserUrlTo } from '../url';
import { IUser } from '../../types';

export default ({
  value,
  action,
}: {
  value: IUser,
  action?: string,
}) => (
  <LinkToEntityInLists
    id={value.id}
    name={value.email}
    to={makeUserUrlTo(value, action)}
  />
);
