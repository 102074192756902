import React from 'react';

export default ({ value }: { value: string }) => {
  return (
    <React.Fragment>
      <svg style={{ height: '1em' }} viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="47" fill={value} stroke="none" />
      </svg>
      &nbsp;
      <span>{value}</span>
    </React.Fragment>
  );
};
