import React from 'react';
import { InfoRow } from './InfoRow';
import { InfoRowNotSet } from './InfoRowNotSet';
import { IInfoRowOptions } from './types';

export const InfoRowCost = ({
  label,
  value,
  options,
}: {
  label: string,
  value: number | null,
  options?: IInfoRowOptions,
}) => {
  if (value === null) {
    return <InfoRowNotSet label={label} options={options} />;
  }
  return (
    <InfoRow label={label} {...options}>
      <React.Fragment>
        {value.toFixed(2)}
        &nbsp;
        <i className="fa fa-ruble" />
      </React.Fragment>
    </InfoRow>
  );
};
