import { IFormValues as IItemsFormValues, IItem } from 'features/items/types';

export enum EventSourceEnum {
  vk = 'vk',
  webinar = 'webinar',
  self_streaming = 'self_streaming',
}

export interface IEvent {
  duration: number;
  finished_at: string;
  link: string;
  source: EventSourceEnum;
  started_at: string;
  is_all_speakers: boolean;
  is_autorecord: boolean;
}

export interface IChat {
  id: number;
  moderators: {
    id: number;
    email: string;
  };
  blockedUsers: {
    id: number;
    email: string;
  };
  speakers: {
    id: number;
    email: string;
  };
}

export interface IFormValues extends IItemsFormValues {
  event: IEvent;
  chat: IChat;
}
