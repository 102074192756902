import moment from 'moment';
import i18n from 'features/intl/i18n';
import { RowRenderProps } from 'react-table';
import IsActiveColumn from 'features/ui/IsActiveColumn';

export default () => [
  {
    Cell: IsActiveColumn,
    Header: i18n.t('features:is_active_column'),
    accessor: (data: any) => data.is_active,
    filterable: false,
    id: 'is_active',
    maxWidth: 100,
  },
  {
    Header: i18n.t('features:name_column'),
    accessor: 'name',
    filterable: true,
    minWidth: 100,
  },
  {
    Cell: (props: RowRenderProps) => {
      return props.value ? moment(props.value).format('YYYY-MM-DD') : null;
    },
    Header: i18n.t('features:begin_date_column'),
    accessor: 'begin_date',
    filterable: false,
    minWidth: 100,
  },
];
