import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import field from 'lib/field';
import filterChangedValues from 'lib/filterChangedValues';
import { crudMutate } from 'features/common/helpers';
import BaseForm from './Form';

import getUserGroupQuery from 'features/usergroups/queries/getUserGroupQuery.gql';
import updateUserGroupMutation from 'features/usergroups/queries/updateUserGroupMutation.gql';
import createUserGroupMutation from 'features/usergroups/queries/createUserGroupMutation.gql';

import { IFormValues } from 'features/usergroups/types';
import { IUserGroup } from 'features/types';

interface IFormProps extends WithTranslation {
  data?: IUserGroup;
}

class Form extends React.PureComponent<IFormProps> {
  onSubmit = (values: IFormValues, formActions: FormikActions<IFormValues>) => {
    const { data } = this.props;
    const id = data && data.id;
    const userGroup = Boolean(data)
      ? filterChangedValues(this.buildInitialFormData(), values)
      : { ...values };
    crudMutate({
      id,
      formActions,
      mutation: id ? updateUserGroupMutation : createUserGroupMutation,
      variables: id ? { id, userGroup } : { userGroup },
      redirect: '/usergroups',
      updateRefetchQuery: getUserGroupQuery,
      check: Boolean(Object.keys(userGroup).length),
    });
  };

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape(Object.assign({
      name: Yup.string().required(t('forms:required_field', { field: t('name') })),
      alias: Yup.string().required(t('forms:required_field', { field: t('alias') })),
      offer: Yup.number()
        .positive()
        .min(1, t('forms:required_field', { field: t('offer') }))
        .integer(),
    }));
  };

  buildInitialFormData = () => {
    const { data } = this.props;
    return {
      name: field(data, 'name', ''),
      alias: field(data, 'alias', ''),
      offer: field(data, 'offer.id', '0'),
    };
  };

  render() {
    return (
      <Formik
        validationSchema={this.validationSchema()}
        enableReinitialize
        initialValues={this.buildInitialFormData()}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('usergroups')(Form);
