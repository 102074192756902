import React from 'react';
import LinkBlock from 'features/ui/LinkBlock';
import Form from 'features/hybrids/lib/Form';
import PageEdit from 'features/items/PageEdit';
import { makeItemUrlTo } from 'features/items/lib/url';
import { graphqlById } from 'features/graphql/helpers';
import getHybridQuery from 'features/hybrids/queries/getHybridQuery.gql';
import { FEATURE_ICON } from 'features/hybrids/consts';
import {
  IBasePageEditProps,
  IItem,
  IItemQueryResponse,
} from 'features/types';

class BasePageEdit extends React.PureComponent<IBasePageEditProps<IItem>> {
  renderForm(data: IItem) {
    return (
      <React.Fragment>
        <LinkBlock viewLink={makeItemUrlTo(data, 'view')} />
        <Form data={data} />
      </React.Fragment>
    );
  }

  render() {
    const { data } = this.props;

    return (
      <PageEdit
        i18ns="hybrids"
        icon={FEATURE_ICON}
        {...this.props}
      >
        {data ? this.renderForm(data) : null}
      </PageEdit>
    );
  }
}

export default graphqlById<IItemQueryResponse, IBasePageEditProps<IItem>>({
  query: getHybridQuery,
  queryType: 'ItemQuery',
})(BasePageEdit);
