import {
  ErrorResponse,
  onError,
} from 'apollo-link-error';
import AuthService from 'features/auth/AuthService';
import { toast } from 'features/ui/Toast';
import { logError } from 'features/errors';

const errorLink = onError((data: ErrorResponse) => {
  logError('errorLink', data);
  const error = data.networkError;
  const gqlErrors = data.graphQLErrors;
  if (gqlErrors && gqlErrors.length) {
    gqlErrors.forEach(({
      extensions,
      message,
      locations,
      path,
    }) => {
      if (extensions && extensions.category && extensions.category !== 'graphql') {
        const errorExtentions = extensions[extensions.category];
        try {
          Object.keys(errorExtentions).forEach((keyName) => {
            toast.error(errorExtentions[keyName][0]);
          });
        } catch (err) {
          global.console.error(`[Error]: ${err}`);
        }
      } else {
        toast.error(`Ошибка: ${message}`);
        global.console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      }
    });
  }
  if (error) {
    global.console.log(`[Network error]: ${error}`, JSON.stringify(error), data);
    // 429 too many request
    if (error.statusCode === 429 || (error.response && error.response.status === 429)) {
      return toast.error(data.graphQLErrors[0].message);
    }
    if (error.statusCode === 401 || (error.response && error.response.status === 401)) {
      AuthService.logout();
    } else {
      toast.error('Ошибка сетевого запроса');
    }
  }
});

export default errorLink;
