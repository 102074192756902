import crudUrlBuilder from 'lib/crudUrlBuilder';
import { ICategory } from '../../types';

export const makeCategoryUrl = crudUrlBuilder('category', 'categories');

export const makeCategoryUrlTo = (user: ICategory, action: string = 'view') => {
  return makeCategoryUrl({
    action,
    id: user.id,
  });
};
